import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from 'farmx-redux-core';
import i18n from 'i18next';
import { setMomentLocale } from './helper/common';

export function useLanguageSetting() {
  const dispatch = useDispatch();
  const userSettings = useSelector((state) => selectors.selectUserSettings(state));

  useEffect(() => {
    dispatch(actions.loadUserSettings());
  }, [dispatch]);

  useEffect(() => {
    if (userSettings) {
      setMomentLocale(userSettings.language);
      if (userSettings.language === 'system') {
        localStorage.removeItem('i18nextLng');
        i18n.changeLanguage();
      } else {
        i18n.changeLanguage(userSettings.language);
      }
    }
  }, [dispatch, userSettings]);

  return null;
}
