import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { SensorAlarmButton } from 'farmx-web-ui';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from 'farmx-redux-core';
import { CardLayout } from '../../components/CardLayout';
import PumpStateText from '../../components/PumpStateText';
import ValveStateText from '../../components/ValveStateText';
import IrrigationStatus from '../../components/IrrigationStatus';
import { getPumpAndValveCounts } from '../../../../helper/controlHelper';

const {
  selectBlockControlStatus,
  selectSensorsForRanchId,
  selectBlockValveControllers,
  selectBlockPumpControllers,
} = selectors;

const { loadBlockControlStatus, loadSensorStatus } = actions;

export default function ControlSummaryListItem(props) {
  const { block } = props;
  const { Title } = Typography;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadBlockControlStatus(block.id));
  }, [block.id, dispatch]);

  let ranchIdForBlock = block.ranchId;
  if (block.ranchId && block.ranchId.id) {
    ranchIdForBlock = block.ranchId.id;
  }

  const allValves = useSelector((state) => selectSensorsForRanchId(state, ranchIdForBlock, 'valve'))
    .filter((d) => d && d.visible);

  const pumpIdentifiers = JSON.stringify(block.vfds || []);
  const valves = block.valves || [];

  const controlStatus = useSelector((state) => selectBlockControlStatus(state, [block.id]));
  const pumpController = useSelector(
    (state) => selectBlockPumpControllers(state, block.id),
  );

  const valveController = useSelector(
    (state) => selectBlockValveControllers(state, block.id),
  );

  const valveIdentifiers = JSON.stringify(allValves.filter((d) => d && valves.includes(d.id))
    .map((d) => ({ identifier: d.identifier, type: d.type })));

  useEffect(() => {
    if (JSON.parse(valveIdentifiers).length) {
      JSON.parse(valveIdentifiers).forEach((param) => {
        dispatch(loadSensorStatus(param));
      });
    }
  }, [dispatch, valveIdentifiers]);

  useEffect(() => {
    if (JSON.parse(pumpIdentifiers).length) {
      JSON.parse(pumpIdentifiers).forEach((param) => {
        dispatch(loadSensorStatus(param));
      });
    }
  }, [dispatch, pumpIdentifiers]);

  const pumpStatus = controlStatus[block.id].vfdStatus || {};
  const valveStatus = controlStatus[block.id].valveStatus || {};
  const statusObj = {
    blockId: block.id,
    pumpCurrentState: (pumpStatus.controlStatus && pumpStatus.controlStatus.status) || '',
    valveCurrentState: (valveStatus.controlStatus && valveStatus.controlStatus.state) || '',
    blockName: block.name,
    alarmCount: valveStatus.alarmCount,
    irrigationStartDate: valveStatus.scheduleStatus && valveStatus.scheduleStatus.dateScheduledOpen,
    irrigationEndDate: valveStatus.scheduleStatus && valveStatus.scheduleStatus.dateScheduledClose,
    pumpIdentifiers: JSON.parse(pumpIdentifiers),
    valveIdentifiers: JSON.parse(valveIdentifiers),
  };

  const pumpAndValveCountObj = getPumpAndValveCounts(pumpController, valveController, statusObj);
  const blockObj = { ...statusObj, ...pumpAndValveCountObj };

  return (
    <CardLayout>
      <div className="title-and-alarm-container">
        <Title level={4}>{blockObj.blockName}</Title>
        <SensorAlarmButton
          isMobileView
          alarmCount={blockObj.alarmCount}
          type={valveStatus.type || ''}
          identifier={valveStatus.identifier || ''}
        />
      </div>
      <div className="pump-status-container">
        {(block.vfd || (block.vfds && block.vfds.length))
          ? (
            <PumpStateText
              state={blockObj.pumpCurrentState}
              multipleStates={blockObj.multiplePumps}
            />
          ) : null}
      </div>
      <div className="pump-status-container">
        {(block.valve || (block.valves && block.valves.length))
          ? (
            <ValveStateText
              state={blockObj.valveCurrentState}
              multipleStates={blockObj.multipleValves}
            />
          ) : null}
      </div>
      <IrrigationStatus
        block={blockObj}
      />
    </CardLayout>
  );
}

ControlSummaryListItem.propTypes = {
  block: PropTypes.objectOf(Object).isRequired,
};
