import React from 'react';
import PropTypes from 'prop-types';
import NotificationItem from './NotificationItem';

function NotificationGroupFlat(props) {
  const { activityGroup } = props;
  const { activities } = activityGroup;
  const latestActivity = activities[0];

  return (
    <NotificationItem
      activity={latestActivity}
    />
  );
}

NotificationGroupFlat.propTypes = {
  activityGroup: PropTypes.shape({
    activities: PropTypes.array,
  }).isRequired,
};

export default NotificationGroupFlat;
