import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import './connectivityStateTag.css';

export default function ConnectivityStateTag(props) {
  const { t } = useTranslation();
  const { color, label, isOnline } = props;

  const offline = t('offline').toUpperCase();
  const online = t('online').toUpperCase();

  const displayLabel = label || (isOnline ? online : offline);
  const displayColor = color || (isOnline ? 'success' : 'default');

  return (
    <Tag
      className="connectivity-state-tag"
      style ={{ outline: 'none' }}  // Writing CSS class name not working, so added inline-style
      color={displayColor}
    >
      {displayLabel}
    </Tag>
  );
}

ConnectivityStateTag.defaultProps = {
  color: '',
  label: '',
  isOnline: false,
};

ConnectivityStateTag.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  isOnline: PropTypes.bool,
};
