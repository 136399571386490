import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions, selectors } from 'farmx-redux-core';
import {
  Typography,
  Radio,
  Switch,
  Space,
} from 'antd';
import './map-settings.css';

const {
  setShowOnlyPresentionModeSensors,
  setMapPresentationModes,
  setMapShowSoilType,
  setMapShowTemperature,
  setMapShowWind,
} = actions;

const {
  selectMapShowTemperature,
  selectMapShowWind,
} = selectors;

export const presModeTitles = {
  irrigationRecommendation: 'Irrigation Recommendation',
  soilstatus: 'Soil Moisture State',
  connectivity: 'Connectivity',
  waterpressure: 'Water Pressure',
  control: 'Control',
  provision: 'Provision',
  anomaly: 'Anomalies',
};

export function MapSettings({
  presMode,
  selMapPresentationModes,
  selShowAllSensors,
  selMapShowSoilType,
  onChange,
  isImageryDataVisible,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userInfo = useSelector(selectors.selectLoginUserInfo).payload;
  const isAdmin = (userInfo && userInfo.admin);

  const availableModes = [
    'soilstatus',
    'connectivity',
    'waterpressure',
    'control',
    'irrigationRecommendation',
    'anomaly',
  ];

  if (isAdmin) {
    availableModes.push('provision');
  }

  const showTemperatureOverlay = useSelector(selectMapShowTemperature);
  const showWindOverlay = useSelector(selectMapShowWind);

  const toggleTemperatureOverlay = useCallback((e) => {
    dispatch(setMapShowTemperature(e));
  }, [dispatch]);

  const toggleWindOverlay = useCallback((e) => {
    dispatch(setMapShowWind(e));
  }, [dispatch]);

  return (
    <div className="map-settings-container">
      <Typography.Title level={4}>
        {t('Select Map View')}
      </Typography.Title>

      <div className="flex-row map-settings-row">
        <Radio.Group
          size="large"
          onChange={({ target: { value } }) => {
            dispatch(setMapPresentationModes({ [value]: true }));
          }}
          value={presMode}
        >
          <Space direction="vertical">
            {Object
              .keys(selMapPresentationModes)
              // exclude modes not yet implemented
              .filter((mode) => availableModes.includes(mode))
              .map((mode) => (
                <Radio key={mode} value={mode} size="large">
                  {t(presModeTitles[mode])}
                </Radio>
              ))}
          </Space>
        </Radio.Group>
      </div>

      <div className="margin-top-10">
        <Typography.Title level={4}>
          {t('Select Map Overlays')}
        </Typography.Title>
      </div>

      <div className="flex-row map-settings-row">
        <div className="flex-column switch-box">
          <div className="flex-row padding-bottom-10">
            <Switch
              checked={selMapShowSoilType}
              onChange={(v) => {
                dispatch(setMapShowSoilType(v));
              }}
            />
            <div className="margin-left-10">
              <Typography.Text>
                {t('Soil Type')}
              </Typography.Text>
            </div>
          </div>
          <div className="flex-row padding-bottom-10">
            <Switch
              checked={isImageryDataVisible}
              onChange={(v) => {
                if (onChange) onChange(v);
              }}
            />
            <div className="margin-left-10">
              <Typography.Text>
                {t('Show Imagery')}
              </Typography.Text>
            </div>
          </div>

          <div className="flex-row padding-bottom-10">
            <Switch checked={showTemperatureOverlay} onChange={toggleTemperatureOverlay} />
            <div className="margin-left-10">
              <Typography.Text>
                {`${t('Temperature')}/${t('Weather')}`}
              </Typography.Text>
            </div>
          </div>

          <div className="flex-row padding-bottom-10">
            <Switch checked={showWindOverlay} onChange={toggleWindOverlay} />
            <div className="margin-left-10">
              <Typography.Text>
                {t('Wind Speed')}
              </Typography.Text>
            </div>
          </div>

          {/* <div className="flex-row padding-bottom-10">
            <Switch
              checked={selMapShowLabels}
              onChange={(v) => {
                dispatch(setMapShowLabels(v));
              }}
            />
            <div className="margin-left-10">
              <Typography.Text>
                {t('Show Labels')}
              </Typography.Text>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

MapSettings.propTypes = {
  presMode: PropTypes.string.isRequired,
  selMapPresentationModes: PropTypes.object.isRequired,
  selShowAllSensors: PropTypes.bool.isRequired,
  selMapShowSoilType: PropTypes.bool.isRequired,
  selMapShowLabels: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isImageryDataVisible: PropTypes.bool.isRequired,
};
