import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';
import {
  Button, Tooltip, Typography, Switch, Drawer, Grid,
} from 'antd';
import { icons } from 'farmx-web-ui';
import { FaTimes } from 'react-icons/fa';
import { selectors, hooks } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { SensorDetailHeader } from './SensorDetailHeader';
import { SensorDetailBody } from './SensorDetailBody';
import { BlockRecommendationSummary } from '../home/BlockRecommendationSummary';
import { SatelliteImageryWrapper } from './SatelliteImageryWrapper';
import { renderAnomalyInfo } from '../../../helper/mapHelper';
import 'react-spring-bottom-sheet/dist/style.css';
import { getBlockArea } from '../../../helper/block';

// This will be replaced with correct logic
const headerHeight = 60;
const isApp = matchMedia('(display-mode: standalone)').matches;
const safeAreaTop = isApp ? 48 : 0;
const safeAreaBottom = isApp ? 34 : 0;
const padding = 20;

const {
  selectRanchById,
  selectBlockById,
} = selectors;

function getTitleForDrawer(selectedFeature, expandedGeoJSON) {
  if (selectedFeature && selectedFeature?.properties) {
    return selectedFeature.properties.name;
  }
  if (expandedGeoJSON?.features && expandedGeoJSON.features.length) {
    return expandedGeoJSON.features[0].properties.name;
  }
  return 'Details';
}

export default function ShowDetails({
  presentationMode,
  isBottomSheetVisible,
  selectedFeature,
  dispatchMap,
  sheetRef,
  bottomSheetType,
  isImageryPanelVisible,
  selectedObjFromState,
  selectedImageryData,
  isExpandBtnVisible,
  expandedGeoJSON,
  ranchId,
  blockId,
  isMobile,
}) {
  const { t } = useTranslation();

  const ranch = useSelector((state) => selectRanchById(state, ranchId));
  const block = useSelector((state) => selectBlockById(state, blockId));
  const ranchArea = getBlockArea(ranch);
  const blockArea = getBlockArea(block);
  const getUserUnits = hooks.useUnits();
  const userRanchArea = getUserUnits(ranchArea, 'square_meters', 'area') || { value: '', label: '' };
  const userBlockArea = getUserUnits(blockArea, 'square_meters', 'area') || { value: '', label: '' };
  const displayRanchArea = `${userRanchArea.value} ${userRanchArea.label}`;
  const displayBlockArea = `${userBlockArea.value} ${userBlockArea.label}`;
  // Declared variables with conditions to avoid repeating codes
  const isSensor = bottomSheetType === 'sensor';
  const isSettings = bottomSheetType === 'settings';
  const isAnomaly = bottomSheetType === 'anomaly';
  const CONDITION_CHECK_1 = !isSettings && !isAnomaly && !selectedFeature && isBottomSheetVisible
    && (!isImageryPanelVisible || !isMobile);
  const CONDITION_CHECK_2 = !isSettings && !isAnomaly && !isSensor && !selectedFeature
    && !isImageryPanelVisible;
  const CONDITION_CHECK_3 = (isSettings && selectedImageryData.visible);
  const CONDITION_CHECK_4 = !isSettings && isImageryPanelVisible;
  const CONDITION_CHECK_5 = isAnomaly && selectedFeature;

  function renderRanchInfo(obj) {
    return (
      <div className="flex-column margin-top-10 ranch-map-detail sensor-detail-header">
        <div className="sensor-header-type">{obj && obj.crop}</div>
        <Typography.Text>{displayRanchArea}</Typography.Text>
      </div>
    );
  }

  function renderBlockInfo(obj) {
    if (obj == null) return null;
    return (
      <div className="flex-column margin-top-10 block-map-detail">
        <div className="sensor-header-type">{obj && obj.crop}</div>
        <Typography.Text>{displayBlockArea}</Typography.Text>
        <BlockRecommendationSummary block={obj} />
      </div>
    );
  }

  function bottomSheetHeaderTooltip(text) {
    return (
      <Tooltip // 28 is maximum text length to display.
        title={text.length > 28 ? text : ''}
        trigger="click"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        <div className="sensor-header-name sensor-header-name-ellipsis">{text}</div>
      </Tooltip>
    );
  }

  function getAnomalyHeader() {
    const anomalyHeader = selectedFeature?.properties
      ? `${selectedFeature.name} - ${selectedFeature.properties.anomaly_type}`
      : null;
    return bottomSheetHeaderTooltip(anomalyHeader);
  }

  function renderImageryToggle() {
    return (
      <div className="satellite-imagery-toggle-switch">
        <Switch
          checked={selectedImageryData.visible}
          onChange={(v) => {
            dispatchMap({
              type: 'setSelectedImageryData',
              payload: {
                visible: v,
                data: selectedImageryData.data,
              },
            });
          }}
        />
        <div className="margin-left-10 text-imagery">
          <Typography.Text>
            {t('Show Imagery')}
          </Typography.Text>
        </div>
      </div>
    );
  }

  function renderRanchBlockHeader(type) {
    if (type === 'ranch') {
      return CONDITION_CHECK_2 && ranch && bottomSheetHeaderTooltip(ranch.name);
    }
    if (type === 'block') {
      return CONDITION_CHECK_2 && block && bottomSheetHeaderTooltip(block.name);
    }
    return null;
  }

  function bottomSheetHeader() {
    const { name, type } = (selectedFeature?.properties) || {};
    const anomalyHeader = selectedFeature?.properties
      ? `${selectedFeature.name} - ${selectedFeature.properties.anomaly_type}`
      : null;

    return (
      <div className="bottom-sheet-header-container">
        <div className="sensor-detail-header">
          <div className="flex-row">
            {isSensor && !!selectedFeature?.properties
              && !isSettings && !isImageryPanelVisible
              && name && type && (
                <>
                  <div className="sensor-header-icon-block">
                    <i className={icons[type] ? icons[type] : icons.cavalier} />
                  </div>
                  {bottomSheetHeaderTooltip(name)}
                </>
            )}
            {renderRanchBlockHeader(selectedObjFromState?.type)}

            {CONDITION_CHECK_5 && !isImageryPanelVisible && bottomSheetHeaderTooltip(anomalyHeader)}

            {CONDITION_CHECK_4 && renderImageryToggle()}
          </div>
        </div>
        <div className="bottom-sheet-header">
          <Button
            shape="circle"
            icon={<FaTimes />}
            onClick={() => {
              dispatchMap({
                type: 'setSelectedFeatureWithType',
                payload: {
                  selectedFeature: undefined,
                  bottomSheetType: undefined,
                  showBottomSheet: !!CONDITION_CHECK_3,
                },
              });
              dispatchMap({
                type: 'setIsImageryPanelVisible',
                payload: !!CONDITION_CHECK_3,
              });
            }}
          />
        </div>
      </div>
    );
  }

  function renderSatelliteImagery() {
    return CONDITION_CHECK_4
      && (
        <SatelliteImageryWrapper
          ranchId={ranchId}
          blockId={blockId}
          dispatchMap={dispatchMap}
          imageryVisible={selectedImageryData.visible}
        />
      );
  }

  function onDismissBottomSheet() {
    dispatchMap({
      type: 'setIsBottomSheetVisible',
      payload: false,
    });
    dispatchMap({
      type: 'setSelectedFeatureWithType',
      payload: {
        selectedFeature: undefined,
        bottomSheetType: undefined,
        showBottomSheet: false,
      },
    });
    dispatchMap({
      type: 'setIsImageryPanelVisible',
      payload: false,
    });
  }

  function onClosesDrawer() {
    dispatchMap({
      type: 'setSelectedFeatureWithType',
      payload: {
        selectedFeature: undefined,
        bottomSheetType: undefined,
        showBottomSheet: false,
      },
    });
  }

  function getSnapPoints(maxHeight, height) {
    if (isSettings) {
      return [maxHeight - (headerHeight + safeAreaTop)];
    }

    const satelliteDiv = document.getElementById('satellite-imagery-section');
    const satelliteDivHeight = (satelliteDiv && satelliteDiv.offsetHeight);
    if (CONDITION_CHECK_4) {
      if (isApp) return [satelliteDivHeight + safeAreaTop + safeAreaBottom + padding];
      return [(satelliteDivHeight + headerHeight + padding)];
    }

    const previewSize = maxHeight - ((maxHeight - 250) - safeAreaBottom);
    if (height > previewSize && isExpandBtnVisible) {
      dispatchMap({
        type: 'setIsExpandBtnVisible',
        payload: false,
      });
    } else if (height <= previewSize && !isExpandBtnVisible) {
      dispatchMap({
        type: 'setIsExpandBtnVisible',
        payload: true,
      });
    }
    return [
      maxHeight - (headerHeight + safeAreaTop),
      previewSize,
    ];
  }

  function renderRanchBlockInfo(type) {
    if (type === 'ranch') {
      return CONDITION_CHECK_1 && renderRanchInfo(ranch);
    }
    if (type === 'block') {
      return CONDITION_CHECK_1 && block && renderBlockInfo(block);
    }
    return null;
  }
  const renderDetails = () => (
    <div className={!isMobile ? 'bottomsheet-block desktop-sidebar-details' : ''}>
      {isSensor && !!selectedFeature?.properties && !isMobile && (
        <SensorDetailHeader sensorProperties={selectedFeature.properties} />
      )}
      {isSensor && (!isImageryPanelVisible || !isMobile)
        && !!selectedFeature?.properties && (
          <SensorDetailBody sensorProperties={selectedFeature.properties} />
      )}
      {renderRanchBlockInfo(selectedObjFromState?.type)}
      {CONDITION_CHECK_5 && renderAnomalyInfo(selectedFeature, t)}
    </div>
  );

  const renderDrawerTitle = () => (
    <div className="sensor-detail-header map-desktop-sensor-header-detail">
      <div className="flex-row">
        {isSensor && !!selectedFeature?.properties
          && !isSettings
          && selectedFeature.properties.name && selectedFeature.properties.type && (
            <>
              <div className="sensor-header-icon-block">
                <i className={icons[selectedFeature.properties.type]
                  ? icons[selectedFeature.properties.type] : icons.cavalier}
                />
              </div>
            </>
        )}
        <div className="map-desktop-sensor-header">
          <Typography.Text>
            <strong>
              {!isAnomaly && selectedFeature && getTitleForDrawer(
                selectedFeature, expandedGeoJSON,
              )}
              {CONDITION_CHECK_5 && getAnomalyHeader(selectedFeature)}
              {selectedObjFromState.type === 'block' ? isBottomSheetVisible
                && CONDITION_CHECK_1 && block?.name
                : selectedObjFromState.type === 'ranch' && isBottomSheetVisible
                && CONDITION_CHECK_1 && ranch?.name}
            </strong>
          </Typography.Text>
        </div>
      </div>
      <div className="bottom-sheet-header">
        <Button
          shape="circle"
          icon={<FaTimes />}
          onClick={() => onClosesDrawer()}
        />
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <BottomSheet
          ref={sheetRef}
          open={isBottomSheetVisible}
          blocking={false}
          onDismiss={() => onDismissBottomSheet()}
          defaultSnap={({ snapPoints }) => snapPoints[-1]}
          snapPoints={({ maxHeight, height }) => getSnapPoints(maxHeight, height)}
          header={bottomSheetHeader()}
        >

          <div className="bottomsheet-block">
            {isSensor && !isImageryPanelVisible
              && !!selectedFeature?.properties && (
                <>
                  <SensorDetailHeader sensorProperties={selectedFeature.properties} />
                  {isExpandBtnVisible && (
                    <div className="expand-btn">
                      <Button
                        onClick={() => {
                          sheetRef.current
                            .snapTo(({ maxHeight }) => maxHeight - (headerHeight + safeAreaTop));
                        }}
                      >
                        {t('Expand to see details')}
                      </Button>
                    </div>
                  )}
                </>
            )}

            {renderDetails()}
          </div>
          {renderSatelliteImagery()}
        </BottomSheet>
      ) : null}
      {!isMobile && (
        <Drawer
          className="drawer-desktop"
          style={{ marginTop: '50px', minWidth: isBottomSheetVisible ? '400px' : '0px' }}
          title={renderDrawerTitle()}
          placement="right"
          closable={false}
          visible={isBottomSheetVisible}
          key="right"
          mask={false}
        >
          {renderDetails()}
        </Drawer>
      )}
    </>
  );
}

ShowDetails.propTypes = {
  isMobile: PropTypes.bool,
  presentationMode: PropTypes.string,
  isBottomSheetVisible: PropTypes.bool,
  selectedFeature: PropTypes.shape(),
  dispatchMap: PropTypes.func,
  sheetRef: PropTypes.shape(),
  bottomSheetType: PropTypes.string,
  isImageryPanelVisible: PropTypes.bool,
  selectedImageryData: PropTypes.shape(),
  isExpandBtnVisible: PropTypes.bool,
  expandedGeoJSON: PropTypes.shape(),
  ranchId: PropTypes.number.isRequired,
  blockId: PropTypes.number.isRequired,
  selectedObjFromState: PropTypes.shape().isRequired,
};

ShowDetails.defaultProps = {
  isMobile: false,
  presentationMode: null,
  isBottomSheetVisible: false,
  selectedFeature: null,
  dispatchMap: null,
  sheetRef: null,
  bottomSheetType: '',
  isImageryPanelVisible: false,
  selectedImageryData: null,
  isExpandBtnVisible: false,
  expandedGeoJSON: null,
};
