import React from 'react';
import PropTypes from 'prop-types';
import { controlApi } from 'farmx-api';
import { pumpOverrideState } from '../../../../constants';
import OverrideButtons from './OverrideButtons';


const statusLabels = {
  [pumpOverrideState.CLOSED]: {
    label1: 'OFF',
    label2: 'Pump always off',
  },
  [pumpOverrideState.OPEN]: {
    label1: 'ON',
    label2: 'Pump always on',
  },
  [pumpOverrideState.SCHEDULED]: {
    label1: 'SCHEDULED',
    label2: 'Pump follows schedule',
  },
};

export const VFD_TYPE = 'vfd';

const { createOverride, endOverride } = controlApi;

export default function PumpButtons(props) {
  const {
    pumpSchedule,
    pumpName,
    vfdIdentifier,
  } = props;
  return (
    <OverrideButtons
      sensorType={VFD_TYPE}
      sensorSchedule={pumpSchedule}
      sensorName={pumpName}
      sensorIdentifier={vfdIdentifier}
      statusLabels={statusLabels}
      sensorOverrideState={pumpOverrideState}
      createOverride={createOverride}
      endOverride={endOverride}
    />
  );
}

PumpButtons.defaultProps = {
  pumpSchedule: [],
  pumpName: '',
  vfdIdentifier: null,
};

PumpButtons.propTypes = {
  pumpSchedule: PropTypes.arrayOf(Object),
  pumpName: PropTypes.string,
  vfdIdentifier: PropTypes.string,
};
