import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import './NoDataCard.less';

export function NoDataCard({ title }) {
  return (
    <div className="no-data-card">
      <Typography.Text>{title}</Typography.Text>
    </div>
  );
}

NoDataCard.propTypes = {
  title: PropTypes.string,
};

NoDataCard.defaultProps = {
  title: 'Nothing found',
};
