import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FaPlus } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { useTracking } from '../../../helper/mixpanel';

export function CreateScheduleButton(props) {
  const { blockIds } = props;
  const history = useHistory();
  const location = useLocation();
  const tracking = useTracking();
  return (
    <Button
      type="primary"
      className="circle-create-btn"
      shape="circle"
      size="large"
      icon={<FaPlus size={35} />}
      onClick={() => {
        history.push('/schedule/new', {
          from: location.pathname,
          blockIds,
        });
        if (tracking) tracking.track('Clicked On Create Schedule Button');
      }}
    />
  );
}

CreateScheduleButton.propTypes = {
  blockIds: PropTypes.arrayOf(PropTypes.any),
};

CreateScheduleButton.defaultProps = {
  blockIds: null,
};
