import React, {
  useState, useMemo, useEffect, useCallback,
} from 'react';
import { Typography, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { hooks } from 'farmx-redux-core';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ZendeskHelpModal from './ZendeskHelpModal';


const { Search } = Input;
const ZENDESK_KEY = '98fae2cd-0d5d-4adc-80e1-c5dbc7d6395b';
const setting = {
  webWidget: {
    color: {
      theme: '#3c61b4',
    },
    launcher: {
      mobile: {
        labelVisible: true,
      },
    },
    offset: {
      mobile: {
        vertical: '65px',
      },
    },
  },
};

/**
 * This component may contain the content for resources and help section
 */
export default function Documentation() {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [articleId, setArticleId] = useState('');
  const [visible, setVisible] = useState(false);
  const [searchData, loadingSearchData, searchError, searchApi] = hooks.useZendeskSearch();
  const [data, loading, error] = hooks.useZendeskArticles();
  const { results } = searchData || {};
  const { articles } = data || {};
  const list = useMemo(() => (
    searchQuery ? (results || []) : (articles || [])
  ), [articles, results, searchQuery]);
  const searchArticle = useCallback(_.debounce((query) => {
    if (query) {
      searchApi(query);
    }
    setSearchQuery(query);
  }, 1000), []);
  const onChange = useCallback((e) => {
    searchArticle(e.target.value);
  }, [searchArticle]);
  const onCancel = () => {
    setArticleId('');
    setVisible(false);
  };
  const [showError, showLoader, showList, showNoData] = useMemo(() => ([
    (error || searchError),
    (loading || loadingSearchData),
    (list.length > 0 && !(error || searchError) && !(loading || loadingSearchData)),
    list.length === 0 && !(loading || loadingSearchData),
  ]), [error, list, loading, loadingSearchData, searchError]);

  // Hide the widget on unmount
  useEffect(() => () => {
    ZendeskAPI('webWidget', 'hide');
  }, []);

  // Show isn't working at the moment
  const onZendeskLoaded = () => {
    // ZendeskAPI('webWidget', 'show');
    ZendeskAPI('webWidget', 'hide');
  };

  return (
    <div className="div-default background-white padding-10">
      <Typography.Title level={2}>{t('Documentation')}</Typography.Title>
      <Search
        onChange={onChange}
        placeholder={t('Search for Farmx article')}
      />
      <div className="padding-10">
        {showLoader && <LoadingOutlined />}
        {showError && (
          <Typography.Text type="danger">
            {error || searchError}
          </Typography.Text>
        )}
        {showList && list.map((article) => (
          <div className="padding-10" key={article.id}>
            <Link to={`/help/article/${article.id}`}>
              {article.title}
            </Link>
          </div>
        ))}
        {showNoData && <Typography.Text>No data found</Typography.Text>}
      </div>
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        {...setting}
        onLoaded={onZendeskLoaded}
      />
      <ZendeskHelpModal
        articleId={articleId}
        visible={visible}
        onCancel={onCancel}
      />
    </div>
  );
}
