import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getSoilTypeColor, getSoilTypeName } from './soilTypes';

export function SoilTypeTag({ soilType }) {
  const { t } = useTranslation();
  const color = getSoilTypeColor(soilType);
  const soilTypeName = getSoilTypeName(soilType);

  return (
    <Tag color={color}>{t(soilTypeName)}</Tag>
  );
}

SoilTypeTag.propTypes = {
  soilType: PropTypes.string.isRequired,
}
