import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import RenderBlockSettings from './RenderBlockSettings';

const {
  selectBlocksForRanchIdAlphabetically,
} = selectors;

const RenderRanchData = (props) => {
  const { globalAutoSchedule, ranch } = props;
  const blocks = useSelector((state) => (
    selectBlocksForRanchIdAlphabetically(state, ranch.id))) || [];

  return blocks.length > 0 ? (
    <div className="render-ranch-data">
      <Typography.Text className="ranch-name">{ranch.name}</Typography.Text>
      <div className="padding-10">
        {blocks.map((block) => (
          <RenderBlockSettings
            globalAutoSchedule={globalAutoSchedule}
            key={block.id}
            block={block}
          />
        ))}
      </div>
    </div>
  ) : null;
};

RenderRanchData.propTypes = {
  ranch: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }).isRequired,
  globalAutoSchedule: PropTypes.shape({
    autoSchedule: PropTypes.bool,
    source: PropTypes.string,
  }).isRequired,
};

export default RenderRanchData;
