import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

const options = [
  { label: 'Auto', value: true },
  { label: 'Let me decide', value: false },
];

const AutoScheduleButtons = (props) => {
  const { autoSchedule, onClick, disabled } = props;
  const [selected, setSelected] = useState();
  const onChange = (e) => {
    setSelected(e.target.value);
    onClick(e.target.value);
  };

  useEffect(() => {
    setSelected(autoSchedule);
  }, [autoSchedule]);

  return (
    <Radio.Group disabled={disabled} options={options} onChange={onChange} value={selected} />
  );
};

AutoScheduleButtons.defaultProps = {
  autoSchedule: false,
  disabled: false,
  onClick: () => { },
};

AutoScheduleButtons.propTypes = {
  autoSchedule: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default AutoScheduleButtons;
