export const mapHTMLScripts = `<script id="vertexShader" type="x-shader/x-vertex">
varying vec2 vUv;
varying float x;
varying float y;
varying float z;
varying vec3 worldPos;
varying vec3 fadeCenter;
void main()
{
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
    worldPos = worldPosition.xyz;
    gl_Position = projectionMatrix * mvPosition;
    x = position.x;
    y = position.y;
    z = position.z;
}
</script>
<script id="fragment_shader_test" type="x-shader/x-fragment">
varying vec2 vUv;
varying float x;
varying float y;


void main(void)
{
float red = 1.0;
float green = 1.0;
float blue = 0.0;
gl_FragColor = vec4( red, green, blue, 1.0 );
}
</script>
<script id="fragmentShaderTexture" type="x-shader/x-fragment">
uniform sampler2D texture1;
uniform float saturation;
uniform float fade;
uniform float scale;
uniform float opacity;
uniform vec3 fadeCenter;
varying vec2 vUv;
varying vec3 worldPos;

vec3 hsv2rgb(vec3 c)
{
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

vec3 rgb2hsv(vec3 c)
{
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

void main() {
    vec3 pos = fadeCenter;//vec3(5514, -12836, 0.);
    //pos.y = -fadeCenter.y;
    vec3 diff = worldPos - pos;
    float dist = length(diff);
    dist = distance(worldPos.xy, pos.xy);
    dist = dist / 3. / scale;
    dist = 0.7 - log(dist);
    vec4 color = texture2D(texture1, vUv);
    //color.b *= saturation;
    vec3 hsv = rgb2hsv(color.rgb);
    hsv.y *= saturation;
    gl_FragColor.rgb = hsv2rgb(hsv);
    gl_FragColor.a = opacity;
    if (fade > 0.5) gl_FragColor.a = dist * opacity;//2. * (0.5 - dist / 1.0);
}
</script>
<script id="interpolate" type="x-shader/x-fragment">
precision highp float;
uniform sampler2D data;
//uniform vec2 wh;
uniform float power;
uniform float time;
uniform int sensors;
uniform int times;
varying float x;
varying float y;

void main(void)
{
vec4 d = vec4(1.0);
//vec2 t = texCoord * wh;
vec2 t = vec2(x, y);
float vsum = 0.0;
float wsum = 0.0;
float cutoff_dist = 0.6;
float min_dist = cutoff_dist + 1.0;
for (int i = 0; i < 3; ++i) //SIZE
{
  // Interpolate time from center of first to center of last pixel
  float xi = 0.0;//(0.5 + time * float(times - 1)) / float(times);
  float yi = (float(i) +  0.5) / float(sensors); // (float(i) + 0.5) / float(sensors);
  d = texture2D(data, vec2(xi, yi));
  float dist = distance(t, d.xy);// * wh);

  if (dist < min_dist) {
    min_dist = dist;
  }
  float weight = pow(dist, power);
  //if (weight < 0.1) weight = 0.0;
  vsum += weight * d.z;//* d.w;
  wsum += weight;
}
//if (vsum > 0.5) vsum = 0.0;
if (min_dist >= cutoff_dist) {
    //vsum = 0.0;
}
float v = wsum == 0.0 ? 0.0 : vsum / wsum;

float a = 1.0-pow(.9*min_dist, 10.0);

gl_FragColor = vec4(1.0, v, 0.0, a); //vec4(v, v, d.z, d.w);
}
</script>
<script id="interpolate-3d" type="x-shader/x-fragment">
precision highp float;
uniform sampler2D data;
//uniform vec2 wh;
uniform float power;
uniform float time;
uniform int sensors;
uniform int times;
varying float x;
varying float y;
varying float z;
uniform float depth;
varying vec2 vUv;
uniform float minValue;
uniform float maxValue;

vec3 hsv2rgb(vec3 c)
{
vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

void main(void)
{
vec4 d = vec4(1.0);
//vec2 t = texCoord * wh;
vec2 t = vec2(x, y);
float vsum = 0.0;
float wsum = 0.0;
float cutoff_dist = 0.6;
float min_dist = cutoff_dist + 1.0;

float depth_percent = 1.0 - (z / depth);

float nearest_val = 0.0;
float max_weight = -1.0;

for (int i = 0; i < SENSOR_DATA_SIZE; ++i) //SIZE
{
  if (i == sensors) break;
  // Interpolate time from center of first to center of last pixel
  float yi = (float(time) +  0.5) / float(times);//(0.5 + time * float(times - 1)) / float(times);
  float xi = (float(i) +  0.5) / float(sensors); // (float(i) + 0.5) / float(sensors);
  d = texture2D(data, vec2(xi, yi));
  float dist = distance(t, d.xy);// * wh);

  float weight_z = 1.0 - abs(d.z-depth_percent);

  if (dist < min_dist) {
    min_dist = dist;
    nearest_val = d.w;
  }

  nearest_val = d.w;
  //dist = dist * dist2;

  float weight = pow(dist, power);
  //if (dist > 0.5) weight = 0.0;

  weight = weight_z * weight;

  if (weight > max_weight) {
    max_weight = weight;
  }

  //weight = weight * dist2;
  //if (weight < 0.1) weight = 0.0;
  vsum += weight * d.w;//* d.w;
  wsum += weight;
}

//if (vsum > 0.5) vsum = 0.0;
if (min_dist >= cutoff_dist) {
    //vsum = 0.0;
}
float v = wsum == 0.0 ? 0.0 : vsum / wsum;  // calculate v as quotient of sums

float a = 1.0-pow(.9*min_dist, 10.0);

// adjust the value to new range [0.3, 0.4]
if (v < minValue) {
  float low = 0.0;
  float high = minValue;
  v = (v - low) / (high-low);
  // clamp to [0,1]
  if (v < 0.0) v = 0.0;
  if (v > 1.0) v = 1.0;
  vec3 hsv = vec3(0, 0.8, v);
  vec3 rgb = hsv2rgb(hsv);
  gl_FragColor.rgb = rgb;
} else if (v > maxValue) {
  float low = maxValue;
  float high = 1.0;
  v = (v - low) / (high-low);
  // clamp to [0,1]
  if (v < 0.0) v = 0.0;
  if (v > 1.0) v = 1.0;
  vec3 hsv = vec3(v/3. + .333, 0.8, 1.);
  vec3 rgb = hsv2rgb(hsv);
  gl_FragColor.rgb = rgb;
} else {
  float low = minValue;
  float high = maxValue;
  v = (v - low) / (high-low);
  // clamp to [0,1]
  if (v < 0.0) v = 0.0;
  if (v > 1.0) v = 1.0;

  float hue = v;
  v = clamp(v, 0.0, 1.0);
  vec3 hsv = vec3(v/3., 0.8, 1.);
  vec3 rgb = hsv2rgb(hsv);
  gl_FragColor.rgb = rgb;
}
}
</script>`;
