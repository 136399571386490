import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { hooks } from 'farmx-redux-core';

const {
  useUnits,
} = hooks;

export default function PumpSpecs(props) {
  const { pumpDetails } = props;
  const { t } = useTranslation();
  const getUserUnits = useUnits();


  const {
    pumpFlowRate, pumpPower, pumpRevolutions, pumpFrequency, units,
  } = pumpDetails;

  const flowRateUnits = getUserUnits(pumpFlowRate, units.flowRate, 'flow_rate');
  const powerUnits = getUserUnits(pumpPower, units.power, 'power');
  const speedUnits = getUserUnits(pumpRevolutions, units.speed, units.speed);
  const frequencyUnits = getUserUnits(pumpFrequency, units.frequency, units.frequency);

  const displayFlowRate = `${flowRateUnits.value} ${flowRateUnits.label}`;
  const displayPower = `${powerUnits.value} ${powerUnits.label}`;
  const displaySpeed = `${speedUnits.value} ${speedUnits.label}`;
  const displayFrequency = `${frequencyUnits.value} ${frequencyUnits.label}`;

  return (
    <div className="pump-specs-container">
      <div className="pump-single-spec">
        <Typography.Text>{t('Flow Rate')}</Typography.Text>
        <Typography.Text strong>{displayFlowRate}</Typography.Text>
      </div>
      <div className="pump-single-spec">
        <Typography.Text>{t('Power')}</Typography.Text>
        <Typography.Text strong>{displayPower}</Typography.Text>
      </div>
      <div className="pump-single-spec">
        <Typography.Text>{t('Revolutions')}</Typography.Text>
        <Typography.Text strong>{displaySpeed}</Typography.Text>
      </div>
      <div className="pump-single-spec">
        <Typography.Text>{t('Hertz')}</Typography.Text>
        <Typography.Text strong>{displayFrequency}</Typography.Text>
      </div>
    </div>
  );
}

PumpSpecs.defaultProps = {
  pumpDetails: {
    units: {
      frequency: 'hertz',
      speed: 'revolutions_per_minute',
      power: 'kilowatts',
      flowRate: 'gallons_per_minute',
    },
  },
};

PumpSpecs.propTypes = {
  pumpDetails: PropTypes.shape({
    pumpFlowRate: PropTypes.string,
    pumpPower: PropTypes.string,
    pumpRevolutions: PropTypes.string,
    pumpFrequency: PropTypes.string,
    units: PropTypes.object,
  }),
};
