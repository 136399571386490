import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gaugeLib } from '../../../../lib/gauge';

const { Gauge } = gaugeLib();

const getGaugeStrokeRange = (max, pressureCutoff) => {
  const breakponts = { bPoint1: 5, bPoint2: 5.2, max: 30 };
  if (max >= 30) {
    breakponts.bPoint1 = Math.min(pressureCutoff);
    breakponts.bPoint2 = Math.max(pressureCutoff);
    breakponts.max = max;
  }

  return breakponts;
};

const PressureGauge = memo((props) => {
  const {
    id, pressure, pressureCutoff, height, width,
  } = props;

  useEffect(() => {
    const opts = {
      angle: -0.16, // The span of the gauge arc
      lineWidth: 0.05, // The line thickness
      radiusScale: 0.5, // Relative radius
      pointer: {
        length: 0.47, // Relative to gauge radius
        strokeWidth: 0.017, // The thickness
        color: '#000000', // Fill color
      },
      limitMax: false, // If false, max value increases automatically if value > maxValue
      limitMin: false, // If true, the min value of the gauge will be fixed
      colorStart: '#6FADCF', // Colors
      colorStop: '#8FC0DA', // just experiment with them
      strokeColor: '#E0E0E0', // to see which ones work best for you
      generateGradient: true,
      highDpiSupport: true, // High resolution support
    };
    const pressureCutoffArr = pressureCutoff
      ? pressureCutoff.split(',').map((num) => Number(num))
      : [];

    const breakPoint = getGaugeStrokeRange(Number(pressure || 0),
      pressureCutoffArr.indexOf(undefined) >= 0 ? pressureCutoffArr : []);

    const staticZonesRpm = [
      { strokeStyle: '#C1C1C1', min: 0, max: breakPoint.bPoint1 }, // Green
      { strokeStyle: '#FFFFFF', min: breakPoint.bPoint1, max: breakPoint.bPoint2 }, // Green
      { strokeStyle: '#42ABE3', min: breakPoint.bPoint2, max: breakPoint.max }, // Yellow
    ];

    const elRmp = document.getElementById(id);
    const target = elRmp;
    opts.renderTicks = undefined;
    opts.staticZones = staticZonesRpm;
    const gauge = new Gauge(target).setOptions(opts);
    gauge.maxValue = Number(pressure) > 30 ? Number(pressure) : 30;
    gauge.setMinValue(0);
    gauge.animationSpeed = 32;
    gauge.set(pressure);
  }, [id, pressure, pressureCutoff]);

  return (
    <div className={width < 100 ? 'pressure-canvas-container-valve' : 'pressure-canvas-container'}>
      <canvas id={id} style={{ width, height }} />
    </div>
  );
});

PressureGauge.defaultProps = {
  id: '',
  pressure: '',
  pressureCutoff: '',
  height: 200,
  width: 200,
};

/**
 * Accepting pressureCutoff as string instead of array makes it easier for comparison.
 * It causes very few rerenders and solves the problem of
 * gauge being redrawn multiple times for the same set of values.
 */
PressureGauge.propTypes = {
  id: PropTypes.string,
  pressure: PropTypes.string,
  pressureCutoff: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default PressureGauge;
