import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { hooks, selectors } from 'farmx-redux-core';
import { RanchBlockSelect } from './RanchBlockSelect';
import { SensorSummaryList } from './SensorSummaryList';
import { PageHeader } from '../components/PageHeader';
import './list.css';

const {
  selectLoadingSensors,
} = selectors;

const { useRanchBlockSelection } = hooks;

export function ListPage() {
  const { t } = useTranslation();

  const loadingSensors = useSelector((state) => selectLoadingSensors(state));

  const { selectedObjFromState, blockArray } = useRanchBlockSelection();

  return (
    <div className="div-default padding-10 page-content">
      <div className="inline-page-loader-div">
        <div className="desktop-container">
          <div>
            <PageHeader
              data-testid="list-title"
              title={t('Devices Summary')}
            />
            {loadingSensors.loading ? (
              <div className="loader-common">
                <LoadingOutlined />
              </div>
            ) : null}
          </div>
          <RanchBlockSelect
            selectedObj={selectedObjFromState}
          />
          <SensorSummaryList
            blocks={blockArray}
            selectedObj={selectedObjFromState}
          />
        </div>
      </div>
    </div>
  );
}

ListPage.defaultProps = {
  history: {},
};

ListPage.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.object,
  }),
};
