import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { hooks } from 'farmx-redux-core';

const { useBlockNames } = hooks;

function BlockTag({ blockId }) {
  const blockNames = useBlockNames([blockId]);

  if (blockNames === undefined || blockNames === null) return null;
  return (
    <Tag color="default">
      { blockNames[blockId] }
    </Tag>
  );
}

BlockTag.propTypes = {
  blockId: PropTypes.number,
};

BlockTag.defaultProps = {
  blockId: undefined,
};

export default BlockTag;
