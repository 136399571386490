import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions, selectors } from 'farmx-redux-core';
import { BlockSelect, SchedulerMobile } from 'farmx-web-ui';
import './mediaQuery.css';
import './styles.css';
import { PageHeader } from '../components/PageHeader';
import { useTracking } from '../../../helper/mixpanel';
import { blockTagRender, useColorsByBlock } from './blockTagRender';
import { CreateScheduleButton } from './CreateScheduleButton';

const {
  selectSelectedBlocks,
} = selectors;

const initialMobileScheduler = { schedulerMode: 'week', schedulerStartDate: new Date() };

export function ScheduleCalendar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const blocksFromState = useSelector((state) => selectSelectedBlocks(state));
  const { t } = useTranslation();
  const blocks = blocksFromState.payload;
  const [localBlocks, setLocalBlocks] = useState([]);
  const blockColors = useColorsByBlock(localBlocks);
  const tracking = useTracking();
  const userTimeFormat = useSelector((state) => selectors.selectUserTimeFormat(state));

  const [scheduler, setScheduler] = useState(initialMobileScheduler);
  const { schedulerMode, schedulerStartDate: currentDate } = scheduler;

  function getDateRangeForScheduler(mode, date) {
    // 6 weeks * 7 days = 42 days is the most shown on the calendar
    // you can transition to month view from any mode
    return [
      moment(date).startOf('month').subtract(42, 'days'),
      moment(date).endOf('month').add(42, 'days'),
    ];
  }
  const [dateRange, setDateRange] = useState(getDateRangeForScheduler(schedulerMode, currentDate));
  const [dateStart, dateEnd] = dateRange;
  useEffect(() => {
    setDateRange(getDateRangeForScheduler(schedulerMode, currentDate));
  }, [currentDate, schedulerMode]);

  useEffect(() => {
    if (tracking) tracking.track('Loaded Schedule Calendar Page');
  }, [tracking]);

  useEffect(() => {
    if (blocks) {
      setLocalBlocks(blocks);
    }
  }, [blocks]);

  useEffect(() => {
    dispatch(actions.loadUserSettings());
  }, [dispatch]);

  // load schedules for dates
  useEffect(() => {
    if (dateStart && dateEnd && localBlocks) {
      for (let blockIndex = 0; blockIndex < localBlocks.length; blockIndex += 1) {
        dispatch(actions.loadBlockRenderedSchedule({
          blockId: localBlocks[blockIndex],
          dateStart,
          dateEnd,
        }));
      }
    }
  }, [dateStart, dateEnd, localBlocks, dispatch]);

  const scheduleDataRendered = useSelector(
    (state) => selectors.selectSchedulerTimelineDataRendered(state, {
      blockIds: localBlocks,
      dateStart,
      dateEnd,
      blockColors,
    }),
  );

  const handleOnchange = (d) => {
    setLocalBlocks(d);
    if (tracking) tracking.track('Selected Blocks', { blockIds: d });
  };

  return (
    <div className="single-column-page scheduler schedule-calendar-container padding-10">
      <PageHeader showBack toPath="/schedule" title={t('calendar')} />
      <div className="schedule-select div-select-container">
        <BlockSelect
          placeholder={t('Select Blocks To Irrigate')}
          mode="multiple"
          bordered={false}
          onChange={handleOnchange}
          value={localBlocks}
          tagRender={(d) => blockTagRender(d, blockColors)}
        />
      </div>
      <div className="scheduler-outer mobile-list-item">
        <div className="scheduler-inner">
          <SchedulerMobile
            // blocks={localBlocks}
            onClickChart={(data) => {
              if (data.rootId && data.block) {
                history.push(`/schedule/detail/${data.block}/${data.rootId}`, {
                  eventIndex: data.event_index,
                  breadcrumbs: '/schedule/calendar',
                });
              }
              if (tracking) tracking.track('Schedule Chart Clicked');
            }}
            types={['scheduled']}
            colorObj={blockColors}
            is24Hour={userTimeFormat === '24_hour'}
            scheduleData={scheduleDataRendered}
            setScheduler={setScheduler}
          />
        </div>
      </div>
      <CreateScheduleButton blockIds={localBlocks} />
    </div>
  );
}
