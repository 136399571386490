import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Typography, Radio } from 'antd';
import { PageHeader } from '../components/PageHeader';

export function AppearanceSettings() {
  const { t } = useTranslation();
  const history = useHistory();
  const [value, setValue] = useState('system');

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="appearance-settings-container div-default padding-20 white-bg">
      <PageHeader
        showBack
        title={t('AppearanceSettings')}
      />
      <div className="appearance-settings">
        <Typography.Title level={4}>{`${t('AppearanceSettings Mode')}:`}</Typography.Title>
        <div className="appearance-settings-options">
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="system">{t('System')}</Radio>
            <Radio value="light">{t('Light')}</Radio>
            <Radio value="dark">{t('Dark')}</Radio>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
}
