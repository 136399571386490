import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import centroid from '@turf/centroid';
import isEqual from 'react-fast-compare';
import { actions } from 'farmx-redux-core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { notifyError } from '../../map/components/utils';
import { reDraw } from './reDraw';
import { loadBlockAnomalies } from '../../../helper/mapHelper';

const {
  cleanSensorsFetchState,
  loadAndPrepareMapDataByRanchIds,
} = actions;

export const prepData = async (dispatchMap, dispatch, ranchIds) => {
  try {
    dispatchMap({ type: 'setIsLoading', payload: true });
    dispatch(cleanSensorsFetchState());
    await dispatch(loadAndPrepareMapDataByRanchIds(ranchIds));
  } catch (error) {
    console.log('ERROR in prepData', error);
    notifyError(error.message);
  } finally {
    dispatchMap({ type: 'setIsLoading', payload: false });
  }
};

export const useLoadMapData = (dispatchMap, ranchId, dispatch) => {
  useEffect(() => {
    const ranchIds = [];
    if (ranchId) {
      ranchIds.push(ranchId);
    }
    prepData(dispatchMap, dispatch, ranchIds).catch();
  }, [ranchId, dispatch, dispatchMap]);
};

export const useLoadAnomalyData = (dispatchMap, blockIdsForRanch, blockId, type) => {
  useEffect(() => {
    if (type === 'block' && blockId) {
      loadBlockAnomalies([blockId], (response) => {
        if (response) {
          dispatchMap({
            type: 'setAnomalyGeoJSON',
            payload: response,
          });
        }
      });
    } else if (type === 'ranch' && JSON.parse(blockIdsForRanch).length) {
      loadBlockAnomalies(JSON.parse(blockIdsForRanch), (response) => {
        if (response) {
          dispatchMap({
            type: 'setAnomalyGeoJSON',
            payload: response,
          });
        }
      });
    }
  }, [blockId, blockIdsForRanch, dispatchMap, type]);
};

export const useSensorsByRanchIdGeoJSON = (ranchSensorFeatures) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isEqual(ranchSensorFeatures, data)) {
      setData(ranchSensorFeatures);
    }
  }, [ranchSensorFeatures, data]);

  return data;
};

export const useBlocksGeoJSON = (blockFeatures) => {
  const [data, setData] = useState(undefined);
  useEffect(() => {
    const blocksGeoJSON = {
      type: 'FeatureCollection',
      features: cloneDeep(blockFeatures),
    };
    if (!isEqual(blocksGeoJSON, data)) {
      setData(blocksGeoJSON);
    }
  }, [blockFeatures, data]);

  return data;
};

export const useAllRanchesGeoJSON = (allRanchesFeatures, ranchId) => {
  const [allRanchesFeaturesData, setAllRanchesFeaturesData] = useState({
    type: 'FeatureCollection',
    features: [],
  });

  const [allRanchesPointsGeoJSON, setAllRanchesPointsGeoJSON] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [ranchGeoJson, setRanchGeoJson] = useState(undefined);
  useEffect(() => {
    const allRanchesFeaturesGeoJSON = {
      type: 'FeatureCollection',
      features: cloneDeep(allRanchesFeatures),
    };

    let rGeoJSON;

    if (ranchId && (allRanchesFeatures instanceof Array)) {
      const ranch = allRanchesFeatures.find(({ id }) => id === ranchId);
      const ranchGJ = {
        type: 'FeatureCollection',
        features: [],
      };
      ranchGJ.features.push(cloneDeep(ranch));
      rGeoJSON = (ranchGJ);
    }

    if (!isEqual(allRanchesFeaturesGeoJSON, allRanchesFeaturesData)) {
      setAllRanchesFeaturesData(allRanchesFeaturesGeoJSON);

      const ranchesPointsGeoJSON = allRanchesFeatures
        .reduce((acc, feature) => {
          const ranchPoint = centroid(feature);
          ranchPoint.properties = cloneDeep(feature.properties);
          acc.features.push(ranchPoint);
          return acc;
        }, {
          type: 'FeatureCollection',
          features: [],
        });
      setAllRanchesPointsGeoJSON(ranchesPointsGeoJSON);
    }
    if (allRanchesFeaturesData?.features?.length && !isEqual(rGeoJSON, ranchGeoJson)) {
      setRanchGeoJson(rGeoJSON);
    }
    if (rGeoJSON && rGeoJSON?.features?.length) {
      if (rGeoJSON.features[0] && rGeoJSON.features[0].id !== ranchId) {
        setRanchGeoJson(undefined);
      }
    }
    if (!rGeoJSON && ranchGeoJson) {
      setRanchGeoJson(undefined);
    }
  }, [allRanchesFeatures, allRanchesFeaturesData, allRanchesPointsGeoJSON, ranchGeoJson, ranchId]);

  return [ranchGeoJson, allRanchesPointsGeoJSON, allRanchesFeaturesData];
};

// Function to decide whether the reducer state should be update or not
function isStateUpdateNeeded(bottomSheetType) {
  return bottomSheetType !== 'sensor' && bottomSheetType !== 'anomaly';
}

export const useRecenter = (
  blockId,
  ranchId,
  blocksGeoJSON,
  ranchGeoJSON,
  allRanchesFeaturesData,
  dispatchMap,
  selectedObjFromStateType,
  isBottomSheetVisible,
  bottomSheetType,
) => {
  useEffect(() => {
    if (selectedObjFromStateType === 'block' && blockId && blocksGeoJSON?.features?.length
     && isStateUpdateNeeded(bottomSheetType)) {
      // if block exist set recenter json to blockGeoJSON
      const selBlock = blocksGeoJSON.features.find((item) => item.id === blockId);
      const blockGeoJSON = {
        type: 'FeatureCollection',
        features: selBlock ? [selBlock] : [],
      };

      dispatchMap({ type: 'setRecenterGeoJSON', payload: blockGeoJSON });
      dispatchMap({
        type: 'setSelectedFeatureWithType',
        payload: {
          selectedFeature: blockGeoJSON,
          type: 'block',
          showBottomSheet: isBottomSheetVisible,
        },
      });
      return;
    }

    if (selectedObjFromStateType === 'ranch' && ranchId && ranchGeoJSON?.features?.length
     && isStateUpdateNeeded(bottomSheetType)) {
      // if ranch exists set recenter json to ranchGeoJSON
      dispatchMap({ type: 'setRecenterGeoJSON', payload: ranchGeoJSON });
      dispatchMap({
        type: 'setSelectedFeatureWithType',
        payload: {
          selectedFeature: ranchGeoJSON,
          type: 'ranch',
          showBottomSheet: isBottomSheetVisible,
        },
      });
      return;
    }

    if (allRanchesFeaturesData?.features?.length
     && isStateUpdateNeeded(bottomSheetType)) {
      // if all ranches exist set recenter json to allRanches geoJSON
      dispatchMap({ type: 'setRecenterGeoJSON', payload: allRanchesFeaturesData });
      dispatchMap({
        type: 'setSelectedFeatureWithType',
        payload: {
          selectedFeature: allRanchesFeaturesData,
          type: 'all_ranches',
          showBottomSheet: isBottomSheetVisible,
        },
      });
    }
  }, [
    blockId,
    ranchId,
    blocksGeoJSON,
    ranchGeoJSON,
    allRanchesFeaturesData,
    dispatchMap,
    selectedObjFromStateType,
    isBottomSheetVisible,
    bottomSheetType,
  ]);
};

export const useReDraw = (
  mapFeatureGroupRef,
  dataForRedraw,
  presMode,
  selectedFeature,
  dispatchMap,
  selMapShowLabels,
  selShowAllSensors,
  allRanchesPoints,
  ranchId,
  zoomSensorsVisible,
) => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    // redraw renders markers directly to the map
    // using mapFeatureGroupRef
    reDraw({
      mapFeatureGroupRef,
      dataForRedraw,
      presentationMode: presMode,
      selectedFeature,
      dispatchMapPage: dispatchMap,
      showLabels: selMapShowLabels,
      selShowOnlyPresentionModeSensors: !selShowAllSensors,
      ranchesPointsGeoJSON: allRanchesPoints,
      ranchId,
      zoomShowSensors: zoomSensorsVisible,
      dispatch,
      history,
    });
  }, [
    dataForRedraw,
    allRanchesPoints,
    history,
    ranchId,
    dispatch,
    zoomSensorsVisible,
    selShowAllSensors,
    presMode,
    selMapShowLabels,
    selectedFeature,
    dispatchMap,
    mapFeatureGroupRef,
  ]);
};
