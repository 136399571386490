import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function ValveStateText(props) {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { state, valveName, multipleStates } = props;

  return (
    <>
      {!multipleStates.isMultiple && (
      <Title className="pump-state-text" level={5}>
        {valveName ? `${valveName} is` : t('Valve is')}
        <span className={state.toLowerCase() === 'open' ? 'pump-on-state' : ''}>
          {` ${state ? t(state) : 'unknown'}`}
        </span>
      </Title>
      )}
      {multipleStates.isMultiple && (
      <Title className="pump-state-text" level={5}>
        Valves:
        {!multipleStates.bothStates && (
        <>
          {t(` ${multipleStates.openCount}`)}
          <span className={multipleStates.openState
            .toLowerCase() === 'open' ? 'pump-on-state' : ''}
          >
            {` ${t(multipleStates.openState)}`}
          </span>
          {t(` ${multipleStates.closedCount}`)}
          <span className={multipleStates.closedState
            .toLowerCase() === 'open' ? 'pump-on-state' : ''}
          >
            {` ${t(multipleStates.closedState)}`}
          </span>
        </>
        )}
        {multipleStates.bothStates && (
          <>
            {t(` ${multipleStates.totalCount}`)}
            <span className={multipleStates.bothStates
              .toLowerCase() === 'open' ? 'pump-on-state' : ''}
            >
              {` ${t(multipleStates.bothStates)}`}
            </span>
          </>
        )}
      </Title>
      )}
    </>
  );
}

ValveStateText.propTypes = {
  state: PropTypes.string.isRequired,
  valveName: PropTypes.string,
  multipleStates: PropTypes.shape(),
};

ValveStateText.defaultProps = {
  valveName: null,
  multipleStates: {},
};
