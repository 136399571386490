import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChartComponent } from 'farmx-web-ui';
import { createChartOptions } from '../../../helper/plantSensorHelper';

export function PlantSensorChart(props) {
  const { data } = props;
  const [options, setOptions] = useState(createChartOptions(data));

  useEffect(() => {
    setOptions(createChartOptions(data));
  }, [data]);

  return (
    <div className="plant-sensor-chart-container min-height-150 relative">
      <div className="absolute">
        <ChartComponent
          options={options}
        />
      </div>
    </div>
  );
}

PlantSensorChart.propTypes = {
  data: PropTypes.shape({
    twd: PropTypes.array,
    crop_stress: PropTypes.array,
  }).isRequired,
};
