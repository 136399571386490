import { satelliteApi } from 'farmx-api';

export const getAvailableSatelliteData = async (type, id) => {
  const { data } = await satelliteApi.getAvailableSatelliteData(type, id);
  return data;
};

export const getSatelliteDataByDataTypeAndDate = (type, id, dataType, time) => (
  satelliteApi.getSatelliteDataByDataTypeAndDate(type, id, dataType, time)
);
