import moment from 'moment';

const graphColors = {
  unknown_stress: '#333', // dark green
  no_stress: '#2C6838', // dark green
  low_stress: '#53B64B', // light green
  moderate_stress: '#EE5A35', // orange
  severe_stress: '#C12C2D', // red
};

const CROP_STRESS = {
  LOW_STRESS: 'low_stress',
  MODERATE_STRESS: 'moderate_stress',
  HIGH_STRESS: 'high_stress',
  UNKNOWN_STRESS: 'unknown_stress',
};

export const getStress = (cropStress) => {
  switch (cropStress) {
    case CROP_STRESS.LOW_STRESS:
      return {
        label: 'LOW STRESS',
        type: 'primary',
      };
    case CROP_STRESS.MODERATE_STRESS:
      return {
        label: 'MED STRESS',
        type: 'primary',
      };
    case CROP_STRESS.HIGH_STRESS:
      return {
        label: 'HIGH STRESS',
        type: 'danger',
      };
    case CROP_STRESS.UNKNOWN_STRESS:
    case null:
    case undefined:
      return {
        label: 'UNKNOWN',
        type: 'primary',
      };
    default:
      return {
        label: 'NO STRESS',
        type: 'success',
      };
  }
};

export const getCropStress = (cropStressSet) => {
  if (cropStressSet.has(CROP_STRESS.HIGH_STRESS)) return CROP_STRESS.HIGH_STRESS;
  if (cropStressSet.has(CROP_STRESS.MODERATE_STRESS)) return CROP_STRESS.MODERATE_STRESS;
  if (cropStressSet.has(CROP_STRESS.LOW_STRESS)) return CROP_STRESS.LOW_STRESS;
  if (cropStressSet.has(CROP_STRESS.UNKNOWN_STRESS)) return CROP_STRESS.UNKNOWN_STRESS;
  return null;
};

export const getTwdAvg = (twdMin, twdMax) => {
  if (twdMin && twdMax) {
    return Math.floor((twdMin + twdMax) / 2);
  }
  return null;
};

export const createChartOptions = (response) => {
  const defaultChartOptions = {
    chart: {
      zoomType: 'xy',
      type: 'scatter',
      height: 150,
    },

    plotOptions: {
      scatter: {
        marker: {
          radius: 8,
        },
      },
    },

    legend: { enabled: false },

    tooltip: { enabled: false },

    xAxis: {
      type: 'datetime',
      tickInterval: 86400000, // 1 day interval (in milliseconds)
      labels: { enableHTML: true, style: { fontSize: '6px', fontWeight: 'bold' } },
    },

    yAxis: {
      tickInterval: 25,
      tickWidth: 1,
      gridLineWidth: 0,
      lineWidth: 1,
      tickAmount: 7,
      title: {
        text: 'TWD (μm)',
      },
      plotLines: [{
        color: '#55a0d6',
        dashStyle: 'dash',
        width: 1,
        value: 87.5,
      }],
      labels: { enableHTML: true, style: { fontSize: '6px', fontWeight: 'bold' } },
    },

    series: { data: [] },
  };

  let records;
  let stressData;
  let minValue;
  let maxValue;

  try {
    const twd = response.twd[0];
    records = twd.data;
    minValue = twd.min;
    maxValue = twd.max;
    stressData = response.crop_stress[0].data;
  } catch {
    return defaultChartOptions;
  }


  if (records.length === 0) return defaultChartOptions;

  const mappedStress = stressData.reduce((ms, cs) => {
    const [key, value] = cs;

    return {
      ...ms,
      [key]: value,
    };
  }, {});


  const data = records.map((record) => {
    const [x, y] = record;

    return {
      color: graphColors[mappedStress[x]],
      x: moment(x).startOf('day').valueOf(),
      y,
    };
  });

  const tickInterval = Math.ceil((maxValue - minValue) / 4);
  const plotLineValue = (maxValue + minValue) / 2;

  defaultChartOptions.yAxis.tickInterval = tickInterval;
  defaultChartOptions.yAxis.plotLines[0].value = plotLineValue;
  defaultChartOptions.series = {
    data,
  };

  return defaultChartOptions;
};
