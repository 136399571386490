import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from 'farmx-redux-core';
import { PageHeader } from '../components/PageHeader';
import { UnitItem } from './UnitItem';
import { unitsData } from './unitsData';
import { withUserSettings } from './withUserSettings';
import { isMobile } from '../../../utils/detectDevice';

const {
  patchUserSettings,
} = actions;

function Units() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userSettings = useSelector((state) => selectors.selectUserSettings(state));

  const [time, setTime] = useState(unitsData.timeOptions[0].value);
  const [length, setLength] = useState(unitsData.lengthOptions[0].value);
  const [volume, setVolume] = useState(unitsData.volumeOptions[0].value);
  const [area, setArea] = useState(unitsData.areaOptions[0].value);
  const [flowRate, setFlowRate] = useState(unitsData.flowRateOptions[0].value);
  const [power, setPower] = useState(unitsData.powerOptions[0].value);
  const [temperature, setTemperature] = useState(unitsData.temperatureOptions[0].value);

  useEffect(() => {
    if (userSettings) {
      setTime(userSettings.units_time);
      setLength(userSettings.units_length);
      setVolume(userSettings.units_volume);
      setArea(userSettings.units_area);
      setFlowRate(userSettings.units_flow_rate);
      setPower(userSettings.units_power);
      setTemperature(userSettings.units_temperature);
    }
  }, [dispatch, userSettings]);

  const updateUnit = useCallback((e) => {
    const { dataName, name, value } = e.target;

    const setters = {
      time: setTime,
      length: setLength,
      volume: setVolume,
      area: setArea,
      flowRate: setFlowRate,
      power: setPower,
      temperature: setTemperature,
    };

    setters[name](value);
    dispatch(patchUserSettings({ [dataName]: value }));
  }, [dispatch]);

  const onImperialClick = () => {
    dispatch(patchUserSettings(unitsData.imperialSelection));
  };

  const onMetricClick = () => {
    dispatch(patchUserSettings(unitsData.metricSelection));
  };

  return (
    <div className="units-container white-bg div-default">
      <PageHeader
        showBack={!!isMobile}
        title={t('Units of Measure')}
        toPath="/settings"
      />
      <div className="imperial-metric-buttons-wrapper">
        <Button onClick={onImperialClick} className="primary-button">{t('Imperial')}</Button>
        <Button onClick={onMetricClick} className="primary-button">{t('Metric')}</Button>
      </div>
      <div className="units-content">
        <div className="units-gray-bg">
          <UnitItem
            showDivider
            label={t('Time')}
            options={unitsData.timeOptions}
            value={time}
            onChange={updateUnit}
            name="time"
          />
          <UnitItem
            showDivider
            label={t('Temperature')}
            options={unitsData.temperatureOptions}
            value={temperature}
            onChange={updateUnit}
            name="temperature"
          />
          <UnitItem
            showDivider
            label={t('Length')}
            options={unitsData.lengthOptions}
            value={length}
            onChange={updateUnit}
            name="length"
          />
          <UnitItem
            showDivider
            label={t('Water')}
            options={unitsData.volumeOptions}
            value={volume}
            onChange={updateUnit}
            name="volume"
          />
          <UnitItem
            showDivider
            label={t('Area')}
            options={unitsData.areaOptions}
            value={area}
            onChange={updateUnit}
            name="area"
          />
          <UnitItem
            showDivider
            label={t('Flow Rate')}
            options={unitsData.flowRateOptions}
            value={flowRate}
            onChange={updateUnit}
            name="flowRate"
          />
          <UnitItem
            label={t('Power')}
            options={unitsData.powerOptions}
            value={power}
            onChange={updateUnit}
            name="power"
          />
        </div>
      </div>
    </div>
  );
}

export const UnitsSettings = withUserSettings(Units);
