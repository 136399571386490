import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RWebShare } from 'react-web-share';
import {
  Form, Button, DatePicker, Input,
} from 'antd';
import moment from 'moment-timezone';
import { BlockSelect } from 'farmx-web-ui';
import { selectors, actions, hooks } from 'farmx-redux-core';
import { PageHeader } from '../components/PageHeader';
import { useTracking } from '../../../helper/mixpanel';
import { blockTagRender, useColorsByBlock } from './blockTagRender';

import './Schedule.less';

const {
  selectUserTimeFormat,
  selectLoadingBlockRenderedSchedules,
  selectBlockRenderedSchedules,
} = selectors;

const {
  useRanchBlockSelection,
} = hooks;

const dateFormat = 'dddd, MMMM DD, YYYY';
const timeFormat12Hr = 'dd M-DD h:mm a';
const timeFormat24Hr = 'dd M-DD HH:mm';

export function ScheduleShare() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { blockArray } = useRanchBlockSelection();
  // limit to 10 blocks for performance reasons
  const blockIdsFromState = blockArray.slice(0, 10).map((block) => block.id);
  const [blocks, setBlocks] = useState(blockIdsFromState);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(7, 'days'));
  const [title, setTitle] = useState(t('FarmX Irrigation Schedule'));
  const [scheduleText, setScheduleText] = useState('');
  const [message, setMessage] = useState();
  const tracking = useTracking();
  const blockSchedules = useSelector((state) => selectBlockRenderedSchedules(state, {
    blockIds: blocks,
    dateStart: startDate,
    dateEnd: endDate,
  }));
  const blockNames = hooks.useBlockNames(blocks);

  const userTimeFormat = useSelector((state) => selectUserTimeFormat(state));
  const timeFormat = userTimeFormat === '24_hour' ? timeFormat24Hr : timeFormat12Hr;

  const isLoading = useSelector((state) => selectLoadingBlockRenderedSchedules(state, blocks));
  const colorsByBlock = useColorsByBlock(blocks);

  useEffect(() => {
    if (tracking) tracking.track('Loaded Schedule Calendar Page');
  }, [tracking]);

  useEffect(() => {
    dispatch(actions.loadUserSettings());
  }, [dispatch]);

  // load schedules for dates
  useEffect(() => {
    if (startDate && endDate && blocks) {
      for (let blockIndex = 0; blockIndex < blocks.length; blockIndex += 1) {
        dispatch(actions.loadBlockRenderedSchedule({
          blockId: blocks[blockIndex],
          dateStart: startDate,
          dateEnd: endDate,
        }));
      }
    }
  }, [startDate, endDate, blocks, dispatch]);

  // create schedule text
  useEffect(() => {
    const getBlockScheduleEventText = (event) => {
      const eventStart = moment(event.start_date).local();
      const eventEnd = moment(event.stop_date).local();
      return `${eventStart.format(timeFormat)} - ${eventEnd.format(timeFormat)}`;
    };

    const getBlockScheduleText = (blockSchedule, noScheduleText) => {
      const header = `${blockNames[blockSchedule.blockId]}\n`;
      const eventsText = (blockSchedule && blockSchedule.events && blockSchedule.events.length)
        ? blockSchedule.events.map((event) => getBlockScheduleEventText(event)).join('\n')
        : noScheduleText;
      const body = eventsText;
      return `${header}${body}`;
    };

    const blockSchedulesText = blockSchedules.map(
      (blockSchedule) => getBlockScheduleText(blockSchedule, t('Nothing scheduled')),
    );
    const scheduleHeader = `From: ${startDate.format(dateFormat)}\nTo: ${endDate.format(dateFormat)}`;
    const zone = moment.tz.guess();
    const timezoneAbbreviation = moment.tz(zone).format('z');
    const timezoneMessage = `All times in ${timezoneAbbreviation}`;
    const fullScheduleText = `${scheduleHeader}\n${timezoneMessage}\n\n${blockSchedulesText.join('\n\n')}`;
    setScheduleText(fullScheduleText);
  }, [
    startDate,
    endDate,
    blockSchedules,
    setScheduleText,
    t,
    blockNames,
    timeFormat,
  ]);

  const blockSelectOnChange = (blockIds) => {
    setBlocks(blockIds);
    if (tracking) tracking.track('Selected Blocks', { blockIds });
  };

  let text = `${title}\n${scheduleText}\n\n`;
  if (message && message.length) {
    text = `${message}${text}\n\n`;
  }

  return (
    <div className="page-content margin-10">
      <PageHeader showBack toPath="/schedule" title={t('share schedule')} />
      <div className="schedule-scrollable-container">
        <Form
          className="schedule-share-form"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          size="large"
        >
          <Form.Item label={t('Irrigation Blocks')}>
            <BlockSelect
              placeholder={t('Select Blocks')}
              mode="multiple"
              onChange={blockSelectOnChange}
              value={blocks}
              tagRender={(d) => blockTagRender(d, colorsByBlock)}
            />
          </Form.Item>
          <Form.Item label={t('Start Date')}>
            <DatePicker
              format={dateFormat}
              size="large"
              allowClear={false}
              inputReadOnly
              value={startDate}
              suffixIcon={null}
              onChange={(date) => setStartDate(date)}
            />
          </Form.Item>
          <Form.Item label={t('End Date')}>
            <DatePicker
              format={dateFormat}
              size="large"
              allowClear={false}
              inputReadOnly
              value={endDate}
              suffixIcon={null}
              onChange={(date) => setEndDate(date)}
            />
          </Form.Item>
          <Form.Item label={t('Title')}>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={t('Message')}>
            <Input.TextArea
              placeholder={t('Optional message')}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              autoSize={{ minRows: 3 }}
            />
          </Form.Item>
        </Form>
      </div>
      <RWebShare
        className="schedule-share"
        data={{
          text,
          url: 'https://web.farmx.co/schedule',
          title,
        }}
        onClick={() => {
          if (tracking) tracking.track('Shared Block Schedule', { blocks });
        }}
      >
        <Button className="bottom-button" disabled={isLoading} loading={isLoading} type="primary">{t('Share Schedule')}</Button>
      </RWebShare>
    </div>
  );
}
