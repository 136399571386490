import React from 'react';
import { ChangePasswordForm } from 'farmx-web-ui';
import { Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';

export default function ChangePasswordPage() {
  const { t } = useTranslation();
  return (
    <>
      <Typography.Title level={4}>{t('Change Password')}</Typography.Title>
      <Divider />
      <div className="settings-form">
        <ChangePasswordForm />
      </div>
    </>
  );
}
