import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrowUpOutlined, LoadingOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';

const { selectMapShowWind } = selectors;

const WindOverlay = (props) => {
  const { value, isLoading, windDirection } = props;
  const showWindOverlay = useSelector(selectMapShowWind);

  return showWindOverlay ? (
    <span className="map-overlay">
      {isLoading ? <LoadingOutlined /> : value}
      <ArrowUpOutlined style={{ transform: `rotate(${windDirection}deg)` }} />
    </span>
  ) : null;
};

WindOverlay.defaultProps = {
  value: '--',
  windDirection: 0,
  isLoading: false,
};

WindOverlay.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  windDirection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
};

export default WindOverlay;
