import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select, Input, Radio, Space, DatePicker, Modal, Button,
  notification,
} from 'antd';
import { scheduleApi } from 'farmx-api';
import moment from 'moment-timezone';
import { BlockSelect } from 'farmx-web-ui';
import { hooks, selectors } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { PageHeader } from '../components/PageHeader';
import { useTracking } from '../../../helper/mixpanel';
import { blockTagRender, useColorsByBlock } from './blockTagRender';
import { convertToCSV, downloadCSVFile } from '../../../helper/scheduleHelper';
import { unitsData } from '../settings/unitsData';
import './Schedule.less';

const {
  useRanchBlockSelection, useUnits,
} = hooks;

const { selectUserVolumeFormat } = selectors;

const { Option } = Select;
const { RangePicker } = DatePicker;

export function WaterUsageShare() {
  const { t } = useTranslation();

  const { blockArray } = useRanchBlockSelection();
  const getUserUnits = useUnits();

  // limit to 10 blocks for performance reasons
  const blockIdsFromState = blockArray.slice(0, 10).map((block) => block.id);
  const [blocks, setBlocks] = useState(blockIdsFromState);
  const [message, setMessage] = useState();
  const tracking = useTracking();

  const colorsByBlock = useColorsByBlock(blocks);

  const [selectedDateRange, setSelectedDateRange] = useState([
    moment().subtract(1, 'weeks').startOf('Week'),
    moment().subtract(1, 'weeks').endOf('Week')]);
  const [selectMonth, setSelectMonth] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const dateRangeOptions = ['Current Week', 'Last Week', 'This month to date', 'Choose month',
    'This season', 'This year'];
  const [emailModal, setEmailModal] = useState(false);
  // Show the loading spinner when the data is converted to CSV format and downloaded.
  const [loading, setLoading] = useState(false);
  // Show the loading spinner while email is called
  const [callingEmailApi, setCallingEmailApi] = useState(false);

  const userUnitVolume = useSelector((state) => selectUserVolumeFormat(state));
  const [units, setUnits] = useState(userUnitVolume);
  const [emailAddress, setEmailAddress] = useState(null);
  const [emailMessage, setEmailMessage] = useState('');

  // Regular expression to validate multiple email ids
  const emailRegex = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*(;|,)\s*|\s*$))*$/;

  const validateEmail = (email) => {
    setEmailAddress(email);
    if (!emailRegex.test(email)) {
      setEmailMessage('Please enter a valid email!');
    } else {
      setEmailMessage('');
    }
  };

  const blockSelectOnChange = (blockIds) => {
    setBlocks(blockIds);
    if (tracking) tracking.track('Selected Blocks', { blockIds });
  };

  const chooseDateRange = (range) => {
    let selectedDate = selectedDateRange;
    setSelectMonth(false);
    setShowDatePicker(false);
    if (range && range === 'Last Week') {
      selectedDate = [moment().subtract(1, 'weeks').startOf('Week'),
        moment().subtract(1, 'weeks').endOf('Week')];
    } if (range && range === 'Current Week') {
      selectedDate = [moment().startOf('Week'), moment().endOf('Week')];
    } if (range && range === 'This month to date') {
      selectedDate = [moment().startOf('month'), moment()];
    } if (range && range === 'Choose month') {
      selectedDate = [moment().startOf('month'), moment().endOf('month')];
      setSelectMonth(true);
    } if (range && range === 'This season') setShowDatePicker(true);
    if (range && range === 'This year') {
      selectedDate = [moment().startOf('year'), moment()];
    }
    setSelectedDateRange(selectedDate);
  };

  const chooseMonth = (month) => {
    const monthStartDate = moment(moment().month(month).format('YYYY-MM')).startOf('month');
    const monthEndDate = moment(moment().month(month).format('YYYY-MM')).endOf('month');
    setSelectedDateRange([monthStartDate, monthEndDate]);
  };

  // Optional headers can be passed to helper function
  const optionalHeaders = {
    blockId: 'Block Id',
    blockName: 'Block',
    startDate: 'Start Date',
    endDate: 'End Date',
    waterUsed: 'Water Used',
    units: 'Units',
  };

  const csvFileName = `WaterUsage-${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`;
  const dateStart = moment(selectedDateRange[0]).toISOString();
  const dateEnd = moment(selectedDateRange[1]).toISOString();

  async function fetchData() {
    scheduleApi.retrieveBlockWaterUsageData({ blockIds: blocks, dateStart, dateEnd })
      .then((waterData) => {
        const convertedData = waterData.data.map((d) => {
          if (d.units !== units) {
            const convertedUnits = getUserUnits(d.waterUsed, d.units, 'volume', {}, units);
            const obj = d;
            obj.waterUsed = convertedUnits.value;
            obj.units = convertedUnits.label;
            return obj;
          }
          return d;
        });
        const csvData = convertToCSV(convertedData, optionalHeaders);
        downloadCSVFile(csvData, csvFileName);
        setLoading(false);
        notification.success({
          message: t('Successfully loaded water usage data'),
        });
      }).catch(() => {
        setLoading(false);
        notification.error({
          message: t('Failed to load water usage data'),
        });
      });
  }

  function shareAsEmail() {
    scheduleApi.emailWaterUsageData({
      blockIds: blocks,
      dateStart,
      dateEnd,
      userEmail: emailAddress,
    }).then(() => {
      setEmailAddress(null);
      setCallingEmailApi(false);
      setEmailModal(false);
      notification.success({
        message: t('Email sent successfully'),
      });
    }).catch((error) => {
      if (error.response && error.response.status === 400) {
        notification.error({
          message: t('Bad request'),
        });
      }
      if (error.response && error.response.status === 422) {
        notification.error({
          message: t('Failed to send Email'),
        });
      }
      setCallingEmailApi(false);
    });
  }

  function renderEmailModal() {
    return (
      <Modal
        visible
        closable
        centered
        onCancel={() => {
          setEmailModal(false);
          setEmailMessage('');
        }}
        footer={null}
      >
        <div className="schedule-email-modal-container">
          <span className="schedule-email-modal">{t('Enter E-mail Address')}</span>
          <Input
            placeholder={t('E-Mail')}
            onChange={(d) => validateEmail(d.target.value)}
            value={emailAddress}
            required
          />
          <div className="email-error-message">
            {emailMessage}
          </div>
          <div className="email-button-container">
            <Button
              htmlType="submit"
              onClick={() => {
                if (emailAddress && emailAddress.includes('@')) {
                  setCallingEmailApi(true);
                  shareAsEmail();
                } else {
                  setEmailMessage('Please enter your email');
                }
              }}
              key="email"
              type="primary"
              loading={callingEmailApi}
            >
              {t('Send')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  function renderUnitControls() {
    return unitsData.volumeOptions.map((d) => (
      <Radio value={d.value} className="schedule-type">{t(d.label)}</Radio>
    ));
  }

  return (
    <div className="page-content margin-10">
      <PageHeader showBack toPath="/schedule" title={t('Share Water Usage')} />
      <div className="schedule-scrollable-container">
        <div className="schedule-share-form div-select-container">
          <BlockSelect
            placeholder={t('Select Blocks')}
            mode="multiple"
            bordered={false}
            onChange={blockSelectOnChange}
            value={blocks}
            tagRender={(d) => blockTagRender(d, colorsByBlock)}
          />
        </div>
        <div className="flex-row justify-content-center margin-top-20 margin-bottom-10">
          <span className="schedule-date-range">{t('Date Range')}</span>
          <Select
            style={{ width: '50%' }}
            defaultValue="Last Week"
            placeholder="Select"
            onChange={chooseDateRange}
          >
            {dateRangeOptions.map((option) => (
              <Option value={option}>{option}</Option>
            ))}
          </Select>
        </div>
        {selectMonth && (
        <div className="flex-row justify-content-center margin-top-20 margin-bottom-10">
          <span className="schedule-date-range">{t('Select Month')}</span>
          <Select
            style={{ width: '50%' }}
            defaultValue={moment().format('MMMM')}
            placeholder="Select Month"
            onChange={chooseMonth}
          >
            {moment.months().map((d) => (
              <Option value={d}>{d}</Option>
            ))}
          </Select>
        </div>
        )}
        <div className="flex-row justify-content-center margin-top-20 margin-bottom-10">
          {showDatePicker
            ? (
              <RangePicker
                defaultValue={[moment(moment().subtract(1, 'year')
                  .month(10).startOf('month'), 'YYYY-MM-DD'),
                moment(moment(), 'YYYY-MM-DD')]}
                format="YYYY-MM-DD"
              />
            )
            : (
              <span className="schedule-date-value schedule-font-style">
                {`${selectedDateRange[0].format('ll')} - ${selectedDateRange[1].format('ll')}`}
              </span>
            ) }
        </div>
        <div className="flex-column align-items-center">
          <div className="water-usage-share">
            <span className="schedule-font-style">{t('Type')}</span>
            <span className="schedule-font-style">:</span>
            <span className="schedule-type">{t('CSV')}</span>
          </div>
          <div className="schedule-share-types">
            <span className="schedule-font-style">{t('Share as')}</span>
            <span className="schedule-font-style">:</span>
            <Radio.Group
              onChange={(d) => setUnits(d.target.value)}
              value={units}
            >
              <Space direction="vertical">
                {renderUnitControls()}
              </Space>
            </Radio.Group>
          </div>
        </div>
        <span className="schedule-date-range">{t('Message')}</span>
        <Input.TextArea
          placeholder={t('Optional message')}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          autoSize={{ minRows: 3 }}
        />
      </div>
      {emailModal && renderEmailModal()}
      <div className="flex-row margin-bottom-10">
        <Button
          onClick={() => setEmailModal(true)}
          type="primary"
          className="flex-grow-1"
          size="large"
        >
          {t('Share Water Usage')}
        </Button>
        <Button
          className="flex-grow-1 margin-left-10"
          key="save"
          size="large"
          loading={loading}
          onClick={() => {
            setLoading(true);
            fetchData();
          }}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );
}
