import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions, hooks } from 'farmx-redux-core';
import { useLocation } from 'react-router-dom';
import { CardLayout } from '../../components/CardLayout';
import PressureSummary from '../ControlSummary/PressureSummary';
import { PressureSensorItem } from './PressureSensorItem';
import { useTracking } from '../../../../helper/mixpanel';

const { selectPressureDetails } = selectors;

const { loadAllSensors, loadSensorStatus } = actions;

const { useBlockNames } = hooks;

export function PressureSensorsList(props) {
  const { match } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const blockId = Number(match.params.id);
  const blockName = useBlockNames([blockId]);
  const pressureSensors = useSelector((state) => selectPressureDetails(state, blockId));
  const { state } = location;
  const { pressureSensorIdentifiers } = pressureSensors;
  const tracking = useTracking();

  useEffect(() => {
    if (tracking) tracking.track('Loaded Pressure Sensors');
  }, [tracking]);

  // This will be refactored in future
  if (pressureSensors && pressureSensors.pressureSensorsList) {
    const modifiedList = pressureSensors.pressureSensorsList.map((d) => ({
      units: { waterPressure: d.units || 'psi' },
      waterPressure: d.pressure ? Number(d.pressure.split(' psi')[0]) : 0,
      online: d.sensorOnline,
      ...d,
    }));
    pressureSensors.pressureSensorsList = modifiedList;
  }

  const refreshPressureSensorStatus = useCallback(() => {
    JSON.parse(pressureSensorIdentifiers).forEach((identifier) => {
      const sensorParams = { type: 'water_pressure', identifier };
      dispatch(loadSensorStatus(sensorParams));
    });
  }, [dispatch, pressureSensorIdentifiers]);

  useEffect(() => {
    if (!state) {
      dispatch(loadAllSensors());
    }
  }, [dispatch, state]);

  useEffect(() => {
    if (!state) {
      refreshPressureSensorStatus();
    }
  }, [refreshPressureSensorStatus, state]);

  if (!pressureSensors) {
    return <h2>{t('Pressure Sensor details not available')}</h2>;
  }

  return (
    <div className="div-default pressure-sensors-container">
      <div className="block-name margin-bottom-10">
        {state ? ( // To hide the back button when page directly load by URL based on state
          <Button
            className="back-button"
            icon={<LeftOutlined />}
            onClick={() => props.history.goBack()}
            shape="circle"
            size="large"
          />
        ) : null}
        <Typography.Title level={4}>{`${t('Pressure Sensors')}`}</Typography.Title>
      </div>
      <div className="item-list">
        <CardLayout>
          <PressureSummary
            id={`pressure-${blockName[blockId]}`}
            displayTitle={false}
            pressure={pressureSensors.pressure}
            valveList={pressureSensors.sensorList}
            pressureCutoff={pressureSensors.pressureCutoff}
          />
        </CardLayout>

        {pressureSensors.pressureSensorsList && pressureSensors.pressureSensorsList.length
          ? pressureSensors.pressureSensorsList.map((ps) => (
            <PressureSensorItem
              key={ps.sensorId}
              sensorDetail={ps}
            />
          )) : (
            <div className="mobile-list-item no-config control-card">
              {t('No Sensors Connected')}
            </div>
          )}
      </div>
    </div>
  );
}

PressureSensorsList.defaultProps = {
  history: {},
  match: {},
};

PressureSensorsList.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};
