import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import HelpButton from './HelpButton';
import ZendeskHelpModal from './ZendeskHelpModal';

export default function HelpButtonWithZendeskHelpModal(props) {
  const { articleId, buttonClassName, modalClassName } = props;
  const [visible, setVisible] = useState(false);
  const onClick = useCallback(() => {
    setVisible(true);
  }, []);
  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      <HelpButton onClick={onClick} className={buttonClassName} />
      <ZendeskHelpModal
        className={modalClassName}
        articleId={articleId}
        visible={visible}
        onCancel={onCancel}
      />
    </>
  );
}

HelpButtonWithZendeskHelpModal.defaultProps = {
  buttonClassName: '',
  modalClassName: '',
};
HelpButtonWithZendeskHelpModal.propTypes = {
  articleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  buttonClassName: PropTypes.string,
  modalClassName: PropTypes.string,
};
