import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { hooks } from 'farmx-redux-core';
import { PageHeader } from './PageHeader';
import './zendesk-article.less';

/**
 * Loads the article from zendesk
 */
export default function ZendeskArticle(props) {
  const { t } = useTranslation();
  const { match } = props;
  const { articleId } = match.params;

  const [data, loading, error] = hooks.useZendeskHelpContent(articleId);
  const { article } = data || {};
  const { title, body } = article || {};
  const renderContent = () => {
    if (loading) {
      return <LoadingOutlined />;
    }
    if (error) {
      return (
        <Typography.Text type="danger">
          {t('Error in loading help article.')}
          {' '}
          {error}
        </Typography.Text>
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: body }} />;
  };

  return (
    <div className="padding-10 div-default white-bg">
      <div className="zendesk-article">
        <PageHeader
          showBack
          title={title}
        />
        <div className="padding-20">{renderContent()}</div>
      </div>
    </div>
  );
}

ZendeskArticle.defaultProps = {
  match: {
    params: {
      articleId: '',
    },
  },
};

ZendeskArticle.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};
