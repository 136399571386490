/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { isMobile } from './detectDevice';

export const withPlatformDetect = (MobileComponent, DesktopComponent) => (props) => {
  if (isMobile) {
    return <MobileComponent {...props} />;
  }
  return <DesktopComponent {...props} />;
};
