import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { selectors, actions } from 'farmx-redux-core';
import { PageHeader } from '../components/PageHeader';
import { CardLayout } from '../components/CardLayout';
import RanchList from './RanchList';
import './farmhand.less';

const {
  selectAllRanchesAlphabetically,
  selectRanchesLoadingState,
} = selectors;

const {
  loadFarmData,
} = actions;

const RanchSelection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const ranches = useSelector(selectAllRanchesAlphabetically);
  const { loading } = useSelector(selectRanchesLoadingState);
  useEffect(() => {
    dispatch(loadFarmData(true));
  }, [dispatch]);

  return (
    <div className="div-default farmhand-content padding-10">
      <PageHeader
        title={t('Select a Ranch to Irrigate')}
      />
      <CardLayout>
        {loading ? (
          <Typography.Title level={3}><LoadingOutlined /></Typography.Title>
        ) : (
          <RanchList list={ranches} />
        )}
      </CardLayout>
    </div>
  );
};

export default RanchSelection;
