import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

const BlockListItem = (props) => {
  const { name, ranchId, blockId } = props;

  return (
    <div className="farmhand-ranch-block-item">
      <Link to={`/farmhand/ranches/${ranchId}/${blockId}`}>
        <Typography.Title level={4}>{name}</Typography.Title>
      </Link>
    </div>
  );
};

BlockListItem.propTypes = {
  name: PropTypes.string.isRequired,
  ranchId: PropTypes.string.isRequired,
  blockId: PropTypes.number.isRequired,
};

export default BlockListItem;
