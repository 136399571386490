import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ChartComponent } from 'farmx-web-ui';
import Moment from 'moment';
import { sensorApi, helpers } from 'farmx-api';
import { actions } from 'farmx-redux-core';
import {
  colorCritical, colorWarning, colorOver, colorOk,
} from '../../utils/colors';

const { fieldToHeader } = helpers;
const { loadSensorData } = sensorApi;
const { loadSensorDetail } = actions;

export default function SensorDataChart(props) {
  const {
    sensor: sensorProps,
    variables,
    startDate,
    endDate,
    mergeAxes,
    compact,
  } = props;

  const { type, identifier, id: sensorId } = sensorProps;
  const dispatch = useDispatch();

  const chartRef = useRef(React.createRef());
  const [localChartData, setLocalChartData] = useState(null);

  const validInput = !!(
    sensorId
    && type
    && variables
    && variables.length
    && startDate
    && endDate
  );

  // load initial data
  useEffect(() => {
    if (type && identifier) {
      dispatch(loadSensorDetail({ type, identifier }));
    }
  }, [dispatch, type, identifier]);

  // update data when config changes
  // Disabled eslint rule to avoid multiple API calls for same details
  useEffect(() => {
    function refreshData() {
      const chartObj = chartRef.current.highchartsComponent.current.chart;
      if (!validInput) {
        if (chartObj) chartObj.hideLoading();
        setLocalChartData(null);
        return;
      }
      if (chartObj) chartObj.showLoading();
      loadSensorData(type, sensorId, variables, startDate, endDate).then((response) => {
        if (response && response.status === 200) {
          setLocalChartData(response.data);
        }
        if (chartObj) chartObj.hideLoading();
      });
    }
    refreshData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validInput, startDate, endDate]);

  function preparePlotLines(linesConfig) {
    const defaultStyle = {
      zIndex: 100,
      dashStyle: 'dash',
      width: 2,
    };
    const plotLinesArr = linesConfig.map((configObj) => ({ ...configObj, ...defaultStyle }));
    const filteredConfig = linesConfig.filter((d) => d?.type !== 'middle');
    const plotBandConfigArr = filteredConfig.map((d) => ({
      from: d.from,
      to: d.to,
      color: d.color,
    }));
    return { plotLines: plotLinesArr, plotBands: plotBandConfigArr };
  }

  function buildConfig(seriesData) {
    if (!seriesData) return {};

    const yAxisByUnit = {};
    const allSeries = Object.entries(seriesData).map(([key, value]) => {
      if (!value.length) return undefined;
      const chartData = value[0];
      if (!chartData.data.length) return undefined;

      const { data } = chartData;

      // detect values as strings and convert
      if (typeof data[0][1] === 'string') {
        data.map((datum) => [datum[0], parseFloat(datum[1])]);
      }

      // if int, don't set decimal places, otherwise set to 2
      const isFloat = data[0][1] % 1 !== 0;
      const decimals = isFloat ? 2 : undefined;

      const chartUnits = chartData.units || '';
      const axisKey = mergeAxes ? chartUnits : chartUnits + key;
      const regex = /&deg;/g;
      const units = chartUnits.replace(regex, 'º');
      const unitsStr = ` (${units})`;
      const variableName = fieldToHeader(key);
      const legendTitle = variableName + unitsStr;

      let plotLines = [];
      let plotBands = [];
      const soilData = seriesData.soil_moisture_rootzone_vwc;
      if (chartData && soilData) {
        const linesConfig = [{
          color: colorOver,
          value: chartData.moisture_zone_upper,
          type: 'upper',
          from: chartData.moisture_zone_upper,
          to: (chartData.moisture_zone_upper || 0) + 1,
          className: 'upper-band-line',
        },
        { color: colorWarning, value: chartData.moisture_zone_middle, type: 'middle' },
        {
          color: colorCritical,
          value: chartData.moisture_zone_lower,
          type: 'lower',
          from: 0,
          to: chartData.moisture_zone_lower,
          className: 'lower-band-line',
        }];
        const plotLinesConfig = preparePlotLines(linesConfig);
        plotLines = plotLinesConfig.plotLines;
        plotBands = plotLinesConfig.plotBands;
      }

      let yAxis = yAxisByUnit[axisKey];
      if (!yAxis) {
        yAxis = {
          title: null,
          id: axisKey,
          opposite: false,
          min: chartData.moisture_zone_lower || parseFloat(chartData.min),
          max: chartData.moisture_zone_upper || parseFloat(chartData.max),
          plotLines,
          plotBands,
        };
        yAxisByUnit[axisKey] = yAxis;
      } else {
        yAxis.title = null;
        yAxis.min = Math.min(yAxis.min, chartData.min);
        yAxis.max = Math.max(yAxis.max, chartData.max);
        yAxis.plotLines = plotLines;
      }

      if (compact) {
        yAxis.title = null;
        yAxis.labels = {
          x: 0,
          y: -2,
          align: 'left',
        };
      }

      return {
        name: legendTitle,
        type: 'line',
        data,
        yAxis: axisKey,
        tooltip: {
          valueDecimals: decimals,
        },
      };
    });
    return {
      yAxis: Object.values(yAxisByUnit),
      series: allSeries.filter(Boolean),
    };
  }

  const config = buildConfig(localChartData);

  const xAxisLabels = compact
    ? {
      y: 10,
      x: 1,
      align: 'left',
    } : {};

  const spacing = compact
    ? [1, 1, 1, 1] : [10, 10, 15, 10];

  return (
    <ChartComponent
      ref={chartRef}
      options={{
        legend: {
          enabled: false,
        },
        chart: {
          zoomType: 'xy',
          spacing,
        },
        xAxis: {
          type: 'datetime',
          ordinal: false,
          gridLineWidth: 1,
          labels: xAxisLabels,
          alternateGridColor: 'rgba(200,200,200,0.1)',
        },
        plotOptions: type?.includes('soil') ? {
          series: {
            color: colorOk,
          },
        } : {},
        ...config,
      }}
    />
  );
}

SensorDataChart.propTypes = {
  compact: PropTypes.bool,
  variables: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.instanceOf(Moment),
  endDate: PropTypes.instanceOf(Moment),
  sensor: PropTypes.shape({
    type: PropTypes.string,
    identifier: PropTypes.string,
    id: PropTypes.number,
  }),
  mergeAxes: PropTypes.bool,
};

SensorDataChart.defaultProps = {
  compact: true,
  variables: [],
  startDate: null,
  endDate: null,
  sensor: null,
  mergeAxes: false,
};
