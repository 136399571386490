import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { CardLayout } from '../../components/CardLayout';
import IrrigationStatus from '../../components/IrrigationStatus';
import WaterInformation from '../../control/ControlSummary/WaterInformation';
import './block-schedule-control.css';
import { getDateRangeForScheduler } from '../../../../helper/scheduleHelper';

const { loadBlockSchedule, loadBlockRenderedSchedule, loadBlockControlStatus } = actions;

const {
  selectBlockControlStatus,
} = selectors;

const {
  useBlockNames,
} = hooks;

export const BlockScheduleControl = ({ blockId, eventId }) => {
  const dispatch = useDispatch();

  // FF-751: Below codes to display irrigation status on scheduleDetails page
  const valveStatus = useSelector((state) => selectBlockControlStatus(state, [blockId]));
  const vStatus = (valveStatus && valveStatus[blockId].valveStatus) || {};
  const pStatus = (valveStatus && valveStatus[blockId].vfdStatus) || {};

  const blockName = useBlockNames([blockId]);
  // The one month date range is needed to select the scheduled events
  const dates = [moment(), moment().add(1, 'month')];

  const units = vStatus.units || {
    pressureCutoff: 'psi',
    actualVolume: 'gallons',
  };

  const scheduleDetail = {
    blockId,
    valveCurrentState: (vStatus.controlStatus && vStatus.controlStatus.state) || '',
    blockName: blockName[blockId],
    pumpCurrentState: (pStatus.controlStatus && pStatus.controlStatus.status) || '',
    irrigationStartDate: vStatus.scheduleStatus && vStatus.scheduleStatus.dateScheduledOpen,
    irrigationEndDate: vStatus.scheduleStatus && vStatus.scheduleStatus.dateScheduledClose,
  };

  const dateRange = getDateRangeForScheduler(dates[0]);
  const [dStart, dEnd] = dateRange.map((d) => moment(d).format());

  useEffect(() => {
    // Loading old API for Actual gallons details
    dispatch(loadBlockSchedule(blockId));
    dispatch(loadBlockRenderedSchedule({
      blockId,
      dateStart: moment(dStart),
      dateEnd: moment(dEnd),
    }));
    dispatch(loadBlockControlStatus(blockId));
  }, [blockId, dStart, dEnd, dispatch]);

  return (
    <CardLayout>
      <div className="flex-column">
        <div className="flex-grow-1 text-align-center">
          <div className="flex-grow-1 text-align-center flex-column">
            <IrrigationStatus block={scheduleDetail} />
          </div>
        </div>
        <div className="flex-column">
          <WaterInformation
            blockId={blockId}
            units={units}
            dateRange={dates}
            eventId={eventId}
          />
        </div>
      </div>
    </CardLayout>
  );
};

BlockScheduleControl.propTypes = {
  blockId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
};
