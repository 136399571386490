import React from 'react';
import { Typography, Tooltip } from 'antd';
import { SensorAlarmButton } from 'farmx-web-ui';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import ValveStateText from '../../components/ValveStateText';
import ConnectivityStateTag from '../../components/ConnectivityStateTag';

export default function ControlValveStatus(props) {
  const { t } = useTranslation();
  const { valve, showBlockName, showHeader } = props;
  const { Text, Title } = Typography;

  function renderTitleAndAlarmSection() {
    return (
      <>
        <div className="title-and-alarm-container">
          <Tooltip
            title={!showBlockName ? `${valve.valveName}` : `${valve.blockName} Valves`}
            trigger="click"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            <Title level={4} className="text-ellipsis">
              {!showBlockName
                ? `${valve.valveName}` : `${valve.blockName} Valves`}
            </Title>
          </Tooltip>
          {valve.valveLoading ? <LoadingOutlined className="pump-valve-loader" /> : null}
          <SensorAlarmButton
            isMobileView
            alarmCount={valve.alarmCount}
            identifier={valve.identifier}
            type={valve.type}
          />
        </div>
        <div className="valve-blockname">
          { valve && valve.blockNames
          && <Typography.Link>{valve.blockNames.join(', ')}</Typography.Link>}
        </div>
        <div>
          <Text type="secondary">
            {`${t('Updated')} `}
            <Moment fromNow>{valve.lastUpdated}</Moment>
          </Text>
        </div>
      </>
    );
  }
  return (
    <>
      {showHeader && renderTitleAndAlarmSection()}
      <div className="pump-status-container" data-testid="valve-detail-card">
        <ValveStateText
          state={valve.valveCurrentState}
          valveName={showBlockName ? valve.valveName : null}
        />
        <ConnectivityStateTag
          isOnline={valve.valveOnline}
        />
      </div>
    </>
  );
}

ControlValveStatus.propTypes = {
  valve: PropTypes.objectOf(Object).isRequired,
  showHeader: PropTypes.bool,
  showBlockName: PropTypes.bool,
};

ControlValveStatus.defaultProps = {
  showHeader: true,
  showBlockName: false,
};
