import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions, hooks } from 'farmx-redux-core';
import ControlValveStatus from './ControlValveStatus';
import ValveOverrideButtons from '../ControlSummary/ValveOverrideButtons';
import PressureSummaryList from '../ControlSummary/PressureSummaryList';
import { useTracking } from '../../../../helper/mixpanel';
import RanchBlockTitle from '../../components/RanchBlockTitle';

const {
  selectPressureDetails,
  selectBlockValveControllers,
} = selectors;

const { loadBlockIrrigationSchedule, loadBlockSchedule } = actions;

const { useBlockNames } = hooks;

const getValveDetails = (data, bName) => {
  const valveObj = {
    blockSchedule: [],
    irrigationStartDate: null,
  };

  if (data && data.scheduleStatus) {
    const { scheduleStatus } = data;
    valveObj.irrigationStartDate = scheduleStatus.dateScheduledOpen;
    valveObj.blockSchedule.push({
      blockName: bName,
      scheduledTime: scheduleStatus.dateScheduledOpen,
      endTime: scheduleStatus.dateScheduledClose,
    });
  }
  return valveObj;
};

export function ControlValveDetail(props) {
  const { match, valveId, showPressureSummary } = props;
  let valveDetails = [];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const tracking = useTracking();

  const blockId = match.params ? Number(match.params.id) : valveId;

  const pressureSensors = useSelector((state) => selectPressureDetails(state, blockId));
  const valveObj = useSelector((state) => selectBlockValveControllers(state, blockId));
  const { state } = location;
  const vIdentifier = state && state.identifier;

  const blockIds = (valveObj && valveObj[vIdentifier] && valveObj[vIdentifier].irrigation_blocks)
   || [blockId];

  const blockName = useBlockNames(blockIds);

  useEffect(() => {
    if (tracking) tracking.track('Loaded Valve Details', { blockId });
  }, [blockId, tracking]);

  useEffect(() => {
    dispatch(loadBlockIrrigationSchedule(blockId));
    // Loading old API for Actual gallons details
    dispatch(loadBlockSchedule(blockId));
  }, [blockId, dispatch]);

  const allValveDetails = Object.keys(valveObj).map((d) => {
    const vCtrl = valveObj[d];
    const statusObj = vCtrl && vCtrl.status;
    if (statusObj) {
      return {
        valveCurrentState: (statusObj.controlStatus && statusObj.controlStatus.state) || '',
        blockName: blockName[blockId],
        alarmCount: statusObj.alarmCount,
        valveName: (vCtrl.name) || (vCtrl.identifier),
        lastUpdated: statusObj.lastUpdated,
        valveOnline: statusObj.online,
        // These units are expected from valve status api
        units: statusObj.units || {
          pressureCutoff: 'psi',
          actualVolume: 'gallons',
        },
        identifier: vCtrl.identifier,
        type: vCtrl.type,
        irrigationStartDate: getValveDetails(statusObj, blockName[blockId]).irrigationStartDate,
        valveSchedule: getValveDetails(statusObj, blockName[blockId]).valveSchedule,
      };
    }
    return null;
  });

  const filteredValveDetail = allValveDetails.filter((d) => d && d.identifier === vIdentifier);

  if (filteredValveDetail.length) {
    valveDetails = filteredValveDetail;
  } else valveDetails = allValveDetails;

  const isMultipleValves = valveDetails.length > 1;

  if (!valveDetails[0]) {
    return (
      <div className="block-details-additional-info mobile-list-item">
        {t('Valve details not available')}
      </div>
    );
  }

  function renderDetails() {
    return (
      <div className="block-details-additional-info mobile-list-item">
        {valveDetails.map((valveDetail, index) => (
          <>
            <ControlValveStatus
              valve={valveDetail}
              showHeader={!index}
              showBlockName={isMultipleValves}
            />
            <div className="valve-button">
              <ValveOverrideButtons
                irrigationStartDate={valveDetail.irrigationStartDate}
                valveSchedule={valveDetail.valveSchedule}
                valveName={`${valveDetail.blockName} (${t('Valve')})`}
                valveIdentifier={valveDetail.identifier}
                valveOverrideStatus={valveDetail.overrideStatus}
              />
            </div>
          </>
        ))}
        {showPressureSummary
        && (
        <Link
          to={{
            pathname: `/control/sensors/${blockId}`,
            state: { prevPath: window.location.pathname },
          }}
        >
          {blockIds.map((blkId) => (
            <PressureSummaryList
              displayTitle={false}
              id={`pressure-${blockName[blkId]}`}
              pressure={pressureSensors.pressure}
              valveList={pressureSensors.sensorList}
              loading={pressureSensors.loading}
              pressureCutoff={pressureSensors.pressureCutoff}
              units={valveDetails.units}
              blockName={blockName[blkId]}
            />
          ))}
        </Link>
        )}
      </div>
    );
  }

  const ranchBlockName = `${t('Valve Details')} > ${valveDetails[0].valveName}`;

  return (
    <>
      {state && state.goBack ? (
        <div className="div-default block-details-container">
          <div className="block-name">
            <Button
              icon={<LeftOutlined />}
              onClick={() => {
                if (tracking) tracking.track('Pressed Back Button');
                props.history.goBack();
              }}
              shape="circle"
              size="large"
            />
            <RanchBlockTitle title={ranchBlockName} />
          </div>
          {renderDetails()}
        </div>
      ) : renderDetails()}
    </>
  );
}

ControlValveDetail.defaultProps = {
  history: {},
  match: {},
  valveId: null,
  showPressureSummary: true,
};

ControlValveDetail.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  valveId: PropTypes.number,
  showPressureSummary: PropTypes.bool,
};
