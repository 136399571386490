import React from 'react';
import PropTypes from 'prop-types';
import ControlSummaryList from './ControlSummaryList';

export default function ControlSummary(props) {
  const { blocks } = props;
  return (
    <div className="control-summary-list-container">
      <ControlSummaryList blocks={blocks} />
    </div>
  );
}

ControlSummary.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
};

ControlSummary.defaultProps = {
  blocks: [],
};
