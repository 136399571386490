/* eslint-disable */
const RanchObject = function (ranch, font) {
  this.time = 0;
  this.font = font;

  this.shape = createSimpleShape(ranch.bounds);
  this.geometry = new THREE.ShapeGeometry(this.shape);

  this.baseMaterial = new THREE.MeshBasicMaterial({
    color: 0xff00ff,
    vertexColors: THREE.VertexColors
  });

  this.meshMain = new THREE.Mesh(this.geometry, this.baseMaterial);
  this.mesh = this.meshMain;
  this.bbox = new THREE.Box3().setFromObject(this.mesh);

  this.blocks = loadBlocks(ranch.ranches);
  this.blockGroup = new THREE.Group();
  for (var i = 0; i < this.blocks.length; i++) {
    this.blockGroup.add(this.blocks[i].soilSegments);
  }

  this.blockClusterGroup = new THREE.Group();
  for (var i = 0; i < this.blocks.length; i++) {
    this.blockClusterGroup.add(this.blocks[i].soilSegmentsCluster);
  }

  this.objects = new THREE.Group();
  this.displayBlocks(this.blocks);

  this.selectedBlock = null;

  this.scale = 1;
  this.scaleOverhead = 1;
  this.scaleSide = 4;
};

var loadBlocks = function (blocksData) {
  var blocks = [];
  for (var i = 0; i < blocksData.length; i++) {
    var block = new RanchShape(blocksData[i].bounds, blocksData[i].sensors);
    blocks.push(block);
  }
  return blocks;
};

var featurePointsToArray = function (feature) {
  var points = [];
  var coords = feature.geometry.coordinates[0];
  for (var i = 0; i < coords.length; i++) {
    var coord = [coords[i][1], coords[i][0]];
    var point = getLocalPoint(coord);
    points.push(new THREE.Vector2(point[0], point[1]));
  }
  return points;
};

var createSimpleShape = function (boundary) {
  var boundaryPoints = featurePointsToArray(boundary);
  var geometry = new THREE.Shape(boundaryPoints);
  return geometry;
};

/*
 * Creates a vertical 3d ruler with width from pt1 (left) to pt2 (right)
 */
var createRuler = function (width, height, depth, font) {
  var ruler = new THREE.Group();
  var material = new THREE.LineBasicMaterial({
    color: 0xffffff
  });
  material.linewidth = 3;
  var vertGeo = new THREE.Geometry();
  vertGeo.vertices.push(new THREE.Vector3(0, 0, 0));
  vertGeo.vertices.push(new THREE.Vector3(0, 0, height));

  var vertLine = new THREE.Line(vertGeo, material);

  for (var i = 0; i <= 4; i++) {
    var z = height * i / 4.;
    var geo = new THREE.Geometry();
    geo.vertices.push(new THREE.Vector3(0, 0, z));
    geo.vertices.push(new THREE.Vector3(width, 0, z));
    var line = new THREE.Line(geo, material);
    ruler.add(line);

    var size = height / 15; // .01;

    var textGeo = new THREE.TextGeometry((4 - i) * 12 + " in", {

      font: font,

      size: size,
      height: 0,
      curveSegments: 4,

      bevelThickness: 2,
      bevelSize: 1.5,
      bevelEnabled: false,

      material: 0,
      extrudeMaterial: 1

    });

    var textZ = z - size / 4;
    if (i == 0) textZ = z;

    //textGeo.applyMatrix( new THREE.Matrix4().makeTranslation(width/2+.01, 0, textZ) );
    var textMesh = new THREE.Mesh(textGeo, material);
    textMesh.position.x = width + .01;
    textMesh.position.z = textZ;
    textMesh.position.y = 0;
    textMesh.rotation.x = Math.PI / 2;
    textMesh.scale.set(2, .5, 1);
    ruler.add(textMesh);
  }

  var numStops = 8;
  for (var i = 0; i <= numStops; i++) {
    var z = height * i / numStops;
    var geo = new THREE.Geometry();
    geo.vertices.push(new THREE.Vector3(0, 0, z));
    geo.vertices.push(new THREE.Vector3(width / 2, 0, z));
    var line = new THREE.Line(geo, material);
    ruler.add(line);
  }

  var numStops = 16;
  for (var i = 0; i <= numStops; i++) {
    var z = height * i / numStops;
    var geo = new THREE.Geometry();
    geo.vertices.push(new THREE.Vector3(0, 0, z));
    geo.vertices.push(new THREE.Vector3(width / 4, 0, z));
    var line = new THREE.Line(geo, material);
    ruler.add(line);

    // add depth line
    geo = new THREE.Geometry();
    geo.vertices.push(new THREE.Vector3(0, 0, z));
    geo.vertices.push(new THREE.Vector3(0, depth, z));
    line = new THREE.Line(geo, material);
    ruler.add(line);
  }

  ruler.add(vertLine);
  return ruler;
};

/*
 *      |
 *      *---*--
 *      |   |
 *    __*---*
 *          |
 *
 */
var createRulers = function (bbox, height, font) {
  var rulers = new THREE.Group();

  var width = .2 * height;
  var padding = 0.1 * height;

  var ruler = createRuler(width, height, bbox.max.y - bbox.min.y, font);
  ruler.translateX(bbox.max.x + padding);
  ruler.translateY(bbox.min.y);
  rulers.add(ruler);

  ruler = createRuler(width, height, bbox.max.y - bbox.min.y, font);
  ruler.translateX(bbox.max.x);
  ruler.translateY(bbox.max.y + padding);
  ruler.rotation.z = Math.PI / 2;
  rulers.add(ruler);

  ruler = createRuler(width, height, bbox.max.x - bbox.min.x, font);
  ruler.translateX(bbox.min.x - padding);
  ruler.translateY(bbox.max.y);
  ruler.rotation.z = Math.PI;
  rulers.add(ruler);

  ruler = createRuler(width, height, bbox.max.y - bbox.min.y, font);
  ruler.translateX(bbox.min.x);
  ruler.translateY(bbox.min.y - padding);
  ruler.rotation.z = Math.PI * 3 / 2;
  rulers.add(ruler);

  return rulers;
};

var setMapTexture = function (mesh, texture) {
  mesh.material = new THREE.MeshBasicMaterial({ map: texture.texture });//blockShape.mesh.material.map = tileTexture.texture;//
  mesh.material.opacity = 0.3;
  mesh.material.transparent = true;
};

RanchObject.prototype = {
  update: function (time) {
    this.time = time;
    for (var i = 0; i < this.blocks.length; i++) {
      var block = this.blocks[i];
      block.time = this.time;
      block.updateShaders();
    }
  },

  getCurrentScale: function () {
    if (this.overheadMode) return this.scaleOverhead;
    return this.scaleSide;
  },

  setClusterMode: function (cluster) {

    var depth = this.getCurrentScale();
    console.log("Depth", depth);

    for (var i = this.objects.children.length - 1; i >= 0; i--) { this.objects.remove(this.objects.children[i]) }
    for (var i = 0; i < this.blocks.length; i++) {
      this.blocks[i].setClusterVisible(cluster);
      this.blocks[i].setDepth(depth);
    }



    if (cluster == true) {
      if (this.blocks.length) {
        this.bbox = new THREE.Box3().setFromObject(this.blocks[0].meshCluster);
        console.log("setting cluster mesh");
        this.mesh = this.blocks[0].mesh;
      }

    } else {
      this.mesh = this.meshMain;
      this.bbox = new THREE.Box3().setFromObject(this.mesh);
    }

    this.displayBlocks(this.blocks);
    this.setTexture(this.texture);

  },

  displayBlocks: function (blocks) {
    var height = 0;
    for (var i = 0; i < blocks.length; i++) {
      var block = blocks[i];
      this.objects.add(block.mesh);
      this.objects.add(block.edges);

      this.objects.add(block.soilSegments);
      this.objects.add(block.sensorObjects);
      block.setDepth(1);

      var blockHeight = block.getHeight();
      if (blockHeight > height) height = blockHeight;
    }

    this.rulers = createRulers(this.bbox, height, this.font);
    this.objects.add(this.rulers);
  },

  displayBlocksCluster: function (blocks) {
    for (var i = 0; i < blocks.length; i++) {
      var block = blocks[i];
      //this.objectsCluster.add(block.mesh);
      //this.objectsCluster.add(block.edges);

      this.objectsCluster.add(block.soilSegmentsCluster);
      this.objectsCluster.add(block.sensorObjectsCluster);
      //this.objects.add(block.soilEdges);

      block.setHeight(0.15);
    }
  },

  getCenter: function () {
    return Geometry.getCenterPoint(this.mesh);
  },

  getHeight: function () {
    var height = 0;
    for (var i = 0; i < this.blocks.length; i++) {
      var block = this.blocks[i];
      var blockHeight = block.getHeight();
      if (blockHeight > height) height = blockHeight;
    }
    return height;
  },

  setSelectedDepth(depth) {
    for (var i = 0; i < this.blocks.length; i++) {
      var block = this.blocks[i];
      block.setSelectedDepth(depth);
    }
  },

  setDataRange(minValue, maxValue) {
    for (var i = 0; i < this.blocks.length; i++) {
      var block = this.blocks[i];
      block.setDataRange(minValue, maxValue);
    }
  },

  setOverheadView(overheadMode) {
    this.overheadMode = overheadMode;

    if (overheadMode) {
      this.animateHeight(this.scaleOverhead);
    } else {
      this.animateHeight(this.scaleSide);
    }
  },

  animateHeight: function (scale) {
    for (var i = 0; i < this.blocks.length; i++) {
      var block = this.blocks[i];
      block.animateToDepth(scale);
    }

    if (this.overheadRulerTween) {
      //this.overheadRulerTween.setPaused(true);
    }

    var properties = {
      scale: scale
    };
    var tween = createjs.Tween.get(this).to(properties, 1000, createjs.Ease.cubicInOut).addEventListener("change", handleChange);
    this.overheadRulerTween = tween;
    var that = this;
    function handleChange(event) {
      that.rulers.scale.set(1, 1, that.scale);

      if (that.scale < 1.01) {
        that.rulers.visible = false;
      } else {
        that.rulers.visible = true;
      }
    }
  },

  setTexture: function (texture) {
    this.texture = texture;
    for (var i = 0; i < this.blocks.length; i++) {
      var block = this.blocks[i];
      setMapTexture(block.meshBlock, texture);
      setMapTexture(block.meshCluster, texture);
    }
  },

  recalculateUvs: function (minX, maxX, minY, maxY) {
    for (var i = 0; i < this.blocks.length; i++) {
      var block = this.blocks[i];
      block.recalculateUvs(minX, maxX, minY, maxY);
    }
  },

  selectBlock: function (block) {
    this.selectedBlock = block;
    for (var i = 0; i < this.blocks.length; i++) {
      var b = this.blocks[i];
      if (block == null || i == block) {
        b.setSelected(true);
      } else {
        b.setSelected(false);
      }
    }
  },

  selectBlockObject: function (block) {

    for (var i = 0; i < this.blocks.length; i++) {
      var b = this.blocks[i];
      if (block == null || this.blocks[i] == block) {
        b.setSelected(true);
        this.selectedBlock = i;
      } else {
        b.setSelected(false);
      }
    }
  },

  fadeRuler: function (ruler, opacity) {
    ruler.visible = opacity;
  },

  showRuler: function (ruler) {
    for (var i = 0; i < this.rulers.children.length; i++) {
      if (ruler == i) {
        this.fadeRuler(this.rulers.children[i], true);
      } else {
        this.fadeRuler(this.rulers.children[i], false);
      }
    }
  },

};

module.exports = RanchObject;
