/* eslint-disable no-param-reassign */
/* seslint-disable */

/* global MAX_ZOOM,SCALING_OFFSET,SCALING_FACTOR,SCALING_OFFSET */

import {
  THREE,
  Geometry,
  Tile,
  RanchObject,
  Hammer,
} from './deps';

export const calcCameraDist = (ranch, camera) => {
  const width = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

  const height = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

  ranch.current.mesh.geometry.computeBoundingSphere();
  const objectSize = ranch.current.mesh.geometry.boundingSphere.radius * 1.2;

  // Convert camera fov degrees to radians
  const fov = camera.current.fov * (Math.PI / 180);
  let paddingW = 400;
  if (width < 800) {
    paddingW = 0;
  }

  // Calculate the camera distance
  const distance = Math.abs((objectSize * height) / (width - paddingW) / Math.sin(fov / 2));
  // const distance2 = ranch.current.getHeight() * 10;

  return distance;
};

export const tileImage = (z, x, y) => `https://api.mapbox.com/v4/mapbox.satellite/${z}/${x}/${y}@2x.png?access_token=pk.eyJ1IjoiZmFybXgiLCJhIjoiY2lnODkydzJpMDFlanR6bTdjOXB6MDJyMSJ9.w2lYGZBvj_OP5jbiK4KRAw`;

export const renderTileTexture = (
  minCoord,
  maxCoord,
  ranchObject,
  zoom,
  cameraRTT,
  sceneRTT,
  renderer,
  renderedTexture,
) => {
  const minTile = Geometry.getTileCoords(Geometry.getPositionFromLocal(minCoord), zoom);
  const maxTile = Geometry.getTileCoords(Geometry.getPositionFromLocal(maxCoord), zoom);

  let width = maxTile.x - minTile.x + 1;
  let height = maxTile.y - minTile.y + 1;

  if (width % 2 !== 0) {
    width += 1;
    maxTile.x += 1;
  }
  if (height % 2 !== 0) {
    height += 1;
    maxTile.y += 1;
  }

  if (width > height) {
    const diff = width - height;
    height += diff;
    maxTile.y += diff;
  } else if (width < height) {
    const diff = height - width;
    width += diff;
    maxTile.x += diff;
  }

  // TODO: convert to local coords
  const scaleFactor = 2 ** (MAX_ZOOM - zoom);
  const centerX = ((minTile.x + maxTile.x + 1) / (2 * scaleFactor) - SCALING_OFFSET.x)
    * SCALING_FACTOR;

  const centerY = ((minTile.y + maxTile.y + 1) / (2 * scaleFactor) - SCALING_OFFSET.y)
    * SCALING_FACTOR;

  const viewSize = width * scaleFactor * SCALING_FACTOR;

  cameraRTT.current = new THREE
    .OrthographicCamera(-viewSize / 2, viewSize / 2, viewSize / 2, viewSize / (-2), -10000, 10000);
  cameraRTT.current.position.x = centerX;
  cameraRTT.current.position.y = -centerY;
  cameraRTT.current.position.z = 10;
  cameraRTT.current.up = new THREE.Vector3(0, 1, 0);
  cameraRTT.current.lookAt(new THREE.Vector3(centerX, -centerY, 0));

  // const numTiles = width * height;

  const textureSize = 512;
  const size = textureSize * width;

  // TODO check this line for scaling
  const minX = (minTile.x * scaleFactor - SCALING_OFFSET.x) * SCALING_FACTOR;
  const maxX = ((maxTile.x + 1) * scaleFactor - SCALING_OFFSET.x) * SCALING_FACTOR;
  const minY = ((-maxTile.y - 1) * scaleFactor + SCALING_OFFSET.y) * SCALING_FACTOR;
  const maxY = (-(minTile.y) * scaleFactor + SCALING_OFFSET.y) * SCALING_FACTOR;

  ranchObject.current.recalculateUvs(minX, maxX, minY, maxY);

  for (let { x } = minTile; x < maxTile.x + 1; x += 1) {
    for (let { y } = minTile; y < maxTile.y + 1; y += 1) {
      const tile = new Tile(x, y, zoom);
      tile.shaderMaterial.uniforms.saturation.value = 1.0;
      tile.load(tileImage(tile.zoom, Math.floor(tile.x), Math.floor(tile.y)), renderer.current);
      sceneRTT.current.add(tile.mesh);
    }
  }

  const tileTexture = new THREE.WebGLRenderTarget(size, size, { format: THREE.RGBFormat });

  try {
    // this will throw in case of CanvasRenderer
    tileTexture.anisotropy = renderer.current.getMaxAnisotropy();
  } catch (error) {
    tileTexture.anisotropy = 1;
  }

  renderedTexture.current = tileTexture;
  return tileTexture;
};

export const showGround = (
  visible,
  tiles,
  ground,
  ranchObject,
) => {
  for (let i = 0; i < tiles.current.length; i += 1) {
    if (visible) {
      tiles.current[i].setOpacity(1.0);
    } else {
      tiles.current[i].setOpacity(0.1);
    }
  }
  ground.current.mesh.visible = visible;
  ranchObject.current.mesh.visible = visible;
};

export const loadTiles = (
  zoom,
  offset,
  ranchCenter,
  renderer,
  tileScene,
  tiles,
) => {
  const z = zoom;
  const radius = 6;

  const center = Geometry.getPositionFromLocal(ranchCenter);
  const centerCoords = Geometry.getTileCoords(center, z);

  // const width = radius * 2 + 1;
  for (let xi = 0; xi < radius * 2 + 1; xi += 1) {
    const xeven = (xi + 1) % 2;
    const x = ((xeven - 1) * (xi + 1)) / 2 + (xeven * xi) / 2;

    const xPos = centerCoords.x + x;
    for (let yi = 0; yi < radius * 2 + 1; yi += 1) {
      const yeven = (yi + 1) % 2;
      const y = ((yeven - 1) * (yi + 1)) / 2 + (yeven * yi) / 2;
      const yPos = centerCoords.y + y;

      const tile = new Tile(xPos, yPos, z);
      tile.load(tileImage(tile.zoom, Math.floor(tile.x), Math.floor(tile.y)), renderer.current);
      tile.mesh.material.uniforms.fade.value = 1.0;

      tile.mesh.material.uniforms.fadeCenter.value = ranchCenter;
      tile.mesh.material.uniforms.saturation.value = 0.3;

      tileScene.current.add(tile.mesh);
      tile.mesh.position.z += offset;

      tiles.current.push(tile);
    }
  }
};

export const loadRanch = (
  blockData,
  ranchObject,
  font,
  tileScene,
  cam,
  camera,
  ground,
  cameraRTT,
  sceneRTT,
  renderer,
  renderedTexture,
) => {
  const ranchData = blockData;
  ranchObject.current = new RanchObject(ranchData, font.current);

  tileScene.current.add(ranchObject.current.objects);
  const center = ranchObject.current.getCenter();

  cam.current.center = center;
  cam.current.zoom = calcCameraDist(ranchObject, camera);
  cam.current.focusPointSide(center);

  ground.current.uniforms.groundPosition.value = center;
  ground.current.mesh.position.x = center.x;
  ground.current.mesh.position.y = center.y;

  const zoomLevel = 18;
  const minCoord = { x: ranchObject.current.bbox.min.x, y: ranchObject.current.bbox.max.y };
  const maxCoord = { x: ranchObject.current.bbox.max.x, y: ranchObject.current.bbox.min.y };

  const tileTexture = renderTileTexture(
    minCoord,
    maxCoord,
    ranchObject,
    zoomLevel,
    cameraRTT,
    sceneRTT,
    renderer,
    renderedTexture,
  );

  ranchObject.current.setTexture(tileTexture);

  const mc = new Hammer.Manager(renderer.current.domElement);
  const swipe = new Hammer.Swipe();

  mc.add([swipe]);
  mc.get('swipe').set({ direction: Hammer.DIRECTION_ALL });

  mc.on('swipeleft', () => {
    cam.current.rotateRight();
  });

  mc.on('swiperight', () => {
    cam.current.rotateLeft();
  });

  mc.on('swipedown', () => {
    cam.current.tiltUp();
    ranchObject.current.setOverheadView(true);
    // autoMode = false;
  });

  mc.on('swipeup', () => {
    cam.current.tiltDown();
    ranchObject.current.setOverheadView(false);
  });

  return center;
};
