import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { truncate } from 'lodash';

const colorClass = {
  primary: 'primary-color',
  secondary: 'secondary-color',
  success: 'success-color',
  danger: 'danger-color',
};
export function ValueUnitInfo(props) {
  const {
    ellipsis,
    info,
    maxInfoLength,
    showDegree,
    type,
    unit,
    value,
  } = props;

  const formattedInfo = ellipsis ? truncate(info, { length: maxInfoLength }) : info;

  return (
    <span className="value-unit-info flex-row">
      <span className="value-container">
        <Typography.Title level={2} className={`value-text ${colorClass[type]}`}>{value}</Typography.Title>
      </span>
      <span className="unit-info flex-column">
        <Typography.Text strong className={`unit-text ${colorClass[type]} ${showDegree ? 'degree' : ''}`}>{unit}</Typography.Text>
        <Typography.Text type="secondary" className="info-text">{formattedInfo}</Typography.Text>
      </span>
    </span>
  );
}

ValueUnitInfo.defaultProps = {
  ellipsis: false,
  info: '',
  showDegree: false,
  maxInfoLength: 15,
  type: 'primary',
  unit: '',
  value: '',
};

ValueUnitInfo.propTypes = {
  ellipsis: PropTypes.bool,
  info: PropTypes.string,
  maxInfoLength: PropTypes.number,
  showDegree: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger']),
  unit: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
