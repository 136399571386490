import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectors } from 'farmx-redux-core';

const dateFormat = 'ddd, MMM Do, YYYY';

function getDaysOfWeek(event) {
  const daysOfWeek = [];
  if (event.repeat_monday) {
    daysOfWeek.push(1);
  }
  if (event.repeat_tuesday) {
    daysOfWeek.push(2);
  }
  if (event.repeat_wednesday) {
    daysOfWeek.push(3);
  }
  if (event.repeat_thursday) {
    daysOfWeek.push(4);
  }
  if (event.repeat_friday) {
    daysOfWeek.push(5);
  }
  if (event.repeat_saturday) {
    daysOfWeek.push(6);
  }
  if (event.repeat_sunday) {
    daysOfWeek.push(7);
  }
  return daysOfWeek;
}

export function getFrequencySummary(event, t) {
  let frequency = '';
  if (event.is_recurring) {
    if (event.repeat_frequency === 'daily') {
      if (event.repeat_interval === 1) {
        frequency = t('Daily');
      } else {
        frequency = `${t('Every')} ${event.repeat_interval} ${t('days')}`;
      }
    } else if (event.repeat_frequency === 'weekly') {
      if (event.repeat_interval === 1) {
        frequency = t('Weekly');
      } else {
        frequency = `${t('Every')} ${event.repeat_interval} ${t('weeks')}`;
      }
      const daysOfWeek = getDaysOfWeek(event).map((weekday) => moment().isoWeekday(weekday).format('dd')).join(', ');
      frequency = `${frequency} ${t('on_date')} ${daysOfWeek}`;
    }
  }
  return frequency;
}

export function getRepeatEndsSummary(event, t) {
  if (!event.is_recurring || event.repeat_end_type === 'never') {
    return t('Never');
  }
  if (event.repeat_end_type === 'count') {
    return `${t('After')} ${event.repeat_end_count} ${t('occurrences')}`;
  }
  return `${t('On_date')} ${moment(event.repeat_end_date).format(dateFormat)}`;
}

export function useStartTimeSummary(startDate, endDate) {
  const { t } = useTranslation();
  const userTimeFormat = useSelector((state) => selectors.selectUserTimeFormat(state));
  const timeFormat = userTimeFormat === '24_hour' ? 'HH:mm' : 'h:mma';

  const startTime = moment(startDate).format(timeFormat);
  const duration = moment.duration(moment(endDate).diff(moment(startDate)));
  const durationSummary = duration.format(`h [${t('hrs')}] m [${t('min')}]`);

  return `${startTime} ${t('for')} ${durationSummary}`;
}

export function useScheduledEventSummary(event) {
  const { t } = useTranslation();
  const userTimeFormat = useSelector((state) => selectors.selectUserTimeFormat(state));
  const timeFormat = userTimeFormat === '24_hour' ? 'HH:mm' : 'h:mma';
  let frequency = moment(event.start_date).format(dateFormat);

  const startTime = moment(event.start_date).format(timeFormat);
  const duration = moment.duration(moment(event.stop_date).diff(moment(event.start_date)));
  const durationSummary = duration.format(`h [${t('hrs')}] m [${t('min')}]`);

  if (event.is_recurring) {
    frequency = getFrequencySummary(event, t);
  }

  return `${frequency} ${t('at')} ${startTime} ${t('for')} ${durationSummary}`;
}
