import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { scheduleApi } from 'farmx-api';
// import { hooks } from 'farmx-redux-core';
import moment from 'moment';

function getSequenceDateRange(date, hours, overlapMin) {
  const startDate = moment(date).subtract(overlapMin, 'minutes');
  const endDate = startDate.clone().add(hours, 'hours');

  return {
    start_date: moment(startDate).toISOString(),
    stop_date: moment(endDate).toISOString(),
    date_start_local: startDate.toISOString(true).slice(0, -6),
  };
}

/**
 * @param {*} blockIds - Array of blockIds for sequential irrigation schedule
 * @param {*} date - start_date for the initial blockId
 * @param {*} hours - Hours for sequential irrigation for all the blockIds
 * @param {*} overlapMin - Overlap irrigation minute for the blockIds
 *                         except the first blockId in the blockIds array
 * @returns - Array of objects with blockId, start_date and stop_date
 */
function prepareSequenceSchedules(blockIds, date, hours, overlapMin) {
  let sequenceObj = [];
  let dateRange = {};

  if (blockIds && blockIds.length) {
    sequenceObj = blockIds.map((blockId, index) => {
      let sDate;
      if (!index) {
        sDate = date;
      } else {
        sDate = dateRange.stop_date;
      }
      dateRange = getSequenceDateRange(sDate, hours, !index ? index : overlapMin);
      return { blockId, ...dateRange };
    });
  }
  return sequenceObj;
}

export function useScheduleUpdate(blockNames) {
  const { t } = useTranslation();

  async function createScheduleForBlocks(blockSchedule, newBlockIds, sequential, overlapMin) {
    const failedBlocks = [];
    let sequentialDateRangeObj = {};

    if (sequential) {
      const totalDuration = moment.duration(moment(blockSchedule.stop_date)
        .diff(moment(blockSchedule.start_date)));
      const totalHours = totalDuration.asHours();
      sequentialDateRangeObj = prepareSequenceSchedules(newBlockIds, blockSchedule.start_date,
        totalHours, overlapMin).reduce((a, obj) => {
        const temp = a;
        temp[obj.blockId] = {
          start_date: obj.start_date,
          stop_date: obj.stop_date,
          date_start_local: obj.date_start_local,
        };
        return temp;
      }, {});
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const block of newBlockIds) {
      let newSchedule = {
        ...blockSchedule,
        block,
      };

      if (sequential) {
        newSchedule = {
          ...newSchedule,
          ...sequentialDateRangeObj[block],
        };
      }

      try {
        // eslint-disable-next-line no-await-in-loop
        await scheduleApi.createScheduledIrrigationEvent(newSchedule);
      } catch (error) {
        failedBlocks.push(block);
      }
    }

    if (failedBlocks.length === 1) {
      notification.error({
        message: `${t('Failed to create schedule for block')} ${blockNames[failedBlocks[0]]}`,
      });
    } else if (failedBlocks.length) {
      notification.error({
        message: `${t('Failed to create schedule for')} ${failedBlocks.length} ${t('blocks')}`,
      });
    } else if (newBlockIds.length === 1) {
      notification.success({
        message: `${t('Created schedule for')} ${blockNames[newBlockIds[0]]}`,
      });
      return true;
    } else {
      notification.success({
        message: `${t('Created schedules for')} ${newBlockIds.length} ${t('blocks')}`,
      });
      return true;
    }
    return false;
  }

  return [
    createScheduleForBlocks,
  ];
}
