import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { actions } from 'farmx-redux-core';
import { farmApi } from 'farmx-api';
import { LoadingOutlined } from '@ant-design/icons';
import AutoScheduleButtons from './AutoScheduleButtons';

const RenderBlockSettings = (props) => {
  const { block, globalAutoSchedule } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const onClick = useCallback(async (autoSchedule) => {
    setIsLoading(true);
    try {
      await farmApi.updateBlockAutoSchedule({ blockId: block.id, autoSchedule });
      dispatch(actions.updateBlockItem({ id: block.id, changes: { auto_schedule: autoSchedule } }));
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, [block.id, dispatch]);

  useEffect(() => {
    if (globalAutoSchedule.source === 'user') {
      onClick(globalAutoSchedule.autoSchedule);
    }
  }, [globalAutoSchedule.autoSchedule, globalAutoSchedule.source, onClick]);

  return (
    <div className="render-block-settings">
      <Typography.Text>
        {block.name}
        {isLoading && <LoadingOutlined className="margin-left-10" />}
      </Typography.Text>
      <AutoScheduleButtons
        autoSchedule={block.auto_schedule}
        onClick={onClick}
        disabled={isLoading}
      />
    </div>
  );
};

RenderBlockSettings.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    auto_schedule: PropTypes.bool,
  }).isRequired,
  globalAutoSchedule: PropTypes.shape({
    autoSchedule: PropTypes.bool,
    source: PropTypes.string,
  }).isRequired,
};

export default RenderBlockSettings;
