import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import './AppTemplate.less';
import { AppHeader } from './AppHeader';

export function AppTemplate({
  children, theme, alarms, appMode,
}) {
  return (
    <Layout className="layout-mobile">
      <AppHeader theme={theme} alarms={alarms} appMode={appMode} />
      <Layout.Content>
        {children}
      </Layout.Content>
    </Layout>
  );
}

AppTemplate.defaultProps = {
  appMode: 'default',
};

AppTemplate.propTypes = {
  children: PropTypes.element.isRequired,
  theme: PropTypes.string.isRequired,
  alarms: PropTypes.element.isRequired,
  appMode: PropTypes.string,
};
