import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { RanchBlockSelect } from 'farmx-web-ui';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import {
  PageHeader,
  DatePicker,
  Radio,
} from 'antd';
import {
  ArrowLeftOutlined,
} from '@ant-design/icons';
import SensorDataChart from './SensorDataChart';
import './graph.less';
import { prepareGraphConfig, getChartHeader, groupSensors } from '../../helper/graphHelper';

const { loadAllSensors, setRanchBlockSelection } = actions;

const {
  selectLoginUserInfo,
  selectSensorsForBlockIds,
} = selectors;

const { useRanchBlockSelection } = hooks;

const graphSizeOptions = [
  { value: 'sm', label: 'Small' },
  { value: 'md', label: 'Medium' },
  { value: 'lg', label: 'Large' },
];

export function MultiGraphPage(props) {
  const { showBack } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectLoginUserInfo).payload;

  // To handle the more sensors graph
  const [graphObj, setGraphObj] = useState({ 0: [] });
  const [count, setCount] = useState(0);
  const [items, setItems] = useState(graphObj[count]);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const chunkSize = 6;

  const now = moment();
  const [dateRange, setDateRange] = useState([now.clone().subtract(2, 'weeks'), now]);
  const [graphs, setGraphs] = useState({}); // To maintain the prepared graph data to render
  const [graphSize, setGraphSize] = useState('md'); // To maintain the height of the rendered graph

  // This will be handled in local state in future
  const graphConfig = 'moisture_pressure';
  const soilGraphVariable = 'soil_moisture_rootzone_vwc';
  const pressureGraphVariable = 'water_pressure';
  const graphVariables = {
    moisture_pressure: {
      soilVariables: [soilGraphVariable],
      pressureVariables: [pressureGraphVariable],
    },
  };

  const { selectedObjFromState, blockIds } = useRanchBlockSelection();

  const { type, value } = selectedObjFromState || {};
  const ranchId = type === 'ranch' ? Number(value) : null;
  const blockId = type === 'block' ? Number(value) : null;

  const soilSensorsForBlockIds = useSelector((state) => selectSensorsForBlockIds(state,
    blockIds, 'aquacheck_soil'));
  const pressureSensorsForBlockIds = useSelector((state) => selectSensorsForBlockIds(state,
    blockIds, 'water_pressure'));

  const groupedSensorsForBlocks = groupSensors(soilSensorsForBlockIds,
    pressureSensorsForBlockIds, blockIds);

  // Split array into small chunks and store to local state with key
  useEffect(() => {
    if (graphs[graphConfig]) {
      let i;
      const chunk = chunkSize;
      const obj = {};
      for (i = 0; i < graphs[graphConfig].length; i += 1) {
        const chunkIndex = Math.floor(i / chunk);
        if (!obj[chunkIndex]) {
          obj[chunkIndex] = []; // start a new chunk
        }
        obj[chunkIndex].push(graphs[graphConfig][i]);
      }
      setGraphObj(obj);
      setItems(obj[0]);
      if (graphs[graphConfig].length > chunkSize) {
        setHasMoreItems(true);
      }
    }
  }, [graphs]);

  // To set the prepared graph config to local state
  useEffect(() => {
    let config = [];
    const variables = graphVariables[graphConfig] || {};
    if (blockIds.length) {
      config = prepareGraphConfig(graphConfig, groupedSensorsForBlocks, SensorDataChart,
        variables);
    }
    if (JSON.stringify(config) !== JSON.stringify(graphs)) {
      setGraphs(config);
    }
  }, [blockIds.length, graphVariables, graphs, groupedSensorsForBlocks]);

  useEffect(() => {
    dispatch(loadAllSensors());
  }, [dispatch]);

  // Clear graphs state when blockId/ranchId changes
  useEffect(() => {
    setGraphs([]);
  }, [blockId, ranchId]);

  function handleSelect(d) {
    if (d && d.value !== undefined) {
      setGraphs([]);
      dispatch(setRanchBlockSelection(d));
    }
  }

  function onSizeChange(e) {
    setGraphSize(e.target.value);
  }

  function renderSizeSelect() {
    return (
      <Radio.Group
        options={graphSizeOptions}
        onChange={onSizeChange}
        value={graphSize}
        optionType="button"
      />
    );
  }

  const sizeClass = `chart-${graphSize}`;

  function renderChartContainer(graph) {
    return (
      <div key={graph?.key} className={`chart-container ${sizeClass}`}>
        <div className="chart-header-container">
          <div className="chart-header-form">
            <div className="chart-sensor">
              {graph?.name || graph?.identifier}
            </div>
            <div className="chart-sensor">
              {getChartHeader(graph?.variable)}
            </div>
          </div>
        </div>
        <div className="chart-body">
          <graph.component
            startDate={dateRange?.length ? dateRange[0] : null}
            endDate={dateRange?.length ? dateRange[1] : null}
            variables={[graph.variable]}
            sensor={graph.sensor}
          />
        </div>
      </div>
    );
  }

  const fetchMoreData = useCallback(() => {
    function handleItems(configArr) {
      const prevItems = items;
      const newItems = prevItems.concat(configArr);
      return newItems;
    }

    if (graphObj[count + 1]) {
      setHasMoreItems(true);
      setItems(handleItems(graphObj[count + 1]));
      setCount(count + 1);
    } else {
      setHasMoreItems(false);
    }
  }, [count, graphObj, items]);

  return (
    <div className="graph-page multi-graph-page" id="graph-scrollable-container">
      <Helmet>
        <title>Graph</title>
      </Helmet>
      <PageHeader
        title="Sensor Graph"
        onBack={() => history.goBack()}
        backIcon={
          showBack
            ? <ArrowLeftOutlined /> : false
        }
        extra={renderSizeSelect()}
      />
      <div className="page-body padded graph-row-gap">
        <div className="div-select-container ranch-block-select-container">
          <RanchBlockSelect
            onSelect={handleSelect}
            selected={selectedObjFromState}
            admin={userInfo && userInfo.admin}
          />
        </div>

        <DatePicker.RangePicker
          showTime
          onChange={(d) => setDateRange(d)}
          value={dateRange}
          format="YYYY-MM-DD HH:mm:ss"
          defaultValue={dateRange}
          onOk={null}
          style={{ width: '100%' }}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        />
        <InfiniteScroll
          initialLoad={false}
          loadMore={fetchMoreData}
          hasMore={hasMoreItems}
          loader={<div className="loader" key="0"> Loading... </div>}
          useWindow={false}
          getScrollParent={() => document.getElementById('graph-scrollable-container')}
        >
          <div className="graph-list">
            {items?.map((graph) => renderChartContainer(graph))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

MultiGraphPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  showBack: PropTypes.bool,
};

MultiGraphPage.defaultProps = {
  history: {
    goBack: () => {},
    push: () => {},
  },
  showBack: false,
};
