/* eslint-disable global-require */

// missing global.isWebGLEnabled = require('../../node_modules/detector-webgl/index');
// todo use package.json for that

const createjs = require('./vendor/tweenjs');

global.createjs = createjs;
global.createjs.MotionGuidePlugin.install();

const THREE = require('./vendor/three');

global.THREE = THREE;
global.THREEDFONT = require('./vendor/helvetiker_bold.typeface');
require('./vendor/ConvexGeometry');
require('./vendor/OrbitControls');
require('./vendor/SkyShader');
require('./vendor/dat.gui');

const Hammer = require('./vendor/hammer');

const wNumb = require('./vendor/wNumb');

require('./vendor/EarcutGeometry');
require('./vendor/QuickHull');
require('./vendor/TrackballControls');
require('./vendor/earcut');
// require('./vendor/jQueryRotate');
require('./vendor/nouislider');
require('./Detector');
const Geometry = require('./geometry');

global.Geometry = Geometry;

const RanchObject = require('./RanchObject');

const Tile = require('./Tile');

global.Tile = Tile;

require('./FieldShape');
require('./GroundShader');
require('./SmartCamera');
require('./stats');

export {
  THREE,
  Geometry,
  RanchObject,
  Tile,
  Hammer,
  wNumb,
};
