import React from 'react';
import { Button } from 'antd';
import { FaLocationArrow } from 'react-icons/fa';
import { blockApi } from 'farmx-api';

/**
 * @param {*} blockIds - array of blockIds
 * @param {*} callBack - callback function to return anomaly data
 */
export const loadBlockAnomalies = async (blockIds, callBack) => {
  const allRequests = [];
  const finalData = [];
  blockIds.forEach((blockId) => {
    allRequests.push(blockApi.getBlockAnomaly(blockId));
  });
  Promise.allSettled(allRequests).then((results) => {
    results.forEach((r) => {
      if (r.status === 'fulfilled') {
        if (r?.value?.data?.length) {
          const anomalies = r.value.data;
          if (anomalies) {
            anomalies.forEach((anomaly) => {
              const anomalyObj = {
                id: `${anomaly.name}/${anomaly.anomaly_type}`,
                type: 'Feature',
                geometry: anomaly.center,
                name: anomaly.name,
                properties: anomaly,
              };
              finalData.push(anomalyObj);
            });
          }
        }
      }
    });
    callBack(finalData);
  });
};

export function navigateMap(selectedAnomaly) {
  if (selectedAnomaly === null) return null;
  const baseURL = 'https://www.google.com/maps/dir/?api=1&destination=';
  const lat = selectedAnomaly.properties.center.coordinates[1];
  const lng = selectedAnomaly.properties.center.coordinates[0];
  window.open(`${baseURL + lat},${lng}`, '_blank');
  return true;
}

/**
   * @param {*} selectedFeature - selected anomaly feature
   * @returns - the modified feature object to draw polygon
   */
export function getLayerBounds(selectedFeature) {
  if (selectedFeature) {
    const obj = selectedFeature;
    obj.geometry = selectedFeature.properties.bounds;
    return obj;
  }
  return selectedFeature;
}

export const getDataForReDraw = (ranchSensors, presMode,
  anomalyGeoJSON, selectedFeature) => {
  let data = ranchSensors;
  if (presMode === 'anomaly' && !selectedFeature) data = anomalyGeoJSON;
  else if (presMode === 'anomaly' && selectedFeature) data = selectedFeature;

  return data;
};

export function renderAnomalyInfo(obj, t) {
  return (
    <div className="margin-top-10">
      <Button
        type="ghost"
        onClick={() => navigateMap(obj)}
        icon={<FaLocationArrow className="anomaly-icon" />}
      >
        {t('Navigate')}
      </Button>
    </div>
  );
}
