import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectors, hooks } from 'farmx-redux-core';
import './withUserSettings.css';


const {
  useLoadUserSettings,
} = hooks;

export function withUserSettings(WrappedComponent) {
  return function WithUserSettingsComponent(props) {
    const { loading } = useSelector((state) => selectors.selectLoadingUserSettings(state));

    useLoadUserSettings();

    return (
      <div className="with-user-settings">
        {loading && (
          <div className="with-user-settings-loader">
            <LoadingOutlined />
          </div>
        )}
        <WrappedComponent {...props} />
      </div>
    );
  };
}
