import {
  colorCritical,
  colorError,
  colorWarning,
  colorOk,
  colorUnknown,
  colorOver,
} from '../../../utils/colors';

export function getColorForCurrentPercent(currentValue, cutoffValue) {
  if (currentValue === null || currentValue === undefined) {
    return colorUnknown;
  }
  if (currentValue <= 0) {
    return colorCritical;
  }
  if (currentValue <= cutoffValue / 2) {
    return colorError;
  }
  if (currentValue <= cutoffValue) {
    return colorWarning;
  }
  if (currentValue <= 1) {
    return colorOk;
  }
  return colorOver;
}

export function getSoilState(vwc, wiltingPoint, fieldCapacity, refillPoint) {
  if (vwc == null || wiltingPoint == null || fieldCapacity == null || refillPoint == null) {
    return 'unknown';
  }
  const midPoint = (refillPoint - wiltingPoint) / refillPoint + wiltingPoint;
  if (vwc > fieldCapacity) {
    return 'over_irrigated';
  }
  if (vwc > refillPoint) {
    return 'well_irrigated';
  }
  if (vwc > midPoint) {
    return 'drying';
  }
  if (vwc > wiltingPoint) {
    return 'dry';
  }
  return 'very_dry';
}

export const getNameForSoilState = (soilState) => soilState.replace('_', ' ');

export const getColorClassForSoilState = (soilState) => {
  if (soilState === 'over_irrigated') {
    return 'color-state-over';
  }
  if (soilState === 'well_irrigated') {
    return 'color-state-ok';
  }
  if (soilState === 'drying') {
    return 'color-state-warning';
  }
  if (soilState === 'dry') {
    return 'color-state-error';
  }
  if (soilState === 'very_dry') {
    return 'color-state-critical';
  }
  return 'color-state-unknown';
};

export const getLightColorClassForSoilState = (soilState) => `light-${getColorClassForSoilState(soilState)}`;
