import React, { useEffect, useState } from 'react';
import { Descriptions, Typography, Divider } from 'antd';
import { authService } from 'farmx-api';
import { useTranslation } from 'react-i18next';

export default function UserInfoPage() {
  const [userInfo, setUserInfo] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const getUserInfo = async () => {
      const info = await authService.getUserInfo();
      setUserInfo(info);
    };
    getUserInfo();
  }, [setUserInfo]);

  return (
    <>
      <Typography.Title level={4}>{t('User Info')}</Typography.Title>
      <Divider />
      <Descriptions>
        <Descriptions.Item label={t('Username')}>{userInfo.username}</Descriptions.Item>
      </Descriptions>
    </>
  );
}
