import React from 'react';
import { Typography, Tooltip } from 'antd';
import PropTypes from 'prop-types';

export default function RanchBlockTitle(props) {
  const { title } = props;

  return (
    <Tooltip // 28 is maximum text length to display.
      title={title.length > 28 ? title : ''}
      trigger="click"
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <Typography.Title level={4} className="header-ellipsis">
        {title}
      </Typography.Title>
    </Tooltip>
  );
}

RanchBlockTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
