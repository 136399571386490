import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import { useTranslation } from 'react-i18next';
import SensorDetail from './SensorDetail';
import { PageHeader } from '../components/PageHeader';
import './sensor-detail-page.less';

const SensorDetailPage = () => {
  const { t } = useTranslation();
  const pathname = window.location?.pathname || '';
  const pathnameArr = pathname.split('/');
  const sensorType = pathnameArr[3];
  const sensorIdentifier = pathnameArr[4];
  const sensorDetails = useSelector(
    (state) => selectors.selectSensor(state, sensorType, sensorIdentifier),
  ) || {};

  return (
    <div className="div-default padding-20 background-white list-sensor-detail-page">
      <div className="desktop-container">
        <PageHeader
          showBack
          title={sensorDetails.name || t('No name')}
        />
        <SensorDetail
          blockId={sensorDetails.block}
          type={sensorType}
          identifier={sensorIdentifier}
          location={sensorDetails.location}
        />
      </div>
    </div>
  );
};

export default SensorDetailPage;
