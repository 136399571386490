import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Typography, Button, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { useTracking } from '../../../helper/mixpanel';
import { useScheduledEventSummary } from './scheduleSummary';
import { getColorForBlock } from './blockTagRender';

const {
  setSelectedBlocks,
  loadBlockIrrigationSchedule,
} = actions;

const {
  selectCurrentBlockScheduledEvents,
  selectLoadingBlockScheduledEvents,
} = selectors;

function ScheduleEventSummaryText({ event }) {
  return useScheduledEventSummary(event);
}

export function BlockScheduleList({ block }) {
  const dispatch = useDispatch();
  const tracking = useTracking();
  const history = useHistory();
  const { t } = useTranslation();

  const scheduledEvents = useSelector(
    (state) => selectCurrentBlockScheduledEvents(state, block.id),
  );

  const loading = useSelector((state) => selectLoadingBlockScheduledEvents(state, block.id));

  useEffect(() => {
    dispatch(loadBlockIrrigationSchedule(block.id));
  }, [dispatch, block.id]);

  const onBlockItemClick = useCallback(() => {
    dispatch(setSelectedBlocks([block.id]));
    if (tracking) tracking.track('Clicked On Block Schedule Calendar Icon', { blockId: block.id });
    history.push('/schedule/calendar');
  }, [block.id, dispatch, history, tracking]);

  function renderScheduleItems() {
    if (!scheduledEvents.length) {
      return (
        <div className="schedule-block-times empty">
          {t('Nothing Scheduled')}
        </div>
      );
    }

    return (
      <div className="schedule-block-times">
        {scheduledEvents.map((event) => (
          <Button
            block
            style={{
              backgroundColor: getColorForBlock(event.block),
              borderColor: getColorForBlock(event.block),
            }}
            type="primary"
            key={JSON.stringify(event.id)}
            size="large"
            className="schedule-block-item"
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/schedule/detail/${event.block}/${event.id}/`);
              if (tracking) tracking.track('Clicked On Block Schedule', { schId: event.id });
            }}
          >
            <ScheduleEventSummaryText event={event} />
          </Button>
        ))}
      </div>
    );
  }

  function renderLoading() {
    return (
      <div className="schedule-block-times">
        Loading...
      </div>
    );
  }

  return (
    <div role="button" tabIndex={0} onKeyDown={onBlockItemClick} onClick={onBlockItemClick}>
      <Divider />
      <div className="flex-column padding-bottom-10 padding-top-bottom-10">
        <div className="flex-row block-schedule-header">
          <Button
            type="primary"
            icon={<FaRegCalendarAlt size={30} />}
            size="large"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
          <Typography.Text
            className="block-title"
          >
            {block.name}
          </Typography.Text>
        </div>
        {loading && (
          !scheduledEvents || !scheduledEvents.length
        ) ? renderLoading() : renderScheduleItems()}
      </div>
    </div>
  );
}

BlockScheduleList.propTypes = {
  // block.id: PropTypes.number.isRequired,
  block: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};
