import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { hooks } from 'farmx-redux-core';
import {
  Button,
  Modal,
  notification,
} from 'antd';
import { useScheduleUpdate } from './useScheduleUpdate';
import {
  patchScheduledEvent,
  createScheduledEvent,
  updateScheduledEvent,
  handleSingleRecurrence,
} from '../../../helper/scheduleHelper';
import './ScheduleModal.less';

export function ScheduleEditOptionsModal({
  scheduledEvent,
  originalEvent,
  visible,
  handleOk,
  handleCancel,
  allowModifySingle,
  allowModifyAll,
  initialValues,
  newBlockIds,
}) {
  const { t } = useTranslation();

  const blockNames = hooks.useBlockNames(newBlockIds);
  const [createScheduleForBlocks] = useScheduleUpdate(blockNames);

  const successMessage = t('Modified scheduled irrigation');
  const failureMessage = t('Failed to edit scheduled irrigation');

  function editSingleRecurrence() {
    handleSingleRecurrence(scheduledEvent, originalEvent, newBlockIds,
      createScheduleForBlocks, 'edit', (success) => {
        if (success) {
          notification.success({
            message: successMessage,
          });
          handleOk(true);
        } else {
          notification.error({
            message: failureMessage,
          });
          handleOk(false);
        }
      });
  }

  function editOnAndAfterRecurrence() {
    // Update recurrence to end on date before current recurrence
    // then create new recurrence with new rule set and start date
    const updatedEvent = {
      ...scheduledEvent,
    };

    const rootEvent = {
      id: scheduledEvent.id,
      repeat_end_type: 'date',
      repeat_end_date: scheduledEvent.start_date,
    };

    if (newBlockIds.length) {
      createScheduleForBlocks(scheduledEvent, newBlockIds);
    }

    patchScheduledEvent(rootEvent, (rootSuccess) => {
      if (rootSuccess) {
        createScheduledEvent(updatedEvent, (success) => {
          if (success) {
            notification.success({
              message: successMessage,
            });
            handleOk(true);
          } else {
            notification.error({
              message: failureMessage,
            });
            handleOk(false);
          }
        });
      } else {
        notification.error({
          message: failureMessage,
        });
        handleOk(false);
      }
    });
  }

  function editSchedule() {
    // TODO: dont change start date, just duration and offset
    const startDate = moment(scheduledEvent.start_date);
    const endDate = moment(scheduledEvent.stop_date);
    const { ...updatedEvent } = scheduledEvent;
    const { rootStart } = originalEvent;
    const duration = endDate.diff(startDate);
    const newRootStartDate = moment(rootStart).set({
      hour: startDate.get('hour'),
      minute: startDate.get('minute'),
    });
    const newRootEndDate = newRootStartDate.clone().add(duration);

    updatedEvent.start_date = newRootStartDate.toISOString();
    updatedEvent.stop_date = newRootEndDate.toISOString();
    updatedEvent.start_date_local = newRootStartDate.toISOString(true).slice(0, -6);

    if (newBlockIds.length) {
      createScheduleForBlocks(updatedEvent, newBlockIds);
    }

    updateScheduledEvent(updatedEvent, (success) => {
      if (success) {
        notification.success({
          message: successMessage,
        });
        handleOk(true);
      } else {
        notification.error({
          message: failureMessage,
        });
        handleOk(false);
      }
    });
  }

  function isRecurringEvent() {
    return scheduledEvent.is_recurring;
  }

  function renderTitle() {
    if (isRecurringEvent()) {
      return t('Edit recurring irrigation');
    }
    return t('Edit scheduled irrigation');
  }

  function renderFooter() {
    const options = [];

    if (allowModifySingle) {
      options.push(
        <Button key="single" type="primary" onClick={editSingleRecurrence}>
          {t('This event')}
        </Button>,
      );
    }

    options.push(
      <Button key="following" type="primary" onClick={editOnAndAfterRecurrence}>
        {t('This and all following events')}
      </Button>,
    );

    if (allowModifyAll) {
      options.push(
        <Button key="all" type="primary" onClick={editSchedule}>
          {t('All events')}
        </Button>,
      );
    }
    options.push(
      <Button key="back" onClick={handleCancel}>
        {t('Cancel')}
      </Button>,
    );
    return options;
  }

  if (!scheduledEvent) return null;

  return (
    <Modal
      title={renderTitle()}
      className="schedule-options-modal"
      closable={false}
      centered
      visible={visible}
      onOk={editSchedule}
      onCancel={handleCancel}
      footer={renderFooter()}
    />
  );
}
