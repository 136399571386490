/* eslint-disable */

THREE.AnimatedCamera = function(camera, controls) {
  this.autopilot = true;
  this.camera = camera;
  this.controls = controls;
  this.tiltState = 1;
  this.zoom = 1.5;

  this.currentRotationTween = null;
  this.currentTiltTween = null;
}

THREE.AnimatedCamera.prototype = {
  update: function() {
    if (this.autopilot) {}
  },

  rotateLeft: function() {
    var x = this.camera.position.x;
    var y = this.camera.position.y;
    var target = this.controls.target;

    var length = Math.pow(x - target.x, 2) + Math.pow(y - target.y, 2);
    var dist = Math.sqrt(length);

    var x_local = x - target.x;
    var y_local = y - target.y;

    var path = [x, y, x_local + y_local + target.x, y_local - x_local + target.y, y_local + target.x, -x_local + target.y];
    var tween = createjs.Tween.get(this.camera.position).to({
      guide: {
        path: path
      }
    }, 1000, createjs.Ease.cubicInOut).addEventListener("change", handleChange);

    var that = this;

    function handleChange(event) {
      that.camera.up = new THREE.Vector3(0, 0, 1);
      that.camera.lookAt(target);
    }
  },

  rotateRight: function() {
    var x = this.camera.position.x;
    var y = this.camera.position.y;
    var target = this.controls.target;

    var length = Math.pow(x - target.x, 2) + Math.pow(y - target.y, 2);
    var dist = Math.sqrt(length);

    var x_local = x - target.x;
    var y_local = y - target.y;

    var path = [x, y, x_local - y_local + target.x, y_local + x_local + target.y, -y_local + target.x, x_local + target.y];
    var tween = createjs.Tween.get(this.camera.position).to({
      guide: {
        path: path
      }
    }, 1000, createjs.Ease.cubicInOut).addEventListener("change", handleChange);

    var that = this;

    function handleChange(event) {
      that.camera.up = new THREE.Vector3(0, 0, 1);
      that.camera.lookAt(target);
    }
  },

  focusPointAerial: function(location) {
    var camera = this.camera;
    var control = this.control;
    camera.position.x = location.x;
    camera.position.y = location.y;
    camera.position.z = 10;
    camera.up = new THREE.Vector3(0, 1, 0); // y axis because north is up for this camera

    var focusPt = new THREE.Vector3(location.x, location.y, 0);
    camera.lookAt(focusPt);
    controls.target = focusPt;
  },

  focusLeft: function() {
    var target = this.controls.target;
    var position = new THREE.Vector3(target.x - 3, target.y, target.z + 3);
    this.focusLocation(target, position, 1000);
  },

  focusLocation: function(target, position, time) {
    if (!time) time = 0;

    var camera = this.camera;
		var controls = this.controls;

    var properties = {
      x: position.x,
      y: position.y,
      z: position.z,
    };

    var targetProps = {
      x: target.x,
      y: target.y,
      z: target.z,
    };

    if (time == 0) {
      camera.position.x = properties.x;
      camera.position.y = properties.y;
      camera.position.z = properties.z;
    }

    var tween = createjs.Tween.get(this.camera.position).to(properties, time, createjs.Ease.cubicInOut).addEventListener("change", handleChange);
    var targetTween = createjs.Tween.get(this.controls.target).to(targetProps, time, createjs.Ease.cubicInOut); //.addEventListener("change", handleTargetChange);

    function handleChange(event) {
      camera.up = new THREE.Vector3(0, 0, 1);
      camera.lookAt(controls.target);
    }
  },

  refocus: function() {
    // TODO:
  },

  viewGroundLevel: function() {
    this.tiltState = 0;
    console.log("view ground level");
    var target = this.center.clone();
    target.z = this.zoom / 1.2 / 4;
    var position = new THREE.Vector3(this.camera.position.x, this.camera.position.y, target.z);
    this.focusLocation(target, position, 1000);
  },

  viewAerial: function() {
    this.tiltState = 1;
    var target = this.center.clone();
    target.z = 0;
    var position = new THREE.Vector3(this.camera.position.x, this.camera.position.y, this.zoom);
    this.focusLocation(target, position, 1000);
  },

  viewAbove: function() {
    this.tiltState = 2;
    var target = this.center.clone();
    var position = new THREE.Vector3(this.camera.position.x, this.camera.position.y, this.zoom);
    position.z = this.zoom * 3;
    this.focusLocation(target, position, 1000);
  },

  focusPointSide: function(location, time) {
    if (!time) time = 0;
    var camera = this.camera;
    var control = this.control;
    var target = new THREE.Vector3(location.x, location.y, 0);
    var position = new THREE.Vector3(location.x, location.y - this.zoom, this.zoom);
    this.tiltState = 1;
    this.focusLocation(target, position, time);
  },

  tiltUp: function() {
    if (this.tiltState == 1) {
      //this.viewAbove();
    } else if (this.tiltState == 0) {
      this.viewAerial();
    }

  },

  tiltDown: function() {
    if (this.tiltState == 2) {
      this.viewAerial();
    } else if (this.tiltState == 1) {
      this.viewGroundLevel();
    }

  }
};
