import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Drawer, Typography } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import {
  FaCog,
  FaQuestionCircle,
  FaSignOutAlt,
  FaPen,
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../app/routes';
import './AppHeader.less';
import FarmXLogo from '../../../assets/images/farmx_logo_white_86.png';
import { Feedback } from '../feedback/Feedback';

const logoutOption = {
  icon: FaSignOutAlt,
  text: 'Logout',
  key: 'logout',
  route: '/logout',
  tabIndex: '3',
};

const settings = [
  {
    icon: FaCog,
    text: 'Settings',
    key: ROUTES.SETTINGS_PAGE.key,
    route: ROUTES.SETTINGS_PAGE.path,
    tabIndex: '0',
  },
  {
    icon: FaQuestionCircle,
    text: 'Resources and Help',
    key: 'help',
    route: '/help',
    tabIndex: '1',
  },
  {
    icon: FaPen,
    text: 'Submit Feedback',
    key: 'SubmitFeedback',
    route: '/feedback',
    tabIndex: '2',
  },
  logoutOption,
];

const farmhandSettings = [logoutOption];

export const AppHeader = ({ alarms, appMode }) => {
  const [settingsVisible, setSettingsVisible] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [feedbackFormVisible, setFeedbackFormVisible] = useState(false);

  const onMenuClick = (menu) => {
    setSettingsVisible(false);
    if ((menu.route).includes('/feedback')) setFeedbackFormVisible(true);
    else history.push(menu.route);
  };

  // appMode is not "farmhand"
  const isNormalUser = appMode === 'default';

  // Decide what all options should be seen to user when sidebar is in open state
  const settingsToRender = isNormalUser ? settings : farmhandSettings;

  return (
    <>
      <Helmet>
        <title>FarmX</title>
      </Helmet>

      <Layout.Header>
        <div className="left-menu">
          <div className="mobile-menu-trigger">
            <MenuOutlined
              className="trigger"
              onClick={() => { setSettingsVisible(!settingsVisible); }}
            />
          </div>
        </div>

        <div className="logo">
          <img className="mobile-header-logo" alt="FarmX" src={FarmXLogo} />
        </div>

        <div className="right-menu">
          {isNormalUser ? alarms : (
            <Typography.Text className="fieldHandTitle">{t('Fieldhand')}</Typography.Text>
          )}
        </div>
      </Layout.Header>

      <Drawer
        size="large"
        placement="left"
        closable
        onClose={() => {
          setSettingsVisible(false);
        }}
        visible={settingsVisible}
        getContainer={false}
        style={{ position: 'absolute' }}
        headerStyle={{ display: 'none' }}
        bodyStyle={{ marginTop: '50px' }}
      >
        <div className="mobile-side-menu">
          {settingsToRender.map((s) => (
            <div
              key={s.key}
              className="mobile-side-menu-item"
              aria-hidden="true"
              onClick={() => onMenuClick(s)}
            >
              <div className="mobile-side-menu__icon"><s.icon /></div>
              <p className="mobile-side-menu__link">{t(`${s.text}`)}</p>
            </div>
          ))}
        </div>
      </Drawer>
      {feedbackFormVisible && <Feedback onClick={(d) => setFeedbackFormVisible(d)} />}
    </>
  );
};

AppHeader.defaultProps = {
  appMode: 'default',
};

AppHeader.propTypes = {
  alarms: PropTypes.element.isRequired,
  appMode: PropTypes.string,
};
