import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Radio, Typography } from 'antd';

export function UnitItem(props) {
  const {
    label, options, onChange, value, name, showDivider,
  } = props;

  return (
    <div className="unit-item-container">
      <Typography.Title level={5}>{`${label}`}</Typography.Title>
      <div className="unit-item-options">
        <Radio.Group name={name} onChange={onChange} value={value}>
          {options.map((option) => (
            <Radio
              key={option.id}
              value={option.value}
              dataName={option.name}
            >
              {option.label}

            </Radio>
          ))}
        </Radio.Group>
        {showDivider && (<Divider className="unit-item-divider" />)}
      </div>
    </div>
  );
}

UnitItem.defaultProps = {
  label: '',
  options: [],
  name: '',
  showDivider: false,
};

UnitItem.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string.isRequired,
  showDivider: PropTypes.bool,
};
