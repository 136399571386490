import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  FaUser,
  FaBell,
  FaRuler,
  FaLanguage,
  FaChevronRight,
  FaBroadcastTower,
  FaWater,
} from 'react-icons/fa';
import { ROUTES } from '../../settings/routes';
import { PageHeader } from '../components/PageHeader';
import './preferences.css';

const SettingsItems = [
  {
    label: 'Account',
    key: ROUTES.ACCOUNT.key,
    route: ROUTES.ACCOUNT.path,
    icon: FaUser,
  },
  {
    label: 'Notifications',
    key: ROUTES.NOTIFICATION.key,
    route: ROUTES.NOTIFICATION.path,
    icon: FaBell,
  },
  {
    label: 'Units of Measure',
    key: ROUTES.UNITS.key,
    route: ROUTES.UNITS.path,
    icon: FaRuler,
  },
  {
    label: 'Sensors',
    key: ROUTES.SENSORS.key,
    route: ROUTES.SENSORS.path,
    icon: FaBroadcastTower,
  },
  {
    label: 'Block Irrigation Settings',
    key: ROUTES.IRRIGATION_SETTINGS.key,
    route: ROUTES.IRRIGATION_SETTINGS.path,
    icon: FaWater,
  },
  {
    label: 'Language',
    key: ROUTES.LANGUAGE.key,
    route: ROUTES.LANGUAGE.path,
    icon: FaLanguage,
  },
  /**
   * Appearance Not supported for now
   *
  {
    label: 'Appearance',
    key: ROUTES.APPEARANCE.key,
    route: ROUTES.APPEARANCE.path,
    icon: faImage,
  },
  */
];

function renderIcon(IconComponent) {
  return <IconComponent size={20} />;
}

export function Settings() {
  const { t } = useTranslation();

  return (
    <div className="settings-container page-content margin-10">
      <PageHeader showBack title={t('Settings')} toPath="/" />
      <div className="settings-items">
        {SettingsItems.map((item) => (
          <div key={item.key}>
            <Link to={item.route}>
              {renderIcon(item.icon)}
              <Typography.Title level={4}>{t(item.label)}</Typography.Title>
              <FaChevronRight size={16} className="icon-right" />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
