import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { hooks, selectors } from 'farmx-redux-core';
import './map.css';
import { MapView } from './MapView';

const { useRanchBlockSelection } = hooks;

export function Map() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedObjFromState } = useRanchBlockSelection();
  const [ranchId, setRanchId] = useState(0);
  const [blockId, setBlockId] = useState(0);
  const { getState } = useStore();
  useEffect(() => {
    if (selectedObjFromState?.type === 'block' && selectedObjFromState?.value) {
      const block = selectors.selectBlockById(getState(), Number(selectedObjFromState.value));
      if (block.id && block.ranch && (block.id !== blockId || block.ranch !== ranchId)) {
        setBlockId(Number(block.id));
        setRanchId(Number(block.ranch));
        history.push(`/map?blockId=${Number(selectedObjFromState?.value)}`);
      }
      return;
    }

    if (selectedObjFromState?.type === 'ranch' && selectedObjFromState?.value) {
      if (Number(selectedObjFromState?.value) !== ranchId) {
        setRanchId(Number(selectedObjFromState?.value));
        history.push(`/map?ranchId=${Number(selectedObjFromState?.value)}`);
      }
    }
  }, [history, dispatch, selectedObjFromState, ranchId, blockId, getState]);

  return (
    <MapView
      ranchId={ranchId}
      blockId={blockId}
      selectedObjFromState={selectedObjFromState}
    />
  );
}

Map.defaultProps = {
  history: {},
};

Map.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.object,
  }),
};
