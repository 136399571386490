import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import { truncate } from 'lodash';
import { FaSnowflake } from 'react-icons/fa';
import ConnectivityStateTag from '../../components/ConnectivityStateTag';
import { IconTitle } from '../IconTitle';
import { ValueUnitInfo } from '../ValueUnitInfo';
import {
  getConnectivityLabel,
} from '../../../../helper/listHelper';
import { CardLayout } from '../../components/CardLayout';
import { NoSummaryData } from './NoSummaryData';

const {
  useLoadStatus,
  useUnits,
} = hooks;

const {
  selectFrostSummary,
  selectSensorLoadingStatus,
} = selectors;

export function FrostSensorSummary(props) {
  const { list } = props;
  const { t } = useTranslation();
  const getUserUnits = useUnits();
  const summary = useSelector((state) => selectFrostSummary(state, list));
  const loading = useSelector((state) => selectSensorLoadingStatus(state, list));
  const {
    status,
    names,
    totalOnline,
    temperatureBud,
    temperatureLeaf,
  } = summary;
  const stateTag = useMemo(() => (
    getConnectivityLabel(totalOnline, list.length, t)
  ), [totalOnline, list, t]);

  const units = status.units || {
    temperatureBud: 'degrees_celsius',
    temperatureLeaf: 'degrees_celsius',
  };
  const budsTemperature = getUserUnits(temperatureBud, units.temperatureBud, 'temperature');
  const leafTemperature = getUserUnits(temperatureLeaf, units.temperatureLeaf, 'temperature');
  const showNoDataInfo = (!loading && !list.length) || !status?.units;

  useLoadStatus(list);

  if (showNoDataInfo) {
    return (
      <NoSummaryData
        icon="pipe-valve"
        title={t('Frost')}
      />
    );
  }

  return (
    <CardLayout>
      <div className="flex-row space-between">
        <IconTitle
          icon={<FaSnowflake />}
          title={t('Frost')}
        />
        {!loading && (
          <ConnectivityStateTag
            label={stateTag.label}
            color={stateTag.color}
          />
        )}
      </div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div className="flex-row space-between margin-top-20">
            <ValueUnitInfo
              showDegree
              value={leafTemperature.value}
              unit={t(leafTemperature.label).toUpperCase()}
              info={t('leaf temp')}
            />
            <ValueUnitInfo
              showDegree
              value={budsTemperature.value}
              unit={t(budsTemperature.label).toUpperCase()}
              info={t('bud temp')}
            />
          </div>
          <div className="flex-row flex-end margin-top-10">
            <Typography.Text className="card-footer-txt">{truncate(names.join(', '))}</Typography.Text>
          </div>
        </>
      )}
    </CardLayout>
  );
}

FrostSensorSummary.defaultProps = {
  list: [],
};

FrostSensorSummary.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
};
