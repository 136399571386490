import React from 'react';
import { Tag } from 'antd';

const colors = ['BlueViolet', 'brown', 'chocolate', 'cornflowerblue', 'darkblue', 'seagreen',
  'skyblue', 'peru', 'olive', 'lightslategrey', 'darkslategrey', 'darkcyan', 'darkgreen',
  'darkmagenta', 'goldenrod'];

export const useColorsByBlock = (blocks) => {
  const colorLookup = {};
  if (blocks) {
    for (let i = 0; i < blocks.length; i += 1) {
      colorLookup[blocks[i]] = colors[blocks[i] % colors.length];
    }
  }
  return colorLookup;
};

export const getColorForBlock = (blockId) => colors[blockId % colors.length];

export const blockTagRender = (args, colObj) => {
  const {
    closable, onClose, label, value,
  } = args;
  return (
    <Tag
      closable={closable}
      onClose={onClose}
      color={colObj[value]}
      className="block-select-tag"
    >
      {label.props ? label.props.block.name : label}
    </Tag>
  );
};
