import React from 'react';
import PropTypes from 'prop-types';
import { hooks, selectors } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { SensorListItem } from './SensorListItem';
import { isNotNull } from '../../../helper/listHelper';

const { useUnits } = hooks;

export function FlowSensorListItem(props) {
  const { sensorDetail } = props;
  const { type, name, identifier } = sensorDetail;
  const sensorStatus = useSelector((state) => (
    selectors.selectSensorStatus(state, type, identifier)
  ));
  const getUserUnits = useUnits();
  const units = sensorStatus?.units ? sensorStatus.units : {
    flowRate: 'gallons_per_minute',
  };

  const convertedFlowRate = getUserUnits(sensorStatus.flowRate, units.flowRate, 'flow_rate');
  const content = isNotNull(sensorStatus.flowRate)
    ? `${convertedFlowRate.value} ${convertedFlowRate.label}` : '';
  const showItem = sensorStatus.visible !== false && !sensorStatus.loading;

  return showItem ? (
    <SensorListItem
      alarmCount={sensorStatus.alarmCount}
      content={content}
      date={sensorStatus.latestDate}
      icon="waves"
      online={sensorStatus.online}
      title={name}
      identifier={identifier}
      type={type}
    />
  ) : null;
}

FlowSensorListItem.defaultProps = {
  sensorDetail: {},
};

FlowSensorListItem.propTypes = {
  sensorDetail: PropTypes.shape({
    name: PropTypes.string,
    identifier: PropTypes.string,
    type: PropTypes.string,
  }),
};
