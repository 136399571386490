import moment from 'moment';

/**
 * Returns schedule object
 * @param startDate
 * @param endDate
 */
export const getSchedule = (startDate, endDate) => {
  const start = moment().set({
    hour: startDate.get('hour'),
    minute: startDate.get('minute'),
    second: startDate.get('second'),
  });
  const stop = moment().set({
    hour: endDate.get('hour'),
    minute: endDate.get('minute'),
    second: endDate.get('second'),
  });

  if (start >= stop) return null;

  const timezone = moment.tz.guess();
  return {
    start_date: start.toISOString(),
    stop_date: stop.toISOString(),
    date_start_local: start.toISOString(true).slice(0, -6),
    timezone,
  };
};
