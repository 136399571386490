import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { selectors, actions } from 'farmx-redux-core';
import { PageHeader } from '../components/PageHeader';
import AutoScheduleButtons from './AutoScheduleButtons';
import RenderRanchData from './RenderRanchData';
import './irrigation-settings.less';
import { isMobile } from '../../../utils/detectDevice';

const IrrigationSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [globalAutoSchedule, setGlobalAutoSchedule] = useState({
    autoSchedule: false,
    source: '', // if user performs click, then source is user.
  });
  const ranches = useSelector(selectors.selectAllRanchesAlphabetically) || [];
  const isAutoScheduled = useSelector(selectors.selectIsAutoScheduled);

  useEffect(() => {
    dispatch(actions.loadFarmData());
  }, [dispatch]);

  useEffect(() => {
    setGlobalAutoSchedule({
      autoSchedule: isAutoScheduled,
      source: '',
    });
  }, [isAutoScheduled]);

  const updateGlobalSchedule = useCallback(() => {
    setGlobalAutoSchedule(({ autoSchedule }) => ({
      autoSchedule: !autoSchedule,
      source: 'user',
    }));
  }, []);

  return (
    <div className="block-irrigation-settings div-default padding-20 background-white">
      <PageHeader
        showBack={!!isMobile}
        title={t('Automate Irrigations')}
        toPath="/settings"
      />
      <Typography.Title className="margin-top-20" level={3}>{`${t('Recommendation Irrigation Automation')}:`}</Typography.Title>
      <Typography.Text>
        {t('Recommendations can be implemented automatically such that each recommendation performs the irrigation action without user intervention.')}
      </Typography.Text>
      <Typography.Title className="margin-top-20" level={5}>{`${t('Would you like to automate recommendations, or just choose which ones to act on yourself')}?`}</Typography.Title>
      <div className="auto-schedule-buttons-wrapper padding-10">
        <span />
        <AutoScheduleButtons
          autoSchedule={globalAutoSchedule.autoSchedule}
          onClick={updateGlobalSchedule}
        />
      </div>
      {ranches.map((ranch) => (
        <RenderRanchData key={ranch.id} ranch={ranch} globalAutoSchedule={globalAutoSchedule} />
      ))}
    </div>
  );
};

export default IrrigationSettings;
