import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { hooks } from 'farmx-redux-core';
import './zendesk-help-modal.less';

export default function ZendeskHelpModal(props) {
  const { visible, onCancel, className } = props;
  const { t } = useTranslation();
  const { articleId } = props;
  const [data, loading, error] = hooks.useZendeskHelpContent(articleId);
  const { article } = data || {};
  const { title, body } = article || {};
  const renderContent = () => {
    if (loading) {
      return <LoadingOutlined />;
    }
    if (error) {
      return (
        <Typography.Text type="danger">
          {t('Error in loading help article.')}
          {' '}
          {error}
        </Typography.Text>
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: body }} />;
  };

  return (
    <Modal
      className={`zendesk-help-modal ${className}`}
      title={title || t('Article')}
      centered
      visible={visible}
      closable
      onCancel={onCancel}
      footer={null}
    >
      {renderContent()}
    </Modal>
  );
}

ZendeskHelpModal.defaultProps = {
  visible: false,
  onCancel: () => { },
  articleId: '',
  className: '',
};

ZendeskHelpModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  articleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};
