import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { RanchBlockSelect as RBSelect } from 'farmx-web-ui';
import { useDispatch, useSelector } from 'react-redux';
import { actions, hooks, selectors } from 'farmx-redux-core';

const {
  loadAllSensors,
  setRanchBlockSelection,
} = actions;

const { selectLoginUserInfo } = selectors;

const { useRanchBlockSelection } = hooks;

export function RanchBlockSelect(props) {
  const { selectedObj, setSelectedObj } = props;
  const dispatch = useDispatch();

  const userInfo = useSelector(selectLoginUserInfo).payload;

  const { selectedObjFromState } = useRanchBlockSelection();

  useEffect(() => {
    dispatch(loadAllSensors());
  }, [dispatch]);

  function handleSelect(d) {
    sessionStorage.removeItem('sensorType');
    if (d && d.value !== undefined) {
      if (setSelectedObj) setSelectedObj(d);
      dispatch(setRanchBlockSelection(d));
    } else if (setSelectedObj) setSelectedObj(null);
  }

  return (
    <div className="div-select-container ranch-block-select-container">
      <RBSelect
        selected={selectedObjFromState || selectedObj}
        onSelect={handleSelect}
        admin={userInfo && userInfo.admin}
      />
    </div>
  );
}

RanchBlockSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedObj: PropTypes.object.isRequired,
  setSelectedObj: PropTypes.func.isRequired,
};
