import React from 'react';
import PropTypes from 'prop-types';
import { irrigationStatus } from '../../../constants';

const iconColors = {
  [irrigationStatus.IRRIGATING]: [
    '#ffffff',
    '#54c4da',
    '#2072C3',
  ],
  [irrigationStatus.SCHEDULED]: [
    '#ffffff',
    '#8cc63f',
    '#429246',
  ],
  [irrigationStatus.NOT_SCHEDULED]: [
    '#ffffff',
    '#b3b3b3',
    '#808080',
  ],
};

export default function IrrigationStatusIcon(props) {
  const { status } = props;
  const colors = iconColors[status];

  return (
    <span className="water-drop-icon-container">
      {colors.map((color) => (
        <svg
          style={{
            fill: color,
          }}
          key={color}
          width="60px"
          height="60px"
          viewBox="0 0 264.564 264.564"
        >
          <path d="M132.281,264.564c51.24,0,92.931-41.681,92.931-92.918c0-50.18-87.094-164.069-90.803-168.891L132.281,0l-2.128,2.773    c-3.704,4.813-90.802,118.71-90.802,168.882C39.352,222.883,81.042,264.564,132.281,264.564z" />
        </svg>
      ))}
    </span>
  );
}

IrrigationStatusIcon.defaultProps = {
  status: irrigationStatus.IRRIGATING,
};

IrrigationStatusIcon.propTypes = {
  status: PropTypes.string,
};
