import moment from 'moment';
// import cloneDeep from 'lodash/cloneDeep';

export const initReducerMap3D = () => ({
  dateRange: {
    start: moment().subtract(7, 'days'),
    end: moment(),
  },
  blockData: undefined,
  initedBlockId: undefined,
  depthValue: 0,
  isMenuOpen: false,
  colorSliderStart: [0.0, 0.5],
  compassDegrees: 0,
  paused: true,
  timePositionValue: moment().subtract(7, 'days').valueOf(),
  loadingData: false,
});

export function reducerMap3D(state, action) {
  switch (action.type) {
    case 'setLoadingData':
      return {
        ...state,
        loadingData: action.payload,
      };
    case 'setTimePositionValue':
      return {
        ...state,
        timePositionValue: action.payload,
      };
    case 'setPaused':
      return {
        ...state,
        paused: action.payload,
      };
    case 'setCompassDegrees':
      return {
        ...state,
        compassDegrees: action.payload,
      };
    case 'setColorSliderStart':
      return {
        ...state,
        colorSliderStart: action.payload,
      };
    case 'setDateRange':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'setBlockData': {
      return {
        ...state,
        blockData: action.payload,
        dateRange: {
          start: moment(action.payload.ranches[0].sensors[0].dates[0]),
          end: moment(action.payload.ranches[0].sensors[0].dates[1]),
        },
      };
    }
    case 'setInitedBlockId':
      return {
        ...state,
        initedBlockId: action.payload,
      };
    case 'setDepthValue':
      return {
        ...state,
        depthValue: action.payload,
      };
    case 'toggleIsMenuOpen':
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      };
    case 'reset':
      return initReducerMap3D();
    default:
      throw new Error('Unkown action type');
  }
}
