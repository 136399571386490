export const getConnectivityLabel = (count, listLength, translate) => {
  const offline = translate('offline').toUpperCase();
  const online = translate('online').toUpperCase();

  if (count === 0) {
    return {
      label: offline,
    };
  }

  return (listLength > 0 && count === listLength)
    ? { label: online, color: 'success' }
    : { label: `${count} ${translate('of')} ${listLength} ${online}`, color: 'success' };
};

export const getLoadingStatusForListOfSensors = (list, sensorStatus) => list.some((i) => {
  const status = sensorStatus[`${i.type}/${i.identifier}`];

  if (status) {
    return status.loading;
  }
  return false;
});


/**
 * Check for if a value is not null and not undefined.
 * Useful where values like Zero or empty string ('') shouldn't be ignored.
 */
export const isNotNull = (value) => (value !== null && value !== undefined && value !== '');

/**
 * Returns "(quotes) if label is inches(in) or else will return the label itself
 */
export const displayInchesInQuotes = (label) => (label === 'in' ? '"' : label);
