import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

const getColorForValue = (value) => {
  if (value) return 'green';
  return 'default';
};

function ActiveTag({ value }) {
  const { t } = useTranslation();

  if (value === undefined || value === null) return null;
  return (
    <Tag color={getColorForValue(value)}>
      { value ? t('active') : t('inactive') }
    </Tag>
  );
}

ActiveTag.propTypes = {
  value: PropTypes.bool,
};

ActiveTag.defaultProps = {
  value: undefined,
};

export default ActiveTag;
