/* eslint-disable */
THREE.ShaderLib[ 'ground' ] = {

	uniforms: {
		groundPosition: { value: new THREE.Vector3() }
	},

	vertexShader: [

		"varying vec3 vWorldPosition;",

		"void main() {",

			"vec4 worldPosition = modelMatrix * vec4( position, 1.0 );",
			"vWorldPosition = worldPosition.xyz;",

			"gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

		"}",

	].join( "\n" ),

	fragmentShader: [

		"uniform sampler2D groundSampler;",
		"uniform vec3 groundPosition;",
		"varying vec3 vWorldPosition;",
		

		"vec3 cameraPos = vec3(0., 0., 0.);",

		"void main() {",
			"vec3 FogBaseColor = vec3(.7, .7, .7);",

			"float a = 0.02;",
    		"float b = 0.01;",
    		"vec3 camFrag = vWorldPosition - groundPosition;",

    		"float dist = distance(groundPosition.xy, vWorldPosition.xy);",
    		"dist = length(camFrag);",
    		"float fogAmount2 = a * exp(-groundPosition.z*b) * ( 1.0-exp( -dist*camFrag.z*b ) ) / (b*camFrag.z);",
    		
    		"float fogAmount = dist / 40.0;",

			"vec3 retColor = vec3(60.0, 179.0, 113.0) / 255.0;",
			"gl_FragColor.rgb = mix(retColor, FogBaseColor,  fogAmount);",
			
			"float t = fogAmount;",
			"vec4 testColor = vec4(t, t, t, 1.0);",
			
			"float alpha = (1.-fogAmount+.2)*.9;",//dist / 1000.0;",
			//"if (vWorldPosition.z < 0.0) alpha = 0.0;",
			"gl_FragColor.a = alpha;",
			//"gl_FragColor = testColor;",
		"}",

	].join( "\n" )

};

THREE.Ground = function () {

	var groundShader = THREE.ShaderLib[ "ground" ];
	var groundUniforms = THREE.UniformsUtils.clone( groundShader.uniforms );

	var groundMat = new THREE.ShaderMaterial( {
		fragmentShader: groundShader.fragmentShader,
		vertexShader: groundShader.vertexShader,
		uniforms: groundUniforms,
	} );

	var groundSimpleMat = new THREE.MeshBasicMaterial( {
		color: 0x00ff00,
		depthWrite: false, depthTest: false
	});

	var size = 200;
	var groundGeo = new THREE.PlaneBufferGeometry( size, size );
	var groundMesh = new THREE.Mesh( groundGeo, groundMat );

	groundMesh.material.transparent = true;
	groundMesh.material.side = THREE.DoubleSide;

	groundMesh.material.opacity = 0.1;


	// Expose variables
	this.mesh = groundMesh;
	this.uniforms = groundUniforms;

};
