/* eslint-disable no-underscore-dangle */
/* global L */
import {
  prepareMarker,
  prepareRanchMarker,
  prepareMarkerClusterIcon,
  // For now not showing labels
  // getMarkerTooltipByPresentationMode,
  sensorTypeAllowedForMode,
  prepareZeroIslandSensors,
} from 'farmx-web-ui';
import { actions } from 'farmx-redux-core';
import { isMobile } from '../../../utils/detectDevice';

const {
  setRanch,
} = actions;

const getFeatureType = (presMode) => {
  if (presMode === 'anomaly') return 'anomaly';
  return 'sensor';
};

const marketClusterGroupInit = (presentationMode) => {
  const mcGroup = L.markerClusterGroup({
    maxClusterRadius: 50,
    unspiderfyOnMapClick: false,
    shouldSpiderfy: (c) => c.getChildCount() < 7,
    animate: false,
    iconCreateFunction: prepareMarkerClusterIcon(presentationMode),
    spiderLegPolylineOptions: {
      weight: 1.5, color: '#eee', opacity: 0.8,
    },
    polygonOptions: {
      stroke: false,
      fill: false,
    },
  });

  return mcGroup;
};

const onMarkerClick = (dispatchMapPage, presMode) => (e) => {
  const { feature } = e.target;
  const type = getFeatureType(presMode);
  if (isMobile) {
    dispatchMapPage({
      type: 'setIsImageryPanelVisible',
      payload: false,
    });
  }
  dispatchMapPage({
    type: 'setSelectedFeatureWithType',
    payload: { feature, type, showBottomSheet: true },
  });
};

const onRanchMarkerClick = (dispatch, history) => (e) => {
  const { id } = e.target.feature.properties;
  dispatch(setRanch(Number(id)));
  history.push(`/map?ranchId=${Number(id)}`);
};

const markers = [];
const ranchMarkers = [];

export function reDraw({
  mapFeatureGroupRef,
  dataForRedraw,
  presentationMode,
  selectedFeature,
  dispatchMapPage,
  // For now not showing labels
  // showLabels,
  selShowOnlyPresentionModeSensors,
  ranchesPointsGeoJSON,
  ranchId,
  zoomShowSensors,
  dispatch,
  history,
}) {
  if (!mapFeatureGroupRef.current) {
    return;
  }

  const presMode = !dataForRedraw.length
    || (dataForRedraw.length && dataForRedraw[0].properties.noStatusAvailable)
    ? 'initial'
    : presentationMode;

  const marketClusterRanchesGroup = marketClusterGroupInit(presMode);
  marketClusterRanchesGroup.clearLayers();

  const marketClusterGroup = marketClusterGroupInit(presMode);
  marketClusterGroup.clearLayers();

  // Start clean
  markers.forEach((marker) => {
    marker.on('off', onMarkerClick(dispatchMapPage));
    if (marker.unbindTooltip) {
      marker.unbindTooltip();
    }
  });
  ranchMarkers.forEach((marker) => {
    marker.on('off', onRanchMarkerClick(dispatch, history));
    if (marker.unbindTooltip) {
      marker.unbindTooltip();
    }
  });
  mapFeatureGroupRef.current.leafletElement.eachLayer((layer) => {
    if (!(layer.options.name && layer.options.name === 'soil')) {
      mapFeatureGroupRef.current.leafletElement.removeLayer(layer);
    }
  });
  markers.splice(0, markers.length);
  // End clean

  // Render all ranches
  if (ranchesPointsGeoJSON?.features?.length && !zoomShowSensors) {
    const mcRanchesLayer = L.geoJSON(
      {
        type: 'FeatureCollection',
        features: ranchesPointsGeoJSON.features
          .filter(({ properties: { id } }) => id !== ranchId || !zoomShowSensors),
      },
      {
        pointToLayer: (feature, latlng) => {
          const ranchMarker = prepareRanchMarker(feature, latlng);
          ranchMarker.on('click', onRanchMarkerClick(dispatch, history));
          ranchMarkers.push(ranchMarker);
          return ranchMarker;
        },
      },
    );
    marketClusterRanchesGroup.addLayer(mcRanchesLayer);
    mapFeatureGroupRef.current.leafletElement.addLayer(marketClusterRanchesGroup);
    mapFeatureGroupRef.current.leafletElement._map.setZoom(
      mapFeatureGroupRef.current.leafletElement._map.getZoom(),
    );
  }

  if (!ranchId) {
    return;
  }

  if (!zoomShowSensors) {
    return;
  }

  if (!dataForRedraw.length) {
    return;
  }

  // Rendering logic
  if (presMode === 'initial' && getFeatureType(presMode) !== presMode) { // no status info yet
    let ranchSensorsGeoJSON = {
      type: 'FeatureCollection',
      features: dataForRedraw
        .filter((s) => sensorTypeAllowedForMode(
          presentationMode,
          s.properties,
          selShowOnlyPresentionModeSensors,
        )),
    };

    ranchSensorsGeoJSON = prepareZeroIslandSensors(ranchSensorsGeoJSON);

    const mcLayer = L.geoJSON(ranchSensorsGeoJSON, {
      pointToLayer: (feature, latlng) => {
        const marker = prepareMarker(feature, latlng, presMode, false);
        return marker;
      },
    });
    marketClusterGroup.addLayer(mcLayer);
    mapFeatureGroupRef.current.leafletElement.addLayer(marketClusterGroup);
    mapFeatureGroupRef.current.leafletElement._map.setZoom(
      mapFeatureGroupRef.current.leafletElement._map.getZoom(),
    );

    return;
  }

  const features = {
    type: 'FeatureCollection',
    features: getFeatureType(presMode) !== presMode ? dataForRedraw
      .filter((feature) => sensorTypeAllowedForMode(
        presentationMode,
        feature.properties,
        !!selShowOnlyPresentionModeSensors,
      )) : dataForRedraw,
  };

  const mcLayer = L.geoJSON(features, {
    pointToLayer: (feature, latlng) => {
      const isSelected = selectedFeature?.properties?.id === feature.properties.id;

      const marker = prepareMarker(feature, latlng, presentationMode, isSelected);

      // For now we don't show labels at all
      // if (presentationMode === 'waterpressure' && showLabels) {
      //   const bgColor = feature.properties.waterPressureColor || 'gray';
      //   const fontColor = feature.properties.waterPressureRatio > 0.3 ? 'white' : 'black';
      //   const label = `<div id="label-${feature.properties.id.split('/').join('-')}" style="color: ${fontColor}; padding: 2px 4px 2px 4px; background-color: ${bgColor}">${getMarkerTooltipByPresentationMode(feature, presentationMode)}</div>`;
      //   marker.bindTooltip(
      //     label,
      //     {
      //       direction: 'auto',
      //       permanent: true,
      //       className: 'leaflet-tooltip-waterpressure',
      //     },
      //   );
      // }

      if (!isSelected) {
        marker.on('click', onMarkerClick(dispatchMapPage, presMode));
        markers.push(marker);
      }
      return marker;
    },
  });
  marketClusterGroup.addLayer(mcLayer);
  mapFeatureGroupRef.current.leafletElement.addLayer(marketClusterGroup);
  mapFeatureGroupRef.current.leafletElement._map.setZoom(
    mapFeatureGroupRef.current.leafletElement._map.getZoom(),
  );
}

export function reDrawSatelliteImagery({
  mapFeatureGroupRef,
  selectedImageryData,
  selectedImageryDataOpacity,
}) {
  if (!mapFeatureGroupRef.current) {
    return;
  }

  mapFeatureGroupRef.current.leafletElement.eachLayer((layer) => {
    if (layer.options.attribution === 'satellite') {
      mapFeatureGroupRef.current.leafletElement.removeLayer(layer);
    }
  });

  if (selectedImageryData.visible && selectedImageryData.data) {
    selectedImageryData.data.forEach((d) => {
      const latlng = L.GeoJSON.coordsToLatLngs(JSON.parse(d.bounds).coordinates, 1);
      const bounds = new L.LatLngBounds(latlng);
      const imageUrl = d.png_url;
      const options = {
        opacity: selectedImageryDataOpacity / 100,
        alt: 'satellite imagery',
        attribution: 'satellite',
      };
      // eslint-disable-next-line new-cap
      const imageLayer = new L.imageOverlay(imageUrl, bounds, options);
      mapFeatureGroupRef.current.leafletElement.addLayer(imageLayer);
    });
  }
}
