import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

export function CardLayout({ children }) {
  return (
    <Card
      bodyStyle={{ padding: '12px', width: '100%' }}
      headStyle={{ border: 'none' }}
      bordered
      className="mobile-list-item card-layout-style"
    >
      {children}
    </Card>
  );
}

CardLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
