import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors, hooks } from 'farmx-redux-core';
import { installState } from '../../../constants';
import { isNotNull } from '../../../helper/listHelper';

const {
  selectWeatherStatusFirstRecordForBlockIds,
  selectAllSensorsForBlockIdsByType,
} = selectors;

const {
  useRanchBlockSelection,
  useUnits,
  useLoadStatus,
} = hooks;

const initialWeatherInfo = {
  temperature: '--',
  wind: '--',
};

const useWeatherInfo = () => {
  // Holds the value of the weather overlays (temperature and wind)
  // Currently displaying - in case of no data
  const [weatherInfo, setWeatherInfo] = useState(initialWeatherInfo);
  const { blockIds } = useRanchBlockSelection();

  /**
  * Load weather station data
  */
  const sensors = useSelector((state) => selectAllSensorsForBlockIdsByType(state, {
    blockIds,
    installState: installState.INSTALLED,
  }));
  useLoadStatus((sensors && sensors.weather_station) || []);

  // Fetch weather info, currently using the first record from the weather list
  const { status: firstWeatherRecord, isLoading } = useSelector(
    (state) => selectWeatherStatusFirstRecordForBlockIds(state, {
      blockIds,
      installState: installState.INSTALLED,
    }),
  );
  const getUserUnits = useUnits();
  const getLabel = (label) => (isNotNull(label) ? label : '');

  useEffect(() => {
    const defaultUnits = {
      airTemperature: 'degrees_fahrenheit',
      windSpeed: 'miles_per_hour', // not sure if we want to support wind speed units
    };

    if (firstWeatherRecord) {
      const units = firstWeatherRecord.units || defaultUnits;
      const {
        weatherCurrent: { airTemperature, windSpeed, windDirection },
      } = firstWeatherRecord || {
        weatherCurrent: {},
      };
      const { value, label } = getUserUnits(getLabel(airTemperature), units.airTemperature, 'temperature');

      setWeatherInfo({
        temperature: { value, label },
        wind: `${windSpeed} mph`,
        windDirection,
      });
    } else {
      const { label } = getUserUnits('', defaultUnits.airTemperature, 'temperature');
      setWeatherInfo({
        temperature: {
          value: '--',
          label,
        },
        wind: '-- mph',
        windDirection: 0,
      });
    }
    // eslint-disable-next-line
  }, [firstWeatherRecord]);
  return [weatherInfo, isLoading];
};

export default useWeatherInfo;
