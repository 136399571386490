import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Typography, notification, TimePicker,
} from 'antd';
import { useDispatch } from 'react-redux';
import enUs from 'antd-mobile-v2/lib/date-picker/locale/en_US';
import { FieldTimeOutlined, LoadingOutlined } from '@ant-design/icons';
import { FaTrashAlt } from 'react-icons/fa';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { actions } from 'farmx-redux-core';
import { scheduleApi } from 'farmx-api';
import { getSchedule } from './helper';


const {
  loadBlockRenderedSchedule,
} = actions;

const {
  createScheduledIrrigationEvent,
  updateScheduledIrrigationEvent,
  deleteScheduledIrrigationEvent,
} = scheduleApi;

const timeFormat = 'h:mma';

const DateSelection = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    blockId, scheduledEvent, dateStart, dateEnd,
  } = props;
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [loading, setLoading] = useState(false);
  const eventId = scheduledEvent?.id;

  /**
   * @param data
   * @param data.params Params that needs to be sent to the api
   * @param data.method Method to create, update or delete an event
   * @param data.messages.success Message to display on success
   * @param data.messages.error Message to display on error
   */
  const createUpdateOrDeleteSchedule = useCallback(async ({ params, method, messages }) => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await method(params);
      if (response && [200, 201, 204].includes(response.status)) {
        notification.success({ message: t(messages.success) });
        dispatch(loadBlockRenderedSchedule({ blockId, dateStart, dateEnd }));
        setLoading(false);
      } else {
        notification.error({ message: t(messages.error) });
      }
    } catch {
      notification.error({ message: t(messages.error) });
    }
    setLoading(false);
  }, [blockId, dateEnd, dateStart, dispatch, loading, t]);

  const onTimeChange = useCallback(([startDate, endDate]) => {
    setStartTime(startDate);
    setEndTime(endDate);
    if (startDate && endDate) {
      const schedule = getSchedule(startDate, endDate);
      if (schedule === null) {
        notification.error({
          message: t('End time should occur after start time'),
        });
        return;
      }
      schedule.block = blockId;

      // Decide whether to create an event or update one
      if (eventId) {
        createUpdateOrDeleteSchedule({
          method: updateScheduledIrrigationEvent,
          params: { ...scheduledEvent, ...schedule },
          messages: {
            success: 'Schedule modified',
            error: 'Failed to edit schedule',
          },
        });
      } else {
        createUpdateOrDeleteSchedule({
          method: createScheduledIrrigationEvent,
          params: schedule,
          messages: {
            success: 'Created schedule',
            error: 'Failed to create schedule',
          },
        });
      }
    }
  }, [blockId, createUpdateOrDeleteSchedule, eventId, scheduledEvent, t]);

  const deleteSchedule = () => {
    createUpdateOrDeleteSchedule({
      method: deleteScheduledIrrigationEvent,
      params: eventId,
      messages: {
        success: 'Deleted schedule',
        error: 'Failed to delete schedule',
      },
    });
  };

  /**
   * Dispatch action to fetch block rendered schedule for a period of 24 hours.
   * This 24 hours period can be changed with the help of props: dateStart and dateEnd
   */
  useEffect(() => {
    if (blockId) {
      dispatch(loadBlockRenderedSchedule({ blockId, dateStart, dateEnd }));
    }
  }, [dispatch, blockId, dateStart, dateEnd]);

  /**
   * Set/Reset initial start and stop dates based on schedule
   */
  useEffect(() => {
    if (eventId) {
      setStartTime(moment(scheduledEvent.start_date));
      setEndTime(moment(scheduledEvent.stop_date));
    } else {
      setStartTime(null);
      setEndTime(null);
    }
  }, [eventId, scheduledEvent]);

  const showDeleteButton = useMemo(() => !loading && Boolean(eventId), [loading, eventId]);

  return (
    <>
      <div className="farmhand-date-time-title">
        <Typography.Text>{t('Choose time to irrigate today')}</Typography.Text>
      </div>
      <div className="farmhand-date-time-selection flex-row align-center">
        <FieldTimeOutlined className="time-icon" style={{ fontSize: '26px', color: '#66696D' }} />
        <TimePicker.RangePicker
          size="large"
          inputReadOnly
          use12Hours
          allowClear={false}
          order={false}
          disabled={[loading, loading]}
          onChange={onTimeChange}
          disabledDate={(current) => current && current.isSameOrBefore(moment().subtract(1, 'minutes'))}
          locale={enUs}
          format={timeFormat}
          suffixIcon={null}
          placeholder={[t('Start'), t('End')]}
          value={[startTime, endTime]}
        />
        {loading && <LoadingOutlined className="loading-schedule" />}
        {showDeleteButton && (
          <button
            type="button"
            onClick={deleteSchedule}
            className="delete-schedule"
          >
            <FaTrashAlt />
          </button>
        )}
      </div>
    </>
  );
};

DateSelection.defaultProps = {
  scheduledEvent: PropTypes.shape({
    id: '',
    start_date: moment(),
    stop_date: moment().add(1, 'day'),
  }),
  dateStart: moment(),
  dateEnd: moment().add(1, 'day'),
};

DateSelection.propTypes = {
  blockId: PropTypes.number.isRequired,
  scheduledEvent: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.objectOf(moment),
    stop_date: PropTypes.objectOf(moment),
  }),
  dateStart: PropTypes.objectOf(moment),
  dateEnd: PropTypes.objectOf(moment),
};

export default DateSelection;
