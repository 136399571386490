import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import EventLogLevelIcon from './EventLogLevelIcon';
import { NotificationShareButton } from '../../mobile/components/NotificationShareButton';
import { isMobile } from '../../../utils/detectDevice';
import './NotificationItem.css';

function NotificationItem({ activity }) {
  const { description, time, level } = activity;
  const timeAware = `${time}Z`;

  const renderAvatar = () => <EventLogLevelIcon value={level} />;

  const renderRight = () => {
    if (isMobile) {
      return <NotificationShareButton notification={activity} />
    }
    return null;
  };

  const renderContent = () => {
    const text = description;
    return (
      <div className="raf-activity__content">
        <div className="raf-notification__header">
          <strong>{text}</strong>
        </div>
        <div>
          <small>
            <Moment fromNow>{timeAware}</Moment>
            {' - '}
            <Moment format="YYYY-MM-DD h:mma">{timeAware}</Moment>
          </small>
        </div>
      </div>
    );
  };

  const renderExtra = () => null;

  return (
    <div className="raf-notification">
      <div className="notification-left">
        {renderAvatar()}
      </div>
      <div className="notification-content">
        {renderContent()}
      </div>
      <div className="notification-extra">
        {renderExtra()}
      </div>
      <div className="notification-right">
        {renderRight()}
      </div>
    </div>
  );
}

NotificationItem.propTypes = {
  activity: PropTypes.any,
};

export default NotificationItem;
