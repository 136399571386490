import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from 'farmx-redux-core';
import { useLocation } from 'react-router-dom';
import { PageHeader } from '../../components/PageHeader';
import { ControlPumpDetailItem } from './ControlPumpDetailItem';
import { useTracking } from '../../../../helper/mixpanel';

const {
  selectBlockPumpControllers,
  selectBlockFlowMeter,
} = selectors;

const { loadSensorStatus } = actions;

export function ControlPumpDetails(props) {
  const {
    match, pumpId, loadStatus,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const tracking = useTracking();

  const blockId = match.params ? Number(match.params.id) : pumpId;

  useEffect(() => {
    if (tracking) tracking.track('Loaded Pump Details', { pumpId: blockId });
  }, [blockId, tracking]);

  const pumpControllers = useSelector(
    (state) => selectBlockPumpControllers(state, blockId),
  );

  const flowMeter = useSelector(
    (state) => selectBlockFlowMeter(state, blockId),
  );

  const {
    identifier: flowMeterIdentifier,
    type: flowMeterType,
  } = flowMeter || {};

  useEffect(() => {
    if (flowMeterIdentifier && flowMeterType && loadStatus) {
      const sensorParams = { type: flowMeterType, identifier: flowMeterIdentifier };
      dispatch(loadSensorStatus(sensorParams));
    }
  }, [dispatch, flowMeterType, flowMeterIdentifier, loadStatus]);

  const pumpDetails = Object.keys(pumpControllers).map((id) => pumpControllers[id]);

  const { state } = location;
  const identifier = state && state.identifier;
  const filteredPumpDetail = pumpDetails.filter((d) => d && d.identifier === identifier);

  if (!pumpDetails[0]) {
    return (
      <div className="block-details-additional-info mobile-list-item list-control-pump-detail-item">
        {t('Pump details not available')}
      </div>
    );
  }

  const renderControlPumpDetailItem = (items = [], showPumpSpecs) => (
    items.map((pumpDetail, index) => (
      <ControlPumpDetailItem
        key={pumpDetail.identifier}
        pumpDetail={pumpDetail}
        loadStatus={loadStatus}
        showPumpSpecs={showPumpSpecs}
        showBlockName={items.length > 1}
        showHeader={!index}
      />
    ))
  );

  return (
    <>
      {state && state.goBack ? (
        <div className="div-default block-details-container">
          <PageHeader
            showBack
            title={`${t('Pump Detail')} - ${filteredPumpDetail[0].name
              || filteredPumpDetail[0].identifier}`}
          />
          {renderControlPumpDetailItem(filteredPumpDetail)}
        </div>
      ) : renderControlPumpDetailItem(pumpDetails, false)}
    </>
  );
}

ControlPumpDetails.defaultProps = {
  history: {},
  match: {},
  pumpId: null,
  loadStatus: true,
  status: null,
};

ControlPumpDetails.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  pumpId: PropTypes.number,
  loadStatus: PropTypes.bool,
  status: PropTypes.shape({}),
};
