import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import {
  FaInfoCircle,
  FaExclamationTriangle,
  FaTimesCircle,
} from 'react-icons/fa';

const iconSize = 24;

export default function EventLogLevelIcon({ value }) {
  const { t } = useTranslation();

  function getIcon() {
    if (value === 'info') {
      return <FaInfoCircle color="grey" size={iconSize} />;
    }
    if (value === 'warning') {
      return <FaExclamationTriangle color="orange" size={iconSize} />;
    }
    if (value === 'error') {
      return <FaTimesCircle color="red" size={iconSize} />;
    }
    return null;
  }

  const icon = getIcon();
  if (!icon) return null;
  return (
    <Tooltip title={t(value)} placement="bottom">
      {icon}
    </Tooltip>
  );
}

EventLogLevelIcon.propTypes = {
  value: PropTypes.string.isRequired,
};
