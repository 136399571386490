import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from './components/PageHeader';


export default function Mobile404() {
  const { t } = useTranslation();
  return (
    <div className="page-content margin-10">
      <PageHeader showBack title={t('Page not found')} toPath="/" />
      <h1>{t('404: Sorry, we can\'t find that page')}</h1>
    </div>
  );
}
