import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getColorClassForIrrigationState } from './recommendation';
import './soil-state-legend-modal.less';

const colorAndLabelMap = {
  very_dry: {
    label: 'Very Dry',
    className: getColorClassForIrrigationState('very_dry'),
  },
  dry: {
    label: 'Dry',
    className: getColorClassForIrrigationState('dry'),
  },
  drying: {
    label: 'Getting Dry',
    className: getColorClassForIrrigationState('drying'),
  },
  well_irrigated: {
    label: 'Well Irrigated',
    className: getColorClassForIrrigationState('well_irrigated'),
  },
  over_irrigated: {
    label: 'Saturated',
    className: getColorClassForIrrigationState('over_irrigated'),
  },
  unknown: {
    label: 'Unknown',
    className: 'color-state-unknown',
  },
};

export default function SoilStateLegendModal(props) {
  const { visible, onCancel } = props;
  const { t } = useTranslation();
  return (
    <Modal
      className="soil-state-legend-modal"
      title={t('Soil State Legend')}
      centered
      visible={visible}
      closable
      onCancel={onCancel}
      footer={null}
    >
      <Typography.Text className="state-legend-description">
        {t('The following are the colors and states associated with each recommendation. These values come from Soil Moisture Probes, ETc values, or both.')}
      </Typography.Text>
      <div className="color-label-container">
        {Object.keys(colorAndLabelMap).map((key) => (
          <div className="color-label" key={colorAndLabelMap[key].label}>
            <div style={{ height: 70, width: 70 }} className={colorAndLabelMap[key].className} />
            <Typography.Text className="color-description">{t(colorAndLabelMap[key].label)}</Typography.Text>
          </div>
        ))}
      </div>
      <div className="view-all-help-link">
        <Link to="/help">
          {t('View All Help')}
        </Link>
      </div>
    </Modal>
  );
}

SoilStateLegendModal.defaultProps = {
  visible: false,
  onCancel: () => { },
};

SoilStateLegendModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};
