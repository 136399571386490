import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import ControlPumpListItem from './ControlPumpListItem';
import PumpOverrideButtons from '../ControlSummary/PumpOverrideButtons';
import PumpSpecs from '../ControlSummary/PumpSpecs';

export function ControlPumpDetailItem(props) {
  const {
    pumpDetail, loadStatus, showPumpSpecs, showBlockName, showHeader,
  } = props;
  const { t } = useTranslation();
  const { status, blockName } = pumpDetail || { status: {} };
  const {
    loading, scheduleStatus, flowRate, controlStatus = {},
  } = status;
  const pumpSchedule = [{
    blockName,
    scheduledTime: scheduleStatus?.dateScheduledStart,
    endTime: scheduleStatus?.dateScheduledStop,
  }];

  return (
    <div className="block-details-additional-info mobile-list-item list-control-pump-detail-item">
      {loading && (
        <LoadingOutlined />
      )}
      <span style={{ display: loading ? 'none' : null }}>
        <>
          <ControlPumpListItem
            loadStatus={loadStatus}
            pump={pumpDetail}
            showHeader={showHeader}
            showBlockName={showBlockName}
          />
          <PumpOverrideButtons
            irrigationStartDate={scheduleStatus?.dateScheduledStart}
            pumpSchedule={pumpSchedule}
            pumpName={`${t('Pump')} ${pumpDetail.name}`}
            vfdIdentifier={pumpDetail.identifier}
            pumpOverrideStatus={status.overrideStatus}
          />
          {showPumpSpecs && (
            <PumpSpecs
              pumpDetails={{
                pumpFlowRate: flowRate,
                pumpPower: controlStatus.power || 0,
                pumpFrequency: controlStatus.outFreq || controlStatus.frequency || 0,
                pumpRevolutions: controlStatus.speed || 0,
                units: status.units,
              }}
            />
          )}
        </>
      </span>
    </div>
  );
}

ControlPumpDetailItem.defaultProps = {
  showBlockName: false,
  showHeader: true,
  loadStatus: true,
  showPumpSpecs: true,
  pumpDetail: {},
};

ControlPumpDetailItem.propTypes = {
  showBlockName: PropTypes.bool,
  showHeader: PropTypes.bool,
  loadStatus: PropTypes.bool,
  showPumpSpecs: PropTypes.bool,
  pumpDetail: PropTypes.shape({
    status: PropTypes.object,
    blockName: PropTypes.string,
    name: PropTypes.string,
    identifier: PropTypes.string,
  }),
};
