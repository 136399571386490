import React from 'react';
import PropTypes from 'prop-types';
import { SensorListItem } from './SensorListItem';
import { isNotNull } from '../../../helper/listHelper';

export function IRSensorListItem(props) {
  const { sensorDetail } = props;

  const units = sensorDetail?.units ? sensorDetail.units.cwsi : 'cwsi';

  const displayIRValue = isNotNull(sensorDetail?.cwsi)
    ? `${sensorDetail.cwsi.toFixed(1)} ${units}` : '';

  return (
    <SensorListItem
      alarmCount={sensorDetail.alarmCount}
      content={displayIRValue}
      date={sensorDetail.latestDate}
      icon="radio-waves"
      online={sensorDetail.online}
      title={sensorDetail.name}
      identifier={sensorDetail.identifier}
      type={sensorDetail.type}
    />
  );
}

IRSensorListItem.defaultProps = {
  sensorDetail: {},
};

IRSensorListItem.propTypes = {
  sensorDetail: PropTypes.shape({
    alarmCount: PropTypes.number,
    latestDate: PropTypes.string,
    name: PropTypes.string,
    online: PropTypes.bool,
    cwsi: PropTypes.string,
    units: PropTypes.object,
    identifier: PropTypes.string,
    type: PropTypes.string,
  }),
};
