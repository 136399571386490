import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { hooks } from 'farmx-redux-core';
import {
  Typography,
  Card,
} from 'antd';

export function IrrigationEquation({
  deficit, etcForecast, irrigationDuration, units,
}) {
  const { t } = useTranslation();
  const getUserUnits = hooks.useUnits();

  function parseDuration(duration) {
    if (duration === undefined || duration === null) {
      return t('?');
    }
    const hours = moment.duration(duration).asHours();
    if (Math.abs(hours) < 10) {
      return Math.round(hours * 10) / 10;
    }
    return Math.round(hours);
  }

  function parseWater(water, fromUnit) {
    if (water === undefined || water === null) {
      return t('?');
    }
    const { value: depthValue } = getUserUnits(water, fromUnit, 'depth', { decimalPlaces: 2 });
    if (Math.abs(depthValue) < 10) {
      return Math.round(depthValue * 100) / 100;
    }
    return Math.round(depthValue);
  }

  function getStatusColorClassName(value) {
    if (value === null || value === undefined) {
      return 'text-color-normal';
    }
    if (value < 0) {
      return 'text-color-over';
    }
    if (value > 0) {
      return 'text-color-critical';
    }
    return 'text-color-normal';
  }

  const { label: depthLabel } = getUserUnits(0, units.deficit, 'depth');
  const waterUnits = t(depthLabel);

  const durationString = parseDuration(irrigationDuration);
  const etcForecastString = parseWater(etcForecast, units.etcForecast);
  const deficitString = parseWater(deficit, units.deficit);

  const deficitStringClassName = getStatusColorClassName(deficit);

  return (
    <Card
      className="mobile-list-item irrigation-equation-container"
      style={{ width: '100%', marginBottom: '5px' }}
      bodyStyle={{ padding: '2px', width: '100%' }}
      bordered
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            marginBottom: '4px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '100%',
              padding: '2px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Typography.Text
                style={{
                  fontWeight: '600',
                  fontSize: '32px',
                }}
                className={deficitStringClassName}
              >
                {deficitString}
              </Typography.Text>
            </div>

            <div style={{ marginLeft: '8px' }}>
              <div style={{ marginTop: '14px' }}>
                <Typography.Text style={{ fontWeight: '600', fontSize: '16px' }}>{waterUnits}</Typography.Text>
              </div>
              <div style={{ marginTop: '-8px' }}>
                <Typography.Text style={{ fontStyle: 'italic', fontSize: '12px', color: '#888' }}>{t('deficit')}</Typography.Text>
              </div>
            </div>
          </div>

          <div><Typography.Text style={{ padding: '4px' }}>+</Typography.Text></div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '100%',
              padding: '2px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Typography.Text style={{ fontWeight: '600', fontSize: '32px' }}>
                {etcForecastString}
              </Typography.Text>
            </div>

            <div style={{ marginLeft: '8px' }}>
              <div style={{ marginTop: '14px' }}>
                <Typography.Text style={{ fontWeight: '600', fontSize: '16px' }}>{waterUnits}</Typography.Text>
              </div>
              <div style={{ marginTop: '-8px' }}>
                <Typography.Text style={{ fontStyle: 'italic', fontSize: '12px', color: '#888' }}>{t('ETc')}</Typography.Text>
              </div>
            </div>
          </div>

          <div style={{ padding: '4px' }}>=</div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '100%',
              padding: '2px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Typography.Text style={{ fontWeight: '600', fontSize: '32px' }}>
                {durationString}
              </Typography.Text>
            </div>

            <div style={{ marginLeft: '8px' }}>
              <div style={{ marginTop: '14px' }}>
                <Typography.Text style={{ fontWeight: '600', fontSize: '16px' }}>{t('hours')}</Typography.Text>
              </div>
              <div style={{ marginTop: '-8px' }}>
                <Typography.Text style={{ fontStyle: 'italic', fontSize: '12px', color: '#888' }}>{t('irrigation')}</Typography.Text>
              </div>
            </div>
          </div>

        </div>
        {irrigationDuration === null && (
          <div className="equation-message">
            {t('*Add information about your irrigation system to get irrigation duration recommendations.')}
          </div>
        )}
      </div>
    </Card>
  );
}

IrrigationEquation.defaultProps = {
  units: {
    deficit: 'millimeters',
    etcForecast: 'millimeters',
    totalWater: 'millimeters',
  },
};

IrrigationEquation.propTypes = {
  deficit: PropTypes.number.isRequired,
  etcForecast: PropTypes.number.isRequired,
  irrigationDuration: PropTypes.string.isRequired,
  units: PropTypes.shape({
    deficit: PropTypes.string,
    etcForecast: PropTypes.string,
    totalWater: PropTypes.string,
  }),
};
