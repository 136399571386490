import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { farmApi } from 'farmx-api';
import { hooks } from 'farmx-redux-core';
import { getColorForCurrentPercent } from '../components/soilState';

export function PercentAvailableWaterGraph({ blockId }) {
  // load data for each soil sensor in block
  // select data averaged across block
  const { t } = useTranslation();
  const getUserUnits = hooks.useUnits();
  const { label: depthUnitsLabel } = getUserUnits(0, 'inches', 'depth');
  const depthLabel = depthUnitsLabel === 'inches' ? '"' : t(depthUnitsLabel);

  const [currentWaterData, setCurrentWaterData] = useState();
  const [loading, setLoading] = useState();
  const highchartsComponentRef = React.createRef();

  useEffect(() => {
    setLoading(true);
    farmApi.getBlockAvailableWater(blockId).then((response) => {
      setCurrentWaterData(response.data.currentWaterStats);
      setLoading(false);
    });
  }, [blockId, setCurrentWaterData, setLoading]);

  function buildHighChartsData() {
    if (!currentWaterData) {
      return {
        series: [],
        xAxis: {},
        yAxis: {},
      };
    }

    const seriesData = currentWaterData.depthStats
      .map((depthData) => ({
        // FF-786: Tweaking the data points to make the bar as rounded rectangle
        y: depthData.currentPercent > 1 ? 100 : depthData.currentPercent * 100,
        color: getColorForCurrentPercent(depthData.currentPercent, depthData.refillPercent),
      }));
    const averageRefillPercent = currentWaterData.depthStats.reduce(
      (partialSum, a) => a.refillPercent + partialSum,
      0,
    ) / currentWaterData.depthStats.length;
    const plotLines = [{
      color: '#333',
      value: averageRefillPercent * 100,
      zIndex: 100,
    }];
    const categories = currentWaterData.depthStats.map((depthData) => {
      const { value: depthConverted } = getUserUnits(depthData.depthInches, 'inches', 'depth');
      return `${Math.round(depthConverted)}${depthLabel}`;
    });
    return {
      series: [{
        showInLegend: 'false',
        data: seriesData,
      }],
      xAxis: {
        categories,
        labels: {
          style: {
            fontSize: depthUnitsLabel === 'inches' ? '14px' : '10px',
          },
        },
      },
      yAxis: {
        plotLines,
      },
    };
  }

  const chartData = buildHighChartsData();

  const options = {
    title: null,
    chart: {
      type: 'column',
      marginBottom: 25,
      marginTop: 20,
      inverted: true,
    },
    legend: { enabled: false },
    tooltip: {
      formatter: () => false,
    },
    plotOptions: {
      series: {
        borderRadius: 5,
        groupPadding: 0,
      },
    },
    xAxis: chartData.xAxis,
    yAxis: {
      min: 0,
      max: 100,
      plotLines: chartData.yAxis.plotLines,
      title: {
        text: null,
      },
      labels: {
        x: 0,
        y: 20,
        useHTML: true,
        rotation: 0,
        format: '{value} %',
        style: {
          fontSize: '14px',
        },
      },
      showFirstLabel: false,
      gridLineWidth: 0,
    },
    series: chartData.series,
    credits: {
      enabled: false,
    },
    lang: {
      noData: t('No soil moisture data'),
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#888',
      },
    },
    time: {
      useUTC: false,
    },
  };

  if (!currentWaterData && loading) {
    return (
      <div className="padding-10">{t('Loading...')}</div>
    );
  }

  if (!currentWaterData) {
    return (
      <div className="padding-10">{t('No data available')}</div>
    );
  }

  return (
    <HighchartsReact
      constructorType="chart"
      containerProps={{ style: { height: '100%' } }}
      highcharts={Highcharts}
      options={options}
      ref={highchartsComponentRef}
    />
  );
}

PercentAvailableWaterGraph.propTypes = {
  blockId: PropTypes.number.isRequired,
};
