import React, { useState, useEffect } from 'react';
import {
  PageHeader, Button,
} from 'antd';
import {
  ArrowLeftOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ScheduleCreateForm from './ScheduleCreateForm';
import './ScheduleCreatePage.css';

function ScheduleCreatePage(props) {
  const { history, location } = props;
  const { state } = location;
  const [block, setBlock] = useState([]);
  const [paramDateRange, setParamDateRange] = useState([]);

  const URL = useLocation();
  let propBlockId = [];
  let propDateRange = [];
  if (URL) {
    if (URL.search) {
      const params = new URLSearchParams(URL.search);
      const temp = params.get('blockId');
      const blockId = temp.split(',').filter((d) => d.length > 0).map((d) => Number(d));
      const startDateParam = params.get('startDate');
      const endDateParam = startDateParam
        ? moment(startDateParam).add(12, 'hours')
        : null;
      if (startDateParam && endDateParam) {
        propDateRange = [moment(startDateParam), endDateParam];
      }
      if (blockId) {
        if (blockId.length > 0) propBlockId = blockId;
      }
    }
  }
  useEffect(() => {
    if (URL) {
      if (URL.search) {
        const params = new URLSearchParams(URL.search);
        const temp = params.get('blockId');
        const blockId = temp.split(',').filter((d) => d.length > 0).map((d) => Number(d));
        const startDateParam = params.get('startDate');
        const endDateParam = startDateParam
          ? moment(startDateParam).add(12, 'hours')
          : null;
        if (startDateParam && endDateParam) {
          setParamDateRange([moment(startDateParam), endDateParam]);
        }
        if (blockId) {
          if (blockId.length > 0) setBlock(blockId);
        }
      }
    }
  }, [URL]);

  return (
    <div className="schedule-form-container">
      <div className="form-heading">
        <PageHeader
          title="New Irrigation Schedule"
          onBack={() => history.goBack()}
          backIcon={
            state && state.showBack
              ? (
                <Button className="back-form-button">
                  <ArrowLeftOutlined />
                  <span className="back-form-text">Go Back</span>
                </Button>
              ) : <div className="back-form-button"><span className="back-form-text" /></div>
            }
        />
        <div>&nbsp;</div>
      </div>
      <ScheduleCreateForm
        blocks={block.length > 0 ? block : propBlockId}
        dateRange={paramDateRange.length > 0 ? paramDateRange : propDateRange}
        onSubmit={() => { history.goBack(); }}
      />
    </div>
  );
}

ScheduleCreatePage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      showBack: PropTypes.bool,
    }),
  }),
};

ScheduleCreatePage.defaultProps = {
  history: {
    goBack: () => {},
    push: () => {},
  },
  location: {
    state: {
      showBack: false,
    },
  },
};

export default ScheduleCreatePage;
