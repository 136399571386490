export const irrigationStatus = {
  IRRIGATING: 'IRRIGATING',
  NOT_SCHEDULED: 'NOT_SCHEDULED',
  SCHEDULED: 'SCHEDULED',
};

export const valveOverrideState = {
  CLOSED: 'closed',
  OPEN: 'open',
  SCHEDULED: 'scheduled',
};

export const pumpOverrideState = {
  CLOSED: 'off',
  OPEN: 'on',
  SCHEDULED: 'scheduled',
};

export const installState = {
  INSTALLED: 'installed',
};
