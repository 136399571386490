import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import './help-button.less';

const HelpButton = (props) => {
  const { onClick, className } = props;
  const classNames = `help-button flex-center ${className}`;

  return (
    <Button
      className={classNames}
      onClick={onClick}
      type="text"
      icon={<QuestionCircleFilled />}
    />
  );
};

HelpButton.defaultProps = {
  onClick: () => { },
  className: '',
};

HelpButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default HelpButton;
