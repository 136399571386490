/* eslint-disable no-console */
import { notification } from 'antd';
import cloneDeep from 'lodash/cloneDeep';

export const notifyError = (msg, className = 'notification-error') => {
  notification.error({
    message: 'A problem occured',
    description: msg || 'Unknown Error.',
    className,
  });
};

export const getSelectedDateRange = (arr, idxSelected) => {
  const results = [];

  if (!(arr instanceof Array)) {
    console.log('getSelectedRange: arr not instance of array');

    return results;
  }

  if (!(Number.isInteger(idxSelected) && idxSelected > -1)) {
    console.log('getSelectedRange: idxSelected is not positive integer');

    return results;
  }

  if (!Object.prototype.hasOwnProperty.call(arr, idxSelected)) {
    console.log('getSelectedRange: idxSelected out of bounds');

    return results;
  }

  const range = 5;
  const rangeDynamic = range - 1;
  const idxMax = arr.length - 1;
  const toTheLeft = Math.floor(rangeDynamic / 2);
  const toTheRight = rangeDynamic - toTheLeft;

  const toTheRightAvailable = idxMax - idxSelected;
  const missingFromRight = toTheRightAvailable < toTheRight
    ? toTheRight - toTheRightAvailable
    : 0;

  const toTheLeftAvailable = idxSelected;
  const missingFromLeft = toTheLeftAvailable < toTheLeft
    ? toTheLeft - toTheLeftAvailable
    : 0;

  const getFromTheLeft = toTheLeft + missingFromRight;

  const getFromTheRight = toTheRight + missingFromLeft;

  let stepToTheLeft = getFromTheLeft > toTheLeftAvailable
    ? toTheLeftAvailable
    : getFromTheLeft;

  let stepToTheRight = getFromTheRight > toTheRightAvailable
    ? toTheRightAvailable
    : getFromTheRight;

  results.push(cloneDeep(arr[idxSelected]));

  let i = 1;
  while (stepToTheLeft) {
    const idxWanted = idxSelected - i;
    if (Object.prototype.hasOwnProperty.call(arr, idxWanted)) {
      results.unshift(cloneDeep(arr[idxWanted]));
    }
    i += 1;
    stepToTheLeft -= 1;
  }

  let j = 1;
  while (stepToTheRight) {
    const idxWanted = idxSelected + j;
    if (Object.prototype.hasOwnProperty.call(arr, idxWanted)) {
      results.push(cloneDeep(arr[idxWanted]));
    }
    j += 1;
    stepToTheRight -= 1;
  }

  return results;
};
