import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getTextForIrrigationState,
  getStateForIrrigationState,
  getActionText,
} from './recommendation';
import { StatusTag } from '../components/StatusTag';
import { useTracking } from '../../../helper/mixpanel';
import HelpButton from '../components/HelpButton';
import SoilStateLegendModal from './SoilStateLegendModal';

export function RecommendationListItem({ selectedTab, recommendation, loadInitialTab }) {
  const history = useHistory();
  const location = useLocation();
  const { push } = history;
  const { t } = useTranslation();
  const tracking = useTracking();
  const [showModal, setShowModal] = useState(false);

  const translatedDescription = t(recommendation.description);
  const onDetailsClick = useCallback(() => {
    push({
      pathname: `/recommendation/detail/${recommendation.block}`,
      state: { selectedTab },
    });
    tracking.track('Clicked recommendation details', recommendation);
  }, [selectedTab, push, recommendation, tracking]);

  const openModal = useCallback((e) => {
    e.stopPropagation();
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onActionButtonClick = useCallback((event) => {
    event.stopPropagation();
    push({
      pathname: `/recommendation/action/${recommendation.block}`,
      state: {
        from: location.pathname,
        selectedTab,
      },
    });
    tracking.track('Clicked recommendation action', recommendation);
  }, [selectedTab, location.pathname, push, recommendation, tracking]);
  const { selectedTab: initialTab } = location.state ?? {};

  useEffect(() => {
    loadInitialTab(initialTab);
  }, [initialTab, loadInitialTab]);

  return (
    <>
      <Card
        className="recommendation-list"
        key={recommendation.id}
        title={recommendation.blockName}
        extra={(
          <div className="flex-center column-gap-10">
            <StatusTag
              text={getTextForIrrigationState(recommendation.state)}
              state={getStateForIrrigationState(recommendation.state)}
            />
            <HelpButton onClick={openModal} />
          </div>
        )}
        actions={[
          <div className="recommendation-list-item-footer">
            {recommendation.action !== 'no_action' ? (
              <div className="action-container-primary">
                <Button
                  size="large"
                  block
                  type="primary"
                  onClick={onActionButtonClick}
                >
                  {t(getActionText(recommendation.action))}
                </Button>
              </div>
            ) : null}
          </div>]}
        bordered
        onClick={onDetailsClick}
      >
        <div className="recommendation-list-item-content">
          <Typography.Text
            className="recommendation-description"
          >
            {translatedDescription}
          </Typography.Text>
          <Typography.Text
            className="recommendation-description"
          >
            <span className="ant-btn-link">{` ${t('More...')}`}</span>
          </Typography.Text>
        </div>
      </Card>
      <SoilStateLegendModal
        visible={showModal}
        onCancel={closeModal}
      />
    </>
  );
}

RecommendationListItem.defaultProps = {
  selectedTab: 'new',
  loadInitialTab: () => { },
};

RecommendationListItem.propTypes = {
  recommendation: PropTypes.shape({
    id: PropTypes.number,
    block: PropTypes.number,
    blockName: PropTypes.string,
    description: PropTypes.string,
    action: PropTypes.string,
    state: PropTypes.string,
    isActive: PropTypes.bool,
    isFrozen: PropTypes.bool,
    dateCreated: PropTypes.string,
    dateIrrigationStart: PropTypes.string,
    irrigationDuration: PropTypes.string,
  }).isRequired,
  selectedTab: PropTypes.string,
  loadInitialTab: PropTypes.func,
};
