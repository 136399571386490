import React from 'react';
import { Card, Typography } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './WarningCard.css';

export default function WarningCard({ waterValue }) {
  const { t } = useTranslation();
  return (
    <Card
      bodyStyle={{ padding: '12px', width: '100%' }}
      headStyle={{ border: 'none' }}
      bordered
      className="warning-card card-layout-style"
    >
      <div className="warning-container">
        <div className="warning-icon">
          <WarningFilled />
        </div>
        <div className="irrigation-status-content">
          <Typography.Text className="warning-title" strong>{t('Warning!')}</Typography.Text>
          <Typography.Text>{t('you are irrigating beyond the field capacity.')}</Typography.Text>
          <Typography.Text>
            {t(`which is ${waterValue} gallons in 24-hour period`)}
          </Typography.Text>
        </div>
      </div>
    </Card>
  );
}

WarningCard.propTypes = {
  waterValue: PropTypes.number,
};

WarningCard.defaultProps = {
  waterValue: 0,
};
