import { useMixpanel } from 'react-mixpanel-browser';
import { isMobile } from '../utils/detectDevice';
import { version } from '../../package.json';

export const mixPanelUserConfig = (userInfo, mixpanel) => {
  if (userInfo && mixpanel.config && mixpanel.config.token) {
    mixpanel.identify(userInfo.user_id);
    mixpanel.people.set({
      name: userInfo.username,
      distinct_id: userInfo.user_id,
    });
    // To add app_name and app_version as super property.
    // so it will automatically include them with all events sent
    mixpanel.register({
      app_name: isMobile ? 'mobile' : 'desktop',
      app_version: version,
    });
  }
};

const useTrackingMock = {
  track: () => { },
  setUserInfo: () => { },
};

export const useTracking = () => {
  const mixpanel = useMixpanel();
  if (mixpanel && !mixpanel.setUserInfo) {
    mixpanel.setUserInfo = (userInfo) => {
      mixPanelUserConfig(userInfo, mixpanel);
    };
  }

  return mixpanel && mixpanel.config ? mixpanel : useTrackingMock;
};
