import { Tag, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { hooks } from 'farmx-redux-core';
import { selectFlowDetails } from '../../../../helper/scheduleHelper';

const {
  useUnits,
} = hooks;

export default function WaterInformation(props) {
  const { t } = useTranslation();
  const {
    blockId, units, dateRange, eventId,
  } = props;

  const flowDetailsObj = useSelector((state) => selectFlowDetails(state,
    blockId, dateRange, eventId));

  const getUserUnits = useUnits();

  const scheduledWaterAmount = Math.round(flowDetailsObj.scheduled);
  const actualWaterAmount = Math.round(flowDetailsObj.actual);

  const actualVolume = getUserUnits(actualWaterAmount, units.actualVolume, 'volume');
  const scheduledVolume = getUserUnits(scheduledWaterAmount, units.actualVolume, 'volume');

  const displayActual = `${actualVolume.value} ${actualVolume.label}`;
  const displayScheduled = `${scheduledVolume.value} ${scheduledVolume.label}`;

  return (
    <div className="water-information-container">
      <div className="water-information-single">
        <div className="water-information-label">
          <Typography.Title level={5}>{t('Scheduled')}</Typography.Title>
        </div>
        <div className="water-information-value">
          <Tag color="#56BA2E">
            <i className="icon icon-waterdrop icon-white" />
          </Tag>
          <Tag color="#DEF4CD">{`${displayScheduled} ${t('Scheduled')}`}</Tag>
        </div>
      </div>
      <div className="water-information-single">
        <div className="water-information-label">
          <Typography.Title level={5}>{t('Actual')}</Typography.Title>
        </div>
        <div className="water-information-value">
          <Tag color="#1F71C6">
            <i className="icon icon-waterdrop icon-white" />
          </Tag>
          <Tag color="#DEF4FC">{`${displayActual} ${t('Used')}`}</Tag>
        </div>
      </div>
    </div>
  );
}

WaterInformation.defaultProps = {
  blockId: null,
  units: {
    actualVolume: 'gallons',
  },
  dateRange: [],
  eventId: null,
};

WaterInformation.propTypes = {
  blockId: PropTypes.number,
  units: PropTypes.shape({
    actualVolume: PropTypes.string,
  }),
  dateRange: PropTypes.arrayOf(PropTypes.any),
  eventId: PropTypes.number,
};
