import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import { initReducerSatelliteImagery, reducerSatelliteImagery } from './reducerSatellite';
import { SatelliteImagery } from '../../map/components/satelliteimagery/SatelliteImagery';
import {
  useRanchBlockPreference,
  useSatelliteImagery,
  useSatelliteDataDatesAndTypes,
  useSatelliteDateRangePreparation,
  useDefaultSelectedImageryData,
  useRequestSatelliteData,
} from '../../../helper/satelliteHooks';
import { isMobile } from '../../../utils/detectDevice';

const {
  selectSelectedRanch,
  selectSelectedBlock,
} = selectors;

export function SatelliteImageryWrapper(props) {
  const { ranchId, dispatchMap } = props;

  const selectedRanchId = useSelector((state) => selectSelectedRanch(state));
  const selectedBlockId = useSelector((state) => selectSelectedBlock(state));

  // use local reducer to handle state of the component
  const [stateSatellite, satelliteDispatch] = useReducer(
    reducerSatelliteImagery,
    initReducerSatelliteImagery(),
    initReducerSatelliteImagery,
  );

  // Return the imagery data to parent component when changing
  useEffect(() => {
    if (stateSatellite.selectedImageryData && dispatchMap) {
      dispatchMap({
        type: 'setSelectedImageryData',
        payload: stateSatellite.selectedImageryData,
      });
    }
  }, [dispatchMap, stateSatellite.selectedImageryData]);

  // To close the satellite imagery drawer for desktop
  useEffect(() => {
    if (!stateSatellite.isImageryPanelVisible && dispatchMap && !isMobile) {
      dispatchMap({
        type: 'setIsImageryPanelVisible',
        payload: stateSatellite.isImageryPanelVisible,
      });
    }
  }, [dispatchMap, stateSatellite.isImageryPanelVisible]);

  // Set ranch/block preference to local reducer
  useRanchBlockPreference(
    selectedBlockId?.payload?.length ? undefined : selectedRanchId,
    selectedBlockId,
    satelliteDispatch,
  );

  // get satellite imagery using local reducer
  useSatelliteImagery(
    stateSatellite.selectedRanchId,
    stateSatellite.selectedBlockId,
    stateSatellite.latestReload,
    satelliteDispatch,
  );

  // prepare satellite data dates and types using local reducer
  useSatelliteDataDatesAndTypes(
    stateSatellite.satelliteDates,
    stateSatellite.selectedSatelliteDataType,
    stateSatellite.selectedSatelliteDataSource,
    satelliteDispatch,
  );

  // SatelliteDateRange preparation using local reducer
  useSatelliteDateRangePreparation(
    stateSatellite.selectedSatelliteDatesFiltered,
    stateSatellite.selectedSatelliteDate,
    stateSatellite.selectedSatelliteDatesDataRange,
    stateSatellite.selectedSatelliteDataType,
    stateSatellite.selectedSatelliteDataSource,
    stateSatellite.selectedRanchId,
    stateSatellite.selectedBlockId,
    satelliteDispatch,
  );

  // To maintain the selected imagery data on the map
  useDefaultSelectedImageryData(
    stateSatellite.dateRangeWithDisplayData,
    stateSatellite.selectedImageryData,
    stateSatellite.selectedSatelliteDate,
    satelliteDispatch,
  );

  // request satellite imagery data
  useRequestSatelliteData(stateSatellite.selectedSatelliteDatesDataRange, satelliteDispatch);

  return (
    <div className="flex-row padding-10" id="satellite-imagery-section">
      <div className="flex-column switch-box layout-width">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {!!ranchId && (
          <SatelliteImagery
            dispatchMapPage={satelliteDispatch}
            satelliteDates={stateSatellite.satelliteDates}
            selectedSatelliteDate={stateSatellite.selectedSatelliteDate}
            selectedSatelliteDataType={stateSatellite.selectedSatelliteDataType}
            selectedSatelliteDataSource={stateSatellite.selectedSatelliteDataSource}
            selectedSatelliteDatesFiltered={stateSatellite.selectedSatelliteDatesFiltered}
            dateRangeWithDisplayData={stateSatellite.dateRangeWithDisplayData}
            selectedImageryData={stateSatellite.selectedImageryData}
            isMobile={isMobile}
          />
          )}
        </div>
      </div>
    </div>
  );
}

SatelliteImageryWrapper.propTypes = {
  ranchId: PropTypes.number,
  dispatchMap: PropTypes.func,
};

SatelliteImageryWrapper.defaultProps = {
  ranchId: null,
  dispatchMap: null,
};
