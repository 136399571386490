import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import { SensorAlarmButton } from 'farmx-web-ui';
import { CardLayout } from '../components/CardLayout';
import ConnectivityStateTag from '../components/ConnectivityStateTag';
import { isNotNull } from '../../../helper/listHelper';

export function SensorListItem(props) {
  const { t } = useTranslation();

  const {
    alarmCount,
    date,
    icon,
    online,
    title,
    content,
    type,
    identifier,
  } = props;

  return (
    <CardLayout>
      <div className="title-and-alarm-container">
        <Typography.Title className="pressure-sensor-name flex-row align-center" level={4}>
          <i className={`icon ${icon} icon-${icon}`} />
          {title || t('No Name')}
        </Typography.Title>
        <SensorAlarmButton
          isMobileView
          alarmCount={alarmCount}
          identifier={identifier}
          type={type}
        />
      </div>
      {isNotNull(date) && (
        <div>
          <Typography.Text type="secondary">
            {`${t('Updated')} `}
            <Moment fromNow>{date}</Moment>
          </Typography.Text>
        </div>
      )}
      <div className="pump-status-container">
        <ConnectivityStateTag
          isOnline={online}
        />
        <Typography.Title level={4}>
          {content}
        </Typography.Title>
      </div>
    </CardLayout>
  );
}

SensorListItem.defaultProps = {
  alarmCount: 0,
  content: '',
  date: '',
  icon: '',
  online: false,
  title: '',
  type: '',
  identifier: '',
};

SensorListItem.propTypes = {
  alarmCount: PropTypes.number,
  content: PropTypes.string,
  date: PropTypes.string,
  icon: PropTypes.string,
  online: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  identifier: PropTypes.string,
};
