import area from '@turf/area';

export function getBlockArea(block) {
  if (!block || !block.bounds) {
    return 0;
  }
  const blockFeature = {
    id: block.id,
    type: 'Feature',
    geometry: block.bounds,
  };
  return area(blockFeature);
}
