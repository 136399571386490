import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export default function SensorSettingsUpdateModal(props) {
  const {
    visible,
    onCancel,
    onOk,
    originalFieldCapacity,
    newFieldCapacity,
    originalRefillPercent,
    newRefillPercent,
    originalWiltingPoint,
    newWiltingPoint,
  } = props;
  const { t } = useTranslation();

  /**
   * Description is, if values are changed
   * 33.1% => 33.2%
   *
   * Else: No change
   */
  const getDescription = (originalValue, newValue) => (
    originalValue === newValue
      ? t('No Change')
      : (
        <span>
          {`${(originalValue * 100).toFixed(2)}%`}
          <span className="separator">{'=>'}</span>
          {`${(newValue * 100).toFixed(2)}%`}
        </span>
      )
  );

  return (
    <Modal
      className="sensor-settings-update-modal"
      title={`${t('Confirm Updates')}:`}
      centered
      visible={visible}
      closable
      onCancel={onCancel}
      onOk={onOk}
      okText={t('Update')}
    >
      <div>
        <Typography.Text className="update-item-heading">{`${t('Field Capacity')}: `}</Typography.Text>
        <Typography.Text>{getDescription(originalFieldCapacity, newFieldCapacity)}</Typography.Text>
      </div>
      <div>
        <Typography.Text className="update-item-heading">{`${t('Refill Percent')}: `}</Typography.Text>
        <Typography.Text>{getDescription(originalRefillPercent, newRefillPercent)}</Typography.Text>
      </div>
      <div>
        <Typography.Text className="update-item-heading">{`${t('Wilting Point')}: `}</Typography.Text>
        <Typography.Text>{getDescription(originalWiltingPoint, newWiltingPoint)}</Typography.Text>
      </div>
    </Modal>
  );
}

SensorSettingsUpdateModal.defaultProps = {
  visible: false,
  onCancel: () => { },
  onOk: () => { },
};

SensorSettingsUpdateModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  originalFieldCapacity: PropTypes.string.isRequired,
  newFieldCapacity: PropTypes.string.isRequired,
  originalRefillPercent: PropTypes.string.isRequired,
  newRefillPercent: PropTypes.string.isRequired,
  originalWiltingPoint: PropTypes.string.isRequired,
  newWiltingPoint: PropTypes.string.isRequired,
};
