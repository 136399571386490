import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import moment from 'moment';
import ScheduleForm from './ScheduleForm';

const {
  selectScheduledEventById,
  selectBlockRenderedScheduleEvent,
} = selectors;

const ScheduleUpdateForm = ({
  onCancel,
  onChange,
  onSubmit,
  blockId,
  scheduleId,
  eventIndex,
}) => {
  const dispatch = useDispatch();

  const scheduledEvent = useSelector((state) => selectScheduledEventById(state,
    { id: scheduleId, blockId }));
  const renderedEvent = useSelector((state) => selectBlockRenderedScheduleEvent(
    state,
    { eventId: scheduleId, blockId, eventIndex },
  )) || {};
  const combinedEvent = {
    ...scheduledEvent,
    ...renderedEvent,
    rootStart: scheduledEvent && scheduledEvent.start_date,
    rootStop: scheduledEvent && scheduledEvent.stop_date,
  };

  useEffect(() => {
    dispatch(actions.loadBlockIrrigationSchedule(blockId));
    dispatch(actions.loadBlockDetail(blockId));
  }, [dispatch, blockId]);

  function getInitialValues(event) {
    if (!event) {
      return null;
    }
    const startDate = moment(event.start_date);
    const endDate = moment(event.stop_date);
    return {
      blockIds: [event.block],
      startDate,
      endDate,
      duration: endDate.diff(startDate, 'minutes', true),
      isRecurring: event.is_recurring,
      repeatFrequency: event.repeat_frequency,
      repeatInterval: event.repeat_interval,
      timezone: event.timezone,
      id: event.id,
      eventIndex: event.event_index,
      repeatEndType: event.repeat_end_type,
      repeatEndCount: event.repeat_end_count,
      repeatEndDate: event.repeat_end_date ? moment(event.repeat_end_date) : null,
      repeatWeekdays: {
        Su: event.repeat_sunday,
        Mo: event.repeat_monday,
        Tu: event.repeat_tuesday,
        We: event.repeat_wednesday,
        Th: event.repeat_thursday,
        Fr: event.repeat_friday,
        Sa: event.repeat_saturday,
      },
    };
  }

  return (
    <ScheduleForm
      onCancel={onCancel}
      onChange={onChange}
      onSubmit={onSubmit}
      blocks={[blockId]}
      scheduleId={scheduleId}
      scheduledEvent={combinedEvent}
      initialValues={getInitialValues(combinedEvent)}
      isEditMode
    />
  );
};

ScheduleUpdateForm.propTypes = {
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  blockId: PropTypes.number,
  scheduleId: PropTypes.number,
  eventIndex: PropTypes.number,
};

ScheduleUpdateForm.defaultProps = {
  onCancel: () => null,
  onChange: () => null,
  onSubmit: () => null,
  blockId: null,
  scheduleId: null,
  eventIndex: 0,
};

export default ScheduleUpdateForm;
