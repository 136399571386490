import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined, SettingFilled } from '@ant-design/icons';
import {
  Radio, Button, Modal, Divider,
} from 'antd';
import { RanchBlockSelect } from 'farmx-web-ui';
import { actions, selectors, hooks } from 'farmx-redux-core';
import './home.css';
import InfiniteScroll from 'react-infinite-scroller';
import { compareRecommendations } from './recommendation';
import { RecommendationListItem } from './RecommendationListItem';
import { PageHeader } from '../components/PageHeader';
import { useTracking } from '../../../helper/mixpanel';
import { NoDataCard } from '../components/NoDataCard';
import ReactContentLoader from '../components/ContentLoader';

const {
  selectRecommendationsForBlocks,
  selectLoadingRecommendations,
  selectLoginUserInfo,
} = selectors;

const {
  loadRecommendations,
  setRanchBlockSelection,
} = actions;

const { useRanchBlockSelection } = hooks;

export function Home() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tracking = useTracking();
  const [selectedTab, setSelectedTab] = useState('new');

  const userInfo = useSelector(selectLoginUserInfo).payload;

  // get selected blocks
  const { selectedObjFromState, blockIds } = useRanchBlockSelection();

  // for InfiniteScroll
  const [recommendObj, setRecommendObj] = useState({ 0: [] });
  const [count, setCount] = useState(0);
  const [items, setItems] = useState(recommendObj[count]);
  // Flag to determine whether the intial tab has been loaded.
  const [loadTabFlag, setLoadTabFlag] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const chunkSize = 8;

  // get recommendations from redux
  // Below patch fix will be removed in future
  const recommendations = useSelector((state) => selectRecommendationsForBlocks(state, blockIds))?.filter((d) => d?.type !== 'imagery_anomaly');

  /**
   * recommendationListObj contains data in format
   * { new: [], dismissed: [], scheduled: [] }
   */
  const recommendationListObj = useMemo(() => (
    recommendations.reduce((recommendationsList, currentRecommendation) => {
      if (currentRecommendation.isFrozen === false) {
        // New tab
        recommendationsList.new.push(currentRecommendation);
      } else if (currentRecommendation.freezeType === 'action' && currentRecommendation.isFrozen) {
        // Scheduled tab
        recommendationsList.scheduled.push(currentRecommendation);
      } else {
        // Dismissed tab
        recommendationsList.dismissed.push(currentRecommendation);
      }
      return recommendationsList;
    }, { new: [], dismissed: [], scheduled: [] })
  ), [recommendations]);
  const recommendationsFiltered = recommendationListObj[selectedTab];

  const recommendationsSorted = recommendationsFiltered.sort(compareRecommendations);
  const loadingStatus = useSelector((state) => selectLoadingRecommendations(state));
  const tabOptions = [
    { label: t('New'), value: 'new' },
    { label: t('Scheduled'), value: 'scheduled' },
    { label: t('Dismissed'), value: 'dismissed' },
  ];
  const [modal, setModal] = useState(false);

  useEffect(() => {
    dispatch(loadRecommendations());
  }, [dispatch]);

  useEffect(() => {
    if (tracking) {
      tracking.track('Loaded Recommendations Page');
    }
  }, [tracking]);


  useEffect(() => {
    if (recommendationsSorted && recommendationsSorted.length && !recommendObj[0].length
      && selectedObjFromState.type) {
      let i;
      const chunk = chunkSize;
      const obj = {};
      for (i = 0; i < recommendationsSorted.length; i += 1) {
        const chunkIndex = Math.floor(i / chunk);
        if (!obj[chunkIndex]) {
          obj[chunkIndex] = []; // start a new chunk
        }
        obj[chunkIndex].push(recommendationsSorted[i]);
      }
      setRecommendObj(obj);
      setItems(obj[0]);
      if (recommendationsSorted.length > chunkSize) {
        setHasMoreItems(true);
      }
    }
  }, [recommendationsSorted, recommendObj, selectedObjFromState.type]);

  const fetchMoreData = useCallback(() => {
    function handleItems(recommededArr) {
      const prevItems = items;
      const newItems = prevItems.concat(recommededArr);
      return newItems;
    }

    if (recommendObj[count + 1]) {
      setHasMoreItems(true);
      setItems(handleItems(recommendObj[count + 1]));
      setCount(count + 1);
    } else {
      setHasMoreItems(false);
    }
  }, [count, recommendObj, items]);

  function handleSelect(d) {
    if (d && d.value !== undefined) {
      dispatch(setRanchBlockSelection(d));
      setItems([]);
      setRecommendObj({ 0: [] });
      setCount(0);
      setHasMoreItems(false);
    }
  }

  // Retain tab selection when navigating to detail screen
  const loadInitialTab = useCallback((initialTab = 'new') => {
    // Ensure tab selection doesn't keep on running
    if (!loadTabFlag) {
      setSelectedTab(initialTab);
      setLoadTabFlag(true);
    }
  }, [loadTabFlag]);

  function renderRecommendations(recomendedItems, loading) {
    if (recomendedItems && recomendedItems.length && !loading) {
      return recomendedItems.map((recommendation) => (
        <RecommendationListItem
          key={recommendation.block}
          recommendation={recommendation}
          selectedTab={selectedTab}
          loadInitialTab={loadInitialTab}
        />
      ));
    } if (recomendedItems && !recomendedItems.length && !loading) {
      return <NoDataCard title={t('No recommendations found')} />;
    }

    return <ReactContentLoader />;
  }

  function renderPreferenceModal() {
    return (
      <Modal
        visible={modal}
        centered
        closable
        onCancel={() => setModal(false)}
        footer={null}
      >
        <>
          <div className="flex-column margin-bottom-10">
            <span className="preference-model-sections-header">
              {t('Automatic Scheduling')}
            </span>
            <Link to="/settings/blocks">
              {t('Set Automatic Scheduling preferences here')}
            </Link>
          </div>
          <Divider />
          <div className="margin-top-10">
            <span className="preference-model-sections-header">
              {t('Recommendation Model')}
            </span>
            <p>
              {t(`FarmX provides both Soil + Etc Deficit and Etc Deficit recommendations.
              Soil + Etc Deficit recommendations are used by default.`)}
            </p>
            <p>
              {t('Please contact FarmX at ')}
              <Link
                to="/#"
                onClick={(e) => {
                  window.location.href = 'mailto:support@farmx.co';
                  e.preventDefault();
                }}
              >
                {t('support@farmx.co')}
              </Link>
              {t(' to configure ETc only Irrigation Recommendations.')}
            </p>
          </div>
        </>
      </Modal>
    );
  }
  return (
    <div id="recommended-scrollable-container" className="recommended-scrollable-container">
      <div className="flex-column margin-10 page-content">
        <div className="inline-page-loader-div justify-content-space-between">
          <PageHeader title={t('Recommendations')} />
          {loadingStatus.loading ? (
            <div className="loader-common">
              <LoadingOutlined />
            </div>
          ) : null}
          <Button
            className="preference-button"
            onClick={() => setModal(true)}
            icon={(
              <span className="preference-modal-settings-icon">
                <SettingFilled />
              </span>
            )}
          >
            <strong>{t('Set Preference')}</strong>
          </Button>
          {modal && renderPreferenceModal()}
        </div>
        <div className="div-select-container ranch-block-select-container">
          <RanchBlockSelect
            onSelect={handleSelect}
            selected={selectedObjFromState}
            admin={userInfo && userInfo.admin}
          />
        </div>
        <div className="page-tab-container">
          <Radio.Group
            buttonStyle="solid"
            onChange={(e) => {
              setSelectedTab(e.target.value);
              if (tracking) {
                tracking.track(`Selected ${e.target.value} notifications`);
              }
            }}
            options={tabOptions}
            optionType="button"
            size="large"
            value={selectedTab}
          />
        </div>
        <div className="home-container">
          {renderRecommendations(recommendationsSorted, loadingStatus.loading)}
        </div>
      </div>
    </div>
  );
}
