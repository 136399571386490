/**
 * Calculate refill percent
 * @param {*} refillPoint
 * @param {*} wiltingPoint
 * @param {*} fieldCapacity
 * @returns {*} refill percent
 */
export function calculateRefillPercent(refillPoint, wiltingPoint, fieldCapacity) {
  return (refillPoint - wiltingPoint) / (fieldCapacity - wiltingPoint);
}
/**
 * Calculate refill point
 * @param {Number} refillPercent
 * @param {Number} wiltingPoint
 * @param {Number} fieldCapacity
 * @returns {Number} refill point
 */
export function calculateRefillPoint(refillPercent, wiltingPoint, fieldCapacity) {
  const refillPoint = wiltingPoint + ((refillPercent) * (fieldCapacity - wiltingPoint));
  return isNaN(refillPoint) ? 0 : refillPoint;
}
