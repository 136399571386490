import React from 'react';
import PropTypes from 'prop-types';
import Icons from './icons.svg';

/**
 * In order to add support for new icons,
 * simply extend svg sprite template icons.svg
 * One good way to extend is by using Icomoon app (https://icomoon.io/app/)
 * Upload or select svg into Icomoon app and click on "Generate SVG & More"
 * Download/extract and locate symbol-defs.svg
 * Copy and paste symbol into icons.svg
 *
 *
 * You can use the icon by including <Icon />, example
 * <Icon name="moonrise" color="red" size={20}>
 */

const Icon = ({ name, color, size }) => (
  <svg className={`icon icon-${name}`} fill={color} width={size} height={size}>
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
);

Icon.defaultProps = {
  color: '',
  size: 10,
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Icon;
