export const initReducerSatelliteImagery = () => ({
  selectedRanchId: undefined,
  selectedBlockId: undefined,
  satelliteDates: undefined,
  selectedSatelliteDate: undefined,
  selectedSatelliteDataType: 'NDVI',
  selectedSatelliteDataSource: 'satellite',
  selectedSatelliteDatesFiltered: undefined,
  selectedSatelliteDatesDataRange: undefined,
  dateRangeWithDisplayData: undefined,
  selectedImageryData: {
    visible: true,
    data: undefined,
  },
  showMap3D: false,
  latestReload: undefined,
  isImageryPanelVisible: true,
});

export function reducerSatelliteImagery(state, action) {
  switch (action.type) {
    case 'setIsImageryPanelVisible':
      return {
        ...state,
        isImageryPanelVisible: action.payload,
      };
    case 'setSelectedImageryData':
      return {
        ...state,
        selectedImageryData: action.payload,
      };
    case 'setDateRangeWithDisplayData':
      return {
        ...state,
        dateRangeWithDisplayData: action.payload,
      };
    case 'setSelectedSatelliteDatesDataRange':
      return {
        ...state,
        selectedSatelliteDatesDataRange: action.payload,
      };
    case 'setSelectedSatelliteDatesFiltered':
      return {
        ...state,
        selectedSatelliteDatesFiltered: action.payload,
      };
    case 'setSelectedSatelliteDataSource':
      return {
        ...state,
        selectedSatelliteDataSource: action.payload,
      };
    case 'setSelectedSatelliteDataType':
      return {
        ...state,
        selectedSatelliteDataType: action.payload,
      };
    case 'setSelectedSatelliteDate':
      return {
        ...state,
        selectedSatelliteDate: action.payload,
      };
    case 'setSatelliteDates':
      return {
        ...state,
        satelliteDates: action.payload,
      };
    case 'setSelectedRanchId':
      return {
        ...state,
        selectedRanchId: action.payload ? Number(action.payload) : undefined,
      };
    case 'setSelectedBlockId':
      return {
        ...state,
        selectedBlockId: action.payload ? Number(action.payload) : undefined,
      };
    case 'setShowMap3D':
      return {
        ...state,
        showMap3D: action.payload,
      };
    case 'setLatestReload':
      return {
        ...state,
        latestReload: Date.now(),
      };
    case 'reset':
      return initReducerSatelliteImagery();
    default:
      throw new Error('Unkown action type');
  }
}
