import { withPlatformDetect } from '../../utils/withPlatformDetect';

import Page404 from '../app/Page404';

import {
  UserInfoPage,
  ChangePasswordPage,
  NotificationSettingsPage,
} from './components';

import { AccountSettings } from '../mobile/account/AccountSettings';
import { NotificationsSettings } from '../mobile/settings/NotificationsSettings';
import { UnitsSettings } from '../mobile/settings/UnitsSettings';
import { LanguageSettings } from '../mobile/settings/LanguageSettings';
import { AppearanceSettings } from '../mobile/settings/AppearanceSettings';
import { SensorSettings } from '../mobile/settings/SensorSettings';
import IrrigationSettings from '../mobile/settings/IrrigationSettings';


export const ROUTE_KEYS = {
  USER_INFO: '/settings/user',
  CHANGE_PASSWORD: '/settings/password',
  NOTIFICATION: '/settings/notifications',
  ACCOUNT: '/settings/account',
  UNITS: '/settings/units',
  LANGUAGE: '/settings/language',
  APPEARANCE: '/settings/appearance',
  SENSORS: '/settings/sensors',
  IRRIGATION_SETTINGS: '/settings/blocks',
};

export const ROUTES = {
  USER_INFO: {
    component: withPlatformDetect(AccountSettings, UserInfoPage),
    exact: true,
    key: ROUTE_KEYS.USER_INFO,
    path: ROUTE_KEYS.USER_INFO,
  },
  CHANGE_PASSWORD: {
    component: ChangePasswordPage,
    exact: true,
    key: ROUTE_KEYS.CHANGE_PASSWORD,
    path: ROUTE_KEYS.CHANGE_PASSWORD,
  },
  NOTIFICATION: {
    component: withPlatformDetect(NotificationsSettings, NotificationsSettings),
    exact: true,
    key: ROUTE_KEYS.NOTIFICATION,
    path: ROUTE_KEYS.NOTIFICATION,
  },
  ACCOUNT: { // NOTE: duplicate of USER_INFO for backwards compat
    component: withPlatformDetect(AccountSettings, AccountSettings),
    exact: true,
    key: ROUTE_KEYS.ACCOUNT,
    path: ROUTE_KEYS.ACCOUNT,
  },
  UNITS: {
    component: withPlatformDetect(UnitsSettings, UnitsSettings),
    exact: true,
    key: ROUTE_KEYS.UNITS,
    path: ROUTE_KEYS.UNITS,
  },
  LANGUAGE: {
    component: withPlatformDetect(LanguageSettings, LanguageSettings),
    exact: true,
    key: ROUTE_KEYS.LANGUAGE,
    path: ROUTE_KEYS.LANGUAGE,
  },
  APPEARANCE: {
    component: withPlatformDetect(AppearanceSettings, Page404),
    exact: true,
    key: ROUTE_KEYS.APPEARANCE,
    path: ROUTE_KEYS.APPEARANCE,
  },
  SENSORS: {
    component: withPlatformDetect(SensorSettings, SensorSettings),
    exact: true,
    key: ROUTE_KEYS.SENSORS,
    path: ROUTE_KEYS.SENSORS,
  },
  IRRIGATION_SETTINGS: {
    component: withPlatformDetect(IrrigationSettings, IrrigationSettings),
    exact: true,
    key: ROUTE_KEYS.IRRIGATION_SETTINGS,
    path: ROUTE_KEYS.IRRIGATION_SETTINGS,
  },
};
