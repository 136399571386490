import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { hooks, helpers } from 'farmx-redux-core';
import { getPercentForFraction, roundToNearestPercent } from '../../../../helper/common';

const { getPercentAvailable, getVolumeAvailable } = helpers;

export const useRootzoneValue = ({
  depthRootzone,
  selectedMetrics,
  vwcRootzone,
  wiltingPointRootzone,
  fieldCapacityRootzone,
  units,
  percent,
  volume,
}) => {
  const { t } = useTranslation();
  const getUserUnits = hooks.useUnits();
  const depthUnits = units?.depth || 'inches';

  return useMemo(() => {
    switch (selectedMetrics) {
      case 'percent': return [
        roundToNearestPercent(percent || (getPercentAvailable(
          vwcRootzone,
          wiltingPointRootzone,
          fieldCapacityRootzone,
        ) * 100)),
        `% ${t('Available')}`,
      ];

      case 'volume': {
        const volumeAvailable = volume || getVolumeAvailable(
          depthRootzone, vwcRootzone, wiltingPointRootzone, fieldCapacityRootzone,
        );
        const { value, label } = getUserUnits(volumeAvailable, depthUnits, 'depth');
        return [value, label];
      }

      default: return [
        getPercentForFraction(vwcRootzone),
        `% ${t('VWC')}`,
      ];
    }
  }, [
    depthRootzone,
    depthUnits,
    fieldCapacityRootzone,
    getUserUnits,
    percent,
    selectedMetrics,
    t,
    volume,
    vwcRootzone,
    wiltingPointRootzone,
  ]);
};
