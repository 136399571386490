import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { RanchSelect } from 'farmx-web-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { PageHeader, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import '../../control/components/IrrigationControlSummary.css';

const {
  setRanch,
  setUrlLoad,
} = actions;

const {
  selectSelectedRanch,
} = selectors;

const {
  useSetURLFromState,
  useSetStateFromURL,
} = hooks;


function Dashboard() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { search, pathname } = location;
  const urlSearch = useRef(search);
  const ranchIds = useSelector(selectSelectedRanch).payload || [];
  useSetStateFromURL(urlSearch);
  useSetURLFromState(urlSearch, search, pathname);

  return (
    <div className="control-container">
      <div className="single-column-page padded">
        <PageHeader
          title={t('New Dashboard')}
        />
        <Divider />

        <div className="ranch">
          <h3>Select Ranch</h3>
          <RanchSelect
            onChange={(d) => {
              if (d === undefined) {
                dispatch(setRanch());
                dispatch(setUrlLoad(false));
                history.push('/dashboard');
              }
            }}
            onSelect={(d) => {
              dispatch(setRanch(d));
            }}
            value={ranchIds}
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
