import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors, hooks } from 'farmx-redux-core';
import PropTypes from 'prop-types';
import ControlValveListItem from './ControlValveListItem';

const {
  selectBlockControlStatus,
  selectValveControllersForBlocks,
} = selectors;

const { useBlockNames } = hooks;

export default function ControlValveList(props) {
  const { blocks } = props;
  const { t } = useTranslation();

  const filteredBlocks = blocks.length ? blocks.filter((d) => (d && d.valve)
   || (d && d.valves && d.valves.length)) : [];
  const blockIds = filteredBlocks.map((d) => d && d.id);
  const valveStatus = useSelector((state) => selectBlockControlStatus(state, blockIds));
  const valveObjs = useSelector((state) => selectValveControllersForBlocks(state, blockIds));

  const blockArr = [];
  Object.values(valveObjs).forEach((objArr) => {
    objArr.forEach((valveObj) => {
      if (valveObj && valveObj.irrigation_blocks) {
        blockArr.push(...valveObj.irrigation_blocks);
      }
    });
  });

  const blockName = useBlockNames(blockArr);
  const blocksForValve = [];
  filteredBlocks.forEach((d) => {
    const valveObj = valveObjs[d.id];
    if (valveObj?.length) {
      valveObj.forEach((obj) => {
        if (obj && obj.visible) {
          blocksForValve.push(obj);
        }
      });
    }
  });

  const valveDataArr = blocksForValve.map((valveObj) => {
    const vStatus = (valveStatus && valveStatus[valveObj.blockId].valveStatus) || {};
    const listOfBlockIds = (valveObj && valveObj.irrigation_blocks
      ? valveObj.irrigation_blocks : []);
    const blockNames = listOfBlockIds.map((e) => blockName[e]);

    return {
      blockId: valveObj.blockId,
      valveCurrentState: (vStatus.controlStatus && vStatus.controlStatus.state) || '',
      blockName: blockName[valveObj.blockId],
      valveName: valveObj.name || valveObj.identifier,
      blockNames,
      alarmCount: vStatus.alarmCount,
      lastUpdated: vStatus.lastUpdated,
      valveOnline: vStatus.online,
      pressure: '',
      sensorList: [],
      valveLoading: vStatus.loading,
      type: vStatus.type,
      identifier: valveObj.identifier,
    };
  });

  // Get unique valve data based on identifier
  const valveData = [];
  valveDataArr.forEach((item) => {
    const i = valveData.findIndex((x) => x.identifier === item.identifier);
    if (i <= -1) {
      valveData.push(item);
    }
  });

  return (
    <div className="control-list-container item-list">
      {valveData.length ? valveData.map((valve) => (
        <div key={valve.blockId} data-testid="valve-card">
          <Link
            to={{
              pathname: `/control/valve/${valve.blockId}`,
              state: { goBack: true, identifier: valve.identifier },
            }}
          >
            <ControlValveListItem
              valve={valve}
            />
          </Link>
        </div>
      )) : (
        <div className="mobile-list-item no-config">
          {t('No Valves Connected')}
        </div>
      )}
    </div>
  );
}

ControlValveList.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
};

ControlValveList.defaultProps = {
  blocks: [],
};
