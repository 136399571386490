import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

const languageDetector = new LanguageDetector();

// will load from localStorage first, then use browser setting
// the user language setting will set localStorage if a language is chosen,
// otherwise if "System" is set, it will clear the localStorage variable
// so that i18n falls back on navigator (browser setting)
const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
};

i18n
  .use(languageDetector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en-US', // use en if detected lng is not available
    debug: false,
    detection: DETECTION_OPTIONS,
    backend: {
      projectId: '4d7b263c-9d66-4f3e-b004-d5dd83fc0e3d',
      // api key should only be used in development to collect new keys
      apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
      referenceLng: 'en-US',
    },
    saveMissing: true, // send not translated keys to endpoint
    keySeparator: false, // we do not use keys in form messages.welcome
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
