import React from 'react';
import ContentLoader from 'react-content-loader';

const ReactContentLoader = () => (
  <ContentLoader
    height={550}
    width={360}
    speed={2}
    primaryColor="#f3f3f3"
  >
    <rect x="10" y="20" rx="3" ry="3" width="360" height="100" />
    <rect x="10" y="140" rx="3" ry="3" width="360" height="100" />
    <rect x="10" y="260" rx="3" ry="3" width="360" height="100" />
    <rect x="10" y="380" rx="3" ry="3" width="360" height="100" />
  </ContentLoader>
);

export default ReactContentLoader;
