import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Select,
} from 'antd';

export default function LogLevelSelect({ onChange, value, placeholder }) {
  const { t } = useTranslation();
  const options = [
    {
      value: 'error',
      label: t('Error'),
    },
    {
      value: 'warning',
      label: t('Warning'),
    },
    {
      value: 'info',
      label: t('Info'),
    },
  ];

  return (
    <Select
      value={value}
      placeholder={placeholder || t('Log level')}
      options={options}
      onChange={onChange}
      allowClear
      dropdownMatchSelectWidth={false}
      getPopupContainer={(trigger) => trigger.parentNode}
    />
  );
}

LogLevelSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf([null, undefined, 'error', 'warning', 'info']),
  placeholder: PropTypes.string,
};

LogLevelSelect.defaultProps = {
  onChange: () => {},
  value: null,
  placeholder: null,
};
