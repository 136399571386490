import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, message } from 'antd';
import PropTypes from 'prop-types';
import { selectors, actions } from 'farmx-redux-core';
import OverrideModal from './OverrideModal';
import { useTracking } from '../../../../helper/mixpanel';

export default function OverrideButtons(props) {
  const { t } = useTranslation();
  const {
    sensorSchedule,
    sensorName,
    sensorIdentifier,
    sensorType,
    statusLabels,
    endOverride,
    createOverride,
    sensorOverrideState,
  } = props;

  const dispatch = useDispatch();

  const messages = {
    endSuccess: `Ended ${sensorType} override`,
    createSuccess: `Created ${sensorType}  override`,
    createError: `Failed to create ${sensorType}  override`,
    endError: `Failed to end ${sensorType} override`,
    endUnauthorized: `Failed to end ${sensorType} override, permission denied`,
    createUnauthorized: `Failed to create ${sensorType} override, permission denied`,
  };

  const sensorStatus = useSelector(
    (state) => selectors.selectSensorStatus(state, sensorType, sensorIdentifier),
  );
  const { overrideStatus = null } = sensorStatus;

  const overrideState = overrideStatus
    ? overrideStatus.state
    : sensorOverrideState.SCHEDULED;

  // For responsive UI
  const [localOverrideState, setLocalOverrideState] = useState(overrideState);
  // For passing to modal
  const [newOverrideState, setNewOverrideState] = useState(overrideState);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const statusLabel = statusLabels[localOverrideState];
  const HTTP_SUCCESS_RES_CODE = 200;
  const tracking = useTracking();

  // load valve status
  useEffect(() => {
    if (!isModalVisible) {
      dispatch(actions.loadSensorStatus({ type: sensorType, identifier: sensorIdentifier }));
    }
  }, [dispatch, sensorIdentifier, isModalVisible, sensorType]);

  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => {
    if (tracking) tracking.track('Clicked Cancel Button');
    setIsModalVisible(false);
  };

  function requestFailed(messageString, eventDescription, paramObj) {
    setLocalOverrideState(overrideState);
    message.error(t(messageString));
    if (tracking) tracking.track(eventDescription, paramObj);
  }

  const handleOk = () => {
    let paramObj = {
      sensorType,
      sensorIdentifier,
      state: newOverrideState,
    };
    setLocalOverrideState(newOverrideState);
    if (newOverrideState === sensorOverrideState.SCHEDULED) {
      paramObj = {
        sensorType,
        sensorIdentifier,
      };
    }

    if (paramObj) {
      if (newOverrideState === sensorOverrideState.SCHEDULED) {
        const response = endOverride(paramObj);
        response.then((res) => {
          if (res.status === HTTP_SUCCESS_RES_CODE) {
            message.success(t(messages.endSuccess));
            if (tracking) tracking.track(messages.endSuccess, paramObj);
          } else {
            requestFailed(messages.endError, messages.endError, paramObj);
          }
        }).catch((error) => {
          if (error.response && error.response.status === 403) {
            requestFailed(messages.endUnauthorized, messages.endUnauthorized, paramObj);
          } else {
            requestFailed(messages.endError, messages.endError, paramObj);
          }
        });
      } else {
        const response = createOverride(paramObj);
        response.then((res) => {
          if (res.status === HTTP_SUCCESS_RES_CODE) {
            message.success(t(messages.createSuccess));
            if (tracking) tracking.track(messages.createSuccess, paramObj);
          } else {
            requestFailed(messages.createError, messages.createError, paramObj);
          }
        }).catch((error) => {
          if (error.response && error.response.status === 403) {
            requestFailed(messages.createUnauthorized, messages.createUnauthorized, paramObj);
          } else {
            requestFailed(messages.createError, messages.createError, paramObj);
          }
        });
      }
    }
    setIsModalVisible(false);
  };

  const handleClick = (newState) => {
    if (localOverrideState !== newState) {
      if (tracking) tracking.track('Clicked Override Button', { button: newState });
      setNewOverrideState(newState);
      showModal();
    }
  };

  return (
    <div className="status-buttons-container">
      <div className="status-buttons">
        <button
          className={
            `button left-border ${localOverrideState === sensorOverrideState.CLOSED ? 'selected'
              : ''}`
          }
          onClick={() => handleClick(sensorOverrideState.CLOSED)}
          type="button"
        >
          {t('Closed')}
        </button>
        <button
          className={`button ${localOverrideState === sensorOverrideState.SCHEDULED ? 'selected' : ''}`}
          onClick={() => handleClick(sensorOverrideState.SCHEDULED)}
          type="button"
        >
          {t('Scheduled')}
        </button>
        <button
          className={
            `button right-border ${localOverrideState === sensorOverrideState.OPEN ? 'selected'
              : ''}`
          }
          onClick={() => handleClick(sensorOverrideState.OPEN)}
          type="button"
        >
          {t('Open_action')}
        </button>
      </div>
      <div className="status-container">
        <Typography.Text>{`${t(statusLabel.label1)} = ${t(statusLabel.label2)}`}</Typography.Text>
      </div>
      <OverrideModal
        label1={sensorName}
        label2={sensorType}
        visible={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        schedule={sensorSchedule}
        newState={newOverrideState}
        currentState={overrideState}
      />
    </div>
  );
}

const statusLabelType = PropTypes.shape({
  label1: PropTypes.string,
  label2: PropTypes.string,
});

const sensorOverrideType = PropTypes.shape({
  CLOSED: PropTypes.string,
  OPEN: PropTypes.string,
  SCHEDULED: PropTypes.string,
});

OverrideButtons.propTypes = {
  sensorSchedule: PropTypes.arrayOf(Object).isRequired,
  sensorName: PropTypes.string.isRequired,
  sensorIdentifier: PropTypes.string.isRequired,
  sensorType: PropTypes.string.isRequired,
  sensorOverrideState: sensorOverrideType.isRequired,
  statusLabels: PropTypes.shape({
    [sensorOverrideType.CLOSED]: statusLabelType,
    [sensorOverrideType.OPEN]: statusLabelType,
    [sensorOverrideType.SCHEDULED]: statusLabelType,
  }).isRequired,
  endOverride: PropTypes.func.isRequired,
  createOverride: PropTypes.func.isRequired,
};
