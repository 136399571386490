import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

const RanchListItem = (props) => {
  const { id, name } = props;

  return (
    <Link to={`/farmhand/ranches/${id}`}>
      <div className="farmhand-ranch-block-item">
        <Typography.Title level={4}>{name}</Typography.Title>
      </div>
    </Link>
  );
};

RanchListItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default RanchListItem;
