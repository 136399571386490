import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import RanchItem from './RanchListItem';

const RanchList = ({ list }) => {
  const { t } = useTranslation();

  return list.length === 0
    ? (<Typography.Title level={4}>{t('No ranches found.')}</Typography.Title>)
    : (
      <div className="farmhand-ranch-block-list">
        {list.map((ranch) => <RanchItem key={ranch.id} id={ranch.id} name={ranch.name} />)}
      </div>
    );
};

RanchList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RanchList;
