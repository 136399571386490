import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { hooks } from 'farmx-redux-core';

export function EtcDeficitGraph({
  irrigationApplied,
  irrigationScheduled,
  etcHistoric,
  etcForecast,
  units,
}) {
  const { t } = useTranslation();
  const getUserUnits = hooks.useUnits();
  const highchartsComponentRef = React.createRef();
  const { label: depthUnitsLabel } = getUserUnits(0, 'inches', 'depth', { labelType: 'long' });


  function buildHighChartsData() {
    if (
      irrigationApplied == null
      || irrigationScheduled == null
      || etcHistoric == null
      || etcForecast == null
    ) {
      return {
        series: [],
        xAxis: {},
        yAxis: {},
      };
    }

    const { value: etcHistoricConverted } = getUserUnits(etcHistoric, units.etcHistoric, 'depth');
    const { value: etcForecastConverted } = getUserUnits(etcForecast, units.etcHistoric, 'depth');
    const { value: irrigationAppliedConverted } = getUserUnits(irrigationApplied, units.etcHistoric, 'depth');
    const { value: irrigationScheduledConverted } = getUserUnits(irrigationScheduled, units.etcHistoric, 'depth');
    const pastData = [
      {
        y: etcHistoricConverted,
      },
      {
        y: irrigationAppliedConverted,
      },
    ];

    const futureData = [
      {
        y: etcForecastConverted,
      },
      {
        y: irrigationScheduledConverted,
      },
    ];

    const categories = [
      t('ETc'),
      t('Irrigation'),
    ];

    const xAxis = {
      categories,
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    };

    const yAxis = {
      title: {
        text: t(depthUnitsLabel),
        style: {
          fontSize: '14px',
        },
      },
      gridLineWidth: 0,
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    };
    const series = [{
      name: t('Future'),
      data: futureData,
    }, {
      name: t('Past'),
      data: pastData,
    }];

    return {
      series,
      xAxis,
      yAxis,
    };
  }

  const chartData = buildHighChartsData();

  const options = {
    title: null,
    chart: {
      type: 'column',
      marginBottom: 25,
      marginTop: 20,
      inverted: false,
    },
    legend: {
      align: 'right',
      x: 0,
      verticalAlign: 'top',
      y: -15,
      floating: true,
    },
    tooltip: {
      formatter: () => false,
    },
    plotOptions: {
      series: {
        borderRadius: 0,
        groupPadding: 0,
        stacking: 'normal',
      },
    },
    xAxis: chartData.xAxis,
    yAxis: chartData.yAxis,
    series: chartData.series,
    credits: {
      enabled: false,
    },
    lang: {
      noData: t('No ETc data'),
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#888',
      },
    },
    time: {
      useUTC: false,
    },
  };

  return (
    <HighchartsReact
      constructorType="chart"
      containerProps={{ style: { height: '100%' } }}
      highcharts={Highcharts}
      options={options}
      ref={highchartsComponentRef}
    />
  );
}

EtcDeficitGraph.propTypes = {
  irrigationApplied: PropTypes.number.isRequired,
  irrigationScheduled: PropTypes.number.isRequired,
  etcHistoric: PropTypes.number.isRequired,
  etcForecast: PropTypes.number.isRequired,
  units: PropTypes.shape({
    irrigationApplied: PropTypes.string,
    irrigationScheduled: PropTypes.string,
    etcHistoric: PropTypes.string,
    etcForecast: PropTypes.string,
  }).isRequired,
};
