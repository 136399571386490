import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import { farmApi } from 'farmx-api';
import { Collapse, Table, Typography } from 'antd';
import './chillHoursAndPortions.less';
import { LoadingOutlined } from '@ant-design/icons';

const CollapseWrapper = ({ title, children }) => (
  <Collapse className="margin-bottom-10" defaultActiveKey={['main']}>
    <Collapse.Panel
      key="main"
      header={(
        <Typography.Title
          className="collapse-header-title"
          level={4}
        >
          {title}
        </Typography.Title>
      )}
    >
      {children}
    </Collapse.Panel>
  </Collapse>
);

export const ChillHoursAndPortions = () => {
  const { t } = useTranslation();
  const { blockIds } = hooks.useRanchBlockSelection();
  const blockDetails = useSelector((state) => selectors.selectBlockById(state, blockIds[0]));
  const ranchId = blockDetails && blockDetails.ranch;
  const [chillHoursData, setChillHoursData] = useState();
  const [chillPortionsData, setChillPortionsData] = useState();
  const [chillHoursLoading, setChillHoursLoading] = useState(false);
  const [chillPortionsLoading, setChillPortionsLoading] = useState(false);

  const getChillHours = useCallback(async () => {
    setChillHoursLoading(true);
    try {
      const res = await farmApi.getChillHours(ranchId);
      if (res && res.status === 200 && res.data) {
        setChillHoursData(res.data.chill_hours);
      }
      setChillHoursLoading(false);
    } catch {
      setChillHoursLoading(false);
    }
  }, [ranchId]);

  const getChillPortions = useCallback(async () => {
    setChillPortionsLoading(true);
    try {
      const res = await farmApi.getChillPortions(ranchId);
      setChillPortionsLoading(false);
      if (res && res.status === 200 && res.data) {
        setChillPortionsData(res.data.chill_portions);
      }
    } catch {
      setChillPortionsLoading(false);
    }
  }, [ranchId]);

  useEffect(() => {
    if (ranchId) {
      getChillHours();
      getChillPortions();
    }
  }, [getChillHours, getChillPortions, ranchId]);

  const chillPortionsColumns = [
    {
      title: t('Block'),
      dataIndex: 'block',
      key: 'block',
    },
    {
      title: t('Chill Portions'),
      dataIndex: 'portions',
      key: 'portions',
      render: (data) => data && data.toFixed(2),
    },
  ];
  const chillHoursUtahColumns = [
    {
      title: t('Block'),
      dataIndex: 'block',
      key: 'block',
    },
    {
      title: t('Below 45'),
      dataIndex: 'below_45_utah',
      key: 'below_45_utah',
    },
    {
      title: t('Between 32 and 45'),
      dataIndex: 'above_32_utah',
      key: 'above_32_utah',
    },
  ];
  const chillHoursColumns = [
    {
      title: t('Block'),
      dataIndex: 'block',
      key: 'block',
    },
    {
      title: t('Below 45'),
      dataIndex: 'below_45',
      key: 'below_45',
    },
    {
      title: t('Between 32 and 45'),
      dataIndex: 'above_32',
      key: 'above_32',
    },
  ];

  return (
    <div className="chill-hours-and-portions">
      <CollapseWrapper title={`${t('Chill Portions')} (${'Since Oct'} 1)`}>
        {chillPortionsLoading ? (
          <LoadingOutlined />
        ) : (
          <div className="chill-portions">
            {chillPortionsData && chillPortionsData.length ? (
              <Table
                dataSource={chillPortionsData}
                columns={chillPortionsColumns}
                pagination={false}
              />
            ) : (
              <Typography.Text>{t('Chill portions data not available')}</Typography.Text>
            )}
          </div>
        )}
      </CollapseWrapper>
      <CollapseWrapper title={`${t('Chill Hours')} (${'Since Sept'} 1)`}>
        {chillHoursLoading ? (
          <LoadingOutlined />
        ) : (
          <div className="chill-hours-utah">
            {chillHoursData && chillHoursData.length ? (
              <Table
                dataSource={chillHoursData}
                columns={chillHoursUtahColumns}
                pagination={false}
              />
            ) : (
              <Typography.Text>{t('Chill hours data not available')}</Typography.Text>
            )}
          </div>
        )}
      </CollapseWrapper>
      <CollapseWrapper title={`${t('Chill Hours')} (${'Since Nov'} 1)`}>
        {chillHoursLoading ? (
          <LoadingOutlined />
        ) : (
          <div className="chill-hours">
            {chillHoursData && chillHoursData.length ? (
              <Table
                dataSource={chillHoursData}
                columns={chillHoursColumns}
                pagination={false}
              />
            ) : (
              <Typography.Text>{t('Chill hours data not available')}</Typography.Text>
            )}
          </div>
        )}
      </CollapseWrapper>
    </div>
  );
};

CollapseWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
