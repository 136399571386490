import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Button } from 'antd';
import { useTracking } from '../../../helper/mixpanel';
import './Schedule.less';
import { BlockScheduleControl } from './components/BlockScheduleControl';
import { BlockScheduleInfo } from './components/BlockScheduleInfo';
import { ScheduleDeleteModal } from './ScheduleDeleteModal';
import { PageHeader } from '../components/PageHeader';

const {
  selectScheduledEventById,
  selectBlockRenderedScheduleEvent,
} = selectors;

export function ScheduleDetail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const tracking = useTracking();

  const { t } = useTranslation();
  const params = useParams();
  const id = Number(params.id);
  const blockId = Number(params.blockId);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const { state: { eventIndex = 0, breadcrumbs } = {} } = location;
  const previous = breadcrumbs || '/schedule';
  const scheduledEvent = useSelector((state) => selectScheduledEventById(state, { id, blockId }));
  const renderedEvent = useSelector((state) => selectBlockRenderedScheduleEvent(
    state,
    { eventId: id, blockId, eventIndex },
  )) || {};
  const combinedEvent = {
    ...scheduledEvent,
    ...renderedEvent,
    rootStart: scheduledEvent && scheduledEvent.start_date,
    rootStop: scheduledEvent && scheduledEvent.stop_date,
    rootStartLocal: scheduledEvent && scheduledEvent.date_start_local,
  };

  useEffect(() => {
    dispatch(actions.loadBlockIrrigationSchedule(blockId));
    dispatch(actions.loadBlockDetail(blockId));
  }, [dispatch, blockId]);

  return (
    <div className="page-content margin-10">
      <PageHeader
        showBack
        toPath={previous}
        title={t('schedule detail')}
        extra={(
          <>
            <Button
              type="primary"
              size="large"
              icon={<FaTrashAlt />}
              style={{ textTransform: 'capitalize' }}
              onClick={() => {
                setDeleteModalVisible(true);
                tracking.track('Clicked Schedule Delete');
              }}
            />
            <Button
              type="primary"
              size="large"
              icon={<FaEdit />}
              style={{ textTransform: 'capitalize' }}
              onClick={() => {
                tracking.track('Clicked Schedule Edit');
                history.push('/schedule/edit', {
                  scheduledEvent: combinedEvent,
                });
              }}
            >
              {t('edit')}
            </Button>
          </>
        )}
      />
      {scheduledEvent && Object.keys(scheduledEvent).length > 0 && (
        <BlockScheduleInfo scheduledEvent={combinedEvent} />
      )}
      <BlockScheduleControl blockId={blockId} eventId={id} />
      <ScheduleDeleteModal
        visible={deleteModalVisible}
        scheduledEvent={combinedEvent}
        handleOk={(success) => {
          setDeleteModalVisible(false);
          if (success) {
            history.push(previous);
          }
        }}
        handleCancel={() => setDeleteModalVisible(false)}
      />
    </div>
  );
}
