import React from 'react';
import PropTypes from 'prop-types';
import './WeekDays.less';

export const WeekDays = ({ weekDays, setWeekDays, t }) => (
  <div style={{ marginTop: '10px' }} className="flexbox select-weekdays">
    {Object.keys(weekDays).map((w) => (
      <div
        className={`item ${weekDays[w] ? 'selected' : null}`}
        key={w}
        role="button"
        onClick={() => { setWeekDays({ ...weekDays, ...{ [w]: !weekDays[w] } }); }}
        aria-hidden="true"
      >
        {t(w)}
      </div>
    ))}
  </div>
);

WeekDays.propTypes = {
  weekDays: PropTypes.object.isRequired,
  setWeekDays: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
