import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import BlockItem from './BlockListItem';

const BlockList = ({ list, ranchId }) => {
  const { t } = useTranslation();

  return list.length === 0
    ? (<Typography.Title level={4}>{t('No blocks found.')}</Typography.Title>)
    : (
      <div className="farmhand-ranch-block-list">
        {list.map((block) => (
          <BlockItem
            key={block.id}
            ranchId={ranchId}
            blockId={block.id}
            name={block.name}
          />
        ))}
      </div>
    );
};

BlockList.defaultProps = {
  list: [],
};

BlockList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  ranchId: PropTypes.string.isRequired,
};

export default BlockList;
