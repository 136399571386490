/**
 * Source fastclick.js
 */

/**
* Windows Phone 8.1 fakes user agent string to look like Android and iPhone.
*/
export const deviceIsWindowsPhone = navigator.userAgent.indexOf('Windows Phone') >= 0;

/**
 * Android
 */
export const deviceIsAndroid = navigator.userAgent.indexOf('Android') > 0 && !deviceIsWindowsPhone;

/**
 * iOS requires exceptions.
 */
export const deviceIsIOS = /iP(ad|hone|od)/.test(navigator.userAgent) && !deviceIsWindowsPhone;

/**
 * iOS 4 requires an exception for select elements.
 */
export const deviceIsIOS4 = deviceIsIOS && (/OS 4_\d(_\d)?/).test(navigator.userAgent);

/**
 * iOS 6.0-7.*
 */
export const deviceIsIOSWithBadTarget = deviceIsIOS && (/OS [6-7]_\d/).test(navigator.userAgent);

/**
 * BlackBerry
 */
export const deviceIsBlackBerry10 = navigator.userAgent.indexOf('BB10') > 0;

/**
 * Device is Mobile
 */
export const isMobile = deviceIsIOS
  || deviceIsAndroid
  || deviceIsBlackBerry10
  || deviceIsWindowsPhone;
