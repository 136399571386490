import React from 'react';
import PropTypes from 'prop-types';
import {
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TabBar } from 'antd-mobile';
import {
  FaHome,
  FaMapMarkedAlt,
  FaList,
  FaRegCalendarAlt,
  FaToggleOn,
} from 'react-icons/fa';

import './BottomTabBar.less';

export function BottomTabBar({
  pathname,
  selectedTabPath,
  setSelectedTabPath,
}) {
  const { t } = useTranslation();
  const { push } = useHistory();
  return (
    <div className="bottom-tabbar-container">
      <TabBar
        activeKey={selectedTabPath}
        onChange={(key) => {
          const keyPath = `/${key}`;
          if (pathname !== keyPath) {
            setSelectedTabPath(key);
            push(keyPath);
          }
        }}
      >
        <TabBar.Item
          icon={<FaHome />}
          title={t('Home')}
          key="recommendation"
          selected={selectedTabPath === 'recommendation'}
        />

        <TabBar.Item
          icon={<FaMapMarkedAlt />}
          title={t('Map')}
          key="map"
          selected={selectedTabPath === 'map'}
        />

        <TabBar.Item
          icon={<FaList />}
          title={t('Devices')}
          key="list"
          selected={selectedTabPath === 'list'}
        />

        <TabBar.Item
          icon={<FaRegCalendarAlt />}
          title={t('Schedule')}
          key="schedule"
          selected={selectedTabPath === 'schedule'}
        />

        <TabBar.Item
          icon={<FaToggleOn />}
          title={t('Control')}
          key="control"
          selected={selectedTabPath === 'control'}
        />
      </TabBar>
      <div className="bottom-tabbar-background" />
    </div>
  );
}

BottomTabBar.propTypes = {
  pathname: PropTypes.string.isRequired,
  selectedTabPath: PropTypes.string.isRequired,
  setSelectedTabPath: PropTypes.func.isRequired,
};
