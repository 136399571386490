export const getTextForIrrigationState = (irrigationState) => irrigationState.replace('_', ' ');

export const getColorClassForIrrigationState = (irrigationState) => {
  if (irrigationState === 'over_irrigated') {
    return 'color-state-over';
  }
  if (irrigationState === 'well_irrigated') {
    return 'color-state-ok';
  }
  if (irrigationState === 'drying') {
    return 'color-state-warning';
  }
  if (irrigationState === 'dry') {
    return 'color-state-error';
  }
  if (irrigationState === 'very_dry') {
    return 'color-state-critical';
  }
  return undefined;
};

export const getStateForIrrigationState = (irrigationState) => {
  if (irrigationState === 'over_irrigated') {
    return 'bad';
  }
  if (irrigationState === 'well_irrigated') {
    return 'good';
  }
  if (irrigationState === 'drying') {
    return 'poor';
  }
  if (irrigationState === 'dry') {
    return 'bad';
  }
  if (irrigationState === 'very_dry') {
    return 'critical';
  }
  return undefined;
};

export const getRankForIrrigationState = (irrigationState) => {
  if (irrigationState === 'over_irrigated') {
    return 2;
  }
  if (irrigationState === 'well_irrigated') {
    return 0;
  }
  if (irrigationState === 'drying') {
    return 1;
  }
  if (irrigationState === 'dry') {
    return 3;
  }
  if (irrigationState === 'very_dry') {
    return 4;
  }
  return -1;
};

export const compareRecommendations = (a, b) => {
  const stateA = getRankForIrrigationState(a.state);
  const stateB = getRankForIrrigationState(b.state);
  if (stateA < stateB) {
    return 1;
  }
  if (stateA > stateB) {
    return -1;
  }
  if (a.blockName < b.blockName) {
    return -1;
  }
  if (a.blockName > b.blockName) {
    return 1;
  }
  return 0;
};

export const getActionText = (action) => {
  if (action === 'irrigate') {
    return 'Irrigate';
  }
  if (action === 'no_action') {
    return 'No Action';
  }
  if (action === 'stop_irrigation') {
    return 'Stop Irrigation';
  }
  if (action === 'reduce_irrigation') {
    return 'Reduce Irrigation';
  }
  return action;
};
