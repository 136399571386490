import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from 'farmx-redux-core';
import { CardLayout } from '../../components/CardLayout';
import PressureSummary from '../ControlSummary/PressureSummary';
import ControlValveStatus from './ControlValveStatus';

const {
  selectBlockPumpController,
  selectBlockFlowMeter,
  selectPressureDetails,
} = selectors;

const { loadBlockControlStatus, loadSensorStatus } = actions;

export default function ControlValveListItem(props) {
  const { valve, isLink } = props;

  const dispatch = useDispatch();

  const pressureSensors = useSelector((state) => selectPressureDetails(state, valve.blockId));

  const pumpController = useSelector(
    (state) => selectBlockPumpController(state, valve.blockId),
  );

  const flowMeter = useSelector(
    (state) => selectBlockFlowMeter(state, valve.blockId),
  );

  const {
    identifier: pumpControllerIdentifier,
    type: pumpControllerType,
  } = pumpController;

  const {
    identifier: flowMeterIdentifier,
    type: flowMeterType,
  } = flowMeter || {};

  const { pressureSensorIdentifiers } = pressureSensors;

  const refreshPressureSensorStatus = useCallback(() => {
    JSON.parse(pressureSensorIdentifiers).forEach((identifier) => {
      const sensorParams = { type: 'water_pressure', identifier };
      dispatch(loadSensorStatus(sensorParams));
    });
  }, [dispatch, pressureSensorIdentifiers]);

  useEffect(() => {
    refreshPressureSensorStatus();
  }, [refreshPressureSensorStatus]);

  useEffect(() => {
    const sensorParams = {
      type: pumpControllerType,
      identifier: pumpControllerIdentifier,
    };
    if (pumpControllerIdentifier) {
      dispatch(loadSensorStatus(sensorParams));
    }
  }, [dispatch, pumpControllerType, pumpControllerIdentifier]);

  useEffect(() => {
    if (flowMeterIdentifier && flowMeterType) {
      const sensorParams = { type: flowMeterType, identifier: flowMeterIdentifier };
      dispatch(loadSensorStatus(sensorParams));
    }
  }, [dispatch, flowMeterType, flowMeterIdentifier]);

  useEffect(() => {
    dispatch(loadBlockControlStatus(valve.blockId));
  }, [valve.blockId, dispatch]);

  if (!valve.sensorList || !valve.sensorList.length) {
    valve.sensorList = pressureSensors.sensorList;
    valve.pressure = pressureSensors.pressure;
    valve.pressureLoading = pressureSensors.loading;
  }

  const renderPressureSummary = () => (
    <>
      <div className="valve-content-divider" />
      <PressureSummary
        id={`pressure-${valve.blockName}-${valve.blockId}`}
        pressure={valve.pressure}
        valveList={valve.sensorList}
        loading={valve.pressureLoading}
        pressureCutoff={pressureSensors.pressureCutoff}
      />
    </>
  );

  return (
    <CardLayout>
      <ControlValveStatus valve={valve} />
      {isLink ? (
        <Link
          to={{
            pathname: `/control/sensors/${valve.blockId}`,
            state: { prevPath: window.location.pathname },
          }}
        />
      ) : renderPressureSummary()}
    </CardLayout>
  );
}

ControlValveListItem.defaultProps = {
  isLink: true,
};

ControlValveListItem.propTypes = {
  valve: PropTypes.shape({
    blockId: PropTypes.number,
    blockName: PropTypes.string,
    alarmCount: PropTypes.number,
    lastUpdated: PropTypes.number,
    valveCurrentState: PropTypes.string,
    valveOnline: PropTypes.bool,
    pressure: PropTypes.string,
    sensorList: PropTypes.arrayOf(PropTypes.string),
    valveLoading: PropTypes.bool,
    pressureLoading: PropTypes.bool,
  }).isRequired,
  isLink: PropTypes.bool,
};
