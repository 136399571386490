import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import 'antd-mobile-v2/dist/antd-mobile.css';
import { MixpanelProvider } from 'react-mixpanel-browser';
import { LoginPage, LoginPageMobile, LogoutRedirect } from '../authentication';
import Main from './Main';
import MobileApp from '../mobile/MobileApp';
import './app.css';
import { withPlatformDetect } from '../../utils/withPlatformDetect';

function App() {
  return (
    <MixpanelProvider>
      <Router>
        <Switch>
          <Route component={withPlatformDetect(LoginPageMobile, LoginPage)} exact path="/login" />
          <Route component={LogoutRedirect} exact path="/logout" />
          <Route component={withPlatformDetect(MobileApp, Main)} />
        </Switch>
      </Router>
    </MixpanelProvider>
  );
}

export default App;
