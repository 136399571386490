import React, { useEffect } from 'react';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import Moment from 'react-moment';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
// import { SoilSensorSummary } from '../list/ListCards/SoilSensorSummary';
import { ValueUnitInfo } from '../list/ValueUnitInfo';
import ConnectivityStateTag from '../components/ConnectivityStateTag';
import { PlantSensorListItem } from '../list/PlantSensorListItem';
import { ControlValveDetail } from '../control/ControlValves/ControlValveDetail';
import { ControlPumpDetails } from '../control/ControlPump/ControlPumpDetails';
import { getStress } from '../../../helper/plantSensorHelper';
import { SoilMoistureDepthChart } from '../components/SoilMoistureDepthChart';
import './SensorDetailBody.less';
import { isNotNull } from '../../../helper/listHelper';
import { getPercentForFraction } from '../../../helper/common';

// Placeholder for specific view for sensor
export function SensorDetailBody({ sensorProperties }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { identifier, type } = sensorProperties;
  const status = useSelector(
    (state) => selectors.selectSensorStatus(state, type, identifier),
  ) || {};
  const getUserUnits = hooks.useUnits();

  useEffect(() => {
    dispatch(actions.loadSensorStatus({ type, identifier }));
  }, [dispatch, type, identifier]);

  if (type === 'aquacheck_soil' || type === 'pixl_soil') {
    return (
      <div className="flex-column map-sensor-detail">
        <div className="sensor-detail-row">
          {status && status.vwcRootzone && (
            <ValueUnitInfo
              value={getPercentForFraction(status.vwcRootzone)}
              unit="% VWC"
              info={t('root zone avg')}
            />
          )}
          <ConnectivityStateTag
            isOnline={status && status.online}
          />
        </div>
        <div className="sensor-detail-row soil-moisture-chart-container">
          <SoilMoistureDepthChart sensor={sensorProperties} />
        </div>
      </div>
    );
  }
  if (type === 'water_pressure') {
    const { units = null } = status || {};
    const unitsLabel = units && units.waterPressure ? t(units.waterPressure) : t('psi');
    return (
      <div className="flex-column map-sensor-detail">
        <div className="pump-status-container">
          <ValueUnitInfo
            value={status && status.waterPressure != null ? status.waterPressure.toFixed(1) : '?'}
            unit={unitsLabel}
          />
          <ConnectivityStateTag
            isOnline={status && status.online}
          />
        </div>
        <div>
          <Typography.Text type="secondary">
            {`${t('Updated')} `}
            <Moment fromNow>{status && status.lastUpdated}</Moment>
          </Typography.Text>
        </div>
      </div>
    );
  }
  if (type === 'farmx_dendrometer') {
    return (
      <PlantSensorListItem
        sensorDetails={sensorProperties}
        sensorStatus={status}
      />
    );
  }
  if (type === 'valve') {
    return (
      <ControlValveDetail
        valveId={sensorProperties.blockId}
      />
    );
  }
  if (type === 'vfd') {
    return (
      <ControlPumpDetails
        pumpId={sensorProperties.blockId}
      />
    );
  }
  if (type === 'farmx_infrared') {
    if (!status.cropStress) {
      return null;
    }
    const stressValue = getStress(status.cropStress);
    const cwsiVal = status && status.cwsi;
    return (
      <div className="flex-column map-sensor-detail">
        <div className="sensor-detail-row">
          <ValueUnitInfo
            value={t(stressValue.label)}
            type={stressValue.type}
          />
          <ValueUnitInfo
            value={cwsiVal || '-/-'}
            unit={t('cwsi').toUpperCase()}
            info={status && status.cwsiDate && moment(status.cwsiDate).fromNow()}
          />
        </div>
      </div>
    );
  }
  if (type === 'water_flow' || type === 'water_flow_analog') {
    const flowRate = isNotNull(status.flowRate) ? status.flowRate.toFixed(1) : null;
    const units = status.units || {
      flowRate: 'gallons_per_minute',
    };
    const convertedFlowRate = getUserUnits(flowRate || '', units.flowRate, 'flow_rate');

    return (
      <div className="flex-column map-sensor-detail">
        <div className="sensor-detail-row">
          <ValueUnitInfo
            value={convertedFlowRate.value != null ? convertedFlowRate.value : '?'}
            unit={t(convertedFlowRate.label)}
          />
          <ConnectivityStateTag
            isOnline={status.online}
          />
        </div>
      </div>
    );
  }
  return null;
}
