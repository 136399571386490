import React from 'react';
import PropTypes from 'prop-types';
import { controlApi } from 'farmx-api';
import { valveOverrideState } from '../../../../constants';
import OverrideButtons from './OverrideButtons';

const statusLabels = {
  [valveOverrideState.CLOSED]: {
    label1: 'CLOSED',
    label2: 'Valve always closed',
  },
  [valveOverrideState.OPEN]: {
    label1: 'OPEN',
    label2: 'Valve always open',
  },
  [valveOverrideState.SCHEDULED]: {
    label1: 'SCHEDULED',
    label2: 'Valve follows schedule',
  },
};

export const VALVE_TYPE = 'valve';

const { createOverride, endOverride } = controlApi;

export default function ValveOverrideButtons(props) {
  const {
    valveSchedule,
    valveName,
    valveIdentifier,
  } = props;
  return (
    <OverrideButtons
      sensorType={VALVE_TYPE}
      sensorSchedule={valveSchedule}
      sensorName={valveName}
      sensorIdentifier={valveIdentifier}
      statusLabels={statusLabels}
      sensorOverrideState={valveOverrideState}
      createOverride={createOverride}
      endOverride={endOverride}
    />
  );
}

ValveOverrideButtons.defaultProps = {
  valveSchedule: [],
  valveName: '',
  valveIdentifier: null,
};

ValveOverrideButtons.propTypes = {
  valveSchedule: PropTypes.arrayOf(Object),
  valveName: PropTypes.string,
  valveIdentifier: PropTypes.string,
};
