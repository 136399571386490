import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { truncate } from 'lodash';
import { hooks, selectors } from 'farmx-redux-core';
import ConnectivityStateTag from '../../components/ConnectivityStateTag';
import { IconTitle } from '../IconTitle';
import { ValueUnitInfo } from '../ValueUnitInfo';
import { getConnectivityLabel } from '../../../../helper/listHelper';
import { CardLayout } from '../../components/CardLayout';
import { NoSummaryData } from './NoSummaryData';
import { useRootzoneValue } from './useRootzoneValue';
// import { getPercentAvailable, getVolumeAvailable } from '../../../../helper/soilSensorHelper';

const {
  useLoadStatus,
} = hooks;

const {
  selectSoilSummary,
  selectSensorLoadingStatus,
} = selectors;

export function SoilSensorSummary(props) {
  const { list, selectedMetrics } = props;
  const { t } = useTranslation();
  const summary = useSelector((store) => selectSoilSummary(store, list));
  const loading = useSelector((store) => selectSensorLoadingStatus(store, list));
  const {
    vwcRootzone,
    percentAvailable,
    volumeAvailable,
    onlineCount,
    names,
    min,
    max,
    units,
  } = summary;
  const stateTag = useMemo(() => (
    getConnectivityLabel(onlineCount, list.length, t)
  ), [onlineCount, list, t]);
  const showNoDataInfo = (!loading && !list.length);
  const [rootzoneValue, rootzoneValueLabel] = useRootzoneValue({
    percent: percentAvailable,
    volume: volumeAvailable,
    selectedMetrics,
    vwcRootzone,
    units,
  });

  useLoadStatus(list);

  if (showNoDataInfo) {
    return (
      <NoSummaryData
        icon="eyedropper"
        title={t('Soil Sensors')}
      />
    );
  }

  return (
    <CardLayout>
      <div className="flex-row space-between">
        <IconTitle
          iconName="eyedropper"
          title={t('Soil Sensors')}
        />
        {!loading && (
          <ConnectivityStateTag
            label={stateTag.label}
            color={stateTag.color}
          />
        )}
      </div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div className="flex-row space-between margin-top-20">
            <ValueUnitInfo
              value={rootzoneValue}
              unit={rootzoneValueLabel}
              info={t('root zone avg')}
            />
            <div className="flex-row">
              <ValueUnitInfo
                value={min}
                unit="%"
                info={t('min')}
              />

              <Typography.Text strong className="separator primary-color">{' / '}</Typography.Text>

              <ValueUnitInfo
                value={max}
                unit="%"
                info={t('max')}
              />
            </div>
          </div>
          <div className="flex-row flex-end margin-top-10">
            <Typography.Text className="card-footer-txt">{truncate(names.join(', '))}</Typography.Text>
          </div>
        </>

      )}
    </CardLayout>
  );
}

SoilSensorSummary.defaultProps = {
  list: [],
  selectedMetrics: 'vwc',
};

SoilSensorSummary.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  selectedMetrics: PropTypes.oneOf(['vwc', 'percent', 'volume']),
};
