/* eslint-disable */
const Tile = function (x, y, zoom) {
  this.x = x;
  this.y = y;
  this.latitude = Geometry.tile2lat(y, zoom);
  this.longitude = Geometry.tile2lon(x, zoom);
  this.zoom = zoom;
  this.key = [x, y, zoom].join(',');

  this.opacity = 1.0;

  // note: due to the Mercator projection the tile width in meters is equal
  //       to the tile height in meters.
  var size = Math.pow(2, MAX_ZOOM - this.zoom);//getTileSizeInKM( this.latitude, zoom);
  //size = 1;

  var geometry = new THREE.PlaneBufferGeometry(size * Geometry.SCALING_FACTOR, size * Geometry.SCALING_FACTOR);
  //geometry.rotateX(-Math.PI/2);
  var material = new THREE.MeshBasicMaterial({ color: 0x4c7d4c });

  this.uniforms = {
    texture1: { value: null, type: 't' },
    fade: { value: 0. },
    scale: { value: SCALING_FACTOR },
    //fadeCenterX: { value: 0 },
    fadeCenter: { value: new THREE.Vector3() },
    saturation: { value: 1. },
    opacity: { value: this.opacity },
  };

  this.shaderMaterial = new THREE.ShaderMaterial({
    uniforms: this.uniforms,
    vertexShader: document.getElementById('vertexShader').textContent,// + '\n#define SIZE ' + this.sensors.length,
    fragmentShader: document.getElementById('fragmentShaderTexture').textContent,
    //depthWrite: false, depthTest: false
  });

  this.mesh = new THREE.Mesh(geometry, this.shaderMaterial);

  var posX = (this.x * size - Geometry.SCALING_OFFSET.x) * Geometry.SCALING_FACTOR;
  var posY = (this.y * size - Geometry.SCALING_OFFSET.y) * Geometry.SCALING_FACTOR;
  //this.mesh.translateX(posX);
  //this.mesh.translateZ(posY);
  this.mesh.position.x = posX + (size * Geometry.SCALING_FACTOR) / 2;
  this.mesh.position.y = -posY - (size * Geometry.SCALING_FACTOR) / 2;

  this.mesh.material.transparent = true;
  this.mesh.material.side = THREE.DoubleSide;
  this.mesh.material.opacity = this.opacity;
  this.mesh.renderOrder = 8;
  this.mesh.material.needsUpdate = true;

  this.mesh.receiveShadow = true;
};

Tile.prototype = {
  load: function (url, renderer) {

    var textureLoader = new THREE.TextureLoader();
    textureLoader.load(url, function (image) {

      if (image) {
        this.isReady = true;

        //this.mesh.material = new THREE.MeshBasicMaterial({map: image});
        //this.mesh.material.map = image;
        this.mesh.material.uniforms.texture1.value = image;
        this.mesh.material.color = new THREE.Color(0xffffff);

        try {
          image.anisotropy = renderer.capabilities.getMaxAnisotropy();
        } catch (error) {
          image.anisotropy = 1;
        }

        //this.mesh.material.needsUpdate = true;

      }
    }.bind(this));
  },

  setOpacity: function (opacity) {
    this.opacity = opacity;
    this.mesh.material.opacity = opacity;
    this.mesh.material.uniforms.opacity.value = opacity;
  },

  destroy: function () {
    if (this.texture) {
      this.texture.destroy();
    }
  }
};

module.exports = Tile;
