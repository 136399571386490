import React from 'react';
import PropTypes from 'prop-types';
import { sensorTitles, SensorAlarmButton } from 'farmx-web-ui';
import { FaBell } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './sensor-detail-header.less';
import { Badge } from 'antd';

export function SensorDetailHeader({
  sensorProperties: {
    type,
    location,
    alarmCount,
    identifier,
    // sensorStatus, // needs more information
  },
}) {
  const { t } = useTranslation();
  return (
    <div className="flex-row margin-top-10 sensor-detail-header">
      <div className="flex-column">
        <div className="flex-row">
          <div className="flex-column">
            <div className="sensor-header-type">{t(sensorTitles[type]?.singular || '')}</div>
          </div>
        </div>
        <div className="sensor-header-location">
          {`${location.lat} ${location.lng}`}
        </div>
      </div>
      <div className="sensor-alarm-status-block">
        <SensorAlarmButton
          isMobileView
          alarmCount={alarmCount}
          identifier={identifier}
          type={type}
        />
        {/* <div className="sensor-header-status">
          Status
        </div> */}
      </div>
    </div>
  );
}

SensorDetailHeader.propTypes = {
  sensorProperties: PropTypes.shape({
    identifier: PropTypes.string,
    type: PropTypes.string,
    alarmCount: PropTypes.number,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }).isRequired,
};
