import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import {
  FaExpand,
  FaChartBar,
  FaRedo,
  FaSatellite,
  FaCube,
} from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { prepData } from './mapHooks';
import useWeatherInfo from './useWeatherInfo';
import TemperatureOverlay from './TemperatureOverlay';
import WindOverlay from './WindOverlay';

export default function MapControlButtons({
  selectedImageryData,
  dispatchMap,
  isBottomSheetVisible,
  isImageryPanelVisible,
  selectedObjFromState,
  ranchId,
  blockId,
  isMobile,
}) {
  const dispatch = useDispatch();
  const [weatherInfo, isWeatherLoading] = useWeatherInfo();

  function onClickButton(type) {
    if (type === 'satellite') {
      dispatchMap({
        type: 'setIsImageryPanelVisible',
        payload: !isImageryPanelVisible,
      });
      if (!isBottomSheetVisible) {
        dispatchMap({
          type: 'setIsBottomSheetVisible',
          payload: !isBottomSheetVisible,
        });
      }
      if (!selectedImageryData.visible) {
        dispatchMap({
          type: 'setSelectedImageryData',
          payload: {
            visible: true,
            data: selectedImageryData.data,
          },
        });
      }
    }
    if (type === '3d') {
      dispatchMap({ type: 'setShowMap3D', payload: true });
    }
    if (type === 'reload' && ranchId) {
      const ranchIds = [];
      ranchIds.push(ranchId);
      prepData(dispatchMap, dispatch, ranchIds).catch();
      dispatchMap({ type: 'setLatestReload' });
    }
    if (type === 'expand') {
      dispatchMap({
        type: 'setSelectedFeatureWithType',
        payload: {
          selectedFeature: undefined,
          bottomSheetType: undefined,
          showBottomSheet: isBottomSheetVisible,
        },
      });
    }
  }

  return (
    <>
      <Button
        id="expand"
        size="large"
        type="primary"
        icon={<FaExpand />}
        style={{
          backgroundColor: 'rgba(0,0,0,0.7)', border: 'none', color: 'white', marginTop: '10px',
        }}
        onClick={(d) => onClickButton(d.currentTarget.id)}
      />
      <Button
        id="chart"
        size="large"
        type="primary"
        ghost={false}
        style={{ width: '40px', color: 'white', marginTop: '10px' }}
        icon={<FaChartBar style={{ marginTop: '5px' }} />}
        href={`https://app.farmx.co/home/graph/${ranchId}`}
      />
      <Button
        id="reload"
        size="large"
        type="primary"
        ghost={false}
        style={{ width: '40px', marginTop: '10px' }}
        icon={<FaRedo style={{ marginTop: '5px' }} />}
        onClick={(d) => onClickButton(d.currentTarget.id)}
      />

      {!isMobile && blockId && selectedObjFromState.type === 'block' ? (
        <Button
          id="3d"
          size="large"
          type="primary"
          ghost={false}
          style={{ width: '40px', marginTop: '10px' }}
          icon={<FaCube style={{ marginTop: '5px' }} />}
          onClick={(d) => onClickButton(d.currentTarget.id)}
        />
      ) : null}
      <Button
        id="satellite"
        size="large"
        disabled={!ranchId}
        type={isImageryPanelVisible ? 'primary' : 'secondary'}
        ghost={false}
        style={{ width: '40px', marginTop: '10px' }}
        icon={<FaSatellite style={{ marginTop: '5px' }} />}
        onClick={(d) => onClickButton(d.currentTarget.id)}
      />
      <TemperatureOverlay
        value={weatherInfo.temperature.value}
        label={weatherInfo.temperature.label}
        isLoading={isWeatherLoading}
      />
      <WindOverlay
        value={weatherInfo.wind}
        windDirection={weatherInfo.windDirection}
        isLoading={isWeatherLoading}
      />
    </>
  );
}

MapControlButtons.propTypes = {
  isMobile: PropTypes.bool,
  isImageryPanelVisible: PropTypes.bool,
  ranchId: PropTypes.number.isRequired,
  blockId: PropTypes.number.isRequired,
  selectedObjFromState: PropTypes.shape().isRequired,
  dispatchMap: PropTypes.func,
  selectedImageryData: PropTypes.shape(),
  isBottomSheetVisible: PropTypes.bool,
};

MapControlButtons.defaultProps = {
  isMobile: false,
  isImageryPanelVisible: false,
  dispatchMap: null,
  selectedImageryData: null,
  isBottomSheetVisible: false,
};
