import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import './EtcDeficitPercentCard.less';
import { useIrrigationRatio } from '../../../helper/scheduleHelper';

export function EtcDeficitPercentCard({
  irrigationApplied,
  irrigationScheduled,
  etcHistoric,
  etcForecast,
  areaSquareMeters,
  units,
}) {
  const { t } = useTranslation();

  const etcPercent = useIrrigationRatio(irrigationApplied,
    irrigationScheduled, etcHistoric,
    etcForecast, areaSquareMeters, units);

  if (!etcPercent) return null;

  return (
    <Card
      className="mobile-list-item"
      style={{ width: '100%', marginBottom: '5px' }}
      bodyStyle={{ padding: '2px', width: '100%' }}
      bordered
    >
      <div className="etc-percent-container">
        <Typography.Text
          className="etc-percent"
        >
          {`${etcPercent.toFixed(1)}%`}
        </Typography.Text>
        <Typography.Text
          className="etc-description"
        >
          {`${t('of recommended ETc')}`}
        </Typography.Text>
      </div>
    </Card>
  );
}

EtcDeficitPercentCard.propTypes = {
  irrigationApplied: PropTypes.number.isRequired,
  irrigationScheduled: PropTypes.number.isRequired,
  etcHistoric: PropTypes.number.isRequired,
  etcForecast: PropTypes.number.isRequired,
  units: PropTypes.shape({
    irrigationApplied: PropTypes.string,
    irrigationScheduled: PropTypes.string,
    etcHistoric: PropTypes.string,
    etcForecast: PropTypes.string,
  }).isRequired,
  areaSquareMeters: PropTypes.number.isRequired,
};
