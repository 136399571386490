import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hooks } from 'farmx-redux-core';
import { useLocation } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { ScheduleForm } from './ScheduleForm';
import './Schedule.less';

export function ScheduleCreate() {
  const { t } = useTranslation();

  // initial block state
  const { blockIds } = hooks.useRanchBlockSelection();

  // handle go back, dismiss
  const location = useLocation();
  const { state: { from } = {} } = location;
  const schedulePath = from || '/schedule';
  const blockIdsFromLocationState = location.state && location.state.blockIds;

  return (
    <div className="page-content margin-10">
      <PageHeader showBack toPath={schedulePath} title={t('new irrigation schedule')} />
      <ScheduleForm blockIds={blockIdsFromLocationState || blockIds} />
    </div>
  );
}
