import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

export default function EventCategoryTag({ value }) {
  const { t } = useTranslation();
  return (
    <Tag color="default">
      { t(value) }
    </Tag>
  );
}

EventCategoryTag.propTypes = {
  value: PropTypes.string.isRequired,
};
