import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Redirect } from 'react-router-dom';
import moment from 'moment';
import { PageHeader } from '../components/PageHeader';
import { ScheduleForm } from './ScheduleForm';
import { useTracking } from '../../../helper/mixpanel';
import './Schedule.less';

export function ScheduleEdit() {
  const { t } = useTranslation();
  const tracking = useTracking();

  const location = useLocation();
  const { state: { scheduledEvent } = {} } = location;

  function getInitialValues(event) {
    if (!event) {
      return null;
    }
    const startDate = moment(event.start_date);
    const endDate = moment(event.stop_date);
    return {
      blockIds: [event.block],
      startDate,
      endDate,
      duration: endDate.diff(startDate, 'minutes', true),
      isRecurring: event.is_recurring,
      repeatFrequency: event.repeat_frequency,
      repeatInterval: event.repeat_interval,
      timezone: event.timezone,
      id: event.id,
      eventIndex: event.event_index,
      repeatEndType: event.repeat_end_type,
      repeatEndCount: event.repeat_end_count,
      repeatEndDate: event.repeat_end_date ? moment(event.repeat_end_date) : null,
      repeatWeekdays: {
        Su: event.repeat_sunday,
        Mo: event.repeat_monday,
        Tu: event.repeat_tuesday,
        We: event.repeat_wednesday,
        Th: event.repeat_thursday,
        Fr: event.repeat_friday,
        Sa: event.repeat_saturday,
      },
    };
  }

  useEffect(() => {
    tracking.track('Loaded edit schedule page');
  }, [tracking]);

  if (!scheduledEvent) {
    return <Redirect to="/schedule/new" />;
  }

  return (
    <div className="page-content margin-10">
      <PageHeader showBack title={t('schedule edit')} />
      <ScheduleForm
        isEditMode
        scheduledEvent={scheduledEvent}
        initialValues={getInitialValues(scheduledEvent)}
      />
    </div>
  );
}
