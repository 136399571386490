import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function PumpStateText(props) {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { state, pumpName, multipleStates } = props;

  return (
    <>
      {!multipleStates.isMultiple && (
      <Title className="pump-state-text" level={5}>
        {pumpName ? `${pumpName} is` : t('Pump is')}
        <span className={state.toLowerCase() === 'on' ? 'pump-on-state' : ''}>
          {` ${state ? t(state) : 'unknown'}`}
        </span>
      </Title>
      )}
      {multipleStates.isMultiple && (
      <Title className="pump-state-text" level={5}>
        Pumps:
        {!multipleStates.bothStates && (
        <>
          {t(` ${multipleStates.onCount}`)}
          <span className={multipleStates.onState.toLowerCase() === 'on' ? 'pump-on-state' : ''}>
            {` ${t(multipleStates.onState)}`}
          </span>
          {t(` ${multipleStates.offCount}`)}
          <span className={multipleStates.offState.toLowerCase() === 'on' ? 'pump-on-state' : ''}>
            {` ${t(multipleStates.offState)}`}
          </span>
        </>
        )}
        {multipleStates.bothStates && (
          <>
            {t(` ${multipleStates.totalCount}`)}
            <span className={multipleStates.bothStates
              .toLowerCase() === 'on' ? 'pump-on-state' : ''}
            >
              {` ${t(multipleStates.bothStates)}`}
            </span>
          </>
        )}
      </Title>
      )}
    </>
  );
}

PumpStateText.propTypes = {
  state: PropTypes.string.isRequired,
  pumpName: PropTypes.string,
  multipleStates: PropTypes.shape(),
};

PumpStateText.defaultProps = {
  pumpName: null,
  multipleStates: {},
};
