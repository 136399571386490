import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import Icon from '../components/Icon';

const { selectMapShowTemperature } = selectors;

const TemperatureOverlay = (props) => {
  const { value, label, isLoading } = props;
  const showTemperatureOverlay = useSelector(selectMapShowTemperature);

  return showTemperatureOverlay ? (
    <span className="map-overlay">
      {isLoading ? (
        <LoadingOutlined />
      ) : (
        <>
          <span className="degree">{value}</span>
          {label}
        </>
      )}
      <Icon name="clouds" color="white" size={16} />
    </span>
  ) : null;
};

TemperatureOverlay.defaultProps = {
  value: '--',
  label: '',
  isLoading: false,
};

TemperatureOverlay.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default TemperatureOverlay;
