/* eslint-disable */
/**
 * @author Robin Woodby
 * @fileoverview This is a triangulated mesh generator that uses earcut
 */

THREE.EarcutGeometry = function( vertices, holes ) {

    THREE.Geometry.call( this );

    verticesFlat = flatten(vertices);
    resultsFlat = earcut(verticesFlat, holes);

    /**
     * Flattens array of Vector2d or Vector3d points into array of numbers
     * in the form [x0, y0, x1, y1, x2, y2, ...]
     */
    function flatten( vertices ) {

        var flat = [];

        for (var i = 0; i < vertices.length; i++) {
            flat.push(vertices[i].x);
            flat.push(vertices[i].y);
        }

        return flat;
    }

    /**
     * Whether the face is visible from the vertex
     */
    function visible( face, vertex ) {

        var va = vertices[ face[ 0 ] ];
        var vb = vertices[ face[ 1 ] ];
        var vc = vertices[ face[ 2 ] ];

        var n = normal( va, vb, vc );

        // distance from face to origin
        var dist = n.dot( va );

        return n.dot( vertex ) >= dist;

    }

    /**
     * Face normal
     */
    function normal( va, vb, vc ) {

        var cb = new THREE.Vector3();
        var ab = new THREE.Vector3();

        cb.subVectors( vc, vb );
        ab.subVectors( va, vb );
        cb.cross( ab );

        cb.normalize();

        return cb;

    }

    /**
     * Detect whether two edges are equal.
     * Note that when constructing the convex hull, two same edges can only
     * be of the negative direction.
     */
    function equalEdge( ea, eb ) {

        return ea[ 0 ] === eb[ 1 ] && ea[ 1 ] === eb[ 0 ];

    }

    /**
     * Create a random offset between -1e-6 and 1e-6.
     */
    function randomOffset() {

        return ( Math.random() - 0.5 ) * 2 * 1e-6;

    }

    this.vertices = vertices;

    // Convert flat faces into instances of THREE.Face3
    for ( var i = 0; i < resultsFlat.length; i +=3 ) {

        this.faces.push( new THREE.Face3(
                resultsFlat[ i ],
                resultsFlat[ i + 1 ],
                resultsFlat[ i + 2 ]
        ) );

    }

    this.computeFaceNormals();

    // Compute flat vertex normals
    for ( var i = 0; i < this.faces.length; i ++ ) {

        var face = this.faces[ i ];
        var normal = face.normal;

        face.vertexNormals[ 0 ] = normal.clone();
        face.vertexNormals[ 1 ] = normal.clone();
        face.vertexNormals[ 2 ] = normal.clone();

    }

};

THREE.EarcutGeometry.prototype = Object.create( THREE.Geometry.prototype );
THREE.EarcutGeometry.prototype.constructor = THREE.EarcutGeometry;
