import React, { useEffect, useState } from 'react';
import { Modal, Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import { sortSchedule } from '../../../../helper/blockDetailsHelper';

const {
  useLoadUserSettings,
} = hooks;

const {
  selectUserTimeMomentFormat,
} = selectors;

export default function OverrideModal(props) {
  const { t } = useTranslation();
  const [sortedSchedule, setSortedSchedule] = useState([]);
  const timeFormat = useSelector(selectUserTimeMomentFormat);

  const {
    visible,
    handleOk,
    handleCancel,
    label1,
    label2,
    schedule,
    newState,
    currentState,
  } = props;

  useEffect(() => {
    setSortedSchedule(sortSchedule([...schedule]).splice(0, 3));
  }, [schedule]);

  const truncate = (str) => {
    if (str.length > 8) {
      return `${str.substring(0, 8)}...`;
    }
    return str;
  };

  useLoadUserSettings();

  const formattedTime = `ddd MMM Do, ${timeFormat}`;

  return (
    <>
      <Modal
        className="override-modal"
        centered
        closable
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            className="override-modal-btn override-modal-confirm"
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            {`${t('Confirm')} ${newState.toLowerCase() !== 'SCHEDULED' ? newState : 'End'}`}
          </Button>,
          <Button
            className="override-modal-btn override-modal-dismiss"
            key="back"
            onClick={handleCancel}
          >
            {t('Cancel')}
          </Button>,
        ]}
      >
        <div className="override-container">
          <div>
            <Typography.Text strong>
              {`
                ${t(newState.toLowerCase() !== 'scheduled'
                ? 'You are about to override'
                : 'You are about to end override')}
                ${label1} (${newState.toLowerCase() !== 'scheduled'
                  ? newState
                  : currentState}).
              `}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text strong>
              {`
                ${t('This will affect the following')}
                ${label2}
                ${t('schedule')} :
              `}
            </Typography.Text>
          </div>
          <div className="override-schedule">
            {sortedSchedule.length ? sortedSchedule.map((item) => (
              item && item.scheduledTime && item.endTime
                ? (
                  <div>
                    {!(label2.includes('valve')) && (
                      <>
                        <Typography.Text strong>{truncate(item.blockName)}</Typography.Text>
                        <Typography.Text className="text-link">{' - '}</Typography.Text>
                      </>
                    )}
                    <Typography.Text className="text-link">
                      {`${moment(item.scheduledTime).format(formattedTime)}`}
                      {' ('}
                      <Moment from={item.scheduledTime} ago>{item.endTime}</Moment>
                      )
                    </Typography.Text>
                  </div>
                ) : <div>{t('Nothing Scheduled')}</div>
            )) : <div>{t('Nothing Scheduled')}</div>}
          </div>
        </div>
      </Modal>
    </>
  );
}

OverrideModal.defaultProps = {
  label1: '',
  label2: '',
  visible: false,
  schedule: [],
  newState: '',
  currentState: '',
};

OverrideModal.propTypes = {
  label1: PropTypes.string,
  label2: PropTypes.string,
  visible: PropTypes.bool,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  schedule: PropTypes.arrayOf(Object),
  newState: PropTypes.string,
  currentState: PropTypes.string,
};
