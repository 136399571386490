import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import { Link } from 'react-router-dom';
import { RanchBlockSelect } from './RanchBlockSelect';
import { installState } from '../../../constants';
import { FlowMeterSummary } from './ListCards/FlowMeterSummary';
import { FlowSensorListItem } from './FlowSensorListItem';
import { PageHeader } from '../components/PageHeader';

const {
  selectAllSensorsForBlockIdsByType,
} = selectors;

export function FlowSensorList() {
  const { t } = useTranslation();
  const [selectedObj, setSelectedObj] = useState({});
  const { blockIds } = hooks.useRanchBlockSelection();
  const sensors = useSelector((state) => (
    selectAllSensorsForBlockIdsByType(state, {
      blockIds,
      installState: installState.INSTALLED,
    })
  )) || {};

  const list = [
    ...(sensors.water_flow_analog || []),
    ...(sensors.water_flow || []),
  ];

  return (
    <div className="div-default padding-10 page-content">
      <div className="desktop-container">
        <PageHeader
          showBack
          title={t('Flow Sensors')}
          toPath="/list"
        />
        <RanchBlockSelect
          selectedObj={selectedObj}
          setSelectedObj={setSelectedObj}
        />
        <div className="list-container">
          <FlowMeterSummary
            list={list}
          />

          {list.map((ps) => (
            <Link key={ps.identifier} to={`/list/flow-sensor/${ps.type}/${ps.identifier}`}>
              <FlowSensorListItem
                key={ps.id}
                sensorDetail={ps}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

FlowSensorList.defaultProps = {
  history: {},
};

FlowSensorList.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    location: PropTypes.shape({
      selectedObj: PropTypes.object,
    }),
  }),
};
