import moment from 'moment';
import { irrigationStatus } from '../constants';

export const getStatusDetails = (block, translation) => {
  const {
    pumpCurrentState,
    valveCurrentState,
    irrigationStartDate,
    irrigationEndDate,
  } = block;

  const t = translation;

  let description = '';
  let status;
  let title;

  if (pumpCurrentState.toLowerCase() === 'on' && valveCurrentState.toLowerCase() === 'open') {
    status = irrigationStatus.IRRIGATING;
    title = 'Irrigating Now';
    if (irrigationEndDate) {
      description = `${moment(irrigationEndDate).fromNow(true)} ${t('remaining')}`;
    }
  } else if (irrigationStartDate) {
    status = irrigationStatus.SCHEDULED;
    title = 'Irrigation Scheduled';
    if (irrigationEndDate) {
      description = `${moment(irrigationStartDate).format('ddd, h:mmA')} - ${moment(irrigationEndDate).format('ddd, h:mmA')}`;
    } else {
      description = moment(irrigationStartDate).format('ddd, h:mmA');
    }
  } else {
    status = irrigationStatus.NOT_SCHEDULED;
    title = 'Irrigation Not Scheduled';
  }

  return {
    description,
    status,
    title: t(title),
  };
};
