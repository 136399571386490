import { SettingsPage } from '../settings';
import { withPlatformDetect } from '../../utils/withPlatformDetect';
import { Schedule } from '../mobile/schedule/Schedule';
import { ControlPage } from '../mobile/control/ControlPage';
import { BlockDetails } from '../mobile/control/ControlSummary/BlockDetails';
import { ControlPumpDetails } from '../mobile/control/ControlPump/ControlPumpDetails';
import { ControlValveDetail } from '../mobile/control/ControlValves/ControlValveDetail';
import { Home as HomeMobile } from '../mobile/home/Home';
import { RecommendationAction } from '../mobile/home/RecommendationAction';
import { RecommendationDetail } from '../mobile/home/RecommendationDetail';
import { Map as MapMobile } from '../mobile/map/Map';
import { ListPage } from '../mobile/list/ListPage';
import { ScheduleCalendar } from '../mobile/schedule/ScheduleCalendar';
import { ScheduleShare } from '../mobile/schedule/ScheduleShare';
import { ScheduleDetail } from '../mobile/schedule/ScheduleDetail';
import { ScheduleCreate } from '../mobile/schedule/ScheduleCreate';
import { ScheduleEdit as ScheduleEditMobile } from '../mobile/schedule/ScheduleEdit';
import { SoilSensorList } from '../mobile/list/SoilSensorList';
import { PlantSensorList } from '../mobile/list/PlantSensorList';
import { PressureSensorsList as ControlPressureSensorList } from '../mobile/control/ControlValves/PressureSensorsList';
import { IRSensorList } from '../mobile/list/IRSensorList';
import { ValveSensorList } from '../mobile/list/ValveSensorList';
import { FlowSensorList } from '../mobile/list/FlowSensorList';

import {
  ScheduleCreatePage,
  SchedulePage,
  ScheduleUpdatePage,
} from '../schedule';

import {
  IrrigationControlSummary,
} from '../control';

import {
  EventsPage,
} from '../stream';

import {
  Dashboard,
} from '../dashboard';

import Page404 from './Page404';
import Mobile404 from '../mobile/Mobile404';
import { PumpList } from '../mobile/list/PumpList';
import { WeatherList } from '../mobile/list/WeatherList';
import { PressureSensorList } from '../mobile/list/PressureSensorList';
import { Settings } from '../mobile/settings/Settings';
import { ROUTES as MOBILE_ROUTES } from '../settings/routes';
import { isMobile } from '../../utils/detectDevice';
import { WaterUsageShare } from '../mobile/schedule/WaterUsageShare';
import RanchSelection from '../mobile/farmhand/RanchSelection';
import BlockSelection from '../mobile/farmhand/BlockSelection';
import BlockControl from '../mobile/farmhand/BlockControl';
import Documentation from '../mobile/components/Documentation';
import SensorDetailPage from '../mobile/list/SensorDetailPage';
import { MultiGraphPage } from '../graph';
import ZendeskArticle from '../mobile/components/ZendeskArticle';

export const ROUTE_KEYS = {
  ROOT: '/',
  CONTROL: '/control',
  RECOMMENDATION: '/recommendation',
  RECOMMENDATION_ACTION: '/recommendation/action/:blockId',
  RECOMMENDATION_DETAIL: '/recommendation/detail/:blockId',
  SCHEDULE: '/schedule',
  SCHEDULE_CREATE: '/schedule/new',
  SCHEDULE_EDIT: '/schedule/edit',
  SCHEDULE_CALENDAR: '/schedule/calendar',
  SCHEDULE_DETAIL: '/schedule/detail/:blockId/:id',
  SCHEDULE_SHARE: '/schedule/share',
  WATERUSAGE_SHARE: '/schedule/waterusage/share',
  SETTINGS_PAGE: '/settings',
  MAP: '/map',
  EVENTS: '/events',
  DASHBOARD: '/dashboard',
  LIST: '/list',
  BLOCK_DETAILS: '/control/block/:id',
  CONTROL_PUMP_DETAILS: '/control/pump/:id',
  CONTROL_PRESSURE_SENSORS_LIST: '/control/sensors/:id',
  CONTROL_VALVE_DETAILS: '/control/valve/:id',
  SOIL_SENSOR_LIST: '/list/soil-sensor',
  PLANT_SENSOR_LIST: '/list/plant-sensor',
  PRESSURE_SENSORS_LIST: '/list/pressure-sensor',
  IR_SENSORS_LIST: '/list/infrared-sensor',
  VALVE_SENSORS_LIST: '/list/valve-sensor',
  FLOW_SENSORS_LIST: '/list/flow-sensor',
  PUMP_LIST: '/list/pump',
  WEATHER_LIST: '/list/weather',
  HELP: '/help',
  FARMHAND_RANCHES: '/farmhand/ranches',
  FARMHAND_BLOCKS: '/farmhand/ranches/:id',
  FARMHAND_BLOCK_CONTROL: '/farmhand/ranches/:id/:blockId',
  DOCUMENTATION: '/help',
  ZENDESK_ARTICLE: '/help/article/:articleId',
  SENSOR_DETAIL: '/list/:listType/:sensorType/:id',
  GRAPH: '/graph',
};

const SHARED_ROUTES = {
  SETTINGS_PAGE: {
    component: withPlatformDetect(Settings, SettingsPage),
    exact: isMobile,
    key: ROUTE_KEYS.SETTINGS_PAGE,
    path: ROUTE_KEYS.SETTINGS_PAGE,
  },
  RECOMMENDATION: {
    component: HomeMobile,
    exact: true,
    key: ROUTE_KEYS.RECOMMENDATION,
    path: ROUTE_KEYS.RECOMMENDATION,
  },
  RECOMMENDATION_ACTION: {
    component: RecommendationAction,
    exact: false,
    key: ROUTE_KEYS.RECOMMENDATION_ACTION,
    path: ROUTE_KEYS.RECOMMENDATION_ACTION,
  },
  RECOMMENDATION_DETAIL: {
    component: RecommendationDetail,
    exact: false,
    key: ROUTE_KEYS.RECOMMENDATION_DETAIL,
    path: ROUTE_KEYS.RECOMMENDATION_DETAIL,
  },
  SCHEDULE: {
    component: withPlatformDetect(Schedule, SchedulePage),
    exact: true,
    key: ROUTE_KEYS.SCHEDULE,
    path: ROUTE_KEYS.SCHEDULE,
  },
  SCHEDULE_CREATE: {
    component: withPlatformDetect(ScheduleCreate, ScheduleCreatePage),
    exact: true,
    key: ROUTE_KEYS.SCHEDULE_CREATE,
    path: ROUTE_KEYS.SCHEDULE_CREATE,
  },
  SCHEDULE_EDIT: {
    component: withPlatformDetect(ScheduleEditMobile, ScheduleUpdatePage),
    exact: true,
    key: ROUTE_KEYS.SCHEDULE_EDIT,
    path: ROUTE_KEYS.SCHEDULE_EDIT,
  },
  SCHEDULE_CALENDAR: {
    component: withPlatformDetect(ScheduleCalendar, Page404),
    exact: true,
    key: ROUTE_KEYS.SCHEDULE_CALENDAR,
    path: ROUTE_KEYS.SCHEDULE_CALENDAR,
  },
  SCHEDULE_DETAIL: {
    component: withPlatformDetect(ScheduleDetail, Page404),
    exact: false,
    key: ROUTE_KEYS.SCHEDULE_DETAIL,
    path: ROUTE_KEYS.SCHEDULE_DETAIL,
  },
  SCHEDULE_SHARE: {
    component: withPlatformDetect(ScheduleShare, Page404),
    exact: true,
    key: ROUTE_KEYS.SCHEDULE_SHARE,
    path: ROUTE_KEYS.SCHEDULE_SHARE,
  },
  CONTROL: {
    component: withPlatformDetect(ControlPage, IrrigationControlSummary),
    exact: true,
    key: ROUTE_KEYS.CONTROL,
    path: ROUTE_KEYS.CONTROL,
  },
  MAP: {
    component: withPlatformDetect(MapMobile, MapMobile),
    exact: true,
    key: ROUTE_KEYS.MAP,
    path: ROUTE_KEYS.MAP,
  },
  EVENTS: {
    component: withPlatformDetect(EventsPage, EventsPage),
    exact: true,
    key: ROUTE_KEYS.EVENTS,
    path: ROUTE_KEYS.EVENTS,
  },
  DASHBOARD: {
    component: withPlatformDetect(Mobile404, Dashboard),
    exact: true,
    key: ROUTE_KEYS.DASHBOARD,
    path: ROUTE_KEYS.DASHBOARD,
  },
  LIST: {
    component: ListPage,
    exact: true,
    key: ROUTE_KEYS.LIST,
    path: ROUTE_KEYS.LIST,
  },
  BLOCK_DETAILS: {
    component: withPlatformDetect(BlockDetails, Page404),
    exact: true,
    key: ROUTE_KEYS.BLOCK_DETAILS,
    path: ROUTE_KEYS.BLOCK_DETAILS,
  },
  CONTROL_PUMP_DETAILS: {
    component: withPlatformDetect(ControlPumpDetails, Page404),
    exact: true,
    key: ROUTE_KEYS.CONTROL_PUMP_DETAILS,
    path: ROUTE_KEYS.CONTROL_PUMP_DETAILS,
  },
  SOIL_SENSOR_LIST: {
    component: SoilSensorList,
    exact: true,
    key: ROUTE_KEYS.SOIL_SENSOR_LIST,
    path: ROUTE_KEYS.SOIL_SENSOR_LIST,
  },
  PLANT_SENSOR_LIST: {
    component: PlantSensorList,
    exact: true,
    key: ROUTE_KEYS.PLANT_SENSOR_LIST,
    path: ROUTE_KEYS.PLANT_SENSOR_LIST,
  },
  PUMP_LIST: {
    component: PumpList,
    exact: true,
    key: ROUTE_KEYS.PUMP_LIST,
    path: ROUTE_KEYS.PUMP_LIST,
  },
  WEATHER_LIST: {
    component: WeatherList,
    exact: true,
    key: ROUTE_KEYS.WEATHER_LIST,
    path: ROUTE_KEYS.WEATHER_LIST,
  },
  CONTROL_PRESSURE_SENSORS_LIST: {
    component: withPlatformDetect(ControlPressureSensorList, Page404),
    exact: true,
    key: ROUTE_KEYS.CONTROL_PRESSURE_SENSORS_LIST,
    path: ROUTE_KEYS.CONTROL_PRESSURE_SENSORS_LIST,
  },
  PRESSURE_SENSORS_LIST: {
    component: PressureSensorList,
    exact: true,
    key: ROUTE_KEYS.PRESSURE_SENSORS_LIST,
    path: ROUTE_KEYS.PRESSURE_SENSORS_LIST,
  },
  CONTROL_VALVE_DETAILS: {
    component: withPlatformDetect(ControlValveDetail, Page404),
    exact: true,
    key: ROUTE_KEYS.CONTROL_VALVE_DETAILS,
    path: ROUTE_KEYS.CONTROL_VALVE_DETAILS,
  },
  IR_SENSORS_LIST: {
    component: IRSensorList,
    exact: true,
    key: ROUTE_KEYS.IR_SENSORS_LIST,
    path: ROUTE_KEYS.IR_SENSORS_LIST,
  },
  VALVE_SENSORS_LIST: {
    component: ValveSensorList,
    exact: true,
    key: ROUTE_KEYS.VALVE_SENSORS_LIST,
    path: ROUTE_KEYS.VALVE_SENSORS_LIST,
  },
  FLOW_SENSORS_LIST: {
    component: FlowSensorList,
    exact: true,
    key: ROUTE_KEYS.FLOW_SENSORS_LIST,
    path: ROUTE_KEYS.FLOW_SENSORS_LIST,
  },
  WATERUSAGE_SHARE: {
    component: withPlatformDetect(WaterUsageShare, Page404),
    exact: true,
    key: ROUTE_KEYS.WATERUSAGE_SHARE,
    path: ROUTE_KEYS.WATERUSAGE_SHARE,
  },
  DOCUMENTATION: {
    component: Documentation,
    exact: true,
    key: ROUTE_KEYS.DOCUMENTATION,
    path: ROUTE_KEYS.DOCUMENTATION,
  },
  ZENDESK_ARTICLE: {
    component: ZendeskArticle,
    exact: true,
    key: ROUTE_KEYS.ZENDESK_ARTICLE,
    path: ROUTE_KEYS.ZENDESK_ARTICLE,
  },
  SENSOR_DETAIL: {
    component: SensorDetailPage,
    exact: true,
    key: ROUTE_KEYS.SENSOR_DETAIL,
    path: ROUTE_KEYS.SENSOR_DETAIL,
  },
  GRAPH: {
    component: MultiGraphPage,
    exact: true,
    key: ROUTE_KEYS.GRAPH,
    path: ROUTE_KEYS.GRAPH,
  },
};

const MOBILE_SETTINGS_ROUTES = {
  ACCOUNT: MOBILE_ROUTES.ACCOUNT,
  NOTIFICATION: MOBILE_ROUTES.NOTIFICATION,
  UNITS: MOBILE_ROUTES.UNITS,
  LANGUAGE: MOBILE_ROUTES.LANGUAGE,
  SENSORS: MOBILE_ROUTES.SENSORS,
  IRRIGATION_SETTINGS: MOBILE_ROUTES.IRRIGATION_SETTINGS,
};

export const FARMHAND_ROUTES = {
  FARMHAND_RANCHES: {
    component: withPlatformDetect(RanchSelection, Page404),
    exact: true,
    key: ROUTE_KEYS.FARMHAND_RANCHES,
    path: ROUTE_KEYS.FARMHAND_RANCHES,
  },
  FARMHAND_BLOCKS: {
    component: withPlatformDetect(BlockSelection, Page404),
    exact: true,
    key: ROUTE_KEYS.FARMHAND_BLOCKS,
    path: ROUTE_KEYS.FARMHAND_BLOCKS,
  },
  FARMHAND_BLOCK_CONTROL: {
    component: withPlatformDetect(BlockControl, Page404),
    exact: true,
    key: ROUTE_KEYS.FARMHAND_BLOCK_CONTROL,
    path: ROUTE_KEYS.FARMHAND_BLOCK_CONTROL,
  },
};

export const ROUTES = {
  ...SHARED_ROUTES,
  ...MOBILE_SETTINGS_ROUTES,
};
