import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectors } from 'farmx-redux-core';
import './list.css';
import { WeatherSummary } from './ListCards/WeatherSummary';
import { SoilSensorSummary } from './ListCards/SoilSensorSummary';
import { PlantSensorSummary } from './ListCards/PlantSensorSummary';
import { IRSensorSummary } from './ListCards/IRSensorSummary';
import { PressureSensorSummary } from './ListCards/PressureSensorSummary';
import { PumpSummary } from './ListCards/PumpSummary';
import { FlowMeterSummary } from './ListCards/FlowMeterSummary';
import { ValveSummary } from './ListCards/ValveSummary';
import { FrostSensorSummary } from './ListCards/FrostSensorSummary';
import { installState } from '../../../constants';

const {
  selectAllSensorsForBlockIdsByType,
  selectAllSensorStatusForBlockIdsByType,
} = selectors;

export function SensorSummaryList(props) {
  const { blocks, selectedObj } = props;
  const state = useSelector((store) => store);
  const [sensorsData, setSensorsData] = useState({});
  const [sensorStatusData, setSensorStatusData] = useState({});

  useEffect(() => {
    const blockIds = blocks.map((item) => item.id);
    const sensors = selectAllSensorsForBlockIdsByType(state, {
      blockIds,
      installState: installState.INSTALLED,
    });
    const status = selectAllSensorStatusForBlockIdsByType(state);

    setSensorsData(sensors);
    setSensorStatusData(status);
  }, [blocks, state]);

  const soilList = [
    ...(sensorsData.aquacheck_soil || []),
    ...(sensorsData.pixl_soil || []),
  ];

  const flowMeterList = [
    ...(sensorsData.water_flow_analog || []),
    ...(sensorsData.water_flow || []),
  ];

  return (
    <div className="sensor-summary-list-container item-list">
      <Link
        to={{
          pathname: '/list/weather',
          selectedObj,
        }}
      >
        <WeatherSummary
          list={sensorsData.weather_station}
        />
      </Link>

      <Link
        to={{
          pathname: '/list/soil-sensor',
          selectedObj,
        }}
      >
        <SoilSensorSummary
          list={soilList}
        />
      </Link>

      <Link
        to={{
          pathname: '/list/plant-sensor',
          selectedObj,
        }}
      >
        <PlantSensorSummary
          list={sensorsData.farmx_dendrometer}
        />
      </Link>

      <Link
        to={{
          pathname: '/list/infrared-sensor',
          selectedObj,
        }}
      >
        <IRSensorSummary
          list={sensorsData.farmx_infrared}
          statusList={sensorStatusData.farmx_infrared}
        />
      </Link>

      <Link
        to={{
          pathname: '/list/pressure-sensor',
          selectedObj,
        }}
      >
        <PressureSensorSummary
          list={sensorsData.water_pressure}
        />
      </Link>

      <Link
        to={{
          pathname: '/list/pump',
          selectedObj,
        }}
      >
        <PumpSummary />
      </Link>

      <Link
        to={{
          pathname: '/list/flow-sensor',
          selectedObj,
        }}
      >
        <FlowMeterSummary
          list={flowMeterList}
        />
      </Link>

      <Link
        to={{
          pathname: '/list/valve-sensor',
          selectedObj,
        }}
      >
        <ValveSummary
          list={sensorsData.valve}
        />
      </Link>

      <FrostSensorSummary
        list={sensorsData.farmx_frost}
      />
    </div>
  );
}

SensorSummaryList.defaultProps = {
  blocks: [],
  selectedObj: null,
};

SensorSummaryList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedObj: PropTypes.object,
  blocks: PropTypes.arrayOf(PropTypes.object),
};
