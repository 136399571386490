import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ControlSummaryListItem from './ControlSummaryListItem';

export default function ControlSummaryList(props) {
  const { blocks } = props;
  const { t } = useTranslation();

  return (
    <div className="control-summary-list item-list">
      {blocks.length ? blocks.map((block) => (
        <div key={block.id}>
          <Link
            to={`/control/block/${block.id}`}
          >
            <ControlSummaryListItem
              block={block}
            />
          </Link>
        </div>
      )) : (
        <div className="mobile-list-item no-config">
          {t('No Blocks')}
        </div>
      )}
    </div>
  );
}

ControlSummaryList.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
};

ControlSummaryList.defaultProps = {
  blocks: [],
};
