import React, {
  useReducer, useRef, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FeatureGroup, TileLayer } from 'react-leaflet';
import isEqual from 'react-fast-compare';
import { FaSlidersH } from 'react-icons/fa';
import { selectors, actions } from 'farmx-redux-core';
import { Button, Modal } from 'antd';
import { Map as MapComponent, RanchBlockSelect } from 'farmx-web-ui';
import {
  useLoadMapData,
  useSensorsByRanchIdGeoJSON,
  useBlocksGeoJSON,
  useAllRanchesGeoJSON,
  useRecenter,
  useReDraw,
  useLoadAnomalyData,
} from './mapHooks';
import { initReducerMapMobile, reducerMapMobile } from './reducerMapMobile';
import './map.css';
import { MapSettings, presModeTitles } from './MapSettings';
import { reDrawSatelliteImagery } from './reDraw';
import HelpButton from '../components/HelpButton';
import LegendModal from './LegendModal';
import legendConfig from './legendConfig';
import { SatelliteImageryWrapper } from './SatelliteImageryWrapper';
import { getLayerBounds, getDataForReDraw } from '../../../helper/mapHelper';
import { Map3D } from '../../map/components/threed/Map3D';
import { isMobile } from '../../../utils/detectDevice';
import ShowDetails from './ShowDetails';
import MapControlButtons from './MapControlButtons';

const {
  setRanchMobile,
  setBlockMobile,
  setRanchBlockSelection,
  setRanch,
  setBlocks,
} = actions;

const {
  selectMapShowSoilType,
  selectMapShowLabels,
  selectShowOnlyPresentionModeSensors,
  selectMapPresentationModes,
  selectSensorsFeaturesByRanchId,
  selectBlocksFeaturesByRanchId,
  selectRanchesFeatures,
  selectLoginUserInfo,
} = selectors;

export function MapView({ ranchId, blockId, selectedObjFromState }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userInfo = useSelector(selectLoginUserInfo).payload;
  const isAdmin = userInfo && userInfo.admin;

  // use local reducer to handle state of the component
  const [stateMap, dispatchMap] = useReducer(
    reducerMapMobile,
    initReducerMapMobile(),
    initReducerMapMobile,
  );

  useLoadMapData(dispatchMap, ranchId, dispatch);

  const sensorsByRanchIdGeoJSON = useSensorsByRanchIdGeoJSON(
    useSelector((state) => selectSensorsFeaturesByRanchId(state, ranchId), isEqual),
  );
  const ranchSensors = isAdmin
    ? sensorsByRanchIdGeoJSON
    : sensorsByRanchIdGeoJSON.filter(({ properties }) => properties.type !== 'cavalier');

  const blocksGeoJSON = useBlocksGeoJSON(
    useSelector((state) => selectBlocksFeaturesByRanchId(state, ranchId), isEqual),
  );

  const ranchFeatures = useSelector((state) => selectRanchesFeatures(state));
  const [ranchGeoJSON, allRanchesPoints, allRanchesFeaturesData] = useAllRanchesGeoJSON(
    ranchFeatures,
    ranchId,
  );

  const selShowAllSensors = useSelector(
    (state) => !selectShowOnlyPresentionModeSensors(state), isEqual,
  );

  const selMapPresentationModes = useSelector(
    (state) => selectMapPresentationModes(state), isEqual,
  );
  const presMode = Object
    .keys(selMapPresentationModes)
    .find((k) => selMapPresentationModes[k]);

  const selMapShowSoilType = useSelector(
    (state) => selectMapShowSoilType(state), isEqual,
  );

  const selMapShowLabels = useSelector(
    (state) => selectMapShowLabels(state), isEqual,
  );

  const mapFeatureGroupRef = useRef(null);

  const blockIdsForRanch = JSON.stringify((blocksGeoJSON && blocksGeoJSON.features
    && blocksGeoJSON.features.map((d) => d.id)) || []);

  useLoadAnomalyData(dispatchMap, blockIdsForRanch,
    blockId, (selectedObjFromState && selectedObjFromState.type));

  const dataForReDraw = getDataForReDraw(ranchSensors, presMode,
    stateMap.anomalyGeoJSON, stateMap.selectedFeature);

  useReDraw(
    mapFeatureGroupRef,
    dataForReDraw,
    presMode,
    stateMap.selectedFeature,
    dispatchMap,
    selMapShowLabels,
    selShowAllSensors,
    allRanchesPoints,
    ranchId,
    stateMap.zoomSensorsVisible,
  );

  const blkId = (selectedObjFromState?.type === 'block')
    ? Number(selectedObjFromState.value) : blockId;

  useRecenter(
    blkId,
    ranchId,
    blocksGeoJSON,
    ranchGeoJSON,
    allRanchesFeaturesData,
    dispatchMap,
    (selectedObjFromState && selectedObjFromState.type),
    stateMap.isBottomSheetVisible,
    stateMap.bottomSheetType,
  );

  // To enable to satellite imagery control and show imagery when ranch/block is change
  useEffect(() => {
    if ((ranchId || blockId) && stateMap.selectedImageryData.data?.length && isMobile) {
      dispatchMap({
        type: 'setIsImageryPanelVisible',
        payload: true,
      });
      dispatchMap({
        type: 'setIsBottomSheetVisible',
        payload: true,
      });
    }
    // To avoid multiple useEffect trigger also this will be refactored in future
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockId, ranchId]);

  // To close sidebar when select different ranch/block
  useEffect(() => {
    if (!isMobile) {
      dispatchMap({
        type: 'setSelectedFeatureWithType',
        payload: {
          selectedFeature: undefined,
          bottomSheetType: undefined,
          showBottomSheet: !!(blockId || ranchId),
        },
      });
    }
  }, [blockId, ranchId]);

  const imageryDataOpacityRef = useRef(stateMap.selectedImageryDataOpacity);
  useEffect(() => {
    if (!stateMap.showMap3D) {
      reDrawSatelliteImagery({
        mapFeatureGroupRef,
        selectedImageryData: stateMap.selectedImageryData,
        selectedImageryDataOpacity: imageryDataOpacityRef.current,
      });
    }
  });

  function onRanchBlockClick(id, type) {
    if (type === 'block' && !isMobile) dispatch(setBlocks([Number(id)]));
    if (type === 'ranch' && !isMobile) dispatch(setRanch(Number(id)));

    dispatchMap({
      type: 'setSelectedFeatureWithType',
      payload: {
        selectedFeature: undefined,
        bottomSheetType: undefined,
        showBottomSheet: true,
      },
    });
  }

  function handleSelectedRanchBlock(d) {
    if (d && d.value !== undefined) {
      dispatch(setRanchBlockSelection(d));
      onRanchBlockClick();
    }
  }
  const sheetRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [mapSettingModal, setMapSettingModal] = useState(false);

  function renderSatelliteImagery() {
    return stateMap.bottomSheetType !== 'settings' && stateMap.isImageryPanelVisible
      && (
        <SatelliteImageryWrapper
          ranchId={ranchId}
          blockId={blockId}
          dispatchMap={dispatchMap}
          imageryVisible={stateMap.selectedImageryData.visible}
        />
      );
  }

  function renderMapSettingModal() {
    return (
      <Modal
        visible={mapSettingModal}
        className="map-settings-modal"
        centered
        closable
        onCancel={() => {
          setMapSettingModal(false);
          dispatchMap({
            type: 'setIsImageryPanelVisible',
            payload: stateMap.selectedImageryData.visible,
          });
          dispatchMap({
            type: 'setSelectedFeatureWithType',
            payload: {
              selectedFeature: undefined,
              bottomSheetType: undefined,
              showBottomSheet: stateMap.selectedImageryData.visible,
            },
          });
        }}
        footer={null}
      >
        <MapSettings
          presMode={presMode}
          selMapPresentationModes={selMapPresentationModes}
          selShowAllSensors={selShowAllSensors}
          selMapShowSoilType={selMapShowSoilType}
          selMapShowLabels={selMapShowLabels}
          isImageryDataVisible={stateMap.selectedImageryData.visible}
          onChange={(v) => {
            dispatchMap({
              type: 'setSelectedImageryData',
              payload: {
                visible: v,
                data: stateMap.selectedImageryData.data,
              },
            });
            dispatchMap({
              type: 'setIsImageryPanelVisible',
              payload: v,
            });
            if (!stateMap.isBottomSheetVisible) {
              dispatchMap({
                type: 'setIsBottomSheetVisible',
                payload: v,
              });
            }
          }}
        />
      </Modal>
    );
  }

  const mapBoxPadding = {
    top: isMobile ? 180 : 30,
    left: isMobile ? 10 : 30,
    bottom: isMobile ? 10 : 30,
    right: isMobile ? 10 : 130,
  };

  return (
    <div className="map-mobile">
      <div className={!isMobile && stateMap.isBottomSheetVisible
        ? 'map-desktop-container' : 'map-mobile-container'}
      >
        <div className="map-component-mobile">
          <div className="map-mobile-screen">
            <div className={!isMobile ? 'controls-all-container map-desktop-control-box'
              : 'controls-all-container'}
            >
              {isMobile && (
              <div className="div-select-container ranch-block-select-container">
                <RanchBlockSelect
                  getSelectedRanchBlock={handleSelectedRanchBlock}
                  selected={selectedObjFromState}
                  admin={isAdmin}
                />
              </div>
              )}
              <div className="controls-box">
                <div className="controls-left">
                  <Button
                    size="large"
                    type="primary"
                    icon={<FaSlidersH />}
                    style={{ backgroundColor: 'rgba(0,0,0,0.7)', color: 'white', border: 'none' }}
                    onClick={() => setMapSettingModal(true)}
                  >
                    {t(presModeTitles[presMode])}
                  </Button>
                </div>
                <div className="help-icon">
                  <HelpButton onClick={() => setShowModal(true)} />
                </div>
                <div className="controls-right">
                  {isMobile && (
                  <MapControlButtons
                    isMobile={isMobile}
                    isImageryPanelVisible={stateMap.isImageryPanelVisible}
                    selectedObjFromState={selectedObjFromState}
                    ranchId={ranchId}
                    blockId={blockId}
                    dispatchMap={dispatchMap}
                    isBottomSheetVisible={stateMap.isBottomSheetVisible}
                    selectedImageryData={stateMap.selectedImageryData}
                  />
                  )}
                </div>
              </div>
            </div>
          </div>
          <MapComponent
            exposeZoomChange={(zoom) => {
              dispatchMap({
                type: 'setZoomSensorsVisible',
                payload: zoom,
              });
            }}
            ranchesGeoJSON={ranchGeoJSON}
            blocksGeoJSON={blocksGeoJSON}
            recenterGeoJSON={stateMap.recenterGeoJSON}
            presentationMode={presMode}
            mapBoxPadding={mapBoxPadding}
            onClickRanch={(id) => {
              dispatch(setRanchMobile(Number(id)));
              onRanchBlockClick(id, 'ranch');
            }}
            onClickBlock={(id) => {
              dispatch(setBlockMobile([Number(id)]));
              onRanchBlockClick(id, 'block');
            }}
            isDataLoading={stateMap.isLoading}
            isShowingImagery={stateMap.selectedImageryData}
            anomalyGeoJSON={presMode === 'anomaly' ? getLayerBounds(stateMap.selectedFeature)
              : undefined}
          >
            <FeatureGroup
              ref={(r) => {
                if (r && !mapFeatureGroupRef.current) {
                  mapFeatureGroupRef.current = r;
                }
              }}
            >
              {
                selMapShowSoilType && (
                  <TileLayer
                    url="https://map.farmx.co/api/soil/wms/?x={x}&y={y}&z={z}"
                    name="soil"
                    maxZoom={19}
                    minZoom={0}
                    showOnSelector={false}
                  />
                )
              }
              {!isMobile && (
              <div className="controls-right">
                <MapControlButtons
                  isMobile={isMobile}
                  isImageryPanelVisible={stateMap.isImageryPanelVisible}
                  selectedObjFromState={selectedObjFromState}
                  ranchId={ranchId}
                  blockId={blockId}
                  dispatchMap={dispatchMap}
                  isBottomSheetVisible={stateMap.isBottomSheetVisible}
                  selectedImageryData={stateMap.selectedImageryData}
                />
              </div>
              )}
            </FeatureGroup>
          </MapComponent>
        </div>
      </div>

      {!isMobile && stateMap.isImageryPanelVisible && !stateMap.showMap3D && (
      <div
        style={{
          position: 'absolute',
          bottom: '-2px',
          width: '500px',
          zIndex: '1',
          marginRight: stateMap.isImageryPanelVisible && stateMap.isBottomSheetVisible
            ? '400px' : '0',
        }}
      >
        {renderSatelliteImagery()}
      </div>
      )}

      {renderMapSettingModal()}
      <LegendModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        legendDetails={legendConfig[presModeTitles[presMode]]}
      />
      {blockId && stateMap.showMap3D && !isMobile ? (
        <Map3D
          dispatchMapPage={dispatchMap}
          selectedBlockId={blkId}
        />
      ) : null}

      <ShowDetails
        blockId={blockId}
        ranchId={ranchId}
        selectedObjFromState={selectedObjFromState}
        isMobile={isMobile}
        presentationMode={presMode}
        isBottomSheetVisible={stateMap.isBottomSheetVisible}
        selectedFeature={stateMap.selectedFeature}
        dispatchMap={dispatchMap}
        sheetRef={sheetRef}
        bottomSheetType={stateMap.bottomSheetType}
        isImageryPanelVisible={stateMap.isImageryPanelVisible}
        selectedImageryData={stateMap.selectedImageryData}
        isExpandBtnVisible={stateMap.isExpandBtnVisible}
        expandedGeoJSON={stateMap.expandedGeoJSON}
      />
    </div>
  );
}

MapView.propTypes = {
  ranchId: PropTypes.number.isRequired,
  blockId: PropTypes.number.isRequired,
  selectedObjFromState: PropTypes.shape().isRequired,
};
