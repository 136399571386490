const soilTypeColors = {
  sand: '#e2dfbc',
  'loamy-sand': '#c1ba91',
  'sandy-loam': '#cfca9b',
  loam: '#C9C1A4',
  'sandy-clay-loam': '#D5BD96',
  'sandy-clay': '#DDD7C1',
  'clay-loam': '#C5A48D',
  clay: '#F7C095',
  'silty-clay': '#CEAE94',
  'silty-clay-loam': '#BEAEA7',
  'silt-loam': '#E9E3D5',
  silt: '#A6A8A7',
  'fine-sandy-loam': '#bbbaaa',
  unknown: '#999',
};

export function getSoilTypeColor(soilType) {
  if (!soilType) {
    return soilTypeColors.unknown;
  }
  return soilTypeColors[soilType] || soilTypeColors.unknown;
}

export function getSoilTypeName(soilType) {
  if (!soilType) {
    return 'unknown';
  }
  return soilType.replace('-', ' ');
}
