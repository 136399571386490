import { useEffect, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import { notifyError, getSelectedDateRange } from '../components/map/components/utils';
import { getAvailableSatelliteData, getSatelliteDataByDataTypeAndDate } from './satelliteImageryHelper';

export const useRanchBlockPreference = (selectedRanchId, selectedBlockId, dispatchSatellite) => {
  useEffect(() => {
    // Set ranch/block preference to local reducer
    if (selectedBlockId?.payload?.length) {
      const [bId] = selectedBlockId.payload;
      dispatchSatellite({ type: 'setSelectedBlockId', payload: bId });
    } else {
      dispatchSatellite({ type: 'setShowMap3D', payload: false });
      dispatchSatellite({ type: 'setSelectedBlockId', payload: undefined });
    }
    if (selectedRanchId?.payload?.length) {
      const [rId] = selectedRanchId.payload;
      dispatchSatellite({ type: 'setSelectedRanchId', payload: rId });
    } else {
      dispatchSatellite({ type: 'setSelectedRanchId', payload: undefined });
    }
  }, [selectedRanchId, selectedBlockId, dispatchSatellite]);
};

export const useSatelliteImagery = (selectedRanchId,
  selectedBlockId, latestReload, dispatchSatellite) => {
  useEffect(() => {
    // get satellite imagery using local reducer
    let type;
    let id;

    if (selectedBlockId) {
      type = 'block';
      id = selectedBlockId;
    } else if (selectedRanchId) {
      type = 'ranch';
      id = selectedRanchId;
    }

    if (type && id) {
      (async () => {
        try {
          const data = await getAvailableSatelliteData(type, id);
          const dataObj = {};
          const dataArr = [];
          const uniqueDataTypes = {};
          data.forEach((d) => {
            const dateString = moment(d.date * 1000).format('YYYY-MM-DD');
            const momentDate = moment(dateString, 'YYYY-MM-DD');
            if (!dataObj[dateString]) {
              dataObj[dateString] = { dateString, momentDate };
              d.datatypes.forEach((dt) => {
                dataObj[dateString][dt] = d.date;
                uniqueDataTypes[dt] = dt;
              });
            } else {
              d.datatypes.forEach((dt) => {
                uniqueDataTypes[dt] = dt;
                if (!dataObj[dateString][dt]) {
                  dataObj[dateString][dt] = d.date;
                } else if (Number(dataObj[dateString][dt]) < Number(d.date)) {
                  dataObj[dateString][dt] = d.date;
                }
              });
            }
          });

          Object.keys(dataObj).forEach((k) => {
            dataArr.push(dataObj[k]);
          });

          const availableSatelliteTypes = Object.keys(uniqueDataTypes)
            .filter((dt) => dt.startsWith('satellite:'))
            .map((k) => k.split(':')[1]);

          const availableAerialTypes = Object.keys(uniqueDataTypes)
            .filter((dt) => dt.startsWith('aerial:'))
            .map((k) => k.split(':')[1]);

          const availableDataSources = [];
          if (availableSatelliteTypes.length) {
            availableDataSources.push('satellite');
          }

          if (availableAerialTypes.length) {
            availableDataSources.push('aerial');
          }

          dispatchSatellite({
            type: 'setSatelliteDates',
            payload: {
              type,
              id: type === 'ranch'
                ? selectedRanchId
                : selectedBlockId,
              datesObj: dataObj,
              datesArray: dataArr,
              uniqueDataTypes,
              availableSatelliteTypes,
              availableAerialTypes,
              availableDataSources,
            },
          });
        } catch (error) {
          notifyError(error.message);
        }
      })().catch();
    }
  }, [selectedRanchId, selectedBlockId, latestReload, dispatchSatellite]);
};

export const useSatelliteDataDatesAndTypes = (satelliteDates,
  selectedSatelliteDataType, selectedSatelliteDataSource, dispatchSatellite) => {
  const dataSourceRef = useRef(undefined);
  useEffect(() => {
    // prepare satellite data dates and types using local reducer
    dataSourceRef.current = selectedSatelliteDataSource;
    dispatchSatellite({ type: 'setSelectedSatelliteDatesFiltered', payload: undefined });
    if (satelliteDates) { // this is initial or there are no dates
      const selectedSatelliteDatesFilteredSkeleton = {
        id: satelliteDates.id,
        type: satelliteDates.type,
        datesObj: {},
        datesArray: [],
      };

      if (!satelliteDates.availableDataSources.length) {
        notifyError('No data sources for satellite imagery available');
        dispatchSatellite({ type: 'setSelectedSatelliteDatesFiltered', payload: undefined });
        return;
      }

      if (!satelliteDates.availableDataSources
        .includes(selectedSatelliteDataSource)) {
        dispatchSatellite({
          type: 'setSelectedSatelliteDataSource',
          payload: satelliteDates.availableDataSources[0],
        });
        return;
      }

      if (
        selectedSatelliteDataSource === 'satellite'
          && !satelliteDates.availableSatelliteTypes
            .includes(selectedSatelliteDataType)
      ) {
        if (!satelliteDates.availableSatelliteTypes.length) {
          notifyError('No available satellite imagery types');
          dispatchSatellite({ type: 'setSelectedSatelliteDatesFiltered', payload: undefined });
          return;
        }
        dispatchSatellite({
          type: 'setSelectedSatelliteDataType',
          payload: satelliteDates.availableSatelliteTypes[0],
        });
        return;
      }

      if (
        selectedSatelliteDataSource === 'aerial'
          && !satelliteDates.availableAerialTypes
            .includes(selectedSatelliteDataType)
      ) {
        if (!satelliteDates.availableAerialTypes.length) {
          notifyError('No available satellite imagery types');
          dispatchSatellite({ type: 'setSelectedSatelliteDatesFiltered', payload: undefined });
          return;
        }
        dispatchSatellite({
          type: 'setSelectedSatelliteDataType',
          payload: satelliteDates.availableAerialTypes[0],
        });
        return;
      }

      const selectedSatelliteDatesFiltered = satelliteDates.datesArray
        .reduce((acc, curr) => {
          const dataSourceAndType = `${selectedSatelliteDataSource}:${selectedSatelliteDataType}`;
          if (!curr[dataSourceAndType]) {
            return acc;
          }

          const dateObj = {};
          dateObj[dataSourceAndType] = curr[dataSourceAndType];
          dateObj.time = curr[dataSourceAndType];
          dateObj.dateString = curr.dateString;
          dateObj.momentDate = curr.momentDate;

          dateObj.dataSource = selectedSatelliteDataSource;
          dateObj.dataType = selectedSatelliteDataType;

          dateObj.id = satelliteDates.id;
          dateObj.type = satelliteDates.type;

          acc.datesArray.push(cloneDeep(dateObj));
          acc.datesObj[curr.dateString] = cloneDeep(dateObj);
          return acc;
        }, selectedSatelliteDatesFilteredSkeleton);

      if (!selectedSatelliteDatesFiltered.datesArray.length) {
        dispatchSatellite({ type: 'setSelectedSatelliteDatesFiltered', payload: undefined });

        return;
      }

      dispatchSatellite({
        type: 'setSelectedSatelliteDatesFiltered',
        payload: selectedSatelliteDatesFiltered,
      });
    }
  }, [satelliteDates, selectedSatelliteDataType, selectedSatelliteDataSource, dispatchSatellite]);
};

export const useSatelliteDateRangePreparation = (selectedSatelliteDatesFiltered,
  selectedSatelliteDate, selectedSatelliteDatesDataRange, selectedSatelliteDataType,
  selectedSatelliteDataSource, ranchId, blockId, dispatchSatellite) => {
  const dateRange = selectedSatelliteDatesDataRange
    ? selectedSatelliteDatesDataRange.map((d) => d.dateString) : [];
  const dataSource = selectedSatelliteDatesDataRange
    ? selectedSatelliteDatesDataRange.map((d) => d.dataSource) : [];
  const dataType = selectedSatelliteDatesDataRange
    ? selectedSatelliteDatesDataRange.map((d) => d.dataType) : [];

  const updateDateRangeWithData = ((!dateRange.includes(moment(selectedSatelliteDate)
    .format('YYYY-MM-DD')) || !dataSource.includes(selectedSatelliteDataSource)
    || !dataType.includes(selectedSatelliteDataType))
    || ((selectedSatelliteDatesDataRange && selectedSatelliteDatesDataRange[0].id !== blockId)
    && (selectedSatelliteDatesDataRange && selectedSatelliteDatesDataRange[0].id !== ranchId)));

  useEffect(() => {
    if (!selectedSatelliteDatesFiltered) {
      return;
    }

    if (updateDateRangeWithData) {
      const idxMax = selectedSatelliteDatesFiltered.datesArray.length - 1;
      if (!selectedSatelliteDate) {
        const selectedSatelliteDateLocal = selectedSatelliteDatesFiltered
          .datesArray[idxMax].momentDate;
        dispatchSatellite({
          type: 'setSelectedSatelliteDate',
          payload: selectedSatelliteDateLocal,
        });

        return;
      }

      let idxSelected = -1;

      for (let i = idxMax; i > -1; i -= 1) {
        const selectedDateString = selectedSatelliteDate.format('YYYY-MM-DD');
        const currDateString = selectedSatelliteDatesFiltered.datesArray[i].dateString;
        if (selectedDateString === currDateString) {
          idxSelected = i;

          break;
        }
      }

      if (idxSelected < 0) {
        const selectedSatelliteDateLocal = selectedSatelliteDatesFiltered
          .datesArray[idxMax].momentDate;
        dispatchSatellite({
          type: 'setSelectedSatelliteDate',
          payload: selectedSatelliteDateLocal,
        });

        return;
      }

      const selectedSatelliteDatesDataRangeLocal = getSelectedDateRange(
        selectedSatelliteDatesFiltered.datesArray,
        idxSelected,
      );

      dispatchSatellite({
        type: 'setSelectedSatelliteDatesDataRange',
        payload: selectedSatelliteDatesDataRangeLocal,
      });
    }
  }, [updateDateRangeWithData, dispatchSatellite, selectedSatelliteDate,
    selectedSatelliteDatesFiltered]);
};

export const useDefaultSelectedImageryData = (dateRangeWithDisplayData, selectedImageryData,
  selectedSatelliteDate, dispatchSatellite) => {
  useEffect(() => {
    const displayData = dateRangeWithDisplayData;
    const dateString = moment(selectedSatelliteDate).format('YYYY-MM-DD');

    const imageryData = selectedImageryData;
    if (displayData && displayData.length) {
      const filteredData = displayData.filter((d) => d && d.dateString === dateString);
      if (filteredData.length && !isEqual(filteredData[0].data, imageryData.data)
             && imageryData.visible) {
        dispatchSatellite({
          type: 'setSelectedImageryData',
          payload: {
            visible: true,
            data: filteredData[0].data,
          },
        });
      }
    }
  }, [dateRangeWithDisplayData, dispatchSatellite, selectedImageryData, selectedSatelliteDate]);
};

// Time conversion logic to render imagery data
function unixOffset(date) {
  let result;
  if (date) {
    // 1000: Number to add milliseconds to Epoch by multiplying Epoch with 1000
    const momentDate = moment(Number(date) * 1000);
    result = momentDate.unix() + (momentDate.utcOffset() * 60);
  }

  return result;
}

export const useRequestSatelliteData = (selectedSatelliteDatesDataRange, dispatchSatellite) => {
  useEffect(() => {
    // request satellite data
    if (!selectedSatelliteDatesDataRange) {
      dispatchSatellite({ type: 'setDateRangeWithDisplayData', payload: undefined });

      return;
    }

    if (!selectedSatelliteDatesDataRange.length) {
      dispatchSatellite({ type: 'setDateRangeWithDisplayData', payload: undefined });

      return;
    }

    const dateRangeWithDisplayData = cloneDeep(selectedSatelliteDatesDataRange);
    const satelliteRequests = [];
    const satelliteRequestDates = [];
    selectedSatelliteDatesDataRange.forEach((dr) => {
      const {
        id,
        type,
        dataType,
        time,
      } = dr;
      satelliteRequestDates.push(unixOffset(time));
      satelliteRequests.push(getSatelliteDataByDataTypeAndDate(type, id, dataType, time));
    });

    Promise.allSettled(satelliteRequests)
      .then((results) => {
        results.forEach((r, index) => {
          if (r.status === 'fulfilled') {
            if (r?.value?.data?.length) {
              const dateString = moment(Number(satelliteRequestDates[index]) * 1000)
                .format('YYYY-MM-DD');
              for (let i = 0; i < dateRangeWithDisplayData.length; i += 1) {
                if (dateRangeWithDisplayData[i].dateString === dateString) {
                  dateRangeWithDisplayData[i].data = r.value.data;

                  break;
                }
              }
            }
          }
        });

        dispatchSatellite({
          type: 'setDateRangeWithDisplayData',
          payload: dateRangeWithDisplayData,
        });
      });
  }, [dispatchSatellite, selectedSatelliteDatesDataRange]);
};
