import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import {
  BlockControlList,
  PumpControlList,
  ControlSystemStatus,
  ValveControlList,
} from 'farmx-web-ui';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import './IrrigationControlSummary.css';

function IrrigationControlSummary(props) {
  const { ranchId, onClick } = props;
  const {
    selectSensorsForRanchId,
    selectBlocksForRanchId,
    selectLoadingSensors,
    selectBlocksLoadingState,
  } = selectors;
  const {
    loadAllSensors,
    setBlocks,
  } = actions;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllSensors());
  }, [dispatch, loadAllSensors]);

  const blocks = useSelector((state) => selectBlocksForRanchId(state, ranchId));
  const allPumps = useSelector((state) => selectSensorsForRanchId(state, ranchId, 'vfd'));
  const allValves = useSelector((state) => selectSensorsForRanchId(state, ranchId, 'valve'));
  const loadingSensors = useSelector((state) => selectLoadingSensors(state));
  const loadingBlocks = useSelector((state) => selectBlocksLoadingState(state));

  const pumps = allPumps.filter((d) => d.install_state
    && d.install_state.toLowerCase() === 'installed');

  // Filter valve sensors by visible=True sate
  const valveData = allValves.filter((d) => d.visible);

  const onClickPumpCalendar = (pump) => {
    // Get blocks for the pump
    if (blocks) {
      const blocksForPump = blocks.filter((d) => d.vfd
        && d.vfd.identifier === pump.identifier);
      const link = `/schedule?blockId=${blocksForPump.map((d) => d.id)}`
        + '&irrigationTypes=scheduled,recommended,past'
        + `&startDate=${moment().startOf('week').format('YYYY-MM-DDTHH:mm:ss')}`
        + `&endDate=${moment().endOf('week').format('YYYY-MM-DDTHH:mm:ss')}`;
      props.history.push(link, { showBack: true });
      onClick();
      dispatch(setBlocks(blocksForPump.map((d) => d.id)));
    }
  };

  const onClickBlockCalendar = (block) => {
    const link = `/schedule?blockId=${block.id}&irrigationTypes=scheduled,recommended,past`
      + `&startDate=${moment().startOf('week').format('YYYY-MM-DDTHH:mm:ss')}`
      + `&endDate=${moment().endOf('week').format('YYYY-MM-DDTHH:mm:ss')}`;
    props.history.push(link, { showBack: true });
    onClick();
    dispatch(setBlocks([block.id]));
  };

  function renderPumpControlList() {
    if (!pumps || !pumps.length) return null;
    return (
      <PumpControlList
        pumps={pumps}
        onClickCalendar={onClickPumpCalendar}
        pumpLoading={pumps.length ? false : loadingSensors.loading}
      />
    );
  }

  return (
    <div className="inner-container">
      <ControlSystemStatus ranchId={ranchId} />
      {renderPumpControlList()}
      <ValveControlList
        valves={valveData}
        blocks={blocks}
        onClickCalendar={onClickBlockCalendar}
        valveLoading={valveData.length ? false : loadingSensors.loading}
      />
      <BlockControlList
        blocks={blocks}
        valves={valveData}
        onClickCalendar={onClickBlockCalendar}
        blockLoading={blocks.length ? false : loadingBlocks.loading}
      />
    </div>
  );
}

IrrigationControlSummary.propTypes = {
  history: PropTypes.shape(PropTypes.any.isRequired),
  ranchId: PropTypes.number,
  onClick: PropTypes.func,
};

IrrigationControlSummary.defaultProps = {
  history: null,
  ranchId: null,
  onClick: () => null,
};

export default withRouter(IrrigationControlSummary);
