import React from 'react';
import PropTypes from 'prop-types';
import { SensorDetailBody } from '../map/SensorDetailBody';
import SensorDetailHeader from './SensorDetailHeader';

const SensorDetail = (props) => {
  const {
    type, identifier, location, blockId,
  } = props;

  return (
    <div className="sensor-detail">
      <SensorDetailHeader
        type={type}
        location={location}
        blockId={blockId}
        identifier={identifier}
      />
      <SensorDetailBody sensorProperties={{ type, identifier, blockId }} />
    </div>
  );
};

SensorDetail.defaultProps = {
  type: '',
  identifier: '',
  location: '',
};

SensorDetail.propTypes = {
  blockId: PropTypes.number.isRequired,
  type: PropTypes.string,
  identifier: PropTypes.string,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default SensorDetail;
