import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors, hooks } from 'farmx-redux-core';
import { Link } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { PressureSensorSummary } from './ListCards/PressureSensorSummary';
import { RanchBlockSelect } from './RanchBlockSelect';
import { PressureSensorItem } from '../control/ControlValves/PressureSensorItem';
import { installState } from '../../../constants';

const {
  selectAllSensorsForBlockIdsByType,
} = selectors;

export function PressureSensorList() {
  const { t } = useTranslation();
  const [selectedObj, setSelectedObj] = useState({});
  const { blockIds } = hooks.useRanchBlockSelection();

  const sensors = useSelector((state) => selectAllSensorsForBlockIdsByType(state, {
    blockIds,
    installState: installState.INSTALLED,
  }));
  const list = sensors.water_pressure || [];

  return (
    <div className="div-default padding-10 page-content">
      <div className="desktop-container">
        <PageHeader
          showBack
          title={t('Pressure Sensors')}
          toPath="/list"
        />
        <RanchBlockSelect
          selectedObj={selectedObj}
          setSelectedObj={setSelectedObj}
        />
        <div className="list-container">
          <PressureSensorSummary
            list={list}
          />

          {list.map((ps) => (
            <Link key={ps.identifier} to={`/list/pressure-sensor/${ps.type}/${ps.identifier}`}>
              <PressureSensorItem
                key={ps.id}
                sensorDetail={ps}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
