import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { selectors, actions } from 'farmx-redux-core';
import { PageHeader } from '../components/PageHeader';
import { CardLayout } from '../components/CardLayout';
import BlockList from './BlockList';
import './farmhand.less';

const {
  selectBlocksForRanchIdAlphabetically,
  selectRanchById,
  selectBlocksLoadingState,
} = selectors;

const {
  loadFarmData,
} = actions;

const BlockSelection = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { match } = props;
  const { id } = match.params;

  const blocks = useSelector((state) => selectBlocksForRanchIdAlphabetically(state, Number(id)));
  const ranch = useSelector((state) => selectRanchById(state, Number(id)));
  const { loading } = useSelector(selectBlocksLoadingState);
  const ranchName = ranch?.name || '';
  const title = useMemo(() => `${ranchName} > ${t('Select a Block')}`, [ranchName, t]);

  useEffect(() => {
    dispatch(loadFarmData(true));
  }, [dispatch]);

  return (
    <div className="div-default farmhand-content padding-10">
      <PageHeader
        showBack
        title={title}
        toPath="/farmhand/ranches/"
      />
      <CardLayout>
        {loading ? (
          <Typography.Title level={3}><LoadingOutlined /></Typography.Title>
        ) : (
          <BlockList list={blocks} ranchId={id} />
        )}
      </CardLayout>
    </div>
  );
};

BlockSelection.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

BlockSelection.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};

export default BlockSelection;
