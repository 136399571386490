import React from 'react';
import PropTypes from 'prop-types';
import ScheduleForm from './ScheduleForm';

const ScheduleCreateForm = ({
  onSubmit,
  onChange,
  dateRange,
  blocks,
}) => (
  <ScheduleForm
    onChange={onChange}
    onSubmit={onSubmit}
    dateRange={dateRange}
    blocks={blocks}
  />
);

ScheduleCreateForm.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  dateRange: PropTypes.arrayOf(PropTypes.any),
  blocks: PropTypes.arrayOf(PropTypes.any),
};

ScheduleCreateForm.defaultProps = {
  onChange: () => null,
  onSubmit: () => null,
  dateRange: null,
  blocks: null,
};

export default ScheduleCreateForm;
