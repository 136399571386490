import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors, hooks } from 'farmx-redux-core';
import {
  Checkbox,
  DatePicker,
  Radio, Button,
  PageHeader,
  Tag,
  Modal,
  Divider,
  Alert,
} from 'antd';
import {
  ReloadOutlined,
  ArrowRightOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BlockSelect } from 'farmx-web-ui';
import IrrigationSchedule from './IrrigationSchedule';
import ScheduleCreateForm from './ScheduleCreateForm';
import ScheduleUpdateForm from './ScheduleUpdateForm';
import './mediaQuery.css';
import './styles.css';

const {
  setSelectedBlocks,
  setBlocks,
  setUrlLoad,
  loadBlockSchedule,
  loadBlockControlStatus,
} = actions;

const {
  selectSelectedBlocks,
} = selectors;

const {
  useSetURLFromState,
} = hooks;

function IrrigationSchedulePage() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const blocksFromState = useSelector((state) => selectSelectedBlocks(state));
  const { state, search, pathname } = location;
  const { t } = useTranslation();
  const refresh = useRef(false); // To trigger the schedule api calls in IrrigationSchedule
  const blocks = blocksFromState.payload;
  const urlSearch = useRef(search);
  const mobileScreen = window.innerWidth < 599;
  const color = ['BlueViolet', 'brown', 'chocolate', 'cornflowerblue', 'darkblue', 'seagreen',
    'skyblue', 'peru', 'olive', 'lightslategrey', 'darkslategrey', 'darkcyan', 'darkgreen',
    'darkmagenta', 'goldenrod'];
  const refreshClearBlkSchInterval = useRef(null);

  const cObj = {};
  if (blocks) {
    for (let i = 0; i < blocks.length; i += 1) {
      if (color[i]) {
        cObj[blocks[i]] = color[i];
      }
    }
  }

  function getDateRangeFromUrl() {
    const params = new URLSearchParams(urlSearch.current);
    const startDateParam = params.get('startDate');
    const endDateParam = params.get('endDate');
    if (startDateParam && endDateParam) {
      return [moment(startDateParam), moment(endDateParam)];
    }
    return undefined;
  }

  function getIrrigationTypesFromUrl() {
    const params = new URLSearchParams(urlSearch.current);
    const irrigationTypesParam = params.get('irrigationTypes');
    const irrigationParams = irrigationTypesParam ? irrigationTypesParam.split(',') : undefined;
    if (irrigationParams) return irrigationParams;
    return undefined;
  }

  function getRadioOptionFromUrl() {
    const params = new URLSearchParams(urlSearch.current);
    return params.get('radioOption');
  }

  const now = moment();
  const defaultStateValue = [
    moment(now.startOf('week').format('YYYY-MM-DD')),
    moment(now.endOf('week').format('YYYY-MM-DD'))];
  // TODO: update this to by key, not display name
  const [radioOption, setRadioOption] = useState(() => getRadioOptionFromUrl() || 'week');
  const [irrigationTypes, setIrrigationTypes] = useState(() => getIrrigationTypesFromUrl()
    || ['scheduled']);
  const [dateRange, setDateRange] = useState(() => getDateRangeFromUrl() || defaultStateValue);
  const [modalData, setModalData] = useState(null);
  const changedObj = { irrigationTypes, radioOption, dateRange };
  const modifiedDateRange = dateRange.map((d, i) => (d && i > 0
    ? moment(d.format('YYYY-MM-DD 23:59:59')) : d));
  // Hook to update URL with state values by history.push()
  useSetURLFromState(urlSearch, search, pathname, changedObj);

  const filteredIrrigationTypes = irrigationTypes.filter((d) => d && d !== 'scheduled');
  useEffect(() => {
    async function loadData() {
      for (let blockIndex = 0; blockIndex < blocks.length; blockIndex += 1) {
        // Using old api for 'recommended' and 'past' irrigationTypes
        dispatch(loadBlockSchedule(blocks[blockIndex]));
        if (!mobileScreen) {
          dispatch(loadBlockControlStatus(blocks[blockIndex]));
        }
      }
    }

    if (blocks && blocks.length && filteredIrrigationTypes.length) {
      loadData();
    }
  }, [blocks, dispatch, filteredIrrigationTypes, mobileScreen]);

  const mobileBlockTagRender = (args, colObj) => {
    const {
      closable, onClose, label, value,
    } = args;
    return (
      <Tag
        closable={closable}
        onClose={onClose}
        color={mobileScreen ? colObj[value] : null}
        style={{ marginRight: 3 }}
      >
        {label.props ? label.props.block.name : label}
      </Tag>
    );
  };

  function showCreateSchedule({
    blockId,
    startDate,
    endDate,
    scheduleId,
    eventIndex,
  }) {
    // TODO: fix the magic number below
    if (window.innerWidth > 599) { // show modal only on laptop and desktop screens
      setModalData({
        blocks: blockId,
        dateRange: [moment(startDate), moment(endDate)],
        scheduleId,
        createNew: !scheduleId,
        eventIndex,
      });
    } else if (scheduleId) {
      const link = `/schedule/edit?blockId=${blockId}`
        + `&scheduleId=${scheduleId}`;
      history.push(link, { showBack: true, eventIndex });
    } else {
      const link = `/schedule/new?blockId=${blockId}`
        + `&startDate=${startDate}`;
      history.push(link, { showBack: true });
    }
  }

  function onClickChart(obj) {
    // Integration of dhtmlx scheduler timeline with mock data
    const { startDate } = obj;
    const endDate = moment(startDate).add(12, 'hours').format('YYYY-MM-DD HH:mm');
    return showCreateSchedule({
      blockId: obj.id || obj.blockId,
      startDate: moment(startDate).format('YYYY-MM-DD HH:mm'),
      endDate,
      scheduleId: obj.scheduleId,
      eventIndex: obj.eventIndex,
    });
  }

  function onClickCreateSchedule() {
    const startDate = moment().add(1, 'hours').format('YYYY-MM-DD HH:mm');
    const endDate = moment(startDate).add(12, 'hours').format('YYYY-MM-DD HH:mm');
    return showCreateSchedule({
      blockId: blocks.length && blocks[0],
      startDate,
      endDate,
      scheduleId: null,
    });
  }

  function renderDateRangeSelect() {
    if (!blocks || (blocks && !blocks.length)) return null;

    function handleOnClick(event) {
      const start = moment(dateRange[0].format());
      const end = moment(dateRange[1].format());
      const diff = moment(end).diff(moment(start), 'days');
      let dayCount = 0;

      if (!diff) {
        dayCount = 1;
      } else if (diff > 0 && diff <= 6) {
        dayCount = 7;
      } else {
        dayCount = 14;
      }

      if (event === 'previous' && radioOption !== 'month') {
        setDateRange([moment(start).subtract(dayCount, 'days'),
          moment(end).subtract(dayCount, 'days')]);
      } else if (event === 'next' && radioOption !== 'month') {
        setDateRange([moment(start).add(dayCount, 'days'),
          moment(end).add(dayCount, 'days')]);
      } else if (event === 'previous' && radioOption === 'month') {
        const startMonth = moment(dateRange[0]).subtract(1, 'months')
          .startOf('month').format('YYYY-MM-DD');
        const endMonth = moment(dateRange[0]).subtract(1, 'months')
          .endOf('month').format('YYYY-MM-DD');
        setDateRange([
          moment(startMonth),
          moment(endMonth)]);
      } else if (event === 'next' && radioOption === 'month') {
        const startMonth = moment(dateRange[0]).add(1, 'months')
          .startOf('month').format('YYYY-MM-DD');
        const endMonth = moment(dateRange[0]).add(1, 'months')
          .endOf('month').format('YYYY-MM-DD');
        setDateRange([
          moment(startMonth),
          moment(endMonth)]);
      }
    }

    function handleDateRange(d) {
      if (d) {
        if (moment(d[0]).isSame(d[1])) {
          const selectedDates = d.map((e, i) => (i > 0 ? e.add(23, 'hours') : e));
          setDateRange(selectedDates);
        } else setDateRange(d);
      } else {
        setRadioOption('week');
        setDateRange(defaultStateValue);
      }
    }

    return (
      <div className="schedule-date-picker">
        <span style={{ marginRight: '2vw' }}>
          <Button
            className="previous-date-button"
            onClick={() => handleOnClick('previous')}
          >
            <ArrowLeftOutlined />
            <span className="previous-date-icon">Previous</span>
          </Button>
        </span>
        <DatePicker.RangePicker
          onChange={handleDateRange}
          value={dateRange}
          format="YYYY-MM-DD"
          defaultValue={dateRange}
          onOk={null}
          style={{ width: '100%' }}
        />
        <span style={{ marginLeft: '2vw' }}>
          <Button
            className="next-date-button"
            onClick={() => handleOnClick('next')}
          >
            <span className="next-date-icon">Next</span>
            <ArrowRightOutlined />
          </Button>
        </span>
      </div>
    );
  }

  function renderRadioButton() {
    if (!blocks || (blocks && !blocks.length)) return null;
    function radioOnChange(event) {
      setRadioOption(event.target.value);
      if (event.target.value === 'today') {
        setDateRange([
          moment().startOf('day'),
          moment().endOf('day')]);
      } else if (event.target.value === 'week') {
        setDateRange([
          moment().startOf('week'),
          moment().endOf('week')]);
      } else if (event.target.value === 'two-week') {
        setDateRange([
          moment().startOf('week'),
          moment().endOf('week').add(1, 'weeks')]);
      } else if (event.target.value === 'month') {
        setDateRange([
          moment().startOf('month'),
          moment().endOf('month')]);
      }
    }

    return (
      <Radio.Group
        onChange={radioOnChange}
        defaultValue="week"
        value={radioOption}
      >
        <Radio.Button value="today">{t('Today')}</Radio.Button>
        <Radio.Button value="week">{t('This Week')}</Radio.Button>
        <Radio.Button value="two-week">{t('Two Weeks')}</Radio.Button>
        <Radio.Button value="month">{t('Month')}</Radio.Button>
      </Radio.Group>
    );
  }

  function onCheckboxChecked(d) {
    let valueToset;
    if (irrigationTypes.indexOf(d.target.value) !== -1) {
      valueToset = irrigationTypes.filter((e) => e !== d.target.value);
    } else {
      valueToset = irrigationTypes.concat(d.target.value);
    }
    setIrrigationTypes(valueToset.sort());
  }

  function renderIrrigationTypeSelect() {
    if (!blocks || (blocks && !blocks.length)) return null;
    return (
      <section className="checkbox">
        <span className="left-half">
          <Checkbox
            value="recommended"
            onChange={(d) => onCheckboxChecked(d)}
            checked={irrigationTypes ? irrigationTypes.includes('recommended') : false}
          >
            <Tag color="rgb(193, 218, 214)" style={{ cursor: 'pointer' }}>{t('Recommended')}</Tag>
          </Checkbox>
          <Checkbox
            value="scheduled"
            onChange={(d) => onCheckboxChecked(d)}
            checked={irrigationTypes ? irrigationTypes.includes('scheduled') : false}
          >
            <Tag color="lightblue" style={{ cursor: 'pointer' }}>{t('Scheduled')}</Tag>
          </Checkbox>
          <Checkbox
            value="past"
            onChange={(d) => onCheckboxChecked(d)}
            checked={irrigationTypes ? irrigationTypes.includes('past') : false}
          >
            <Tag color="rgb(232, 208, 169)" style={{ cursor: 'pointer' }}>{t('Past')}</Tag>
          </Checkbox>
        </span>
        <div className="right-half">
          {renderRadioButton()}
        </div>
      </section>
    );
  }

  function getHeaderExtra() {
    if (!blocks || (blocks && !blocks.length)) return [];
    return [
      <Button
        key={0}
        onClick={() => onClickCreateSchedule()}
        type="primary"
      >
        <span className="header-button-text">{t('Create')}</span>
        <PlusOutlined />
      </Button>,
      <Button
        key={1}
        onClick={() => {
          dispatch(setSelectedBlocks([...blocks].sort()));
          refresh.current = true;
        }}
      >
        <span className="header-button-text">{t('Refresh')}</span>
        <ReloadOutlined />
      </Button>,
    ];
  }

  // Set interval to clear blockSchedule for every minute
  // Clear the interval when component unmounts
  useEffect(() => {
    if (blocks && blocks.length) {
      refreshClearBlkSchInterval.current = setInterval(() => {
        dispatch(setSelectedBlocks([...blocks]));
        refresh.current = true;
      }, 60000);
    }
    return () => {
      if (refreshClearBlkSchInterval.current) {
        clearInterval(refreshClearBlkSchInterval.current);
        refreshClearBlkSchInterval.current = null;
      }
    };
  }, [blocks, dispatch]);

  return (
    <div className="single-column-page scheduler" id="schedule-page-mobile">
      <div className="padded-margin">
        <PageHeader
          title={t('Irrigation Schedule')}
          onBack={() => history.goBack()}
          backIcon={
            state && state.showBack
              ? (
                <Button className="back-schedule-button">
                  <ArrowLeftOutlined />
                  <span className="back-schedule-text">{t('Go Back')}</span>
                </Button>
              ) : null
          }
          extra={getHeaderExtra()}
        />
        {mobileScreen ? null : (
          <a href="https://app.farmx.co/home/schedule">
            <Alert
              message="This is the new schedule page. Click here to view the old schedule page"
            />
          </a>
        )}

        <Divider />
        {mobileScreen ? null : (
          <div style={{ fontWeight: '500', paddingLeft: '1px' }}>
            {`${t('Irrigation Blocks')}:`}
          </div>
        )}
        <BlockSelect
          placeholder={t('Select Blocks To Irrigate')}
          mode="multiple"
          onChange={(d) => {
            dispatch(setUrlLoad(false));
            dispatch(setBlocks(d));
            refresh.current = true;
          }}
          value={blocks}
          tagRender={(d) => mobileBlockTagRender(d, cObj)}
        />
        {mobileScreen ? null
          : (
            <div>
              <Divider dashed />
              {renderDateRangeSelect()}
              {renderIrrigationTypeSelect()}
            </div>
          )}

      </div>
      {(blocks && blocks.length > 0)
        ? (
          <div className="scheduler-outer">
            <div className="scheduler-inner">
              <IrrigationSchedule
                blocks={blocks}
                dates={modifiedDateRange}
                onClickChart={onClickChart}
                irrigationTypes={mobileScreen ? ['scheduled'] : irrigationTypes}
                colorObj={mobileScreen ? cObj : {}}
                mobileDevice={mobileScreen}
                callApi={refresh.current}
                onDataRetrieval={() => {
                  refresh.current = false;
                }}
              />
            </div>
          </div>
        ) : null }
      {modalData ? (
        <Modal
          title={t(`${modalData.createNew ? 'New' : 'Modify'} Irrigation Schedule`)}
          centered
          visible={!!modalData}
          closable
          onCancel={() => {
            refresh.current = true;
            setModalData(null);
            dispatch(setSelectedBlocks([...blocks]));
          }}
          maskClosable={false}
          destroyOnClose
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
          {modalData.createNew
            ? (
              <ScheduleCreateForm
                blocks={[modalData.blocks]}
                dateRange={modalData.dateRange}
                onSubmit={(d) => {
                  if (d.status && d.status === 'Created') {
                    refresh.current = true;
                    setModalData(null);
                    dispatch(setSelectedBlocks([...blocks]));
                  }
                }}
              />
            ) : (
              <ScheduleUpdateForm
                onCancel={() => {
                  setModalData(null);
                }}
                blockId={modalData.blocks}
                scheduleId={modalData.scheduleId}
                eventIndex={modalData.eventIndex}
                onSubmit={(d) => {
                  if (d.status && d.status === 'Created') {
                    refresh.current = true;
                    setModalData(null);
                    dispatch(setSelectedBlocks([...blocks]));
                  }
                }}
              />
            )}
        </Modal>
      ) : null}
    </div>
  );
}

export default IrrigationSchedulePage;
