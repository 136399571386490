import { scheduleApi } from 'farmx-api';
import moment from 'moment';
import { selectors, hooks } from 'farmx-redux-core';
import { getColorCodeForIrrigationState } from 'farmx-web-ui';
import {
  colorCritical,
  colorError,
  colorWarning,
  colorOk,
  colorOver,
  colorUnknown,
} from '../utils/colors';

const {
  selectAllBlocks,
  selectBlockControlStatus,
  selectBlockRenderedSchedules,
  selectAllBlockSchedule,
  selectBlockById,
} = selectors;

const {
  updateBlockScheduledIrrigationEventEdit,
  createBlockScheduledIrrigationEventEdit,
  patchScheduledIrrigationEvent,
  createScheduledIrrigationEvent,
  updateScheduledIrrigationEvent,
  deleteScheduledIrrigationEvent,
} = scheduleApi;

export const updateBlockScheduledEventEdit = (eventEdit, callBack) => {
  updateBlockScheduledIrrigationEventEdit(eventEdit)
    .then(() => callBack(true)).catch(() => callBack(false));
};

export const createBlockScheduledEventEdit = (eventEdit, callBack) => {
  createBlockScheduledIrrigationEventEdit(eventEdit)
    .then(() => callBack(true)).catch(() => callBack(false));
};

export const patchScheduledEvent = (rootEvent, callBack) => {
  patchScheduledIrrigationEvent(rootEvent)
    .then(() => callBack(true)).catch(() => callBack(false));
};

export const createScheduledEvent = (updatedEvent, callBack) => {
  createScheduledIrrigationEvent(updatedEvent)
    .then(() => callBack(true)).catch(() => callBack(false));
};

export const updateScheduledEvent = (updatedEvent, callBack) => {
  updateScheduledIrrigationEvent(updatedEvent)
    .then(() => callBack(true)).catch(() => callBack(false));
};

export const deleteScheduledEvent = (scheduledEventId, callBack) => {
  deleteScheduledIrrigationEvent(scheduledEventId)
    .then(() => callBack(true)).catch(() => callBack(false));
};

// This function will handle the single recurrence edit and delete logic
export const handleSingleRecurrence = (scheduledEvent, originalEvent,
  blockIds, createScheduleForBlocks, type, callBack) => {
  // Create an event edit at current index
  const eventEdit = {
    ...scheduledEvent,
    event: scheduledEvent.id,
  };

  if (type === 'edit') eventEdit.event_index = originalEvent.event_index;
  if (type === 'delete') eventEdit.is_deleted = true;

  if (blockIds && blockIds.length && createScheduleForBlocks) {
    createScheduleForBlocks(
      {
        ...scheduledEvent,
        is_recurring: false,
      },
      blockIds,
    );
  }

  if (scheduledEvent.edit_id) {
    eventEdit.id = scheduledEvent.edit_id;
    updateBlockScheduledIrrigationEventEdit(eventEdit)
      .then(() => callBack(true)).catch(() => callBack(false));
  } else {
    createBlockScheduledIrrigationEventEdit(eventEdit)
      .then(() => callBack(true)).catch(() => callBack(false));
  }
};

/**
 * @param {*} applicationRate - from block object
 * @param {*} hours - from the irrigation dial
 * @returns the gallons value by multiplying applicationRate with hours
 */
export const getGallonsValue = (applicationRate, hours) => (
  Math.round(Number(applicationRate) * Number(hours)));

export const selectSchedulerTimelineDataRenderedForDesktop = (
  state, dates, blockIds, irrigationTypes, mobileScreen, colorObj,
) => {
  const chartData = [];
  const sections = [];
  const jsonKeys = {
    recommended: 'recommended_events',
    past: 'past_events',
    scheduled: 'scheduled_events',
  };

  const irrIds = {
    recommended: 1,
    past: 2,
    scheduled: 3,
  };

  const colors = {
    recommended: 'rgb(193, 218, 214)',
    past: 'rgb(232, 208, 169)',
    scheduled: 'lightblue',
  };

  const dummyStartDate = moment(moment(dates[1]).format('YYYY-MM-DD 23:59:57')).toISOString();
  const dummayEndDate = moment(moment(dates[1]).format('YYYY-MM-DD 23:59:58')).toISOString();

  const dateRange = dates[1].diff(dates[0], 'day') === 0
    ? [moment(dates[0]).subtract('1', 'day'), moment(dates[1]).add('1', 'day')]
    : [moment(dates[0]).subtract('1', 'week'), moment(dates[1]).add('1', 'week')];

  const allBlockScheduleData = selectAllBlockSchedule(state);
  const oldBlockScheduleData = allBlockScheduleData.filter(
    (bSch) => bSch && blockIds.some((d) => d === bSch.id),
  );

  const blockScheduleData = selectBlockRenderedSchedules(
    state,
    { blockIds, dateStart: dates[0], dateEnd: dates[1] },
  );
  const blockControlStatus = selectBlockControlStatus(state, blockIds);

  //= =============== DATA Preparation ==================================
  function getAlarmListUrl(blockId) {
    const params = {
      blockId,
      logLevel: 'error',
      active: true,
    };
    const paramStr = new URLSearchParams(params).toString();
    return `/events?${paramStr}`;
  }

  function getControlPageUrl(blockId) {
    const params = {
      blockId,
    };
    const paramStr = new URLSearchParams(params).toString();
    return `/control?${paramStr}`;
  }

  function renderOverrideNotice(block) {
    if (!block.overrideActive) return null;
    return (`
      <a
        class="overrideAlert"
        href=${getControlPageUrl(block.blockId)}
      >
        Override Active
      </a>`
    );
  }

  const blocks = selectAllBlocks(state);
  const filteredBlocks = blocks.filter((d) => blockIds.some((id) => id === d.id));
  const blockNames = filteredBlocks.reduce((a, block) => {
    const x = a;
    x[block.id] = block.name;
    return x;
  }, {});

  const abbrDuration = (duration) => {
    const durationHr = duration.asHours();
    if (durationHr % 1 !== 0) return `${durationHr.toFixed(1)} hrs`;
    return `${durationHr.toFixed()} hrs`;
  };

  const abbrPeriod = (data) => {
    const duration = moment.duration(moment(data[1]).diff(moment(data[0])));
    return abbrDuration(duration);
  };


  function addColorToBlocks(blockSch, irrgType) {
    return mobileScreen ? colorObj[blockSch.blockId] : colors[irrgType];
  }

  function getSections(blockId) {
    const pumpOverride = blockControlStatus
    && blockControlStatus[blockId].vfdStatus
    && blockControlStatus[blockId].vfdStatus.overrideStatus;

    const valveOverride = blockControlStatus
    && blockControlStatus[blockId].valveStatus
    && blockControlStatus[blockId].valveStatus.overrideStatus;

    const overrideActive = !!(valveOverride || pumpOverride);

    const obj = {
      name: blockNames[blockId],
      blockId,
      overrideActive,
      alarmCount: blockControlStatus
      && blockControlStatus[blockId].valveStatus
      && blockControlStatus[blockId].valveStatus.blockAlarmCount,
    };

    sections.push({
      key: blockId,
      label:
      `<div class="schedule-timeline-container">
        <div class="sub-container"><div class="name">${blockNames[blockId]}</div>
        ${obj.overrideActive ? `<div class="active-name">${renderOverrideNotice(obj)}</div>` : ''}
      </div>
      <div class="image">
        <a href="${getAlarmListUrl(blockId)}" class="notification">
            <span class="svg-container">
              <button class="btn">
                <svg viewBox="64 64 896 896" id="bell_svg" focusable="false" class="" 
                data-icon="bell" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path d="M816 768h-24V428c0-141.1-104.3-257.8-240-277.2V112c0-22.1-17.9-40-40-40s
                -40 17.9-40 40v38.8C336.3 170.2 232 286.9 232 428v340h-24c-17.7 0-32 14.3-32 
                32v32c0 4.4 3.6 8 8 8h216c0 61.8 50.2 112 112 112s112-50.2 112-112h216c4.4 0 
                8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM512 888c-26.5 0-48-21.5-48-48h96c0 
                26.5-21.5 48-48 48z"></path></svg>
              </button>
            </span>
            ${obj.alarmCount ? `<span class="badge">${obj.alarmCount}</span>`
    : '<span class="nobadge"></span>'}
          </a>
      </div>
    </div>`,
    });
  }

  const filteredIrrTypes = irrigationTypes.filter((d) => d && d !== 'scheduled');
  const loading = oldBlockScheduleData.reduce((a, p) => !p || a || p.loading, false);
  const newLoading = blockScheduleData.reduce((a, p) => !p || a || p.loading, false);
  if (!loading) {
    blockScheduleData.forEach((blockSch) => {
      const irrgType = 'scheduled';
      if (blockSch && blockSch.events && irrigationTypes.includes(irrgType)) {
        blockSch.events.forEach((event) => {
          chartData.push({
            ...event,
            name: blockNames[event.block],
            id: event.uid,
            irrId: irrIds[irrgType],
            start_date: event.start_date,
            end_date: event.stop_date,
            text: abbrPeriod([event.start_date, event.stop_date]),
            type: jsonKeys[irrgType],
            color: addColorToBlocks(blockSch, irrgType),
            section_id: event.block,
          });
        });
      }
      if (!oldBlockScheduleData.length) getSections(blockSch.blockId);
    });
    oldBlockScheduleData.forEach((blockSch) => {
      Object.keys(jsonKeys).forEach((irrgType) => {
        if (blockSch[jsonKeys[irrgType]] && filteredIrrTypes.includes(irrgType)) {
          // Filter by selected dates
          let events = [];
          if (mobileScreen) {
            events = blockSch[jsonKeys[irrgType]];
          } else {
            events = blockSch[jsonKeys[irrgType]].filter((event) => (
              (moment(event.start_date).diff(dateRange[0]) >= 0
                && moment(event.stop_date).diff(dateRange[1]) <= 0))
              || (moment(event.start_date).isBetween(dateRange[0], dateRange[1])
                || moment(event.stop_date).isBetween(dateRange[0], dateRange[1])));
          }
          events.forEach((event) => {
            chartData.push({
              name: blockNames[blockSch.id],
              id: event.id,
              irrId: irrIds[irrgType],
              start_date: event.start_date,
              end_date: event.stop_date,
              text: abbrPeriod([event.start_date, event.stop_date]),
              type: jsonKeys[irrgType],
              color: addColorToBlocks(blockSch, irrgType),
              section_id: blockSch.id,
            });
          });
        }
      });
      getSections(blockSch.id);
    });
  }

  // Add dummy data when there is no schedule exist for the selected irrigtion types
  if (!chartData.length && irrigationTypes.length && blockIds.length) {
    const irrgType = 'scheduled';
    const events = [];
    chartData.push({
      name: blockNames[blockIds[0]],
      id: events.id,
      irrId: irrIds[irrgType],
      start_date: dummyStartDate,
      end_date: dummayEndDate,
      text: '',
      type: jsonKeys[irrgType],
      color: colors[irrgType],
      section_id: blockIds[0],
    });
  }

  return [chartData, sections, loading, newLoading];
};

/**
 * @param {*} value - from irrigation dial minute field
 * @param {*} step - minute step
 * @returns the nearby rounded minute of given value based on step count
 * Example 1: value = 128, step = 5
 * result: 128 - (3) + 5 = 130
 * Example 2: value = 127, step = 5
 * result: 127 - (2) + 5 = 125
 */
export function getClosestMinute(value, step) {
  if (value % step > step / 2) {
    return value - (value % step) + step;
  } return value - (value % step);
}

/**
 * @param {*} date - start date to get next and previous 42 days
 * @returns the date range array
 */
export function getDateRangeForScheduler(date) {
  // 6 weeks * 7 days = 42 days is the most shown on the calendar
  return [
    moment(date).startOf('month').subtract(42, 'days'),
    moment(date).endOf('month').add(42, 'days'),
  ];
}

export const selectFlowDetails = (state, blockId, dates = [], eventId) => {
  let actual = 0;
  let scheduled = 0;

  // To get the application rate
  const blockObj = selectBlockById(state, blockId);

  // Get the scheduled events from new API for Scheduled gallons calculations
  const newBlockScheduleData = selectBlockRenderedSchedules(
    state,
    { blockIds: [blockId], dateStart: dates[0], dateEnd: dates[1] },
  );

  // Get the old scheduled events for Actual gallons calculation
  const allBlockScheduleData = selectAllBlockSchedule(state);
  const blockScheduleData = allBlockScheduleData.filter(
    (bSch) => bSch && [blockId].some((d) => d === bSch.id),
  );

  let filteredRecurringEvents = [];
  let filteredNonRecurringEvents = [];
  newBlockScheduleData.forEach((sch) => {
    if (sch && sch.blockId === blockId && sch.events) {
      filteredRecurringEvents = sch.events.filter((d) => {
        // filter events based on the dateRange, eventId and isRucurring = true
        if ((moment(moment(d.start_date)).isBetween(dates[0], dates[1])
        || moment(moment(d.stop_date)).isBetween(dates[0], dates[1]))
        && d.is_recurring && d.id === eventId) {
          return d;
        }
        return false;
      });
      // Filter the non recurring scheduled events
      filteredNonRecurringEvents = sch.events.filter((d) => (d.id === eventId && !d.is_recurring));
    }
  });

  if (filteredNonRecurringEvents.length) {
    const duration = moment.duration(moment(filteredNonRecurringEvents[0].stop_date)
      .diff(filteredNonRecurringEvents[0].start_date));
    const hours = duration.asHours();
    // For non-recurring events: hours * application_rate
    scheduled = getGallonsValue(blockObj.application_rate, hours);
  }
  if (filteredRecurringEvents.length) {
    const duration = moment.duration(moment(filteredRecurringEvents[0].stop_date)
      .diff(filteredRecurringEvents[0].start_date));
    const hours = duration.asHours();
    // For recurring events: hours * application_rate * events count for one month
    scheduled = filteredRecurringEvents.length * getGallonsValue(blockObj.application_rate, hours);
  }

  // To calculate the Actual gallons from old API data
  if (blockScheduleData && blockScheduleData.length) {
    blockScheduleData.forEach((d) => {
      if (d && d.past_events) {
        d.past_events.forEach((sch) => {
          if (sch && sch.flow && sch.block === blockId) {
            actual += Number(sch.flow);
          }
        });
      }
    });
  }

  return { actual, scheduled };
};

/**
 * @param {*} data - array of objects that from API response
 * Functoion to convert JSON array to CSV format
 */
export function convertToCSV(data, headers) {
  let csv;
  if (data && data.length) {
    const json = data;
    let fields = [];
    if (json && json[0]) {
      fields = Object.keys(json[0]);
    }
    const replacer = (key, value) => (value === null ? '' : value);
    csv = json.map((row) => fields.map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(','));
    if (fields.length) {
      // add header column
      let csvHeaders = fields;
      if (headers) csvHeaders = fields.map((field) => headers[field] || field);
      csv.unshift(csvHeaders.join(','));
    }
    csv = csv.join('\r\n');
  }

  return csv;
}

export function showWarningCard(waterLimit, block) {
  if (block && block.maximum_daily_water && Number(block.maximum_daily_water)) {
    return waterLimit > Number(block.maximum_daily_water);
  }
  return false;
}

export function downloadCSVFile(jsonData, csvFileName) {
  const defaultName = `CSV-Data-${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`;
  const url = window.URL.createObjectURL(new Blob([jsonData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', csvFileName || defaultName);
  document.body.appendChild(link);
  link.click();
}

export function useIrrigationRatio(
  irrigationApplied,
  irrigationScheduled,
  etcHistoric,
  etcForecast,
  areaSquareMeters,
  units,
) {
  const getUserUnits = hooks.useUnits();

  if (
    etcHistoric === undefined
    || etcForecast === undefined
    || irrigationApplied === undefined
    || irrigationScheduled === undefined
    || areaSquareMeters === undefined
  ) return null;

  const etcHistoricMeters = etcHistoric / 1000;
  const etcHistoricCubicMeters = etcHistoricMeters * areaSquareMeters;

  const etcForecastMeters = etcForecast / 1000;
  const etcForecastCubicMeters = etcForecastMeters * areaSquareMeters;

  const { value: etcHistoricConverted } = getUserUnits(etcHistoricCubicMeters,
    'cubic_meters', 'volume');
  const { value: etcForecastConverted } = getUserUnits(etcForecastCubicMeters,
    'cubic_meters', 'volume');
  const irrigationAppliedConverted = Number(irrigationApplied);
  const irrigationScheduledConverted = irrigationScheduled;
  const etcFraction = (
    irrigationAppliedConverted + irrigationScheduledConverted
  ) / (etcHistoricConverted + etcForecastConverted);
  const etcPercent = etcFraction * 100;

  return etcPercent;
}

export function getIrrigationStateByRatio(irrigationRatio) {
  if (irrigationRatio > 1) return 'over_irrigated';
  if (irrigationRatio > 0.95) return 'well_irrigated';
  if (irrigationRatio > 0.75) return 'drying';
  if (irrigationRatio > 0.50) return 'dry';
  return 'very_dry';
}

export function getIrrigationDialColor(percent) {
  return getColorCodeForIrrigationState(getIrrigationStateByRatio(percent / 100));
}

export function useScheduledValveForIrrigationRatio(etcHistoric, etcForecast, irrigationApplied,
  applicationRate, maxHours, areaSquareMeters) {
  const getUserUnits = hooks.useUnits();

  // To convert the etcHistoric and etcForecast values from millimeters to cubicMeters
  const etcHistoricMeters = etcHistoric / 1000;
  const etcHistoricCubicMeters = etcHistoricMeters * areaSquareMeters;

  const etcForecastMeters = etcForecast / 1000;
  const etcForecastCubicMeters = etcForecastMeters * areaSquareMeters;
  // To convert the cubicMeter value to gallons
  const { value: etcHistoricConverted } = getUserUnits(etcHistoricCubicMeters,
    'cubic_meters', 'volume');
  const { value: etcForecastConverted } = getUserUnits(etcForecastCubicMeters,
    'cubic_meters', 'volume');

  const applied = irrigationApplied;

  const irrigationRatios = [0.5, 0.75, 0.95, 1];
  const scheduled = irrigationRatios.map((ratio) => (ratio
    * (etcHistoricConverted + etcForecastConverted) - Number(applied)));
  const hours = scheduled.map((d) => (d / Number(applicationRate)));
  const cutOffPoints = hours.map((d) => (d / maxHours));
  return cutOffPoints;
}

export function getIrrigationStateCutoffs(irrigationDuration, wiltingPointDuration,
  refillPointDuration, fieldCapacityDuration, irrigationModel, maxDurationHours, cutOffValues) {
  if (!irrigationDuration) {
    return null;
  }
  if (wiltingPointDuration && refillPointDuration && fieldCapacityDuration
    && irrigationModel !== 'etc_deficit') {
    return [
      wiltingPointDuration.asHours(),
      (refillPointDuration.asHours() - wiltingPointDuration.asHours())
      / 2 + wiltingPointDuration.asHours(),
      refillPointDuration.asHours(),
      fieldCapacityDuration.asHours(),
    ];
  }
  if (irrigationModel === 'etc_deficit') {
    return cutOffValues.map((d) => maxDurationHours * d);
  }
  return [irrigationDuration.asMinutes()];
}

export function getIrrigationStateColors(irrigationDuration, wiltingPointDuration,
  refillPointDuration, fieldCapacityDuration, irrigationModel) {
  if (!irrigationDuration) {
    return [colorUnknown];
  }
  if (wiltingPointDuration && refillPointDuration && fieldCapacityDuration
    && irrigationModel !== 'etc_deficit') {
    return [colorCritical, colorError, colorWarning, colorOk, colorOver];
  }
  if (irrigationModel === 'etc_deficit') {
    const irrigationRatios = [0.5, 0.75, 0.95, 1];
    const colors = irrigationRatios.map((d) => getIrrigationDialColor(d * 100));

    return [...colors, colorOver];
  }
  return [colorWarning, colorOk];
}

export function parseDurationStr(durationStr) {
  return durationStr ? moment.duration(durationStr) : null;
}
