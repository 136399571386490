import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { controlApi } from 'farmx-api';
import { actions } from 'farmx-redux-core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PressureGauge from '../control/ControlSummary/PressureGauge';

const { createOverride, endOverride } = controlApi;
const { loadSensorStatus } = actions;

const ValveItem = (props) => {
  const { valve } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    pressureData, overrideStatus, loading: valveLoading,
  } = valve;
  const {
    identifier: pressureIdentifier,
    pressureCutoff,
    pressure,
  } = pressureData ?? {
    pressureCutoff: [],
    pressure: '',
  };
  const valveIdentifier = valve?.identifier;
  const isOpen = !(overrideStatus && overrideStatus.state === 'closed');
  const valveOffClassNames = useMemo(() => (isOpen ? '' : 'selected'), [isOpen]);
  const valveOnClassNames = useMemo(() => (isOpen ? 'selected' : ''), [isOpen]);

  /**
   * If the new state is set display the success message
   * and fetch valve status
   */
  const processSuccess = (newState) => {
    const title = newState
      ? t('Valve set to follow schedule')
      : t('Created override');
    message.success(title);
    const sensorParams = { type: 'valve', identifier: valveIdentifier };
    dispatch(loadSensorStatus(sensorParams));
    setLoading(false);
  };

  /**
   * Toggle valve state
   * If new state is on then end override and follow the schedule
   * If new state is off then create override and set the state to closed.
   */
  const toggleValveState = async () => {
    // Exit if request is pending
    if (loading) return;

    setLoading(true);
    const newState = !isOpen;
    let overrideMethod = endOverride;
    const params = {
      sensorType: 'valve',
      sensorIdentifier: valve.identifier,
    };
    // Given that the new state is closed/off
    if (!newState) {
      params.state = 'closed';
      overrideMethod = createOverride;
    }
    try {
      const response = await overrideMethod(params);
      if (response && response.status === 200) {
        processSuccess(newState);
        return;
      }
      message.error(t('Failed to update'));
    } catch {
      message.error(t('Failed to update'));
    }
    setLoading(false);
  };

  /**
   * Load valve sensor status
   */
  useEffect(() => {
    if (valveIdentifier) {
      const sensorParams = { type: 'valve', identifier: valveIdentifier };
      dispatch(loadSensorStatus(sensorParams));
    }
  }, [dispatch, valveIdentifier]);

  /**
   * Load pressure sensor status associated with the valve
   */
  useEffect(() => {
    if (pressureIdentifier) {
      const sensorParams = { type: 'water_pressure', identifier: pressureIdentifier };
      dispatch(loadSensorStatus(sensorParams));
    }
  }, [dispatch, pressureIdentifier]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (valveIdentifier) {
        const sensorParams = { type: 'valve', identifier: valveIdentifier };
        dispatch(loadSensorStatus(sensorParams));
      }
      if (pressureIdentifier) {
        const sensorParams = { type: 'water_pressure', identifier: pressureIdentifier };
        dispatch(loadSensorStatus(sensorParams));
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [dispatch, valveIdentifier, pressureIdentifier]);

  return (
    <div className="farmhand-valve-item">
      {valve ? (
        <>
          <div className="flex-item">
            <div className="valve-status-container">
              <div className="valve-status-name">
                <Typography.Title level={4}>{valve.name || t('No Name')}</Typography.Title>
              </div>
              <span
                tabIndex={0}
                onKeyPress={() => { }}
                role="button"
                className="valve-status"
                onClick={toggleValveState}
              >
                <span className={`valve-status-item valve-status-on ${valveOnClassNames}`}>{t('ON')}</span>
                <span className={`valve-status-item valve-status-off ${valveOffClassNames}`}>{t('OFF')}</span>
              </span>
              {loading && <span className="spinner"><LoadingOutlined /></span>}
            </div>
          </div>
          <div className="flex-item pressure-gauge-container">
            <PressureGauge
              id={valve.id}
              pressure={pressure}
              pressureCutoff={pressureCutoff.toString()}
              height={140}
              width={140}
            />
          </div>
          <Typography.Title className="pressure-value flex-item" level={2}>{pressure}</Typography.Title>
        </>
      ) : (<Typography.Text>{t('Valve details not found')}</Typography.Text>)}
    </div>
  );
};

ValveItem.propTypes = {
  valve: PropTypes.shape({
    id: PropTypes.number,
    block: PropTypes.number,
    identifier: PropTypes.string,
    name: PropTypes.string,
    loading: PropTypes.bool,
    overrideStatus: PropTypes.object,
    pressureData: PropTypes.object,
  }).isRequired,
};

export default ValveItem;
