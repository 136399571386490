import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { Typography, Button } from 'antd';
import './PageHeader.less';

export function PageHeader({
  title,
  dataTestId,
  extra,
  toPath,
  showBack,
  state,
}) {
  const history = useHistory();
  const { location } = history;
  const { key, pathname } = location || {};

  const onBackButtonClick = () => {
    if (toPath) {
      history.push({
        pathname: toPath,
        state,
      });
    } else if (key) {
      history.goBack();
    } else {
      const path = (pathname || '').split('/')[1] || '';
      history.replace(`/${path}`);
    }
  };

  function renderBackButton() {
    if (!showBack) return null;
    return (
      <Button
        className="back-btn"
        shape="circle"
        size="large"
        icon={<FaChevronLeft size={20} />}
        onClick={onBackButtonClick}
      />
    );
  }

  return (
    <div className="page-header">
      <div className={`page-header-main ${showBack && 'back-header'}`}>
        {renderBackButton()}
        <Typography.Title level={1} datatest-id={dataTestId}>{title}</Typography.Title>
      </div>
      <div className="page-header-extra">
        {extra}
      </div>
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  toPath: PropTypes.string,
  dataTestId: PropTypes.string,
  extra: PropTypes.node,
  showBack: PropTypes.bool,
  // eslint-disable-next-line
  state: PropTypes.object,
};

PageHeader.defaultProps = {
  dataTestId: 'header-title',
  extra: null,
  toPath: null,
  showBack: false,
  state: {},
};
